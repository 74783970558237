import { subject } from '@casl/ability';

import { type Statement } from '@amalia/core/types';

import { SubjectsEnum } from '../../types';

export type StatementSubject = Pick<Statement, 'userId'> & {
  user?: Pick<Required<Statement>['user'], 'id'>;
  plan?: Pick<Required<Statement>['plan'], 'isHidden'>;
  period?: Pick<Required<Statement>['period'], 'endDate' | 'startDate'>;
};

export const statementSubject = (payload: StatementSubject) => subject(SubjectsEnum.Statement, payload);

export type ReviewStatementThreadSubject = {
  statement: StatementSubject;
  isReviewed: boolean;
};

export const reviewStatementThreadSubject = (payload: ReviewStatementThreadSubject) =>
  subject(SubjectsEnum.Statement, payload);
