import {
  Icon123,
  IconCalendar,
  IconCurrencyDollar,
  IconLetterCase,
  IconPercentage,
  IconTable,
  IconUser,
} from '@tabler/icons-react';

import { IconTokenBoolean } from '@amalia/amalia-lang/tokens/components';
import { FormatsEnum, PropertyRef } from '@amalia/data-capture/fields/types';
import { type TablerIconElement } from '@amalia/design-system/components';

export const formatToIcon: Record<FormatsEnum, TablerIconElement> = {
  [FormatsEnum.number]: <Icon123 />,
  [FormatsEnum.currency]: <IconCurrencyDollar />,
  [FormatsEnum.percent]: <IconPercentage />,
  [FormatsEnum.date]: <IconCalendar />,
  [FormatsEnum['date-time']]: <IconCalendar />,
  [FormatsEnum.table]: <IconTable />,
  [FormatsEnum.text]: <IconLetterCase />,
  [FormatsEnum.boolean]: <IconTokenBoolean />,
} as const;

export const propertyRefToIcon: Record<PropertyRef, TablerIconElement> = {
  [PropertyRef.USER]: <IconUser />,
} as const;
