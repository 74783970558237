import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { linearAnimation } from './LinearLoader.styles';

export type LinearLoaderProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Height of the loader. */
      readonly size?: number;
    },
  ]
>;

export const LinearLoader = forwardRef(function LinearLoader(
  { size = 4, ...props }: LinearLoaderProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      css={(theme) => css`
        height: ${size}px;
        width: 100%;

        background-color: ${theme.ds.colors.primary[100]};

        overflow: hidden;
      `}
    >
      <div
        css={(theme) => css`
          height: 100%;
          width: 100%;

          background-color: ${theme.ds.colors.primary[400]};

          animation: ${linearAnimation} 1s linear infinite;

          transform-origin: 0 50%;
        `}
      />
    </div>
  );
});
