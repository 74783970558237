import { type TeamContract } from '@amalia/tenants/teams/types';

export const teamsQueryKeys = {
  all: () => ['teams'] as const,
  list: () => [...teamsQueryKeys.all(), 'list'] as const,
  team: (teamId: TeamContract['id']) => [...teamsQueryKeys.all(), teamId] as const,
} as const;

export const teamsMutationKeys = {
  all: () => ['teams'] as const,
  create: () => [...teamsMutationKeys.all(), 'create'] as const,
  setTeamName: () => [...teamsMutationKeys.all(), 'set-team-name'] as const,
  linkExistingTeams: () => [...teamsMutationKeys.all(), 'link-existing-teams'] as const,
  unlinkFromParentTeam: () => [...teamsMutationKeys.all(), 'unlink-from-parent-team'] as const,
  archive: () => [...teamsMutationKeys.all(), 'archive'] as const,
  unarchive: () => [...teamsMutationKeys.all(), 'unarchive'] as const,
  update: () => [...teamsMutationKeys.all(), 'update'] as const,
  delete: () => [...teamsMutationKeys.all(), 'delete'] as const,
} as const;
