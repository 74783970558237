import { type Quota } from '@amalia/amalia-lang/tokens/types';
import { type SearchQuotasWithAssignedPlansRequest } from '@amalia/assignments/quotas/types';
import { type UnixTimestampInSeconds } from '@amalia/ext/dates';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

export const quotasQueryKeys = {
  all: () => ['quotas'] as const,
  withAssignedPlans: {
    all: () => [...quotasQueryKeys.all(), 'with-assigned-plans'] as const,
    list: (request?: SearchQuotasWithAssignedPlansRequest) =>
      [...quotasQueryKeys.withAssignedPlans.all(), 'list', request] as const,
    details: (quotaId: Quota['id']) => [...quotasQueryKeys.withAssignedPlans.all(), 'details', quotaId] as const,
  } as const,
} as const;

export const quotaAssignmentsQueryKeys = {
  all: () => ['quota-assignments'] as const,
  ofVariable: {
    all: (variableId: Quota['id']) => [...quotaAssignmentsQueryKeys.all(), 'of-variable', variableId] as const,
    list: (
      variableId: Quota['id'],
      startDate: UnixTimestampInSeconds,
      endDate: UnixTimestampInSeconds,
      planIds?: Plan['id'][],
    ) => [...quotaAssignmentsQueryKeys.ofVariable.all(variableId), startDate, endDate, planIds] as const,
  } as const,
  ofUser: {
    all: (userId: UserContract['id']) => [...quotaAssignmentsQueryKeys.all(), 'of-user', userId] as const,
    list: (userId: UserContract['id'], startDate?: Date | null, endDate?: Date | null) =>
      [...quotaAssignmentsQueryKeys.ofUser.all(userId), startDate, endDate] as const,
  },
} as const;

export const quotaAssignmentsMutationKeys = {
  all: () => ['quota-assignments'] as const,
  upsertOne: () => [...quotaAssignmentsMutationKeys.all(), 'upsert-one'] as const,
  upsertMany: () => [...quotaAssignmentsMutationKeys.all(), 'upsert-many'] as const,
  deleteOne: () => [...quotaAssignmentsMutationKeys.all(), 'delete-one'] as const,
  deleteMany: () => [...quotaAssignmentsMutationKeys.all(), 'delete-many'] as const,
} as const;
