import styled from '@emotion/styled';

export const SideMenuPlaceholder = styled.div`
  ${({ theme }) => theme.ds.typographies.bodySmallRegular};

  padding: 4px 8px;
  min-height: 30px;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.ds.colors.gray[600]};
`;
