import { cloneDeep } from 'lodash';

import { type CustomObject, type DatasetRow } from '@amalia/core/types';
import { type RecordContent } from '@amalia/data-capture/connectors/types';
import { FormatsEnum, type Property } from '@amalia/data-capture/fields/types';
import { type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type DatasetRowContent } from '@amalia/payout-calculation/types';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { convertDateToTimestamp } from './dates';

export class RowConverterUtils {
  /**
   * Convert row content dates and currencies according to definition, so that they can be used for calculation :
   * - currency fields are converted to their value
   * - date fields are converted to timestamps
   * @param currentObjectMachineName
   * @param content
   * @param relationshipsMap
   * @param customObjectDefinitionsMap
   */
  public static convertRowDatesAndCurrencies(
    currentObjectMachineName: string,
    content: DatasetRowContent,
    relationshipsMap: Record<string, Relationship>,
    customObjectDefinitionsMap: Record<string, BaseCustomObjectDefinition>,
  ): DatasetRowContent {
    const customObjectDefinition = customObjectDefinitionsMap[currentObjectMachineName];
    const properties = customObjectDefinition.properties;
    const convertedContent = cloneDeep(content);

    Object.keys(properties).forEach((key) => {
      const prop = properties[key] as Property;

      if (prop.format === FormatsEnum.date || prop.format === FormatsEnum['date-time']) {
        // If property is a variable date => converts to timestamp.
        convertedContent[key] = null;
        if (content[key]) {
          convertedContent[key] =
            typeof content[key] === 'string' ? convertDateToTimestamp(content[key]) : content[key];
        }
      } else if (prop.format === FormatsEnum.currency) {
        convertedContent[key] = this.getCurrencyValue(content[key]);
      }
    });

    Object.keys(convertedContent).forEach((key) => {
      if (convertedContent[key]) {
        if (key in relationshipsMap) {
          const relation = relationshipsMap[key];

          // 1 - search if property is a relation => if so, run convertRowDatesAndCurrencies recursively according to relation definition.
          const level = content[key];
          if (Array.isArray(level)) {
            convertedContent[key] = level.map((contentItem) =>
              RowConverterUtils.convertRowDatesAndCurrencies(
                relation.toDefinitionMachineName,
                contentItem,
                relationshipsMap,
                customObjectDefinitionsMap,
              ),
            );
          } else {
            const currentValue = convertedContent[key] as DatasetRowContent;
            convertedContent[key] = RowConverterUtils.convertRowDatesAndCurrencies(
              relation.toDefinitionMachineName,
              currentValue,
              relationshipsMap,
              customObjectDefinitionsMap,
            );
          }
        } else if (isCurrencyValue(convertedContent[key])) {
          // 2 - search and convert variable object
          // If it's a variable currency => converts to its value.
          convertedContent[key] = this.getCurrencyValue(convertedContent[key]);
        }
      }
    });

    return convertedContent as RecordContent;
  }

  /**
   * Get a row content (row can be a filter row with a content property or
   * a relation : in case of a relation row there is no content property,
   * content is the row object itself).
   * @param row
   */
  public static getRowContent(row: CustomObject | DatasetRow | DatasetRowContent): DatasetRowContent {
    return 'content' in row ? (row.content as DatasetRowContent) : row;
  }

  private static getCurrencyValue(currencyValue: unknown): number | null {
    if (currencyValue === null || currencyValue === undefined) {
      return null;
    }

    return isCurrencyValue(currencyValue) ? currencyValue.value : (currencyValue as number);
  }
}
