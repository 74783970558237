import { IconArrowDown, IconArrowUp, IconEqual, IconEqualNot } from '@tabler/icons-react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { forwardRef, Fragment, memo, type ComponentPropsWithoutRef, type ForwardedRef } from 'react';
import { FormattedNumber } from 'react-intl';
import { match } from 'ts-pattern';

import { TypographyVariant } from '@amalia/design-system/meta';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconComponent } from '../../general/icons/types';
import { Typography } from '../../general/typography/Typography';

import * as styles from './Difference.styles';
import { DifferenceDirection, DifferenceSize } from './Difference.types';

const RATIO_CLASSNAME_MAPPING: Record<DifferenceDirection, string> = {
  [DifferenceDirection.DOWN]: styles.DOWN_CLASSNAME,
  [DifferenceDirection.UP]: styles.UP_CLASSNAME,
};

const RATIO_ICON_MAPPING: Record<DifferenceDirection, TablerIconComponent> = {
  [DifferenceDirection.DOWN]: IconArrowDown,
  [DifferenceDirection.UP]: IconArrowUp,
};

const SIZE_ICON_SIZE_MAPPING: Record<DifferenceSize, number> = {
  [DifferenceSize.SMALL]: 10,
  [DifferenceSize.MEDIUM]: 12,
} as const;

const SIZE_TYPOGRAPHY_MAPPING: Record<DifferenceSize, TypographyVariant> = {
  [DifferenceSize.SMALL]: TypographyVariant.BODY_SMALL_REGULAR,
  [DifferenceSize.MEDIUM]: TypographyVariant.BODY_BASE_REGULAR,
} as const;

export enum DifferenceSpecialFormat {
  currency = 'currency',
  percent = 'percent',
}

export type DifferenceProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Current value. */
      currentValue: boolean | number | string | null;
      /** Value to compare current value to. */
      previousValue: boolean | number | string | null;
      /** Difference size. */
      size?: DifferenceSize;
      /** Format of the value. */
      tokenFormat: DifferenceSpecialFormat | string;
      /** currencySymbol indicates if the value is a currency and the currencySymbol */
      currencySymbol?: CurrencySymbolsEnum | null;
    },
  ]
>;

const DifferenceForwardRef = forwardRef(function Difference(
  { previousValue, currentValue, size = DifferenceSize.SMALL, tokenFormat, currencySymbol, ...props }: DifferenceProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Fragment>
      {match([previousValue, currentValue, currencySymbol, tokenFormat])
        // number, currency or percentage and difference between values
        .when(
          ([previousValue, currentValue, currencySymbol, tokenFormat]) =>
            currencySymbol !== null &&
            Number.isFinite(previousValue) &&
            Number.isFinite(currentValue) &&
            previousValue !== currentValue &&
            tokenFormat !== 'date' &&
            tokenFormat !== 'date-time',
          ([previousValue, currentValue]: [
            number,
            number,
            CurrencySymbolsEnum | undefined,
            DifferenceSpecialFormat | string,
          ]) => {
            const difference = currentValue - previousValue;

            const differenceDirection: DifferenceDirection =
              difference > 0 ? DifferenceDirection.UP : DifferenceDirection.DOWN;

            const Icon = RATIO_ICON_MAPPING[differenceDirection];

            return (
              <div
                {...props}
                ref={ref}
                className={clsx(RATIO_CLASSNAME_MAPPING[differenceDirection], size)}
                css={styles.difference}
              >
                <Icon size={SIZE_ICON_SIZE_MAPPING[size]} />

                <Typography variant={SIZE_TYPOGRAPHY_MAPPING[size]}>
                  <FormattedNumber
                    currency={currencySymbol ?? undefined}
                    maximumFractionDigits={2}
                    minimumFractionDigits={0}
                    signDisplay="never"
                    value={difference}
                    style={
                      tokenFormat === DifferenceSpecialFormat.percent
                        ? 'percent'
                        : tokenFormat === DifferenceSpecialFormat.currency
                          ? 'currency'
                          : undefined
                    }
                  />
                </Typography>
              </div>
            );
          },
        )
        // equal values
        .when(
          ([previousValue, currentValue]) => isEqual(previousValue, currentValue),
          () => (
            <div
              {...props}
              ref={ref}
              className={clsx(styles.EQUAL_CLASSNAME, size)}
              css={styles.differenceIconOnly}
              data-testid="difference-equal"
            >
              <IconEqual size={SIZE_ICON_SIZE_MAPPING[size]} />
            </div>
          ),
        )
        // non equal values
        .when(
          ([previousValue, currentValue]) => !isEqual(previousValue, currentValue),
          () => (
            <div
              {...props}
              ref={ref}
              className={clsx(styles.NOT_EQUAL_CLASSNAME, size)}
              css={styles.differenceIconOnly}
              data-testid="difference-not-equal"
            >
              <IconEqualNot size={SIZE_ICON_SIZE_MAPPING[size]} />
            </div>
          ),
        )
        .otherwise(() => null)}
    </Fragment>
  );
});

export const Difference = Object.assign(memo(DifferenceForwardRef), {
  Size: DifferenceSize,
});
