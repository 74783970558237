import { isNil } from 'lodash';
import { type ReactElement, forwardRef, memo, type ForwardedRef, isValidElement } from 'react';
// eslint-disable-next-line no-restricted-imports -- Allowed here only.
import { Link as ReactRouterLink, type LinkProps as ReactRouterLinkProps } from 'react-router-dom';

import { type MergeAll } from '@amalia/ext/typescript';

import * as styles from './Link.styles';

export type LinkProps = MergeAll<
  [
    ReactRouterLinkProps,
    {
      // Make `to` optional.
      readonly to?: ReactRouterLinkProps['to'];
      /** Should render a `<a>` element without `href`. */
      readonly disabled?: boolean;
      /** Unset `<a>` element styles. */
      readonly unsetStyle?: boolean;
      /** Should open in a new tab. Defaults to `true` the target location is external. */
      readonly openInNewTab?: boolean;
    },
  ]
>;

const OPEN_IN_NEW_TAB_PROPS = {
  rel: 'noopener noreferrer',
  target: '_blank',
} as const;

const LinkForwardRef = forwardRef(function Link(
  { to, children, disabled = false, openInNewTab = undefined, unsetStyle = false, ...props }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  // By default, open absolute links in a new tab. Allow to override via the `openInNewTab` prop.
  const shouldOpenInNewTab = openInNewTab ?? (typeof to === 'string' && to.startsWith('http'));

  return !isNil(to) && !disabled ? (
    <ReactRouterLink
      {...props}
      {...(shouldOpenInNewTab ? OPEN_IN_NEW_TAB_PROPS : {})}
      ref={ref}
      css={!!unsetStyle && styles.linkUnset}
      to={to}
    >
      {children}
    </ReactRouterLink>
  ) : (
    // Render a regular `<a>` without href if `to` is not provided or if `disabled` is `true`.
    // react-router doesn't allow passing undefined to `to` prop.
    <a
      {...props}
      ref={ref}
      data-disabled
      css={!!unsetStyle && styles.linkUnset}
    >
      {children}
    </a>
  );
});

export const Link = memo(LinkForwardRef);

type LinkElementProps<TAllowChildren extends boolean = false> = (TAllowChildren extends true
  ? LinkProps
  : Omit<LinkProps, 'children'>) & { ref?: ForwardedRef<HTMLAnchorElement> };

export type LinkElement<TAllowChildren extends boolean = false> = ReactElement<LinkElementProps<TAllowChildren>>;
export type ToOrLinkElement<TAllowChildren extends boolean = false> = LinkElement<TAllowChildren> | LinkProps['to'];

export const isLinkElement = <TAllowChildren extends boolean = false>(
  to: ToOrLinkElement<TAllowChildren>,
): to is LinkElement<TAllowChildren> => isValidElement<LinkElementProps<TAllowChildren>>(to);

export const toLinkElement = <TAllowChildren extends boolean = false>(
  to: ToOrLinkElement<TAllowChildren>,
): LinkElement<TAllowChildren> => (isLinkElement<TAllowChildren>(to) ? to : <Link to={to} />);
