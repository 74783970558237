export enum DateTypeCondition {
  MANUAL = 'Manual Input',
  TIME_BASED = 'Today By',
}

export interface FilterType {
  property: string;
  // Joins where the field can be selected.
  joins?: string[];
  condition: ConditionsEnum;
  // Not (invert the operator condition).
  not?: boolean;
  value?: boolean | number | string | null;
  values?: string[];

  // Date Time Type Condition: Manual or Time Based
  dateTypeCondition?: DateTypeCondition;
}

export interface FilterOrsType {
  ors: FilterType[];
}

export type OperandType = 'AND' | 'OR';

export interface FiltersType {
  operand?: OperandType;
  conditions: FilterType[];
}
export enum ConditionsEnum {
  'textContains' = 'textContains',
  'textDoesNotContain' = 'textDoesNotContain',
  'textExactlyMatches' = 'textExactlyMatches',
  'textDoesNotExactlyMatch' = 'textDoesNotExactlyMatch',
  'textIsIn' = 'textIsIn',
  'textIsNotIn' = 'textIsNotIn',
  'textStartsWith' = 'textStartsWith',
  'textDoesNotStartWith' = 'textDoesNotStartWith',
  'textEndsWith' = 'textEndsWith',
  'textDoesNotEndWith' = 'textDoesNotEndWith',
  'numberGreaterThan' = 'numberGreaterThan',
  'numberGreaterThanOrEqual' = 'numberGreaterThanOrEqual',
  'numberLessThan' = 'numberLessThan',
  'numberLessThanOrEqual' = 'numberLessThanOrEqual',
  'numberEquals' = 'numberEquals',
  'numberNotEquals' = 'numberNotEquals',
  'dateTimeAfter' = 'dateTimeAfter',
  'dateTimeAfterOrEqual' = 'dateTimeAfterOrEqual',
  'dateTimeBefore' = 'dateTimeBefore',
  'dateTimeBeforeOrEqual' = 'dateTimeBeforeOrEqual',
  'dateTimeEquals' = 'dateTimeEquals',
  'booleanIsTrue' = 'booleanIsTrue',
  'booleanIsFalse' = 'booleanIsFalse',
  'exists' = 'exists',
  'doesNotExist' = 'doesNotExist',
}

export const ConditionsWithoutValue = [
  ConditionsEnum.booleanIsTrue,
  ConditionsEnum.booleanIsFalse,
  ConditionsEnum.exists,
  ConditionsEnum.doesNotExist,
];
