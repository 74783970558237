import { ActionsEnum, SubjectsEnum, type UserRoleForAccessControl, type DefinePermissions } from '../../types';

import { type DeleteCommentThreadMessageSubject, type ModifyCommentThreadMessageSubject } from './subjects';

export const commentThreadMessagesAbilityDefinitions = {
  ADMIN({ user }, { can }) {
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: DeleteCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: ModifyCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );
  },

  READ_ONLY_ADMIN({ user }, { can }) {
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: DeleteCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: ModifyCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );
  },

  MANAGER({ user }, { can }) {
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: DeleteCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: ModifyCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );
  },

  READ_ONLY_MANAGER({ user }, { can }) {
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: DeleteCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: ModifyCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );
  },

  EMPLOYEE({ user }, { can }) {
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: DeleteCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: ModifyCommentThreadMessageSubject) => statementComment.authorId === user.id,
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
