import { type VariableType, type Variable } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

export const variablesQueryKeys = {
  all: () => ['variables'] as const,
  allLists: () => [...variablesQueryKeys.all(), 'lists'] as const,
  list: (filters?: { types?: VariableType[]; userIds?: UserContract['id'][]; planIds?: Plan['id'][] }) =>
    [...variablesQueryKeys.allLists(), filters] as const,
  listByCustomObjectDefinition: (customObjectDefinitionId: CustomObjectDefinition['id']) =>
    [...variablesQueryKeys.allLists(), 'by-custom-object-definition', customObjectDefinitionId] as const,
  details: (variableId: Variable['id']) => [...variablesQueryKeys.all(), 'details', variableId] as const,
} as const;

export const variablesMutationKeys = {
  all: () => ['variables'] as const,
  create: () => [...variablesMutationKeys.all(), 'create'] as const,
  patch: () => [...variablesMutationKeys.all(), 'patch'] as const,
  delete: () => [...variablesMutationKeys.all(), 'delete'] as const,
} as const;
