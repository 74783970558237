import { type DataConnector } from '@amalia/data-capture/connectors/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

export const dataImportsQueryKeys = {
  all: () => ['data-imports'] as const,
  lastConnectorImport: (
    connectorId: DataConnector['id'],
    objectDefinitionName: CustomObjectDefinition['machineName'],
  ) => [...dataImportsQueryKeys.all(), 'last-connector-import', connectorId, objectDefinitionName] as const,
  lastRecordModelsImport: () => [...dataImportsQueryKeys.all(), 'last-record-models-import'] as const,
} as const;
