import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { quotaAssignmentsQueryKeys } from '@amalia/assignments/quotas/state';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { TeamAssignmentsApiClient } from '@amalia/tenants/assignments/teams/api-client';
import { type ImportedTeamAssignmentsRows } from '@amalia/tenants/assignments/teams/types';

import { teamAssignmentsMutationKeys, teamAssignmentsQueryKeys } from './queries.keys';

export const useTeamsAssignments = <TRelations extends ('team' | 'user')[] = []>(
  filters: Parameters<typeof TeamAssignmentsApiClient.getTeamAssignments<TRelations>>[0],
) => {
  const { snackError } = useSnackbars();

  const query = useQuery({
    queryKey: teamAssignmentsQueryKeys.teams(filters),
    queryFn: () => TeamAssignmentsApiClient.getTeamAssignments<TRelations>(filters),
    enabled: filters.teamsIds.length > 0,
  });

  useEffect(() => {
    if (query.isError) {
      snackError(toError(query.error).message);
    }
  }, [query.isError, query.error, snackError]);

  return query;
};

export const useCreateTeamAssignments = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: teamAssignmentsMutationKeys.create(),
    mutationFn: TeamAssignmentsApiClient.createTeamAssignments,
    onSuccess: async (_, assignmentsToCreate) => {
      snackSuccess(
        formatMessage(
          { defaultMessage: '{count, plural, one {Assignment} other {# assignments}} created successfully.' },
          { count: assignmentsToCreate.length },
        ),
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: teamAssignmentsQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: quotaAssignmentsQueryKeys.all() }),
      ]);
    },
    onError: (err, assignmentsToCreate) => {
      snackError(
        formatMessage(
          { defaultMessage: '{count, plural, one {Assignment} other {# assignments}} could not be created: {error}.' },
          { count: assignmentsToCreate.length, error: toError(err).message },
        ),
      );
    },
  });
};

export const useUpdateTeamAssignments = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: teamAssignmentsMutationKeys.update(),
    mutationFn: TeamAssignmentsApiClient.updateTeamAssignments,
    onSuccess: async (_, assignmentsToUpdate) => {
      snackSuccess(
        formatMessage(
          {
            defaultMessage: '{assignmentsCount, plural, one {Assignment} other {# assignments}} updated successfully.',
          },
          { assignmentsCount: assignmentsToUpdate.length },
        ),
      );
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: teamAssignmentsQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: quotaAssignmentsQueryKeys.all() }),
      ]);
    },
    onError: (err, assignmentsToUpdate) => {
      snackError(
        formatMessage(
          {
            defaultMessage:
              '{assignmentsCount, plural, one {Assignment} other {# assignments}} could not be updated: {errorMessage}.',
          },
          { errorMessage: toError(err).message, assignmentsCount: assignmentsToUpdate.length },
        ),
      );
    },
  });
};

export const useDeleteTeamAssignments = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: teamAssignmentsMutationKeys.delete(),
    mutationFn: TeamAssignmentsApiClient.removeTeamAssignments,
    onSuccess: async (_, assignmentsToDelete) => {
      snackSuccess(
        formatMessage(
          {
            defaultMessage: '{assignmentsCount, plural, one {Assignment} other {# assignments}} deleted successfully.',
          },
          { assignmentsCount: assignmentsToDelete.length },
        ),
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: teamAssignmentsQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: quotaAssignmentsQueryKeys.all() }),
      ]);
    },
    onError: (err, assignmentsToDelete) => {
      snackError(
        formatMessage(
          {
            defaultMessage:
              '{assignmentsCount, plural, one {Assignment} other {# assignments}} could not be deleted: {errorMessage}.',
          },
          { errorMessage: toError(err).message, assignmentsCount: assignmentsToDelete.length },
        ),
      );
    },
  });
};

export const useValidateBulkImportTeamAssignments = () => {
  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: teamAssignmentsMutationKeys.validateBulkImport(),
    mutationFn: TeamAssignmentsApiClient.importBulkTeamAssignments,
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useConfirmBulkImportTeamAssignments = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: teamAssignmentsMutationKeys.confirmBulkImport(),
    mutationFn: (rows: ImportedTeamAssignmentsRows) => TeamAssignmentsApiClient.importBulkTeamAssignments(rows, false),
    onError: (e) => {
      snackError(toError(e).message);
    },
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Teams assignments updated.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: teamAssignmentsQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: quotaAssignmentsQueryKeys.all() }),
      ]);
    },
  });
};
