import { css, type Theme } from '@emotion/react';

export const IS_DISABLED_CLASSNAME = 'is-disabled';
export const IS_FOCUSED_CLASSNAME = 'is-focused';
export const HAS_ERROR_CLASSNAME = 'has-error';

export const cellSelectControl = (theme: Theme) => css`
  position: relative;
  transition: ${theme.ds.transitions.default('background-color')};

  &:not(.${IS_DISABLED_CLASSNAME}) {
    &:hover,
    &.${IS_FOCUSED_CLASSNAME} {
      outline: 1px solid ${theme.ds.colors.primary[200]};

      // Make sure that the outline is above an eventual sticky cell.
      position: relative;
      z-index: 1;

      &.${HAS_ERROR_CLASSNAME} {
        outline-color: ${theme.ds.colors.danger[500]};
      }
    }

    &.${IS_FOCUSED_CLASSNAME} {
      z-index: 1;
      background-color: ${theme.ds.colors.gray[0]};
    }
  }
`;

export const buttonContainer = css`
  max-width: 100%;
  display: block;
  width: 100%;
`;

export const dataCellContent = (hasError: boolean, shouldShowClear: boolean) => css`
  /* Add space for error icon, clear button, chevron and gaps between elements. They are positioned in absolute to avoid button in button. */
  padding: 0 ${(hasError ? 18 + 6 : 0) + (shouldShowClear ? 19.2 + 6 : 0) + 19.2 + 16 + 6}px 0 16px;

  /* Note: I'm not sure how we'll implement overflow with custom components, so let's just add a overflow: hidden here for safety. */
  overflow: hidden;
`;

export const placeholder = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[400]};
  transition: ${theme.ds.transitions.default('color')};

  .${IS_DISABLED_CLASSNAME} & {
    color: ${theme.ds.colors.gray[300]};
  }
`;

export const indicatorsContainer = css`
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  top: 50%;
  transform: translateY(-50%);
`;
