import { css, type Theme } from '@emotion/react';

import { TrendSize } from './Trend.types';

export const UP_CLASSNAME = 'up';
export const DOWN_CLASSNAME = 'down';
export const FLAT_CLASSNAME = 'flat';

export const trend = (theme: Theme) => css`
  display: inline-flex;
  align-items: center;
  gap: 1px;

  border-radius: ${theme.ds.borderRadiuses.squared};

  color: ${theme.ds.colors.gray[900]};

  > svg {
    flex: none;
  }

  &.${TrendSize.SMALL} {
    padding: 2px 6px;
    padding-left: 4px;
    height: 20px;
  }

  &.${TrendSize.MEDIUM} {
    padding: 2px 8px;
    padding-left: 6px;
    height: 20px;
  }

  &.${UP_CLASSNAME} {
    background-color: ${theme.ds.colors.success[50]};

    > svg {
      color: ${theme.ds.colors.success[500]};
    }
  }

  &.${DOWN_CLASSNAME} {
    background-color: ${theme.ds.colors.danger[50]};

    > svg {
      color: ${theme.ds.colors.danger[500]};
    }
  }

  &.${FLAT_CLASSNAME} {
    background-color: ${theme.ds.colors.gray[50]};

    > svg {
      color: ${theme.ds.colors.gray[900]};
    }
  }
`;
