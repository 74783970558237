import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const planAssignmentsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan_Assignment);
    can(ActionsEnum.view_list, SubjectsEnum.Plan_Assignment);
    can(ActionsEnum.modify, SubjectsEnum.Plan_Assignment);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan_Assignment);
    can(ActionsEnum.view_list, SubjectsEnum.Plan_Assignment);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
