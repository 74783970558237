import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { isConflictError } from '@amalia/core/http/client';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { useDuplicatePlan } from '@amalia/payout-definition/state';

export type DuplicatePlanFormValues = {
  name: string;
  shouldImportAssignments: boolean;
  assignmentsEffectiveAsOf: number | null;
  assignmentsEffectiveUntil: number | null;
};

type DuplicatePlanFormikProps = FormikFormProps<DuplicatePlanFormValues, Plan>;

export type DuplicatePlanFormProps = Omit<
  DuplicatePlanFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly plan: Pick<Plan, 'id' | 'name'>;
};

export const DuplicatePlanForm = memo(function DuplicatePlanForm({ plan, children, ...props }: DuplicatePlanFormProps) {
  const { formatMessage } = useIntl();
  const { mutateAsync: duplicatePlan } = useDuplicatePlan();

  const initialValues = useMemo(
    (): DuplicatePlanFormValues => ({
      name: formatMessage({ defaultMessage: '{planName} (copy)' }, { planName: plan.name }),
      shouldImportAssignments: false,
      assignmentsEffectiveAsOf: null,
      assignmentsEffectiveUntil: null,
    }),
    [plan.name, formatMessage],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().min(2).max(100).required(),
        shouldImportAssignments: Yup.boolean(),
        assignmentsEffectiveAsOf: Yup.number()
          .nullable()
          .when('shouldImportAssignments', {
            is: true,
            then: (schema) => schema.nullable(),
          }),
        assignmentsEffectiveUntil: Yup.number()
          .nullable()
          .when('shouldImportAssignments', {
            is: (value: boolean) => value === true,
            then: (schema) => schema.nullable(),
          }),
      }),
    [],
  );

  const handleSubmit: DuplicatePlanFormikProps['onSubmit'] = useCallback(
    async (values, { setFieldError }) => {
      try {
        return await duplicatePlan({
          planId: plan.id,
          newPlanName: values.name,
          shouldImportAssignments: values.shouldImportAssignments,
          assignmentsEffectiveAsOf: values.assignmentsEffectiveAsOf,
          assignmentsEffectiveUntil: values.assignmentsEffectiveUntil,
        });
      } catch (err) {
        if (isConflictError(err)) {
          setFieldError('name', formatMessage({ defaultMessage: 'A plan with this name already exists.' }));
        }

        // Don't forget to throw the error again so `onSubmitSuccess` is not called
        throw err;
      }
    },
    [duplicatePlan, formatMessage, plan.id],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
