import { type SetRequired } from 'type-fest';

import { http } from '@amalia/core/http/client';
import {
  type ImportedTeamAssignmentsRows,
  type TeamAssignment,
  type TeamAssignmentsValidateResponse,
  type TeamRole,
  type CreateTeamAssignmentRequest,
  type CreateTeamAssignmentsRequest,
  type DeleteTeamAssignmentsRequest,
  type UpdateTeamAssignmentsRequest,
  type UpdateTeamAssignmentRequest,
} from '@amalia/tenants/assignments/teams/types';

export class TeamAssignmentsApiClient {
  public static async getTeamAssignments<TRelations extends ('team' | 'user')[] = []>({
    teamsIds,
    teamRole,
    relations = [] as unknown as TRelations,
  }: {
    teamsIds: string[];
    teamRole?: TeamRole;
    relations?: TRelations;
  }): Promise<SetRequired<TeamAssignment, TRelations[number]>[]> {
    const { data } = await http.get<SetRequired<TeamAssignment, TRelations[number]>[]>('/team_assignments', {
      params: {
        teamId: teamsIds,
        teamRole: teamRole || undefined,
        relations: relations.join(',') || undefined,
      },
    });

    return data;
  }

  public static async createTeamAssignments(
    createTeamAssignmentRequests: CreateTeamAssignmentRequest[],
  ): Promise<TeamAssignment[]> {
    const { data } = await http.post<TeamAssignment[]>('/team_assignments', {
      teamAssignments: createTeamAssignmentRequests,
    } satisfies CreateTeamAssignmentsRequest);

    return data;
  }

  public static async createTeamAssignment(
    createTeamAssignmentRequest: CreateTeamAssignmentRequest,
  ): Promise<TeamAssignment> {
    return (await this.createTeamAssignments([createTeamAssignmentRequest]))[0];
  }

  public static async importBulkTeamAssignments(
    rowsToImport: ImportedTeamAssignmentsRows,
    dryRun: boolean = true,
  ): Promise<TeamAssignmentsValidateResponse> {
    const { data } = await http.post<TeamAssignmentsValidateResponse>('/team_assignments/bulk-import', {
      rowsToImport,
      dryRun,
    });
    return data;
  }

  public static async updateTeamAssignments(
    updateTeamAssignmentRequests: UpdateTeamAssignmentRequest[],
  ): Promise<TeamAssignment[]> {
    const { data } = await http.patch<TeamAssignment[]>('/team_assignments', {
      teamAssignments: updateTeamAssignmentRequests,
    } satisfies UpdateTeamAssignmentsRequest);

    return data;
  }

  public static async removeTeamAssignments(teamAssignmentIds: TeamAssignment['id'][]) {
    await http.delete('/team_assignments', { data: { teamAssignmentIds } satisfies DeleteTeamAssignmentsRequest });
  }
}
