import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const datesToDate = new AmaliaFunctionDefault<[string, string?], number>({
  name: AmaliaFunctionKeys.toDate,
  category: AmaliaFunctionCategory.DATES,
  nbParamsRequired: 1,
  description: 'Convert any date format to a timestamp',

  // A format can be specified if the date is not formatted like `YYYY-MM-DD`. Format allows using a single digit for months and days.
  // The date will be formatted by dayjs (see documentation here https://momentjs.com/docs/#/parsing/string-format/ or examples below).
  exec: (dateString, format) => dayjs(dateString, format || 'YYYY-M-D').unix(),

  params: [
    {
      name: 'dateString',
      description: 'Date in any format: variables, properties, fields, keywords or a specific date in defined format',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD],
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
    {
      name: 'format',
      description: 'Format of the dateString that is by default YYYY-MM-DD',
      defaultValue: 'YYYY-MM-DD',
    },
  ],

  examples: [
    {
      desc: 'Returns 1685577600.',
      formula: 'toDate("2023-06-01")' as AmaliaFormula,
      result: 1_685_577_600,
    },
    {
      desc: 'Returns 1685577600.',
      formula: 'toDate("2023/06/01", "YYYY/MM/DD")' as AmaliaFormula,
      result: 1_685_577_600,
    },
    {
      desc: 'Returns 1685577600 for June 1st 2023.',
      formula: 'toDate("Jun 23", "MMM YY")' as AmaliaFormula,
      result: 1_685_577_600,
    },
    {
      desc: 'Returns Close Date in timestamp converted from a MMM YY format.',
      formula: 'toDate(opportunity.closeDate, "MMM YY")',
    },
  ],
});
