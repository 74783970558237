import { IconBell } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, Popover } from '@amalia/design-system/components';
import { dayjs } from '@amalia/ext/dayjs';
import { useBoolState, useUpdateEffect } from '@amalia/ext/react/hooks';
import { useAuthenticatedContext } from '@amalia/kernel/auth/state';
import {
  notificationsQueryKeys,
  useInfiniteNotifications,
  useMarkNotificationsAsRead,
  useUnreadNotificationsCount,
  useUsersMap,
} from '@amalia/tenants/users/state';

import { MarkAllAsReadConfirmationModal } from '../mark-all-as-read-confirmation-modal/MarkAllAsReadConfirmationModal';
import { NotificationsPopover } from '../notifications-popover/NotificationsPopover';

import * as styles from './NotificationsWidget.styles';

export const NotificationsWidget = memo(function NotificationsWidget() {
  const { formatMessage } = useIntl();

  const { authenticatedContext } = useAuthenticatedContext();
  const { data: notifications, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteNotifications();
  const { data: unreadCount } = useUnreadNotificationsCount();
  const { mutateAsync: markAsRead, isPending: isMarkAllAsReadLoading } = useMarkNotificationsAsRead();
  const usersMap = useUsersMap(notifications.map((notification) => notification.authorId).filter(Boolean));

  // Invalidate notifications cache when unread count changes.
  const queryClient = useQueryClient();
  useUpdateEffect(() => {
    queryClient.invalidateQueries({ queryKey: notificationsQueryKeys.all() }).catch(() => {
      // Ignore.
    });
  }, [queryClient, unreadCount]);

  const mostRecentNotificationCreationDate = notifications[0]?.createdAt;

  const markAllAsRead = useCallback(async () => {
    if (mostRecentNotificationCreationDate) {
      // Shift by one second or first notification is not marked as read.
      await markAsRead([dayjs(mostRecentNotificationCreationDate).add(1, 'second').toDate()]);
    }
  }, [markAsRead, mostRecentNotificationCreationDate]);

  const { isPopoverOpen, setPopoverOpenFalse, setPopoverOpen } = useBoolState(false, 'popoverOpen');
  const { isConfirmModalOpen, setConfirmModalOpenTrue, setConfirmModalOpenFalse } = useBoolState(
    false,
    'confirmModalOpen',
  );

  const handleMarkAllAsRead = useCallback(async () => {
    await markAllAsRead();
    setConfirmModalOpenFalse();
  }, [markAllAsRead, setConfirmModalOpenFalse]);

  const handleChangeIsOpen = useCallback(
    async (newIsOpen: boolean) => {
      setPopoverOpen(newIsOpen);

      // Mark notifications as read on opening the tooltip.
      // Avoid admin reading user's notifications.
      if (newIsOpen && !authenticatedContext.meta?.isSuperAdmin) {
        await markAllAsRead();
      }
    },
    [authenticatedContext, setPopoverOpen, markAllAsRead],
  );

  return (
    <Fragment>
      <Popover
        isOpen={isPopoverOpen}
        content={
          <NotificationsPopover
            hasNextPage={!!hasNextPage}
            isLoading={isFetchingNextPage || isLoading}
            notifications={notifications}
            usersMap={usersMap}
            onClickMarkAllAsRead={setConfirmModalOpenTrue}
            onClose={setPopoverOpenFalse}
            onFetchNextPage={fetchNextPage}
          />
        }
        onChangeIsOpen={handleChangeIsOpen}
      >
        {/* Wrap in a div otherwise the shake animation makes the popover move lol :perplexed-cat:. */}
        <div>
          <IconButton
            css={!!unreadCount && styles.notificationsAnchorShake}
            icon={<IconBell />}
            isActive={isPopoverOpen}
            label={formatMessage({ defaultMessage: 'Notifications' })}
            withDot={!!unreadCount}
          />
        </div>
      </Popover>

      <MarkAllAsReadConfirmationModal
        isLoading={isMarkAllAsReadLoading}
        isOpen={isConfirmModalOpen}
        onClose={setConfirmModalOpenFalse}
        onConfirm={handleMarkAllAsRead}
      />
    </Fragment>
  );
});
