import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ViewAssignmentsTeamSubject, type ViewTeamSubject } from './subjects';

export const teamsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.Team);
    can(ActionsEnum.automate, SubjectsEnum.Team);
    can(ActionsEnum.view_assignments, SubjectsEnum.Team);
    can(ActionsEnum.modify_assignments, SubjectsEnum.Team);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.view_assignments, SubjectsEnum.Team);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.view_assignments, SubjectsEnum.Team);
  },

  MANAGER({ hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ team, date }: ViewTeamSubject) =>
        !!team.id && hierarchy.getTeamIdsWhereUserIsManager(date ?? new Date()).includes(team.id),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(
      ActionsEnum.view_assignments,
      SubjectsEnum.Team,
      ({ id }: ViewAssignmentsTeamSubject) => !!id && hierarchy.getTeamIdsWhereUserIsManager(new Date()).includes(id),
    );
  },

  READ_ONLY_MANAGER({ hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ team, date }: ViewTeamSubject) =>
        !!team.id && hierarchy.getTeamIdsWhereUserIsManager(date ?? new Date()).includes(team.id),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(
      ActionsEnum.view_assignments,
      SubjectsEnum.Team,
      ({ id }: ViewAssignmentsTeamSubject) => !!id && hierarchy.getTeamIdsWhereUserIsManager(new Date()).includes(id),
    );
  },

  EMPLOYEE({ hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ team, date }: ViewTeamSubject) => !!team.id && hierarchy.isTeamMember(team.id, date ?? new Date()),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(
      ActionsEnum.view_assignments,
      SubjectsEnum.Team,
      ({ id }: ViewAssignmentsTeamSubject) => !!id && hierarchy.isTeamMember(id, new Date()),
    );
  },

  READ_ONLY_EMPLOYEE({ hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ team, date }: ViewTeamSubject) => !!team.id && hierarchy.isTeamMember(team.id, date ?? new Date()),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(
      ActionsEnum.view_assignments,
      SubjectsEnum.Team,
      ({ id }: ViewAssignmentsTeamSubject) => !!id && hierarchy.isTeamMember(id, new Date()),
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
