import '@total-typescript/ts-reset';

import dayjs from 'dayjs';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import objectSupportPlugin from 'dayjs/plugin/objectSupport';
import quarterOfYearPlugin from 'dayjs/plugin/quarterOfYear';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import utcPlugin from 'dayjs/plugin/utc';
import dayjsBusinessDaysPlugin from 'dayjs-business-days2';

dayjs.extend(advancedFormatPlugin); // https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(customParseFormat); // https://day.js.org/docs/en/plugin/custom-parse-format
dayjs.extend(isBetweenPlugin); // https://day.js.org/docs/en/plugin/is-between
dayjs.extend(isoWeekPlugin); // https://day.js.org/docs/en/plugin/iso-week
dayjs.extend(isSameOrBeforePlugin); // https://day.js.org/docs/en/plugin/is-same-or-before
dayjs.extend(localizedFormatPlugin); // https://day.js.org/docs/en/plugin/localized-format
dayjs.extend(objectSupportPlugin); // https://day.js.org/docs/en/plugin/object-support
dayjs.extend(quarterOfYearPlugin); // https://day.js.org/docs/en/plugin/quarter-of-year
dayjs.extend(relativeTimePlugin); // https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(utcPlugin); // https://day.js.org/docs/en/plugin/utc
dayjs.extend(dayjsBusinessDaysPlugin); // https://www.npmjs.com/package/dayjs-business-days2

export { dayjs };
