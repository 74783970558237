export enum PlanRuleCategoryColor {
  BLUE = 'BLUE',
  CYAN = 'CYAN',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  RED = 'RED',
  MAGENTA = 'MAGENTA',
  BROWN = 'BROWN',
  GREY = 'GREY',
}
