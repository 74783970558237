import { type CustomReportConfigurationField, type CustomReport } from '@amalia/reporting/custom-reports/shared';
import {
  type ChartType,
  type DashboardChart,
  type DashboardChartUserFilters,
  type Dashboard,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

export const dashboardsQueryKeys = {
  all: () => ['dashboards-v2'] as const,
  list: () => [...dashboardsQueryKeys.all(), 'list'] as const,
  details: (dashboardId: Dashboard['id']) => [...dashboardsQueryKeys.all(), 'details', dashboardId] as const,
  homeDashboard: () => [...dashboardsQueryKeys.all(), 'home-dashboard'] as const,
  permissions: {
    all: () => [...dashboardsQueryKeys.all(), 'permissions'] as const,
    ofDashboard: {
      all: (dashboardId: Dashboard['id']) =>
        [...dashboardsQueryKeys.permissions.all(), 'of-dashboard', dashboardId] as const,
      shareCandidates: (dashboardId: Dashboard['id']) =>
        [...dashboardsQueryKeys.permissions.ofDashboard.all(dashboardId), 'share-candidates'] as const,
      sharedWith: (dashboardId: Dashboard['id']) =>
        [...dashboardsQueryKeys.permissions.ofDashboard.all(dashboardId), 'shared-with'] as const,
    } as const,
  } as const,
} as const;

export const dashboardsMutationKeys = {
  all: () => ['dashboards-v2'] as const,
  create: () => [...dashboardsMutationKeys.all(), 'create'] as const,
  unsetHomeDashboard: () => [...dashboardsMutationKeys.all(), 'unset-home-dashboard'] as const,
  ofDashboard: {
    all: (dashboardId: Dashboard['id']) => [...dashboardsMutationKeys.all(), 'of-dashboard', dashboardId] as const,
    patch: (dashboardId: Dashboard['id']) => [...dashboardsMutationKeys.ofDashboard.all(dashboardId), 'patch'] as const,
    delete: (dashboardId: Dashboard['id']) =>
      [...dashboardsMutationKeys.ofDashboard.all(dashboardId), 'delete'] as const,
    duplicate: (dashboardId: Dashboard['id']) =>
      [...dashboardsMutationKeys.ofDashboard.all(dashboardId), 'duplicate'] as const,
    patchShareCandidates: (dashboardId: Dashboard['id']) =>
      [...dashboardsMutationKeys.ofDashboard.all(dashboardId), 'patch-share-candidates'] as const,
    deleteUserSharedWith: (dashboardId: Dashboard['id']) =>
      [...dashboardsMutationKeys.ofDashboard.all(dashboardId), 'delete-user-shared-with'] as const,
    setAsHome: (dashboardId: Dashboard['id']) =>
      [...dashboardsMutationKeys.ofDashboard.all(dashboardId), 'set-as-home'] as const,
  } as const,
} as const;

export const dashboardChartsQueryKeys = {
  all: () => ['dashboards-charts'] as const,
  list: (filters: { customReportId: CustomReport['id'] }) =>
    [...dashboardChartsQueryKeys.all(), 'list', filters] as const,
  preview: (dashboardChartConfiguration: DashboardChartConfiguration<ChartType>) =>
    [...dashboardChartsQueryKeys.all(), 'preview', dashboardChartConfiguration] as const,
  ofDashboard: {
    all: (dashboardId: Dashboard['id']) => [...dashboardChartsQueryKeys.all(), 'of-dashboard', dashboardId] as const,
    list: (dashboardId: Dashboard['id']) => [...dashboardChartsQueryKeys.ofDashboard.all(dashboardId), 'list'] as const,
    chartData: (
      dashboardId: Dashboard['id'],
      chartId: DashboardChart<ChartType>['id'],
      filters: DashboardChartUserFilters,
    ) => [...dashboardChartsQueryKeys.ofDashboard.all(dashboardId), 'chart-data', chartId, filters] as const,
    chartFacet: (
      dashboardId: Dashboard['id'],
      chartId: DashboardChart<ChartType>['id'],
      field: CustomReportConfigurationField,
    ) => [...dashboardChartsQueryKeys.ofDashboard.all(dashboardId), 'chart-facet', chartId, field] as const,
  } as const,
} as const;

export const dashboardChartsMutationKeys = {
  all: () => ['dashboards-charts'] as const,
  ofDashboard: {
    all: (dashboardId: Dashboard['id']) => [...dashboardChartsMutationKeys.all(), 'of-dashboard', dashboardId] as const,
    create: (dashboardId: Dashboard['id']) =>
      [...dashboardChartsMutationKeys.ofDashboard.all(dashboardId), 'create'] as const,

    ofChart: {
      all: (dashboardId: Dashboard['id'], chartId: DashboardChart<ChartType>['id']) =>
        [...dashboardChartsMutationKeys.ofDashboard.all(dashboardId), 'of-chart', chartId] as const,
      patch: (dashboardId: Dashboard['id'], chartId: DashboardChart<ChartType>['id']) =>
        [...dashboardChartsMutationKeys.ofDashboard.ofChart.all(dashboardId, chartId), 'patch'] as const,
      delete: (dashboardId: Dashboard['id'], chartId: DashboardChart<ChartType>['id']) =>
        [...dashboardChartsMutationKeys.ofDashboard.ofChart.all(dashboardId, chartId), 'delete'] as const,
    },
  },
} as const;
