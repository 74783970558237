import { css, type Theme } from '@emotion/react';

import { DifferenceSize } from './Difference.types';

export const UP_CLASSNAME = 'up';
export const DOWN_CLASSNAME = 'down';

export const EQUAL_CLASSNAME = 'equal';
export const NOT_EQUAL_CLASSNAME = 'not-equal';

export const difference = (theme: Theme) => css`
  display: inline-flex;
  align-items: center;
  gap: 1px;

  border-radius: ${theme.ds.borderRadiuses.squared};

  color: ${theme.ds.colors.gray[900]};

  > svg {
    flex: none;
  }

  &.${DifferenceSize.SMALL} {
    padding: 2px 6px;
    padding-left: 4px;
    height: 20px;
  }

  &.${DifferenceSize.MEDIUM} {
    padding: 4px 8px;
    padding-left: 6px;
    height: 24px;
  }

  &.${UP_CLASSNAME} {
    background-color: ${theme.ds.colors.success[50]};

    > svg {
      color: ${theme.ds.colors.success[500]};
    }
  }

  &.${DOWN_CLASSNAME} {
    background-color: ${theme.ds.colors.danger[50]};

    > svg {
      color: ${theme.ds.colors.danger[500]};
    }
  }
`;

export const differenceIconOnly = (theme: Theme) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: ${theme.ds.borderRadiuses.squared};

  &.${EQUAL_CLASSNAME} {
    background-color: ${theme.ds.colors.gray[50]};

    > svg {
      color: ${theme.ds.colors.gray[700]};
    }
  }

  &.${NOT_EQUAL_CLASSNAME} {
    background-color: ${theme.ds.colors.primary[50]};

    > svg {
      color: ${theme.ds.colors.primary[500]};
    }
  }

  &.${DifferenceSize.SMALL} {
    height: 20px;
    width: 20px;
  }

  &.${DifferenceSize.MEDIUM} {
    height: 24px;
    width: 24px;
  }
`;
