export enum PagesEnum {
  LIST_COMPANIES = 'list-companies',
  LIST_PLANS = 'list-plans',
  LIST_USERS = 'list-users',
  LIST_REPORTS = 'list-reports',
  LIST_CUSTOM_OBJECTS = 'list-custom-objects',
  LIST_TEAMS = 'list-teams',
  LIST_DASHBOARDS = 'list-dashboards',
  LIST_QUOTAS = 'list-quotas',
}
