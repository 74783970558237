import { css, useTheme } from '@emotion/react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  type FloatingFocusManagerProps,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  type UseFloatingOptions,
  useFocus,
  useInteractions,
  useMergeRefs,
  useRole,
  type UseTransitionStatusProps,
  useTransitionStyles,
  useHover,
  safePolygon,
} from '@floating-ui/react';
import {
  Children,
  cloneElement,
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
  Fragment,
  memo,
  type ReactElement,
  type ReactNode,
  type Ref,
  useCallback,
  useState,
} from 'react';

import { type AnyRecord, type MergeAll } from '@amalia/ext/typescript';

import { useLayer } from '../layers-context/useLayer';

import { PopoverBody } from './popover-body/PopoverBody';
import { PopoverHeader } from './popover-header/PopoverHeader';
import { PopoverHeaderAction } from './popover-header-action/PopoverHeaderAction';
import { PopoverHeaderActions } from './popover-header-actions/PopoverHeaderActions';
import { PopoverLayout } from './popover-layout/PopoverLayout';
import { PopoverTitle } from './popover-title/PopoverTitle';
import * as styles from './Popover.styles';

export type PopoverProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Preferred placement. */
      placement?: UseFloatingOptions['placement'];
      /** Placement strategy. */
      strategy?: UseFloatingOptions['strategy'];
      /** Should close when clicking outside the popover, pressing escape or clicking the anchor multiple times. */
      shouldDismiss?: boolean;
      /** Is the popover visible. Leave as undefined for uncontrolled (auto) visibility. */
      isOpen?: boolean;
      /** Is open change handler. You can pass it and leave isOpen undefined for uncontrolled visibility while reacting to open changes. */
      onChangeIsOpen?: (isOpen: boolean) => void;
      /** Should open popover when focusing the anchor. Note that shouldDismiss needs to be disabled as well if you need to disable this. */
      shouldTriggerOnFocus?: boolean;
      /** Should toggle popover when clicking on the anchor while it is already open. */
      shouldToggleOnClick?: boolean;
      /** Should open popover when hovering. */
      shouldTriggerOnHover?: boolean;
      /** Set initial focus when the popover is open. Set to -1 to ignore. */
      initialFocus?: FloatingFocusManagerProps['initialFocus'];
      /** Determines if focus should be returned to the reference element. */
      returnFocus?: FloatingFocusManagerProps['returnFocus'];
      /** Override FloatingFocusManager default behavior. */
      closeOnFocusOut?: FloatingFocusManagerProps['closeOnFocusOut'];
      /** If the anchor is typable, keyboard events are ignored. */
      isTypableAnchor?: boolean;
      /** Don't show the popover. */
      disabled?: boolean;
      /** Popover content. */
      content: ReactNode;
      /** Popover anchor. Must allow using refs (native HTML element or component wrapped with forwardRef). */
      children: ReactElement;
      /** Override defaults transition duration */
      transitionDuration?: UseTransitionStatusProps['duration'];
      /** Distance to the anchor. */
      distance?: number;
    },
  ]
>;

const PopoverBase = forwardRef(function Popover(
  {
    isOpen: controlledIsOpen = undefined,
    onChangeIsOpen: controlledOnChangeIsOpen = undefined,
    shouldDismiss = true,
    placement = 'bottom-start',
    strategy = 'fixed',
    shouldTriggerOnFocus = false,
    shouldTriggerOnHover = false,
    shouldToggleOnClick = undefined,
    initialFocus = undefined,
    returnFocus = !shouldTriggerOnFocus, // Do not return focus to the trigger element after the dropdown is closed, because it triggers on focus as well lol.
    isTypableAnchor = false,
    disabled = false,
    closeOnFocusOut = false,
    content,
    children,
    transitionDuration,
    distance = 8,
    ...props
  }: PopoverProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const theme = useTheme();

  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState<boolean>(false);

  // The popover can be either controlled or uncontrolled.
  // If no isOpen prop is passed, fallback on internal state.
  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;

  const onSetIsOpen: Required<UseFloatingOptions<HTMLDivElement>>['onOpenChange'] = useCallback(
    (newIsOpen) => {
      controlledOnChangeIsOpen?.(newIsOpen);
      setUncontrolledIsOpen(newIsOpen);
    },
    [controlledOnChangeIsOpen],
  );

  // Create a new floating context.
  const { refs, floatingStyles, context } = useFloating<HTMLDivElement>({
    placement,
    strategy,
    open: !disabled && isOpen,
    onOpenChange: onSetIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      // Distance to the anchor.
      offset(distance),
      // Should change placement if not enough space.
      flip(),
      // Should move the popover if too close to the edge.
      shift({ padding: 8 }),
    ],
  });

  // Add a transition on show/hide popover. Use the default transition parameters.
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration:
      typeof transitionDuration === 'number'
        ? transitionDuration
        : {
            open: transitionDuration?.open ?? theme.ds.transitions.default.durationMs,
            close: transitionDuration?.close ?? theme.ds.transitions.default.durationMs,
          },
    common: {
      transitionTimingFunction: theme.ds.transitions.default.easing,
    },
  });

  const { isTopLayer, layerZIndex } = useLayer({ isOpen: isMounted });

  // Interactions.
  // Show popover on click. Prevent closing if shouldDismiss is false.
  const click = useClick(context, {
    enabled: !disabled,
    toggle: shouldToggleOnClick ?? shouldDismiss,
    keyboardHandlers: !isTypableAnchor,
  });

  // Hide popover on press escape. Prevent closing if shouldDismiss is false.
  const dismiss = useDismiss(context, { enabled: !disabled && shouldDismiss && isTopLayer });
  // Add accessibility role of menu.
  const role = useRole(context, { role: 'menu', enabled: !disabled });
  // Show popover when focusing element. Prevent showing if shouldTriggerOnFocus is false.
  const focus = useFocus(context, {
    enabled: !disabled && shouldTriggerOnFocus && shouldDismiss && (!isOpen || isTopLayer),
  });

  // Show popover on hover.
  const hover = useHover(context, {
    // Delay opening by the default transition duration.
    // This way the popover does not appear if the user skims through the application with their cursor.
    delay: { open: theme.ds.transitions.default.durationMs },
    handleClose: safePolygon(),
    enabled: !disabled && shouldTriggerOnHover,
  });

  // Register interactions declared above.
  const { getReferenceProps, getFloatingProps } = useInteractions([click, focus, dismiss, role, hover]);

  // Get the ref of the child element if it exists.
  const child = Children.only(children);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any -- Cannot get the ref without casting as any.
  const childRef = (child as any)?.ref as Ref<unknown> | undefined;

  // Merge the child ref with the reference from floating-ui.
  const childRefWithFloatingUiRef = useMergeRefs([refs.setReference, childRef, ref]);

  return (
    <Fragment>
      {/* Render the anchor and add the reference from floating-ui to handle interactions. */}
      {cloneElement(
        child,
        getReferenceProps({
          ref: childRefWithFloatingUiRef,
          ...(child.props as AnyRecord),
        }),
      )}

      {isMounted ? (
        <FloatingPortal>
          <FloatingFocusManager
            closeOnFocusOut={closeOnFocusOut}
            context={context}
            disabled={!isTopLayer}
            initialFocus={initialFocus}
            modal={false}
            returnFocus={returnFocus}
          >
            <div
              ref={refs.setFloating}
              {...getFloatingProps(props)}
              css={[
                styles.popover,
                css`
                  z-index: ${layerZIndex};
                `,
              ]}
              style={{
                ...props.style,
                ...floatingStyles,
                ...transitionStyles,
              }}
            >
              {content}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      ) : null}
    </Fragment>
  );
});

export const Popover = Object.assign(memo(PopoverBase), {
  Body: PopoverBody,
  Header: PopoverHeader,
  HeaderAction: PopoverHeaderAction,
  HeaderActions: PopoverHeaderActions,
  Layout: PopoverLayout,
  Title: PopoverTitle,
});
