import styled from '@emotion/styled';
import { useKeyPress } from 'ahooks';
import { memo } from 'react';

import { ShadowVariant } from '@amalia/design-system/meta';

export const KbdContainer = styled.div`
  ${({ theme }) => theme.ds.typographies.bodyXxsmallRegular};
  align-items: center;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.ds.colors.gray[100]}`};
  display: flex;
  justify-content: center;
  padding: 1px 5px;
  width: fit-content;
  color: ${({ theme }) => theme.ds.colors.gray[600]};
  box-shadow: ${({ theme }) => theme.ds.shadows[ShadowVariant.SOFT]};
  white-space: nowrap;
`;

type KbdModifiers = 'alt' | 'ctrl' | 'meta' | 'shift';

const kdbSeparator = '.';
type KbdSeparator = typeof kdbSeparator;

type KbdKeys =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

type SimpleKbdCode = `${KbdModifiers}${KbdSeparator}${KbdKeys}`;
type DoubleKbdCode = `${KbdModifiers}${KbdSeparator}${KbdModifiers}${KbdSeparator}${KbdKeys}`;

type KbdCode<TCode extends DoubleKbdCode | SimpleKbdCode> = TCode extends SimpleKbdCode
  ? TCode
  : TCode extends DoubleKbdCode
    ? TCode extends `${infer FirstModifier}${KbdSeparator}${infer SecondModifier}${KbdSeparator}${KbdKeys}`
      ? FirstModifier extends SecondModifier
        ? never
        : TCode
      : never
    : never;

export type KbdProps<TAcc extends DoubleKbdCode | SimpleKbdCode> = {
  readonly kbdCode: KbdCode<TAcc>;
  /**
   * Ignore this prop if you don't want to handle event and just want to display the key combination.
   */
  readonly onKeysPressed?: () => void;
};

export const Kbd = memo(function Kbd({ kbdCode, onKeysPressed }: KbdProps<DoubleKbdCode | SimpleKbdCode>) {
  useKeyPress(kbdCode, (event: KeyboardEvent) => {
    if (!onKeysPressed) {
      return;
    }

    event.preventDefault();
    onKeysPressed();
  });

  return <KbdContainer>{kbdCode.toUpperCase().replaceAll(kdbSeparator, ' + ')}</KbdContainer>;
});
