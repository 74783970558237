import { type ReactNode, memo, Fragment } from 'react';

import { useParams } from '../hooks';

export type UseParamsProps<TParams extends Record<string, string> = Record<string, string>> = {
  readonly children?: (params: ReturnType<typeof useParams<TParams>>) => ReactNode;
};

const UseParamsBase = function UseParams<TParams extends Record<string, string> = Record<string, string>>({
  children,
}: UseParamsProps<TParams>) {
  const params = useParams<TParams>();
  return <Fragment>{children?.(params)}</Fragment>;
};

export const UseParams = memo(UseParamsBase) as typeof UseParamsBase;
