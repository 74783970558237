import { cloneElement, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Group } from '@amalia/design-system/components';

import { formatToIcon } from '../icons/format-icon.mapper';
import { formatsEnumMessages } from '../intl/formats-enum.messages';

const allFormats = Object.values(FormatsEnum);

export const useTokenFormatOptions = (supportedFormats: FormatsEnum[] = allFormats) => {
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      supportedFormats.map((format) => ({
        value: format,
        label: formatMessage(formatsEnumMessages[format]),
        icon: formatToIcon[format],
        valueLabel: (
          <Group
            align="center"
            gap={6}
          >
            {cloneElement(formatToIcon[format], { height: 16, width: 16 })}
            <FormattedMessage {...formatsEnumMessages[format]} />
          </Group>
        ),
      })),
    [supportedFormats, formatMessage],
  );
};
