import { type Components, type PaletteColor, type PaletteColorOptions, type Shadows, type Theme } from '@mui/material';
import { createTheme, darken, lighten, useTheme } from '@mui/material/styles';
import { fill } from 'lodash';

// FIXME: move this library to the ui folder, it's business not ext.
import { UserExternalIdSource } from '@amalia/tenants/users/types';

// Properly override the interface, so we can add colors to the palette.
// @see https://material-ui.com/customization/palette/
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    link: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    link: PaletteColorOptions;
  }
}

export const networkColors = {
  [UserExternalIdSource.SALESFORCE]: '#00A1E0',
  [UserExternalIdSource.HUBSPOT]: '#F76F00',
};

export const colors = {
  'primary-500': '#FFBF00',
  'primary-400': '#FFDD67',
  'primary-300': '#FFE262',
  'primary-200': '#FFED9F',
  'primary-100': '#FFF7DB',
  'primary-50': '#FFFAE8',

  'secondary-500': '#663C00',
  'secondary-400': '#7F4A00',

  'tertiary-500': '#6A35FF',
  'tertiary-400': '#856BFF',
  'tertiary-200': '#D3CCFC',
  'tertiary-100': '#F2EDFF',

  'grey-100': '#FAF9F7',
  'grey-200': '#E4E4E4',
  'grey-300': '#C4C4C4',
  'grey-600': '#9E9E9E',
  'grey-700': '#707070',
  'grey-800': '#424242',

  'blue-500': '#00AAFB',
  'blue-100': '#DFEFFF',

  'green-100': '#E8FAF0',
  'red-100': '#FCE9E9',
  'orange-100': '#FEF2E0',
  'cyan-100': '#E7FBFF',
  'pink-100': '#FCE8F6',
  'fuschia-100': '#F9E8FC',
  'brown-100': '#E6E2DC',

  'orange-500': '#EE9517',

  'green-500': '#41D980',

  'quarternary-700': '#9DCDFA',
  'quarternary-500': '#C0DBF4',

  'red-500': '#E54545',
  'red-400': '#ED7D7D',
  'red-50': '#FEF6F6',

  black: 'rgba(0, 0, 0, 0.87)',
};

const palette = {
  primary: {
    main: colors['primary-500'],
    light: colors['primary-400'],
  },
  background: {
    default: 'rgba(0,0,0,0)',
  },
  secondary: {
    main: colors['secondary-500'],
    light: colors['secondary-400'],
    contrastText: '#ffcc00',
  },
  tertiary: {
    main: colors['tertiary-500'],
    light: colors['tertiary-400'],
    contrastText: colors['tertiary-200'],
  },
  grey: {
    100: colors['grey-100'],
    200: colors['grey-200'],
    300: colors['grey-300'],
    600: colors['grey-600'],
    700: colors['grey-700'],
    800: colors['grey-800'],
  },
  link: {
    main: colors['tertiary-500'],
    light: colors['tertiary-400'],
  },
  error: {
    main: colors['red-500'],
    light: colors['red-100'],
  },
  warning: {
    main: colors['orange-500'],
    light: colors['orange-100'],
  },
  success: {
    main: colors['green-500'],
    light: colors['green-100'],
  },
  info: {
    main: colors['blue-500'],
    light: colors['blue-100'],
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  divider: colors['grey-200'],
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  gradients: {
    main: `linear-gradient(176.82deg, ${colors['primary-500']} 0%, ${colors['primary-400']} 100%)`,
  },
};

const components: Components<Theme> = {
  // MUI5 IconButton uses "5px" padding for size=small.
  // It used to be "3px" before.
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.size === 'small' && {
          padding: '3px',
        }),
      }),
    },
  },
  // MUI5 Alert uses "light" colors with variant=standard.
  // It used to be "main" colors.
  MuiAlert: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.variant === 'standard' && {
          color: darken(palette[ownerState.color || ownerState.severity || 'success'].main, 0.6),
          backgroundColor: lighten(palette[ownerState.color || ownerState.severity || 'success'].main, 0.9),
        }),
      }),
    },
  },
  // MUI5 Grid2 doesn't set width when container=true.
  // It used to be width: '100%' before.
  MuiGrid2: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.container === true && {
          width: '100%',
        }),
      }),
    },
  },
  // MUI5 Divider uses marginLeft/Right instead of marginTop/Bottom when variant=middle, orientation=vertical.
  // It used to be marginLeft/Right when variant=middle for all orientations
  MuiDivider: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.orientation === 'vertical' &&
          ownerState.variant === 'middle' && {
            marginTop: 0,
            marginBottom: 0,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }),
      }),
    },
  },
  // https://mui.com/material-ui/migration/v5-component-changes/#update-default-minwidth-and-maxwidth
  // minWidth was changed from 72px to 90px.
  // maxWidth was changed from 264px to 360px.
  MuiTab: {
    styleOverrides: {
      root: () => ({
        minWidth: '72px',
        maxWidth: '264px',

        '&.Mui-selected': { color: 'inherit' },
      }),
    },
  },
};

export const amaliaThemeInstance = {
  palette,
  shape: {
    borderRadius: 8,
  },
  // Replacing all shadows by the one we define.
  shadows: ['none', ...fill(Array(24), '2px 2px 8px rgba(0, 0, 0, 0.13)')] as Shadows,
  // Here be migration hacks...
  components,
  // Use MUIv4 breakpoints to not break everything after migrating to MUIv5.
  // https://mui.com/material-ui/migration/v5-component-changes/#breakpoint-sizes
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export type AmaliaThemeType = Theme & typeof amaliaThemeInstance;

export const amaliaTheme: AmaliaThemeType = createTheme(amaliaThemeInstance) as AmaliaThemeType;

export const AMALIA_OBJECTS_HIGHLIGHT_COLOR = {
  statement: colors['cyan-100'],
  user: colors['tertiary-100'],
  team: colors['tertiary-100'],
  plan: colors['pink-100'],
  object: colors['primary-100'],
  filter: colors['green-100'],
  rule: colors['cyan-100'],
  link: colors['red-100'],
  quota: colors['tertiary-100'],
  function: amaliaTheme.palette.secondary.main,
  functionOverlay: colors['brown-100'],
  keyword: colors['pink-100'],
  metric: colors['pink-100'],
  record: colors['grey-100'],
};

export const useAmaliaMUITheme = (): AmaliaThemeType => useTheme();
