import { subject } from '@casl/ability';

import { type CommentThreadMessage } from '@amalia/payout-collaboration/comments/types';

import { SubjectsEnum } from '../../types';

export type ModifyCommentThreadMessageSubject = Pick<CommentThreadMessage, 'authorId'>;

export const modifyCommentThreadMessageSubject = (payload: ModifyCommentThreadMessageSubject) =>
  subject(SubjectsEnum.CommentThreadMessage, payload);

export type DeleteCommentThreadMessageSubject = Pick<CommentThreadMessage, 'authorId'>;

export const deleteCommentThreadMessageSubject = (payload: DeleteCommentThreadMessageSubject) =>
  subject(SubjectsEnum.CommentThreadMessage, payload);
