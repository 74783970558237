import { useQuery } from '@tanstack/react-query';
import { endOfYear, startOfYear } from 'date-fns';

import {
  type GetUserQuotaAssignmentsOptions,
  UserQuotaAssignmentsApiClient,
} from '@amalia/assignments/quotas/api-client';
import { useUtcDate } from '@amalia/ext/react/hooks';

import { quotaAssignmentsQueryKeys } from './queries.keys';

export const useUserQuotaAssignmentsByFrequency = (userId: string, options?: GetUserQuotaAssignmentsOptions) =>
  useQuery({
    queryKey: quotaAssignmentsQueryKeys.ofUser.list(
      userId,
      options?.effectiveBetween?.start,
      options?.effectiveBetween?.end,
    ),
    queryFn: () => UserQuotaAssignmentsApiClient.getUserQuotaAssignments(userId, options),
    enabled: !!userId,
  });

export const useUserCurrentYearQuotaAssignmentsByFrequency = (
  userId: string,
  {
    referenceDate = new Date(),
    ...options
  }: Omit<GetUserQuotaAssignmentsOptions, 'effectiveBetween'> & {
    referenceDate?: Date;
  } = {},
) => {
  const utcReferenceDate = useUtcDate(referenceDate);

  return useUserQuotaAssignmentsByFrequency(userId, {
    ...options,
    effectiveBetween: {
      start: startOfYear(utcReferenceDate),
      end: endOfYear(utcReferenceDate),
    },
  });
};
