import { IconHelp, IconMessage, IconSpeakerphone, IconStar } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { IconButtonLink } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';

import * as styles from './LeftBarActions.styles';

export const LeftBarActions = memo(function LeftBarActions() {
  const ability = useAbilityContext();
  const { pathname } = useLocation();

  return (
    <div css={styles.leftBarActions}>
      <IconButtonLink
        icon={<IconMessage />}
        isActive={pathname.includes('threads')}
        label={<FormattedMessage defaultMessage="Threads" />}
        to={generatePath(routes.THREADS)}
      />

      {!!ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin) && (
        <IconButtonLink
          icon={<IconStar />}
          isActive={pathname.includes('superadmin')}
          label={<FormattedMessage defaultMessage="Superadmin" />}
          to={generatePath(routes.SUPERADMIN_HOME)}
        />
      )}

      <IconButtonLink
        icon={<IconSpeakerphone />}
        isActive={pathname === '/changelog'}
        label={<FormattedMessage defaultMessage="What's new" />}
        to={generatePath(routes.CHANGELOG)}
      />

      <IconButtonLink
        icon={<IconHelp />}
        label={<FormattedMessage defaultMessage="Helpdoc" />}
        to="https://help.amalia.io"
      />
    </div>
  );
});
