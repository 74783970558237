import { type Theme, css } from '@emotion/react';

import { InputSize } from './Input.types';

export const IS_DISABLED_CLASSNAME = 'disabled';
export const HAS_LEFT_ICON_CLASSNAME = 'hasLeftIcon';
export const HAS_RIGHT_ICON_CLASSNAME = 'hasRightIcon';
export const HAS_ERROR_CLASSNAME = 'hasError';
export const HAS_ACTION_CLASSNAME = 'hasAction';

export const container = (theme: Theme) => css`
  position: relative;
  max-width: var(--input-max-width, 600px);

  color: ${theme.ds.colors.gray[900]};

  &.${IS_DISABLED_CLASSNAME} {
    color: ${theme.ds.colors.gray[300]};
  }

  svg {
    stroke-width: 1.5px;
  }

  &.${InputSize.SMALL} {
    --input-h-padding: 12px;
    --input-v-padding: 4.5px;
    --input-icon-size: 16px;
    --input-icon-field-gap: 6px;
    --input-icon-gap: 4px;
  }

  &.${InputSize.MEDIUM} {
    --input-h-padding: 16px;
    --input-v-padding: 7px;
    --input-icon-size: 20px;
    --input-icon-field-gap: 8px;
    --input-icon-gap: 6px;
  }
`;

export const iconContainer = (theme: Theme) => css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  display: flex;

  pointer-events: none; /* Clicking the icon focuses the input. */

  transition: ${theme.ds.transitions.default()};

  > svg {
    width: var(--input-icon-size);
    height: var(--input-icon-size);
  }
`;

export const leftIcon = css`
  left: var(--input-h-padding);
`;

export const rightIcon = css`
  right: var(--input-h-padding);
`;

export const input = (theme: Theme) => css`
  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  outline: none;
  outline-offset: -1px;

  width: 100%;

  box-shadow: ${theme.ds.shadows.soft};

  transition: ${theme.ds.transitions.default(
    'border-color',
    'box-shadow',
    'color',
    'background-color',
    'padding',
    'padding-top',
    'padding-bottom',
    'padding-right',
    'padding-left',
    'font-size',
    'line-height',
  )};

  color: inherit;
  background-color: ${theme.ds.colors.gray[0]};

  min-width: 0;

  padding-top: var(--input-v-padding);
  padding-bottom: var(--input-v-padding);

  padding-left: var(--input-h-padding);
  padding-right: var(--input-h-padding);

  &:not(.${HAS_RIGHT_ICON_CLASSNAME}).${HAS_ACTION_CLASSNAME},
    &:not(.${HAS_ACTION_CLASSNAME}).${HAS_RIGHT_ICON_CLASSNAME} {
    padding-right: calc(var(--input-icon-field-gap) - 1px + var(--input-icon-size) + var(--input-h-padding));
  }

  &.${HAS_RIGHT_ICON_CLASSNAME}.${HAS_ACTION_CLASSNAME} {
    padding-right: calc(
      var(--input-icon-field-gap) - 1px + var(--input-icon-size) + var(--input-icon-gap) + var(--input-icon-size) +
        var(--input-h-padding)
    );
  }

  &:focus-visible {
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};

    &.${HAS_ERROR_CLASSNAME} {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
    }
  }

  &.${HAS_LEFT_ICON_CLASSNAME} {
    padding-left: calc(var(--input-icon-field-gap) - 1px + var(--input-icon-size) + var(--input-h-padding));
  }

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};

    &.${HAS_ERROR_CLASSNAME} {
      outline: none;
    }

    &::placeholder {
      color: ${theme.ds.colors.gray[400]};
    }

    .${InputSize.SMALL} & {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};

      &.${HAS_ERROR_CLASSNAME} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
      }
    }

    &.${InputSize.MEDIUM} & {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};

      &.${HAS_ERROR_CLASSNAME} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
      }
    }
  }

  &::placeholder {
    color: ${theme.ds.colors.gray[400]};
  }

  &:disabled {
    color: ${theme.ds.colors.gray[300]};
    background-color: ${theme.ds.colors.gray[50]};

    &::placeholder {
      color: ${theme.ds.colors.gray[200]};
    }
  }

  &:not(:disabled) {
    &.${HAS_ERROR_CLASSNAME} {
      border-color: ${theme.ds.colors.danger[500]};
    }
  }

  .${InputSize.SMALL} & {
    ${theme.ds.typographies.bodyBaseRegular};
  }

  .${InputSize.MEDIUM} & {
    ${theme.ds.typographies.bodyLargeRegular};
  }
`;

export const action = (theme: Theme) => css`
  position: absolute;

  top: 50%;
  transform: translate(0, -50%);

  transition: ${theme.ds.transitions.default()};

  right: var(--input-h-padding);

  .${HAS_RIGHT_ICON_CLASSNAME} ~ & {
    right: calc(var(--input-h-padding) + var(--input-icon-size) + var(--input-icon-gap));
  }
`;
