import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const superadminCompaniesAbilityDefinitions = {
  AMALIA_TECH_ADMIN(_, { can }) {
    can(ActionsEnum.modify, SubjectsEnum.SuperAdmin_companies);
  },

  AMALIA_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_companies);
    can(ActionsEnum.create, SubjectsEnum.SuperAdmin_companies);
    can(ActionsEnum.delete, SubjectsEnum.SuperAdmin_companies);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
