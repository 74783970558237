import { uniqBy } from 'lodash';
import { type MathNode } from 'mathjs';

import {
  type AmaliaFormula,
  type AmaliaFunctionCategory,
  type AmaliaFunctionKeys,
} from '@amalia/amalia-lang/formula/types';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type ComputedFunctionArgs, type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { type ParserScope } from '../CalculationParser';

export type AmaliaFunctionArgument = {
  name: string;
  description: string;
  defaultValue?: ComputeEngineResult;
  /**
   * Token types that are valid for this argument. If not provided, all token types are valid.
   */
  validTokenTypes?: TokenType[];

  /**
   * Token values per token types that are valid for this argument. Useful if you want to restrict the values of a token type.
   * Example: In sum function, second argument can be a function but only `IF` or `DEFAULT` functions are valid.
   */
  validTokenValues?: Partial<Record<TokenType, string[]>>;

  /**
   * Formats that are valid for this argument. If not provided, all formats are valid.
   */
  validFormats?: FormatsEnum[];
};

type MathjsRawArgsFunctionArguments = [MathNode[], unknown, ParserScope];

export interface AmaliaFunctionExec<
  TArgs extends ComputeEngineResult[] | MathjsRawArgsFunctionArguments =
    | ComputeEngineResult[]
    | MathjsRawArgsFunctionArguments,
  TReturnType extends ComputeEngineResult = ComputeEngineResult,
> {
  (...args: TArgs): TReturnType;
  readonly rawArgs?: boolean;
}

export interface AmaliaFunctionExecDefault<
  TArgs extends ComputeEngineResult[] = ComputeEngineResult[],
  TReturnType extends ComputeEngineResult = ComputeEngineResult,
> extends AmaliaFunctionExec<TArgs, TReturnType> {
  readonly rawArgs?: false;
}

export interface AmaliaFunctionExecRawArgs<TReturnType extends ComputeEngineResult = ComputeEngineResult>
  extends AmaliaFunctionExec<MathjsRawArgsFunctionArguments, TReturnType> {
  readonly rawArgs: true;
}

export type AmaliaFunctionMetadata<
  TArgs extends ComputeEngineResult[] | MathjsRawArgsFunctionArguments =
    | ComputeEngineResult[]
    | MathjsRawArgsFunctionArguments,
  TReturnType extends ComputeEngineResult = ComputeEngineResult,
  TExecFn extends AmaliaFunctionExec<TArgs, TReturnType> = AmaliaFunctionExec<TArgs, TReturnType>,
> = {
  name: AmaliaFunctionKeys;
  category: AmaliaFunctionCategory;
  exec: TExecFn;
  execMock?: TExecFn;
  nbParamsRequired?: number;
  description?: string[] | string;
  examples: { desc?: string; formula: AmaliaFormula; result?: TReturnType }[];
  params: AmaliaFunctionArgument[];
  hasInfiniteParams?: boolean;
  hiddenFromLibrary?: boolean;
  generateComputedFunctionResult?: (args: MathNode[]) => ComputedFunctionArgs;
  parametersToEscapeOnParse?: number[];
};

export abstract class AmaliaFunction<
  TArgs extends ComputeEngineResult[] | MathjsRawArgsFunctionArguments =
    | ComputeEngineResult[]
    | MathjsRawArgsFunctionArguments,
  TReturnType extends ComputeEngineResult = ComputeEngineResult,
  TExecFn extends AmaliaFunctionExec<TArgs, TReturnType> = AmaliaFunctionExec<TArgs, TReturnType>,
> {
  // @ts-expect-error -- Will be filled during initialization.
  private static allFunctions: Record<AmaliaFunctionKeys, AmaliaFunction> = {};

  public static getAllFunctions(): Readonly<Record<AmaliaFunctionKeys, AmaliaFunction>> {
    return AmaliaFunction.allFunctions;
  }

  public static getFunctionsEnum() {
    return Object.keys(AmaliaFunction.allFunctions).reduce<Record<string, string>>((acc, key) => {
      acc[key] = key;
      return acc;
    }, {});
  }

  public static getAllFunctionsArray(): AmaliaFunction[] {
    return uniqBy(Object.values(AmaliaFunction.allFunctions), 'name');
  }

  public constructor({
    name,
    category,
    exec,
    params,
    execMock = undefined,
    nbParamsRequired = undefined,
    description = undefined,
    examples,
    hasInfiniteParams = false,
    hiddenFromLibrary = false,
    generateComputedFunctionResult = undefined,
    parametersToEscapeOnParse = undefined,
    nbParamsDeclared,
  }: AmaliaFunctionMetadata<TArgs, TReturnType, TExecFn> & { nbParamsDeclared: number }) {
    this.name = name;
    this.category = category;
    this.exec = exec;
    this.execMock = execMock;
    this.nbParamsRequired = nbParamsRequired;
    this.description = description;
    this.examples = examples;
    this.params = params;
    this.hasInfiniteParams = hasInfiniteParams;
    this.hiddenFromLibrary = hiddenFromLibrary;
    this.generateComputedFunctionResult = generateComputedFunctionResult;
    this.parametersToEscapeOnParse = parametersToEscapeOnParse;
    this.nbParamsDeclared = nbParamsDeclared;

    // Register this function into the global enum.
    AmaliaFunction.allFunctions[name] = this as unknown as AmaliaFunction;
  }

  public readonly name: AmaliaFunctionKeys;

  public readonly category: AmaliaFunctionCategory;

  public readonly nbParamsRequired?: number;

  public readonly description?: string[] | string;

  public readonly examples: { desc?: string; formula: AmaliaFormula; result?: TReturnType }[];

  public readonly params: AmaliaFunctionArgument[];

  protected readonly nbParamsDeclared: number;

  public readonly hasInfiniteParams?: boolean;

  public readonly hiddenFromLibrary?: boolean;

  // The function to call in order to execute the function.
  public readonly exec: TExecFn;

  // Mock the evaluation in formula validation, useful for ComputedFunctionResults for instance.
  public readonly execMock?: TExecFn;

  /**
   * Given the args of the current context, generates the ComputedFunctionResult skeleton
   * for this function.
   */
  public readonly generateComputedFunctionResult?: (args: MathNode[]) => ComputedFunctionArgs;

  /**
   * For some functions, avoid classic parsing on some parameters by ignoring them.
   *
   * It usually means that they should be parsed in another context (for instance a dataset or a different period).
   */
  public readonly parametersToEscapeOnParse?: number[];
}

export class AmaliaFunctionDefault<
  TArgs extends ComputeEngineResult[] = ComputeEngineResult[],
  TReturnType extends ComputeEngineResult = ComputeEngineResult,
> extends AmaliaFunction<TArgs, TReturnType, AmaliaFunctionExecDefault<TArgs, TReturnType>> {
  public constructor({
    exec,
    ...other
  }: AmaliaFunctionMetadata<TArgs, TReturnType, AmaliaFunctionExecDefault<TArgs, TReturnType>>) {
    // const withArgsValidation =
    //   (execFn: typeof exec) =>
    //   (...args: TArgs) => {
    //     if (this.nbParamsRequired !== undefined) {
    //       for (let i = 0; i < this.nbParamsRequired; i++) {
    //         if (args[i] === undefined) {
    //           throw new ConfigurationError(
    //             `${this.name} is missing parameter at position ${i + 1} or its value is undefined`,
    //           );
    //         }
    //       }
    //     }

    //     if (!this.hasInfiniteParams && this.params.length && args.length > this.params.length) {
    //       throw new ConfigurationError(`Too many parameters for function ${this.name}`);
    //     }

    //     return execFn(...args);
    //   };

    super({
      ...other,
      exec,
      nbParamsDeclared: exec.length,
    });
  }
}

export class AmaliaFunctionRawArgs<
  TReturnType extends ComputeEngineResult = ComputeEngineResult,
> extends AmaliaFunction<MathjsRawArgsFunctionArguments, TReturnType, AmaliaFunctionExecRawArgs<TReturnType>> {
  public constructor({
    exec,
    execMock,
    ...other
  }: AmaliaFunctionMetadata<
    MathjsRawArgsFunctionArguments,
    TReturnType,
    AmaliaFunctionExec<MathjsRawArgsFunctionArguments, TReturnType>
  >) {
    super({
      ...other,
      exec: Object.assign(exec, { rawArgs: true } as const),
      execMock: execMock ? Object.assign(execMock, { rawArgs: true } as const) : undefined,
      nbParamsDeclared: exec.length,
    });
  }
}

/**
 * FIXME: this is a designer specific type, move it near the designer.
 */
export type AmaliaFunctionWithId = Omit<AmaliaFunction, 'nbParamsDeclared'> & { id: string | undefined };
