import { IconSearch } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Input, type InputProps } from '../../data-input/input/Input';

export type SearchInputProps = InputProps;

const SearchInputForwardRef = forwardRef(function SearchInput(
  { onChange, ...props }: SearchInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { formatMessage } = useIntl();
  const handleClear = useCallback(() => onChange?.(''), [onChange]);

  return (
    <Input
      {...props}
      ref={ref}
      action={props.action ?? (props.value ? <Input.ClearAction onClick={handleClear} /> : undefined)}
      leftIcon={props.leftIcon ?? <IconSearch />}
      placeholder={props.placeholder ?? formatMessage({ defaultMessage: 'Search' })}
      onChange={onChange}
    />
  );
});

export const SearchInput = Object.assign(SearchInputForwardRef, {
  Action: Input.Action,
  ClearAction: Input.ClearAction,
  Size: Input.Size,
});
