import { ActionsEnum, SubjectsEnum, type UserRoleForAccessControl, type DefinePermissions } from '../../types';

export const customReportsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
    can(ActionsEnum.modify, SubjectsEnum.CustomReport);
    can(ActionsEnum.delete, SubjectsEnum.CustomReport);
    can(ActionsEnum.create, SubjectsEnum.CustomReport);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
  },

  EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
  },

  READ_ONLY_EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.CustomReport);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
