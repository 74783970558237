import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { stubFalse } from 'lodash';
import { useIntl } from 'react-intl';

import { isConflictError } from '@amalia/core/http/client';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import {
  type PutPlanRulesOrderRequest,
  type BadgeConfiguration,
  type CreatePlanBadgeConfigurationRequest,
  type CreatePlanCategoryRequest,
  type ImportTokenFromOtherPlanRequest,
  type PatchPlanBadgeConfigurationRequest,
  type PatchPlanForecastingConfigurationRequest,
  type PatchPlanRequest,
  type PatchPlanVisibility,
  type PatchPlanWorkflowRequest,
  type Plan,
  type PlanCategory,
  type PutPlanHighlightedKpisRequest,
} from '@amalia/payout-definition/plans/types';

import { filtersQueryKeys } from '../filters/filters.keys';
import { plansMutationKeys, plansQueryKeys, rulesV2QueryKeys } from '../queries.keys';
import { variablesQueryKeys } from '../variables/variables.keys';

import { PlansApiClient } from './plans.api-client';

export const usePlansList = (
  filters?: Parameters<(typeof PlansApiClient)['list']>[0],
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: plansQueryKeys.list(filters),
    queryFn: () => PlansApiClient.list(filters),
    ...options,
  });

export const usePlanTemplate = (planId?: Plan['id']) =>
  useQuery({
    queryKey: plansQueryKeys.template.ofPlan(planId!),
    queryFn: () => PlansApiClient.getPlanTemplate(planId!),
    enabled: !!planId,
  });

export const usePlan = (planId?: Plan['id']) =>
  useQuery({
    queryKey: plansQueryKeys.details(planId!),
    queryFn: () => PlansApiClient.get(planId!),
    enabled: !!planId,
  });

export const useCreatePlan = ({
  ignoreConflictError = true,
}: {
  ignoreConflictError?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.create(),
    mutationFn: PlansApiClient.create,
    onSuccess: async (planCreated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan created successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planCreated.id) }),
      ]);
    },
    onError: (err) => {
      if (!ignoreConflictError || !isConflictError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Plan could not be created: {errorMessage}.' },
            { errorMessage: toError(err).message },
          ),
        );
      }
    },
  });
};

export const useDuplicatePlan = ({
  ignoreConflictError = true,
}: {
  ignoreConflictError?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.duplicate(),
    mutationFn: PlansApiClient.duplicatePlan,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan duplicated successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.all() }),
      ]);
    },
    onError: (err) => {
      if (!ignoreConflictError || !isConflictError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Plan could not be duplicated: {errorMessage}.' },
            { errorMessage: toError(err).message },
          ),
        );
      }
    },
  });
};

export const useArchivePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.archive(),
    mutationFn: PlansApiClient.archive,
    onSuccess: async (planUpdated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan archived successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planUpdated.id) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planUpdated.id) }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be archived: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useUnarchivePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.archive(),
    mutationFn: PlansApiClient.unarchive,
    onSuccess: async (planUpdated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan unarchived successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planUpdated.id) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planUpdated.id) }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be unarchived: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useDeletePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: plansMutationKeys.delete(),
    mutationFn: PlansApiClient.delete,
    onSuccess: async (_, planId) => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan deleted successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planId) }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be deleted: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useIsPlanDeletable = (maybePlanId?: Plan['id']) =>
  useQuery({
    queryKey: plansQueryKeys.isDeletable(maybePlanId!),
    queryFn: () => PlansApiClient.isDeletable(maybePlanId!),
    enabled: !!maybePlanId,
  });

export const usePatchPlanVisibility = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patchVisibility(planId),
    mutationFn: (patchPlanVisibility: PatchPlanVisibility) =>
      PlansApiClient.patchPlanVisibility(planId, patchPlanVisibility),
    onSuccess: async (plan: Plan) => {
      snackSuccess(
        plan.isHidden
          ? formatMessage({ defaultMessage: 'Plan unpublished successfully.' })
          : formatMessage({ defaultMessage: 'Plan published successfully.' }),
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planId) }),
      ]);
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchPlanWorkflow = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (patchPlanWorkflowRequest: PatchPlanWorkflowRequest) =>
      PlansApiClient.patchPlanWorkflow({ planId, ...patchPlanWorkflowRequest }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully.' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchPlan = (
  planId: Plan['id'],
  { shouldIgnoreError = stubFalse }: { shouldIgnoreError?: (err: Error) => boolean } = {},
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (patchPlanRequest: PatchPlanRequest) => PlansApiClient.patchPlan({ planId, ...patchPlanRequest }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.allLists() }),
      ]);
    },
    onError: (err) => {
      if (!shouldIgnoreError(err)) {
        snackError(toError(err).message);
      }
    },
  });
};

export const usePatchPlanForecastingConfiguration = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (patchPlanForecastingConfigurationRequest: PatchPlanForecastingConfigurationRequest) =>
      PlansApiClient.patchPlanForecastingConfiguration({ planId, ...patchPlanForecastingConfigurationRequest }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully.' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePutPlanHighlightedKpis = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (request: PutPlanHighlightedKpisRequest) =>
      PlansApiClient.putPlanHighlightedKpis({ planId, ...request }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully.' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useImportTokenFromOtherPlan = (planId: Plan['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: plansMutationKeys.importFromOtherPlan(planId),
    mutationFn: (importTokenFromOtherPlanRequest: ImportTokenFromOtherPlanRequest) =>
      PlansApiClient.importTokenFromOtherPlan({ planId, ...importTokenFromOtherPlanRequest }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: filtersQueryKeys.ofPlan(planId) }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId) }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.ofPlan(planId) }),
      ]);
    },
  });
};

export const useCreatePlanBadgeConfiguration = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (createBadgeConfigurationRequest: CreatePlanBadgeConfigurationRequest) =>
      PlansApiClient.createPlanBadgeConfiguration(planId, createBadgeConfigurationRequest),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchPlanBadgeConfiguration = (planId: Plan['id'], badgeId: BadgeConfiguration['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (patchBadgeConfigurationRequest: PatchPlanBadgeConfigurationRequest) =>
      PlansApiClient.patchPlanBadgeConfiguration(planId, badgeId, patchBadgeConfigurationRequest),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeletePlanBadgeConfiguration = (planId: Plan['id'], badgeId: BadgeConfiguration['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: () => PlansApiClient.deletePlanBadgeConfiguration(planId, badgeId),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useCreatePlanCategory = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (request: CreatePlanCategoryRequest) => PlansApiClient.createCategory({ planId, ...request }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan section created successfully.' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useUpdatePlanCategory = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (request: Omit<Parameters<typeof PlansApiClient.updateCategory>[0], 'planId'>) =>
      PlansApiClient.updateCategory({ planId, ...request }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan section updated successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.list(planId) }),
      ]);
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeletePlanCategory = (planId: Plan['id'], categoryName: PlanCategory['name']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: () => PlansApiClient.deleteCategory({ planId, categoryName }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan section deleted successfully.' }));
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) });
      await queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId) });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePutPlanRulesOrder = (planId: Plan['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: plansMutationKeys.patch(planId),
    mutationFn: (request: PutPlanRulesOrderRequest) => PlansApiClient.putRulesOrder({ planId, ...request }),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan updated successfully.' }));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(planId) }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.list(planId) }),
      ]);
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
