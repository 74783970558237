import '@total-typescript/ts-reset';

function setLoadingScreenVisibility(visible: boolean) {
  const loaderElement = document.getElementById('global-loader');
  if (loaderElement) {
    loaderElement.style.display = visible ? 'flex' : 'none';
  }
}

export function showLoadingScreen() {
  setLoadingScreenVisibility(true);
}

export function hideLoadingScreen() {
  setLoadingScreenVisibility(false);
}
