import { VariableType } from '@amalia/amalia-lang/tokens/types';
import { assert } from '@amalia/ext/typescript';

import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ViewQuotaSubject, type AssignValuesQuotaSubject } from './subjects';

export const quotasAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.assign_values, SubjectsEnum.Quota);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
  },

  MANAGER({ user, hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ quotaType, userId, teamId, date }: ViewQuotaSubject) => {
        if (quotaType === VariableType.user) {
          return userId && (userId === user.id || hierarchy.isManagerOf(userId, date ?? new Date()));
        }

        if (quotaType === VariableType.team) {
          return (
            teamId &&
            // Either you're a direct member of this team (like any regular employee).
            (hierarchy.isTeamMember(teamId, date ?? new Date()) ||
              // Or you manage it, either directly or from a higher hierarchy level.
              hierarchy.isTeamManager(teamId, date ?? new Date()))
          );
        }

        return false;
      },
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });

    can(ActionsEnum.assign_values, SubjectsEnum.Quota, {
      predicate: ({ quotaType, userId, teamId, date }: AssignValuesQuotaSubject) => {
        if (quotaType === VariableType.user) {
          // Quota type should be discriminating but it's not so the subject cannot be either.
          assert(userId, 'User ID missing');
          return hierarchy.isManagerOf(userId, date ?? new Date());
        }

        if (quotaType === VariableType.team) {
          // Quota type should be discriminating but it's not so the subject cannot be either.
          assert(teamId, 'Team ID missing');
          return hierarchy.isTeamManager(teamId, date ?? new Date());
        }

        return false;
      },
    });
  },

  READ_ONLY_MANAGER({ user, hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ quotaType, userId, teamId, date }: ViewQuotaSubject) => {
        if (quotaType === VariableType.user) {
          return userId && (userId === user.id || hierarchy.isManagerOf(userId, date ?? new Date()));
        }

        if (quotaType === VariableType.team) {
          return (
            teamId &&
            // Either you're a direct member of this team (like any regular employee).
            (hierarchy.isTeamMember(teamId, date ?? new Date()) ||
              // Or you manage it, either directly or from a higher hierarchy level.
              hierarchy.isTeamManager(teamId, date ?? new Date()))
          );
        }

        return false;
      },
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });
  },

  EMPLOYEE({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ userId }: ViewQuotaSubject) => userId === user.id,
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });
  },

  READ_ONLY_EMPLOYEE({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ userId }: ViewQuotaSubject) => userId === user.id,
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
