import { type Property } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

import { type Option } from '../types/options';

export const getFieldAsOption = (definition: Pick<CustomObjectDefinition, 'properties'>, fieldName: string) => {
  const property = definition.properties[fieldName];
  return property
    ? {
        label: property.name,
        name: property.machineName,
      }
    : null;
};

export const getFieldsAsOptions = (
  definition: Pick<CustomObjectDefinition, 'properties'>,
): { name: string; label: string }[] =>
  Object.values(definition.properties).map((property) => ({
    label: property!.name,
    name: property!.machineName,
  }));

export const transformFieldToOption = (field: Property): Option => ({ value: field.machineName, label: field.name });
