import { type SingleValueLabelProps } from '@amalia/design-system/components';
import { type UserComputed } from '@amalia/tenants/users/types';

import { LabelDisplay } from '../../types';
import { UserPrettyFormat } from '../pretty-format/UserPrettyFormat';

import { type UserSelectOption } from './types';

export const UserSelectorSingleValueLabel = function UserSelectorSingleValueLabel<
  TProperty extends keyof UserComputed,
>({ option }: SingleValueLabelProps<UserSelectOption<TProperty>>) {
  const { user, value } = option;

  return (
    <UserPrettyFormat
      display={LabelDisplay.INLINE}
      firstName={user.firstName}
      lastName={user.lastName}
      pictureURL={user.pictureURL}
      subLabel={value ?? undefined}
    />
  );
};
