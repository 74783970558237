import { useMemo } from 'react';

import { type AssignmentDates, type AssignmentStatus, getAssignmentStatus } from '@amalia/assignments/common/shared';

export const getAssignmentsWithStatus = <TAssignment extends AssignmentDates>(
  assignments: TAssignment[],
  status: AssignmentStatus,
) => assignments.filter((assignment) => getAssignmentStatus(assignment) === status);

export const useAssignmentsWithStatus = <TAssignment extends AssignmentDates>(
  planAssignments: TAssignment[],
  status: AssignmentStatus,
) => useMemo(() => getAssignmentsWithStatus(planAssignments, status), [planAssignments, status]);
