import { findLastIndex } from 'lodash';
import { type AccessorNode, type ConstantNode } from 'mathjs';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type RecordContent } from '@amalia/data-capture/connectors/types';
import { type ComputeEngine2DTable } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { getValueOrFormula } from '../../utils';
import { getRowFieldAmount, getTableSlice } from '../common';

export const indicesRowMarginal = new AmaliaFunctionDefault<
  [RecordContent, ComputeEngine2DTable<number>, RecordContent[], string, string, number?],
  number
>({
  name: AmaliaFunctionKeys.rowMarginalIndex,
  category: AmaliaFunctionCategory.INDICES,
  nbParamsRequired: 5,
  description:
    'This function will get the index at which the `dataObject` is present ' +
    'in the `filteredRows`, and will compute a cumulative sum of the `fieldToSum` until this ' +
    'row, included. It will then apply the `table` to this cumulative sum.',

  exec: (row, table, rows, indexField, uniqueId, startFromRaw) => {
    const precision = 1_000_000;

    const startFrom = startFromRaw || 0;

    const rowToCompute = rows.find((r) => r[uniqueId] === row[uniqueId]);
    const indexRowToCompute = rows.findIndex((r) => r[uniqueId] === row[uniqueId]);

    if (!rowToCompute) {
      return 0;
    }

    // Retrieve rows until row to compare
    const sumPreviousRows =
      rows.slice(0, indexRowToCompute).reduce((acc, r) => acc + getRowFieldAmount(r, indexField), 0.0) + startFrom;

    const currentRowValue = getRowFieldAmount(rowToCompute, indexField);
    const sumWithCurrentRow = sumPreviousRows + currentRowValue;

    const rowMarginalTable = table.slice().map((slice) => getTableSlice(slice));

    // Get indices of related slices inside the table
    const startAtIndex = findLastIndex(rowMarginalTable, (tableSlice) => sumPreviousRows >= tableSlice.min);
    const endAtIndex = findLastIndex(rowMarginalTable, (tableSlice) => sumWithCurrentRow >= tableSlice.min);

    if (startAtIndex === -1 || endAtIndex === -1) {
      return 0;
    }

    // Sum all values between slices
    const sliceValues = rowMarginalTable
      // Get only related rows
      .slice(startAtIndex, endAtIndex + 1)
      // sum all computed values for each row
      // for first slice, get the max between the slice minimum and the previous rows
      // For last slice, get the min between the slide maximum and the sum with current row
      .reduce(
        (acc, slice) =>
          acc + (Math.min(slice.max, sumWithCurrentRow) - Math.max(slice.min, sumPreviousRows)) * slice.percent,
        0.0,
      );

    return Math.round((currentRowValue !== 0 ? sliceValues / currentRowValue : 0) * precision) / precision;
  },

  generateComputedFunctionResult: (args) => ({
    array: getValueOrFormula(args[2]),
    formula: `${(args[0] as AccessorNode).name}.${(args[3] as ConstantNode).value}` as AmaliaFormula,
  }),

  params: [
    { name: 'dataObject', description: 'Data object' },
    { name: 'table', description: 'Table for indices' },
    { name: 'rows', description: 'Filter of records: can be sorted' },
    { name: 'fieldToSum', description: 'Field used for cumulative sum' },
    { name: 'uniqueId', description: 'A unique id used by Amalia calculation system to retrieve the current line' },
    {
      name: 'startFrom',
      description: "Row marginal index computation will start cumulative sum from this value. By default it's 0.",
      defaultValue: 0,
    },
  ],

  examples: [
    {
      desc: 'Performs a marginal calculation of Amount Adjusted from opportunity based on the filter closedInPeriod starting from the oldest closing date.',
      formula:
        'rowMarginalIndex(opportunity, statement.commissionTable, SORT(filter.closedInPeriod, "closingDate"), "amountAdjusted", "id")' as AmaliaFormula,
    },
    {
      desc: 'Performs a marginal calculation of Amount Adjusted from opportunity based on the filter closedInPeriod starting from the oldest closing date and from record 2000.',
      formula:
        'rowMarginalIndex(opportunity, statement.commissionTable, SORT(filter.closedInPeriod, "closingDate"), "amountAdjusted", "id", 2000)' as AmaliaFormula,
    },
  ],
});
