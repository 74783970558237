import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const arrayIn = new AmaliaFunctionDefault<[(number | string)[], number | string], boolean>({
  name: AmaliaFunctionKeys.IN,
  category: AmaliaFunctionCategory.ARRAY,

  nbParamsRequired: 2,

  description: 'Return true if a given value is found in a given array',

  params: [
    { name: 'array', description: 'Array', validFormats: [FormatsEnum.table] },
    { name: 'searchValue', description: 'Value to look for' },
  ],

  examples: [
    {
      desc: 'Returns true if the team members array contains the ID of the user.',
      formula: 'IN(team.members, user.externalId)' as AmaliaFormula,
    },
    {
      desc: 'Returns true',
      formula: 'IN(["a","b","c"], "b")' as AmaliaFormula,
      result: true,
    },
    {
      desc: 'Returns false',
      formula: 'IN([12,13,14], 1)' as AmaliaFormula,
      result: false,
    },
  ],

  /**
   * Check that array contains exactly an other text.
   * @param sourceStringArray
   * @param searchString
   */
  exec: (sourceStringArray, searchString) =>
    sourceStringArray.some((element) => element.toString() === searchString.toString()),
});
