import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@amalia/design-system/components';
import { PlanVisibility } from '@amalia/payout-definition/plans/types';
import { usePatchPlanVisibility } from '@amalia/payout-definition/state';

import { type PlanVisibilityModalCommonProps } from './types';

export type PlanVisibilityPublishModalProps = PlanVisibilityModalCommonProps;

export const PlanVisibilityPublishModal = memo(function PlanVisibilityPublishModal({
  closeModal,
  isOpen,
  planId,
  planName,
}: PlanVisibilityPublishModalProps) {
  const { mutate: patchPlanVisibility } = usePatchPlanVisibility(planId);

  const handleClickPublish = useCallback(() => {
    patchPlanVisibility({
      visibility: PlanVisibility.LIVE,
    });

    closeModal();
  }, [closeModal, patchPlanVisibility]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Publish plan" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to publish the plan “<b>{planName}</b>”?{br}The users assigned to this plan will now be able to see their statements."
            values={{ planName, b: (chunks) => <strong>{chunks}</strong> }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={handleClickPublish}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
