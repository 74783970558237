export enum TrendDirection {
  DOWN = 'DOWN',
  FLAT = 'FLAT',
  UP = 'UP',
}

export enum TrendSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}
