import { round } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const numbersRound = new AmaliaFunctionDefault<[number, number], number>({
  name: AmaliaFunctionKeys.ROUND,
  category: AmaliaFunctionCategory.NUMBERS,
  nbParamsRequired: 1,
  description: 'Return a rounded number',

  exec: (numberInput, nbDecimals) => round(numberInput, nbDecimals),

  hiddenFromLibrary: true,

  params: [
    { name: 'input', description: 'Input number to round: numbers, fields, properties, variables' },
    {
      name: 'nbDecimals',
      description: 'The number of decimal places of the rounded output. By default it is 0.',
      defaultValue: '0',
    },
  ],

  examples: [
    {
      desc: 'Returns 5',
      formula: 'ROUND(5.3)' as AmaliaFormula,
      result: 5,
    },
    {
      desc: 'Returns 1.7',
      formula: 'ROUND(1.68, 1)' as AmaliaFormula,
      result: 1.7,
    },
    {
      desc: 'Returns 1.57',
      formula: 'ROUND(1.573, 2)' as AmaliaFormula,
      result: 1.57,
    },
    {
      desc: 'Returns the rounded Commission with 2 decimal places.',
      formula: 'ROUND(statement.commission, 2)',
    },
    {
      desc: 'Returns 150',
      formula: 'ROUND(154, -1)' as AmaliaFormula,
      result: 150,
    },
  ],
});
