import { type Theme, css } from '@emotion/react';

export const breadcrumbs = (theme: Theme) => css`
  display: flex;
  align-items: stretch;

  height: 36px;

  transition: ${theme.ds.transitions.default('width')};

  gap: 8px;

  /* Prevent children from shrinking. */
  > * {
    flex: none;
  }
`;

export const breadcrumbsItems = (theme: Theme) => css`
  display: flex;
  align-items: center;
  padding: 0 8px;

  border-radius: ${theme.ds.borderRadiuses.round};

  /* Gap is 8px + width of separator (in absolute positioning) + 8px. */
  gap: 21px;

  > * {
    /* Prevent children from shrinking. */
    flex: none;

    /* Used to position the separator. */
    position: relative;

    /* Items width boundaries and text-overflow. */
    max-width: 256px;
  }

  /* Add a / separator. */
  > *:not(:first-child) {
    &::before {
      content: '';

      display: block;

      border-left: 1px solid ${theme.ds.colors.gray[200]};

      height: 15.5px;
      width: 0;

      position: absolute;
      /* 8px + half of width of separator. */
      left: -11px;

      /* Center vertically. */
      top: 50%;
      transform: translateY(-50%) rotate(17.26deg);
    }
  }
`;
