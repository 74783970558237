import { type SetRequired } from 'type-fest';

import { type UnixTimestampInSeconds } from '@amalia/ext/dates';
import { TeamAssignmentUserType, type TeamContract } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

export interface TeamAssignment {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  effectiveAsOf: UnixTimestampInSeconds | null;
  effectiveUntil: UnixTimestampInSeconds | null;
  teamRole: TeamRole;

  team?: TeamContract;
  teamId: TeamContract['id'];
  user?: UserContract;
  userId: UserContract['id'];

  // TODO is this used?
  hierarchy?: string[];
}

export type TeamAssignmentWithTeam = SetRequired<TeamAssignment, 'team'>;
export type TeamAssignmentWithUser = SetRequired<TeamAssignment, 'user'>;

export enum TeamRole {
  TEAM_EMPLOYEE = 'TEAM_EMPLOYEE',
  TEAM_MANAGER = 'TEAM_MANAGER',
}

export const TeamRoleToTeamAssignmentUserType: Record<TeamRole, TeamAssignmentUserType> = {
  [TeamRole.TEAM_MANAGER]: TeamAssignmentUserType.MANAGER,
  [TeamRole.TEAM_EMPLOYEE]: TeamAssignmentUserType.EMPLOYEE,
} as const;
