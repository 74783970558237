import { type Filter, type Plan } from '@amalia/payout-definition/plans/types';

export const filtersQueryKeys = {
  all: () => ['filters'] as const,
  list: () => [...filtersQueryKeys.all(), 'list'] as const,
  ofPlan: (planId: Plan['id']) => [...filtersQueryKeys.all(), 'of-plan', planId] as const,
  details: (filterId: Filter['id']) => [...filtersQueryKeys.all(), 'details', filterId] as const,
} as const;

export const filtersMutationKeys = {
  all: () => ['filters'] as const,
  create: () => [...filtersMutationKeys.all(), 'create'] as const,
  patch: () => [...filtersMutationKeys.all(), 'patch'] as const,
  delete: () => [...filtersMutationKeys.all(), 'delete'] as const,
} as const;
