import { type RefObject, useEffect, useMemo, useState } from 'react';

export const useIsInViewport = (ref: RefObject<Element | null | undefined>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    // Note: this only works if the element is always mounted.
    // Might need to use a callback ref instead.
    observer.observe(ref.current!);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};
