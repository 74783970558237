import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useMutationState,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { last } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { useFeedback } from '@amalia/ext/react/hooks';
import { assert, toError } from '@amalia/ext/typescript';
import { UserApiClient } from '@amalia/tenants/users/api-client';
import { UserProfileApiClient } from '@amalia/tenants/users/profile/api-client';
import { type UpdateUserProfileRequest, type UserProfile } from '@amalia/tenants/users/profile/types';

import { userProfileMutationKeys, userProfileQueryKeys } from './queries.keys';

export const useAuthorizedProfiles = (options?: { enabled?: boolean }) => {
  const { data, ...rest } = useQuery({
    queryKey: userProfileQueryKeys.list(),
    queryFn: () => UserProfileApiClient.getAuthorizedProfiles(),
    ...options,
  });

  return {
    ...rest,
    data: useMemo(() => data ?? [], [data]),
  };
};

export const useUserProfile = (userId?: UserProfile['id']) =>
  useQuery({
    queryKey: userProfileQueryKeys.details(userId!),
    queryFn: () => {
      // enabled should have made sure userId is defined.
      assert(userId, 'userId is required');
      return UserProfileApiClient.getUserProfile(userId);
    },
    enabled: !!userId,
  });

export const useUpdateUserInfo = (
  userId: UserProfile['id'],
  { shouldInviteToReload = () => false }: { shouldInviteToReload?: (updatedUser: UserProfile) => boolean } = {},
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: userProfileMutationKeys.updateUserInfo(userId),
    mutationFn: (user: UpdateUserProfileRequest) => UserProfileApiClient.updateUserInfo(user),
    onSuccess: async (updatedUser) => {
      snackSuccess(
        shouldInviteToReload(updatedUser)
          ? formatMessage({
              defaultMessage:
                'Your language has been successfully updated. Please refresh the page to apply the changes.',
            })
          : formatMessage(
              { defaultMessage: "{firstName} {lastName}'s information has been successfully updated." },
              { firstName: updatedUser.firstName, lastName: updatedUser.lastName },
            ),
      );

      // Invalidate all profiles to refresh useAuthorizedProfiles() too
      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useToggleUserDeactivation = (userId: UserProfile['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: userProfileMutationKeys.toggleDeactivation(userId),
    mutationFn: () => UserProfileApiClient.toggleUserDeactivation(userId),
    onSuccess: async (updatedUser: UserProfile) => {
      if (updatedUser.clearedAt) {
        snackSuccess(
          formatMessage(
            { defaultMessage: '{firstName} {lastName} has been successfully deactivated.' },
            {
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
            },
          ),
        );
      } else {
        snackSuccess(
          formatMessage(
            { defaultMessage: '{firstName} {lastName} has been successfully reactivated.' },
            {
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
            },
          ),
        );
      }

      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useInviteUser = (user: Pick<UserProfile, 'firstName' | 'id' | 'lastName'>) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();
  const [isSuccess, setSuccess] = useFeedback();

  const mutation = useMutation({
    mutationKey: userProfileMutationKeys.inviteUser(user.id),
    mutationFn: () => UserProfileApiClient.inviteUser(user.id),
    onSuccess: async () => {
      setSuccess(true);
      snackSuccess(
        formatMessage(
          { defaultMessage: 'Invitation has been successfully sent to {firstName} {lastName}' },
          { firstName: user.firstName, lastName: user.lastName },
        ),
      );

      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: (e) => {
      snackError(
        formatMessage(
          {
            defaultMessage:
              'Invitation could not be sent to {firstName} {lastName} due to: {message}. Please try again',
          },
          { firstName: user.firstName, lastName: user.lastName, message: toError(e).message },
        ),
      );
    },
  });

  return { ...mutation, isSuccess };
};

export const useInviteUsers = (userIds: string[]) => {
  const queryClient = useQueryClient();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();
  const [isSuccess, setSuccess] = useFeedback();

  const mutation = useMutation({
    mutationKey: userProfileMutationKeys.inviteUsers(userIds),
    mutationFn: () => UserProfileApiClient.inviteUsers(userIds),
    onSuccess: async () => {
      setSuccess(true);
      snackSuccess(
        formatMessage(
          {
            defaultMessage: 'Invitation has been successfully sent to {count, plural, one {# user} other {# users}}',
          },
          { count: userIds.length },
        ),
      );
      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: async (error: unknown) => {
      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });

      if (!UserProfileApiClient.isBulkInvitationError(error)) {
        return;
      }

      const { errors } = error.response.data;

      snackSuccess(
        formatMessage(
          {
            defaultMessage: 'Invitation has been successfully sent to {count, plural, one {# user} other {# users}}',
          },
          { count: userIds.length - errors.length },
        ),
      );
    },
  });

  return { ...mutation, isSuccess };
};

export const useInviteUsersState = () =>
  last(
    useMutationState({
      filters: { mutationKey: userProfileMutationKeys.anyInviteUsers() },
      select: (mutation) => mutation.state,
    }).toSorted((a, b) => /* ASC */ a.submittedAt - b.submittedAt),
  );

export const useIsUpdatingUserInfo = () => useIsMutating({ mutationKey: userProfileMutationKeys.all() }) > 0;

export const useIsFetchingUserProfile = (userId?: UserProfile['id']) =>
  useIsFetching({ queryKey: userId ? userProfileQueryKeys.details(userId) : userProfileQueryKeys.all() }) > 0;

export const useSyncUsers = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: userProfileMutationKeys.sync(),
    mutationFn: UserApiClient.bulkSyncUsers,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Users have been created successfully.' }));
      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: (err) => {
      snackError(
        formatMessage({ defaultMessage: 'Users could not be created: {errorMessage}.' }, { errorMessage: err.message }),
      );
    },
  });
};
