import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ViewPlanSubject } from './subjects';

const nonAdminCanAccessPlan = (plan: ViewPlanSubject) => plan.isHidden === false;

export const plansAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.Plan);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, {
      predicate: (plan: ViewPlanSubject) => nonAdminCanAccessPlan(plan),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, {
      predicate: (plan: ViewPlanSubject) => nonAdminCanAccessPlan(plan),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });
  },

  EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, {
      predicate: (plan: ViewPlanSubject) => nonAdminCanAccessPlan(plan),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });
  },

  READ_ONLY_EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Plan, {
      predicate: (plan: ViewPlanSubject) => nonAdminCanAccessPlan(plan),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
