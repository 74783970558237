import { ActionsEnum, SubjectsEnum, type UserRoleForAccessControl, type DefinePermissions } from '../../types';

export const companiesAbilityDefinitions = {
  AMALIA_ADMIN(_, { can }) {
    can(ActionsEnum.modify_directory_email_domain, SubjectsEnum.Company);
  },

  // COMMON ROLES
  ADMIN(_, { can }) {
    can(ActionsEnum.view_settings, SubjectsEnum.Company);
    can(ActionsEnum.modify_settings, SubjectsEnum.Company);

    can(ActionsEnum.view_rates, SubjectsEnum.Company);
    can(ActionsEnum.modify_rates, SubjectsEnum.Company);

    can(ActionsEnum.view_directory, SubjectsEnum.Company);

    can(ActionsEnum.delete, SubjectsEnum.Company);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
