export const PLANS_ACTIONS = {
  START: 'PLANS/START',
  ERROR: 'PLANS/ERROR',

  SET_PLANS: 'PLANS/SET_PLANS',
  SET_CURRENT_PLAN: 'PLANS/SET_CURRENT_PLAN',

  RENAME_PLAN: 'PLANS/RENAME_PLAN',
  DELETE_PLAN: 'PLANS/DELETE_PLAN',
  ARCHIVE_PLAN: 'PLANS/ARCHIVE_PLAN',

  SET_SETTINGS: 'PLANS/SET_SETTINGS',

  SET_HIGHLIGHTED_KPI: 'PLANS/SET_HIGHLIGHTED_KPI',
  DELETE_HIGHLIGHTED_KPI: 'PLANS/DELETE_HIGHLIGHTED_KPI',

  ADD_PLAN_RULES: 'PLANS/ADD_PLAN_RULES',
  ON_PATCH_RULES: 'PLANS/ON_PATCH_RULES',
  ON_REMOVE_RULE: 'PLANS/ON_REMOVE_RULE',
  ON_REMOVE_RULE_BY_ID: 'PLANS/ON_REMOVE_RULE_BY_ID',
  ON_PATCH_RULE_KPIS_TO_DISPLAY: 'PLANS/ON_PATCH_RULE_KPIS_TO_DISPLAY',
  ON_PATCH_RULE_KPI_TO_DISPLAY_STATUS: 'PLANS/ON_PATCH_RULE_KPI_TO_DISPLAY_STATUS',
  ON_PATCH_RULE_REMOVE_KPI_TO_DISPLAY: 'PLANS/ON_PATCH_RULE_REMOVE_KPI_TO_DISPLAY',
  ON_PATCH_RULE_FILTERS_TO_DISPLAY: 'PLANS/ON_PATCH_RULE_FILTERS_TO_DISPLAY',
  ON_PATCH_RULE_FILTER_TO_DISPLAY_STATUS: 'PLANS/ON_PATCH_RULE_FILTER_TO_DISPLAY_STATUS',
  ON_PATCH_RULE_REMOVE_FILTER_TO_DISPLAY: 'PLANS/ON_PATCH_RULE_REMOVE_FILTER_TO_DISPLAY',
  ON_PATCH_RULE_FIELDS_TO_DISPLAY: 'PLANS/ON_PATCH_RULE_FIELDS_TO_DISPLAY',
  ON_PATCH_RULE_FIELD_TO_DISPLAY_STATUS: 'PLANS/ON_PATCH_RULE_FIELD_TO_DISPLAY_STATUS',
  ON_PATCH_RULE_REMOVE_FIELD_TO_DISPLAY: 'PLANS/ON_PATCH_RULE_REMOVE_FIELD_TO_DISPLAY',

  CREATE_CATEGORY: 'PLANS/CREATE_CATEGORY',
  DELETE_CATEGORY: 'PLANS/DELETE_CATEGORY',
  EDIT_CATEGORY: 'PLANS/EDIT_CATEGORY',
  MOVE_CATEGORY: 'PLANS/MOVE_CATEGORY',
  MOVE_RULE: 'PLANS/MOVE_RULE',
  EDIT_VISIBILITY: 'PLANS/EDIT_VISIBILITY',
} as const;
