import { type TokenType, type VariableType } from '@amalia/amalia-lang/tokens/types';

import { type FormulaKeyword } from '../formulaKeywords';

export enum ValueOrAttributeType {
  VALUE = 'VALUE',
  ATTRIBUTE = 'ATTRIBUTE',
}

export type ValueOrAttribute = AttributeValue | ManualValue;

export type ManualValue<TValue extends number | string | null = string> = {
  type: ValueOrAttributeType.VALUE;
  value: TValue;
};

export type AttributeValue =
  | AttributeValueField
  | AttributeValueKeyword
  | AttributeValueProperty
  | AttributeValueQuota
  | AttributeValueRelationship
  | AttributeValueVariable;

export type AttributeValueRelationship = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.LINK;
  objectMachineName: string;
  relationshipMachineName: string;
  propertyMachineName: string;
};

export type AttributeValueProperty = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.PROPERTY | TokenType.VIRTUAL_PROPERTY;
  objectMachineName: string;
  propertyMachineName: string;
};

export type AttributeValueField = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.FIELD;
  objectMachineName: string;
  propertyMachineName: string;
};

export type AttributeValueVariable = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.VARIABLE;
  machineName: string;
};

export type AttributeValueQuota = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.QUOTA;
  quotaType: VariableType;
  machineName: string;
};

export type AttributeValueKeyword = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.KEYWORD;
  keyword: FormulaKeyword;
};
