import { useTheme } from '@emotion/react';
import { IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CollapseIconChevron } from '../../../../../general/collapse-icon-chevron/CollapseIconChevron';
import { IconAction } from '../../../../../general/icon-action/IconAction';
import { TextOverflow } from '../../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../../general/typography/Typography';
import { UnstyledButton } from '../../../../../general/unstyled-button/UnstyledButton';
import { type SelectDropdownChildRenderProps } from '../../../../../overlays/select-dropdown/SelectDropdown.types';
import { TableDataCellContent } from '../../../layout/table-data-cell-content/TableDataCellContent';
import { CellErrorIndicator } from '../../cell-error-indicator/CellErrorIndicator';
import { type CellSelectOption } from '../CellSelect.types';

import {
  CellSelectMultiValueContainer,
  type CellSelectMultiValueContainerProps,
} from './cell-select-multi-value-container/CellSelectMultiValueContainer';
import {
  CellSelectSingleValue,
  type CellSelectSingleValueProps,
} from './cell-select-single-value/CellSelectSingleValue';
import * as styles from './CellSelectControl.styles';

export type CellSelectControlProps<
  TOption extends CellSelectOption = CellSelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
> = SelectDropdownChildRenderProps<TOption, TIsMultiple> & {
  /** Id to apply to the button. */
  readonly id?: string;
  /** Is the select disabled. */
  readonly disabled?: boolean;
  /** Error message. */
  readonly error?: ReactNode;
  /** Is multi-select. */
  readonly isMultiple?: TIsMultiple;
  /** Can clear the value in a single select. */
  readonly isClearable?: TIsClearable;
  /** Override default placeholder. */
  readonly placeholder?: string;
  /** Component to render an option in single select mode. */
  readonly SingleValueComponent?: CellSelectSingleValueProps<TOption>['LabelComponent'];
  /** Component to render an option in multi select mode. */
  readonly MultiValueComponent?: CellSelectMultiValueContainerProps<TOption>['LabelComponent'];
};

export const CellSelectControl = function CellSelectControl<
  TOption extends CellSelectOption = CellSelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
>({
  id,
  disabled,
  error,
  isMultiple,
  isClearable,
  placeholder,
  value,
  onClear,
  isDropdownOpen,
  SingleValueComponent,
  MultiValueComponent,
}: CellSelectControlProps<TOption, TIsMultiple, TIsClearable>) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const shouldShowClear = !!isDropdownOpen && !!isClearable && !disabled && !isEmpty(value);

  return (
    <div
      css={styles.cellSelectControl}
      className={clsx({
        [styles.IS_DISABLED_CLASSNAME]: disabled,
        [styles.IS_FOCUSED_CLASSNAME]: isDropdownOpen,
        [styles.HAS_ERROR_CLASSNAME]: !!error,
      })}
    >
      <UnstyledButton
        css={styles.buttonContainer}
        disabled={disabled}
        id={id}
        role="combobox"
      >
        <TableDataCellContent css={styles.dataCellContent(!!error, shouldShowClear)}>
          {isEmpty(value) ? (
            <Typography
              as={TextOverflow}
              css={styles.placeholder}
              variant={Typography.Variant.BODY_BASE_REGULAR}
            >
              {placeholder || <FormattedMessage defaultMessage="Select an option" />}
            </Typography>
          ) : isMultiple ? (
            <CellSelectMultiValueContainer
              disabled={disabled}
              LabelComponent={MultiValueComponent}
              options={value as TOption[]}
            />
          ) : (
            <CellSelectSingleValue
              disabled={disabled}
              LabelComponent={SingleValueComponent}
              option={value as TOption}
            />
          )}
        </TableDataCellContent>
      </UnstyledButton>

      <div css={styles.indicatorsContainer}>
        <CellErrorIndicator
          data-testid="error-anchor"
          error={error}
        />

        {!!shouldShowClear && (
          <IconAction
            key="clear"
            icon={<IconX />}
            label={formatMessage({ defaultMessage: 'Clear value' })}
            size={IconAction.Size.SMALL}
            variant={IconAction.Variant.DANGER}
            onClick={onClear}
          />
        )}

        <CollapseIconChevron
          key="chevron"
          color={disabled ? theme.ds.colors.gray[300] : theme.ds.colors.gray[800]}
          isOpen={isDropdownOpen}
          size={19.2}
        />
      </div>
    </div>
  );
};
