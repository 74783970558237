import { type CustomReportFacetRequest, type CustomReport } from '@amalia/reporting/custom-reports/shared';

export const customReportsQueryKeys = {
  all: () => ['custom-reports'] as const,
  list: () => [...customReportsQueryKeys.all(), 'list'] as const,
  ofCustomReport: {
    all: (customReportId: CustomReport['id']) =>
      [...customReportsQueryKeys.all(), 'of-custom-report', customReportId] as const,
    facet: (customReportId: CustomReport['id'], customReportFacetRequest: CustomReportFacetRequest) =>
      [...customReportsQueryKeys.ofCustomReport.all(customReportId), 'facet', customReportFacetRequest] as const,
  } as const,
} as const;

export const customReportsMutationKeys = {
  all: () => ['custom-reports'] as const,
  create: () => [...customReportsMutationKeys.all(), 'create'] as const,
  patch: () => [...customReportsMutationKeys.all(), 'patch'] as const,
  duplicate: () => [...customReportsMutationKeys.all(), 'duplicate'] as const,
  delete: () => [...customReportsMutationKeys.all(), 'delete'] as const,
} as const;

export const customReportFoldersQueryKeys = {
  all: () => ['custom-reports-folders'] as const,
  list: () => [...customReportFoldersQueryKeys.all(), 'list'] as const,
} as const;

export const customReportFoldersMutationKeys = {
  all: () => ['custom-reports-folders'] as const,
  create: () => [...customReportFoldersMutationKeys.all(), 'create'] as const,
  patch: () => [...customReportFoldersMutationKeys.all(), 'patch'] as const,
  delete: () => [...customReportFoldersMutationKeys.all(), 'delete'] as const,
} as const;
