import { css } from '@emotion/react';
import { type ReactNode, memo } from 'react';

import { IconAmalia } from '@amalia/frontend/ui-icons';

export type LayoutUnauthenticatedProps = {
  /** Page content; */
  readonly children: ReactNode;
};

export const LayoutUnauthenticated = memo(function LayoutUnauthenticated({ children }: LayoutUnauthenticatedProps) {
  return (
    <div
      css={css`
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 64px;
      `}
    >
      <IconAmalia
        height={20.1}
        width={108}
      />

      <div>{children}</div>
    </div>
  );
});
