import { isEnum } from '@amalia/ext/typescript';

import { type SubmissionError } from '../errors/submission-error';

export enum FormikStatusType {
  SUBMISSION_ERROR = 'SUBMISSION_ERROR',
  SUBMISSION_SUCCESS = 'SUBMISSION_SUCCESS',
}

export type FormikStatusSubmissionError = {
  type: FormikStatusType.SUBMISSION_ERROR;
  error: SubmissionError;
};

export type FormikStatusSubmissionSuccess<TSubmitResponse> = {
  type: FormikStatusType.SUBMISSION_SUCCESS;
  response: TSubmitResponse;
};

// Union of all possible formik statuses.
// For now there is only one.
export type FormikStatus<TSubmitResponse> =
  | FormikStatusSubmissionError
  | FormikStatusSubmissionSuccess<TSubmitResponse>;

export const isFormikStatus = <TSubmitResponse>(status: unknown): status is FormikStatus<TSubmitResponse> =>
  !!(status && typeof status === 'object' && 'type' in status && isEnum(status.type, FormikStatusType));

export const isFormikStatusSubmissionError = (status: unknown): status is FormikStatusSubmissionError =>
  isFormikStatus(status) && status.type === FormikStatusType.SUBMISSION_ERROR;

export const isFormikStatusSubmissionSuccess = <TSubmitResponse>(
  status: unknown,
): status is FormikStatusSubmissionSuccess<TSubmitResponse> =>
  isFormikStatus<TSubmitResponse>(status) && status.type === FormikStatusType.SUBMISSION_SUCCESS;
