import { type GetUserTeamAssignmentsOptions } from '@amalia/tenants/assignments/teams/api-client';
import { type TeamRole } from '@amalia/tenants/assignments/teams/types';
import { type TeamContract } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

export const teamAssignmentsQueryKeys = {
  all: () => ['team-assignments'] as const,
  users: () => [...teamAssignmentsQueryKeys.all(), 'users'] as const,
  user: (userId: UserContract['id'], options?: GetUserTeamAssignmentsOptions) =>
    [
      ...teamAssignmentsQueryKeys.users(),
      userId,
      options?.effectiveBetween?.start,
      options?.effectiveBetween?.end,
      options?.teamRole,
    ] as const,
  teams: <TRelations extends ('team' | 'user')[] = []>(filters: {
    teamsIds: TeamContract['id'][];
    teamRole?: TeamRole;
    relations?: TRelations;
  }) => [...teamAssignmentsQueryKeys.all(), 'teams', filters] as const,
} as const;

export const teamAssignmentsMutationKeys = {
  all: () => ['team-assignments'] as const,
  create: () => [...teamAssignmentsMutationKeys.all(), 'create'] as const,
  update: () => [...teamAssignmentsMutationKeys.all(), 'update'] as const,
  delete: () => [...teamAssignmentsMutationKeys.all(), 'delete'] as const,
  validateBulkImport: () => [...teamAssignmentsMutationKeys.all(), 'validate-bulk-import'] as const,
  confirmBulkImport: () => [...teamAssignmentsMutationKeys.all(), 'confirm-bulk-import'] as const,
} as const;

export const teamPlanAssignmentsQueryKeys = {
  all: () => ['team-plan-assignments'] as const,
  team: (teamId: TeamContract['id']) => ['team-plan-assignments', teamId] as const,
} as const;

export const teamPlanAssignmentsMutationKeys = {
  all: () => ['team-plan-assignments'] as const,
  create: (teamId?: TeamContract['id']) => [...teamPlanAssignmentsMutationKeys.all(), 'create', teamId] as const,
  update: (teamId?: TeamContract['id']) => [...teamPlanAssignmentsMutationKeys.all(), 'update', teamId] as const,
  delete: (teamId?: TeamContract['id']) => [...teamPlanAssignmentsMutationKeys.all(), 'delete', teamId] as const,
} as const;
