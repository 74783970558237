import { type Quota } from '@amalia/amalia-lang/tokens/types';
import {
  type DeleteQuotaAssignmentsRequest,
  type DeleteQuotaAssignmentRequest,
  type UpsertQuotaAssignmentRequest,
  type UpsertQuotasAssignmentsRequest,
  type QuotaPlanAssignments,
  type SearchQuotaAssignmentsRequest,
  type QuotaTeamAssignments,
  type QuotaUserAssignments,
} from '@amalia/assignments/quotas/types';
import { http } from '@amalia/core/http/client';

export class QuotasAssignmentsApiClient {
  public static async getQuotaAssignments<
    TQuotaAssignments extends QuotaPlanAssignments | QuotaTeamAssignments | QuotaUserAssignments,
  >(quotaId: Quota['id'], searchQuotaAssignmentsRequest: SearchQuotaAssignmentsRequest) {
    return (
      await http.post<TQuotaAssignments[]>(
        `/quotas/${encodeURIComponent(quotaId)}/assignments/searches`,
        searchQuotaAssignmentsRequest,
      )
    ).data;
  }

  public static async getQuotaPlanAssignments(
    quotaId: Quota['id'],
    { startDate, endDate }: SearchQuotaAssignmentsRequest,
  ) {
    return (
      await http.post<QuotaPlanAssignments[]>(`/quotas/${encodeURIComponent(quotaId)}/assignments/searches`, {
        startDate,
        endDate,
      })
    ).data;
  }

  public static async upsertQuotaAssignment(upsertQuotaAssignmentRequest: UpsertQuotaAssignmentRequest) {
    await http.post('/quotas-assignments', upsertQuotaAssignmentRequest);
  }

  public static async upsertQuotasAssignments(upsertQuotasAssignmentsRequest: UpsertQuotasAssignmentsRequest) {
    await http.post('/quotas-assignments/bulk', upsertQuotasAssignmentsRequest);
  }

  public static async deleteQuotaAssignments(deleteQuotaAssignmentsRequest: DeleteQuotaAssignmentsRequest) {
    await http.delete('/quotas-assignments', { data: deleteQuotaAssignmentsRequest });
  }

  public static async deleteQuotaAssignment({ id }: DeleteQuotaAssignmentRequest) {
    await http.delete(`/quotas-assignments/${encodeURIComponent(id)}`);
  }
}
