import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type Plan, type PlanForecast } from '@amalia/payout-definition/plans/types';

import { PlanForecastApiClient } from './plan-forecast.api-client';
import { plansForecastMutationKeys, plansForecastQueryKeys } from './plan-forecast.keys';

export const usePlanForecast = ({
  planId,
  planForecastId,
  enabled,
}: {
  planId: Plan['id'];
  planForecastId?: PlanForecast['id'];
  enabled: boolean;
}) =>
  useQuery({
    queryFn: () => PlanForecastApiClient.get(planId, planForecastId!),
    queryKey: plansForecastQueryKeys.forecastDetails(planId, planForecastId!),
    enabled: enabled && !!planForecastId,
  });

export const useUpdatePlanForecast = ({
  planId,
  planForecastId,
}: {
  planId: Plan['id'];
  planForecastId: PlanForecast['id'];
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: plansForecastMutationKeys.update(planId, planForecastId),
    mutationFn: (planForecastUpdated: PlanForecast) =>
      PlanForecastApiClient.update(planId, planForecastId, planForecastUpdated),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: plansForecastQueryKeys.forecastDetails(planId, planForecastId),
      });
    },
  });
};
