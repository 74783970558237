import { type Merge } from '@amalia/ext/typescript';

import { type Variable, VariableType } from './variables';

export const QUOTA_TYPES = [VariableType.plan, VariableType.team, VariableType.user] as const;
export type QuotaType = (typeof QUOTA_TYPES)[number];

/** FIXME: extract quota to own concept */
export type Quota = Merge<
  Variable,
  {
    userId?: string;
    type: QuotaType;
  }
>;

export type UserQuota = Merge<Quota, { type: VariableType.user }>;
export type TeamQuota = Merge<Quota, { type: VariableType.team }>;
export type PlanQuota = Merge<Quota, { type: VariableType.plan }>;
