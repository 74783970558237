import { type Plan, type PlanForecast } from '@amalia/payout-definition/plans/types';

export const plansForecastQueryKeys = {
  all: () => ['plan-forecast'] as const,
  details: (planId: Plan['id']) => [...plansForecastQueryKeys.all(), 'details', planId] as const,
  forecastDetails: (planId: Plan['id'], planForecastId: PlanForecast['id']) =>
    [...plansForecastQueryKeys.details(planId), planForecastId] as const,
} as const;

export const plansForecastMutationKeys = {
  all: () => ['plan-forecast'] as const,
  update: (planId: Plan['id'], planForecastId: PlanForecast['id']) =>
    [...plansForecastMutationKeys.all(), 'update', planId, planForecastId] as const,
} as const;
