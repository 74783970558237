import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const stringLower = new AmaliaFunctionDefault<[(number | string)?], string>({
  name: AmaliaFunctionKeys.LOWER,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 0,
  description: 'Return the string in lower case',

  exec: (stringToLower) => stringToLower?.toString().toLowerCase() ?? '',

  params: [{ name: 'string', description: 'String: variables, fields, properties, string' }],

  examples: [
    {
      desc: 'Returns hello.',
      formula: 'LOWER("Hello")' as AmaliaFormula,
      result: 'hello',
    },
    {
      desc: "Returns the user's first name in lower case.",
      formula: 'LOWER(user.firstName)',
    },
  ],
});
