export enum CSV_DATA_TYPES {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  CURRENCY = 'currency',
  NULL = 'null',
}

export const CSV_DATE_FORMAT = {
  'YYYY-M-D': 'YYYY-MM-DD',
  'YYYY-MM-DD': 'YYYY-MM-DD',
  'YYYY/M/D': 'YYYY/MM/DD',
  'YYYY/MM/DD': 'YYYY/MM/DD',
  YYYYMMDD: 'YYYYMMDD',
  YYYYDDMM: 'YYYYDDMM',
  'M/D/YYYY': 'MM/DD/YYYY',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'D/M/YYYY': 'DD/MM/YYYY',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'D-M-YYYY': 'DD-MM-YYYY',
  'DD-MM-YYYY': 'DD-MM-YYYY',
  'M-D-YYYY': 'MM-DD-YYYY',
  'MM-DD-YYYY': 'MM-DD-YYYY',
} as const;

export type CSV_DATE_FORMAT_TYPE = (typeof CSV_DATE_FORMAT)[keyof typeof CSV_DATE_FORMAT];

/**
 * Dayjs is less permissive than moment.js for parsing dates.
 * In database we store the value of CSV_DATE_FORMAT for some reason (shouldn't it be the key?),
 * so a quick fix is to map the value in database to a more permissive format for dayjs.
 */
export const CSV_DATE_FORMAT_INPUT: Record<CSV_DATE_FORMAT_TYPE, string> = {
  'YYYY-MM-DD': 'YYYY-M-D',
  'YYYY/MM/DD': 'YYYY/M/D',
  YYYYMMDD: 'YYYYMMDD',
  YYYYDDMM: 'YYYYDDMM',
  'MM/DD/YYYY': 'M/D/YYYY',
  'DD/MM/YYYY': 'D/M/YYYY',
  'DD-MM-YYYY': 'D-M-YYYY',
  'MM-DD-YYYY': 'M-D-YYYY',
} as const;

export const CSV_DATETIME_FORMAT = {
  'YYYY-M-D h:m:s': 'YYYY-MM-DD hh:mm:ss',
  'YYYY-MM-DD h:m:s': 'YYYY-MM-DD hh:mm:ss',
  'YYYY/M/D h:m:s': 'YYYY/MM/DD hh:mm:ss',
  'YYYY/MM/DD h:m:s': 'YYYY/MM/DD hh:mm:ss',
  'YYYYMMDD h:m:s': 'YYYYMMDD hh:mm:ss',
  'YYYYDDMM h:m:s': 'YYYYDDMM hh:mm:ss',
  'M/D/YYYY h:m:s': 'MM/DD/YYYY hh:mm:ss',
  'MM/DD/YYYY h:m:s': 'MM/DD/YYYY hh:mm:ss',
  'D/M/YYYY h:m:s': 'DD/MM/YYYY hh:mm:ss',
  'DD/MM/YYYY h:m:s': 'DD/MM/YYYY hh:mm:ss',
  'D-M-YYYY h:m:s': 'DD-MM-YYYY hh:mm:ss',
  'DD-MM-YYYY h:m:s': 'DD-MM-YYYY hh:mm:ss',
  'M-D-YYYY h:m:s': 'MM-DD-YYYY hh:mm:ss',
  'MM-DD-YYYY h:m:s': 'MM-DD-YYYY hh:mm:ss',
} as const;

export type CSV_DATETIME_FORMAT_TYPE = (typeof CSV_DATETIME_FORMAT)[keyof typeof CSV_DATETIME_FORMAT];

/**
 * Dayjs is less permissive than moment.js for parsing dates.
 * In database we store the value of CSV_DATETIME_FORMAT for some reason (shouldn't it be the key?),
 * so a quick fix is to map the value in database to a more permissive format for dayjs.
 */
export const CSV_DATETIME_FORMAT_INPUT: Record<CSV_DATETIME_FORMAT_TYPE, string> = {
  'YYYY-MM-DD hh:mm:ss': 'YYYY-M-D h:m:s',
  'YYYY/MM/DD hh:mm:ss': 'YYYY/M/D h:m:s',
  'YYYYMMDD hh:mm:ss': 'YYYYMMDD h:m:s',
  'YYYYDDMM hh:mm:ss': 'YYYYDDMM h:m:s',
  'MM/DD/YYYY hh:mm:ss': 'M/D/YYYY h:m:s',
  'DD/MM/YYYY hh:mm:ss': 'D/M/YYYY h:m:s',
  'DD-MM-YYYY hh:mm:ss': 'D-M-YYYY h:m:s',
  'MM-DD-YYYY hh:mm:ss': 'M-D-YYYY h:m:s',
} as const;
