import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo, forwardRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import * as styles from './CountBadge.styles';
import { CountBadgeSize, CountBadgeVariant } from './CountBadge.types';

export type CountBadgeProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      disabled?: boolean;
      size?: CountBadgeSize;
      variant?: CountBadgeVariant;
      withStroke?: boolean;
    },
  ]
>;

const CountBadgeBase = memo(
  forwardRef<HTMLDivElement, CountBadgeProps>(function CountBadge(
    {
      disabled = false,
      size = CountBadgeSize.MEDIUM,
      variant = CountBadgeVariant.NEUTRAL,
      withStroke = false,
      children,
      ...props
    },
    ref,
  ) {
    return (
      <div
        {...props}
        ref={ref}
        css={styles.countBadge}
        className={clsx(props.className, variant, size, {
          [styles.IS_DISABLED_CLASSNAME]: disabled,
          [styles.WITH_STROKE_CLASSNAME]: withStroke,
        })}
      >
        {children}
      </div>
    );
  }),
);

export const CountBadge = Object.assign(CountBadgeBase, {
  Size: CountBadgeSize,
  Variant: CountBadgeVariant,
});
