import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import {
  customReportFoldersMutationKeys,
  customReportFoldersQueryKeys,
  customReportsQueryKeys,
} from './custom-reports.keys';

export const useCustomReportsFolders = () =>
  useQuery({
    queryKey: customReportFoldersQueryKeys.list(),
    queryFn: () => CustomReportsApiClient.getCustomReportsFolders(),
  });

export const useCreateCustomReportsFolder = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const { snackInfo, snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportFoldersMutationKeys.create(),
    mutationFn: CustomReportsApiClient.createCustomReportFolder,
    onSuccess: async (newFolder) => {
      await queryClient.invalidateQueries({ queryKey: customReportFoldersQueryKeys.all() });
      snackInfo(formatMessage({ defaultMessage: 'Folder “{folderName}” created!' }, { folderName: newFolder.name }));
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomReportsFolder = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportFoldersMutationKeys.patch(),
    mutationFn: CustomReportsApiClient.patchCustomReportFolder,
    onSuccess: async (folder) => {
      await queryClient.invalidateQueries({ queryKey: customReportFoldersQueryKeys.all() });
      snackSuccess(
        formatMessage({ defaultMessage: 'Folder “{folderName}” edited successfully.' }, { folderName: folder.name }),
      );
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeleteCustomReportsFolder = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportFoldersMutationKeys.delete(),
    mutationFn: CustomReportsApiClient.deleteFolder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        // Invalidating the custom report list is required because the deletion of a folder moves
        // all reports that were in this folder at root.
        queryKey: customReportsQueryKeys.all(),
      });
      await queryClient.invalidateQueries({
        // Invalidating the custom report list is required because the deletion of a folder moves
        // all reports that were in this folder at root.
        queryKey: customReportFoldersQueryKeys.all(),
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
