import { isNil } from 'lodash';

import { type RecordContent } from '@amalia/data-capture/connectors/types';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';

interface TableSlice {
  min: number;
  max: number;
  percent: number;
}

export const getTableSlice = (element: number[]): TableSlice => ({
  min: element[0],
  max: element[1],
  percent: element[2],
});

export const getRowFieldAmount = (row: RecordContent, field: string): number => {
  const value = row[field];

  const fieldValue = isCurrencyValue(value) ? value.value : value;

  if (
    isNil(fieldValue) ||
    Number.isNaN(+fieldValue) ||
    (typeof fieldValue !== 'number' && typeof fieldValue !== 'string')
  ) {
    throw new Error(`${field} value is not a number`);
  }

  return typeof fieldValue === 'string' ? parseFloat(fieldValue) : fieldValue;
};
