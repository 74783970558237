import { IconAddressBook } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Avatar, AvatarSize } from '@amalia/design-system/components';
import { UserRoleBadge, UserStatusBadge } from '@amalia/tenants/users/profile/components';
import { useAuthorizedProfiles } from '@amalia/tenants/users/profile/state';

import { CommandGroup } from '../../../cmdk-styled/CommandGroup';
import { buildActionValue, CommandItem } from '../../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/use-actions';
import { PagesEnum } from '../../shortcut-commands.types';

export const ListUsersCommand = memo(function ListUsersCommand() {
  const { data: usersList } = useAuthorizedProfiles();

  const { primaryAction } = useActions(PagesEnum.LIST_USERS);

  const usersListOptions = useMemo(
    () =>
      usersList.map((user) => ({
        ...user,
        label: `${user.firstName} ${user.lastName}`,
        id: user.id,
      })),
    [usersList],
  );

  return (
    <CommandGroup
      Icon={IconAddressBook}
      title={<FormattedMessage defaultMessage="Users list" />}
    >
      {usersListOptions.map(
        ({
          id,
          label,
          lastName,
          firstName,
          pictureURL,
          status,
          joinedAt,
          clearedAt,
          createdAt,
          invitationSentAt,
          role,
          externalId,
        }) => (
          <CommandItem
            key={id}
            keywords={[id, externalId ?? '', label]}
            label={label}
            value={buildActionValue(id, externalId)}
            labelElement={
              <UserRoleBadge
                role={role}
                size={UserRoleBadge.Size.SMALL}
              />
            }
            leftElement={
              <Avatar
                size={AvatarSize.SMALL}
                user={{ firstName, lastName, pictureURL }}
              />
            }
            rightElement={
              <UserStatusBadge
                size={UserStatusBadge.Size.SMALL}
                user={{ status, joinedAt, clearedAt, createdAt, invitationSentAt }}
              />
            }
            onSelect={primaryAction?.onSelect}
          />
        ),
      )}
    </CommandGroup>
  );
});
