import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const stringEndsWith = new AmaliaFunctionDefault<[string | null | undefined, string], boolean>({
  name: AmaliaFunctionKeys.ENDS_WITH,
  category: AmaliaFunctionCategory.STRING,

  nbParamsRequired: 2,
  description: 'Return true when the given Source String ends with the given Search String',

  exec: (sourceString, searchString) => {
    assert(
      sourceString !== null && sourceString !== undefined,
      `${AmaliaFunctionKeys.ENDS_WITH}: source string is null or undefined`,
    );

    return sourceString.toString().endsWith(searchString);
  },

  params: [
    { name: 'sourceString', description: 'String to look into: array, variables, fields, properties, string' },
    { name: 'searchString', description: 'String to look for: array, variables, fields, properties, string' },
  ],

  examples: [
    {
      desc: 'Returns true if Opportunity type endsWith Renew.',
      formula: 'ENDS_WITH(opportunity.type, "Renew")' as AmaliaFormula,
    },
    {
      desc: 'Returns true.',
      formula: 'ENDS_WITH("Jean Dupont", "pont")' as AmaliaFormula,
      result: true,
    },
    {
      desc: 'Returns false since the check is case sensitive.',
      formula: 'ENDS_WITH("Opportunity - Renewal XYZ", "xyz")' as AmaliaFormula,
      result: false,
    },
  ],
});
