import { type Key, type ReactNode } from 'react';

import { type TablerIconElement } from '../../general/icons/types';
import { type DropdownGroupProps } from '../dropdown/dropdown-group/DropdownGroup';

export type SelectOptionValue = boolean | number | string | symbol | null;

export type SelectDropdownOption<TValue extends SelectOptionValue = SelectOptionValue> = {
  /** Override the key (by default it is option.value). Useful in cases where you have the same option multiple times. */
  key?: Key;
  /** Option value, used to show which value or values are selected. Keep this unique accross all options or some things will not work correctly. */
  value: TValue;
  /** Optional icon. */
  icon?: TablerIconElement;
  /** Optional help text. */
  tooltip?: ReactNode;
  /** Optional secondary label. */
  secondaryLabel?: ReactNode;
  /** Is this option disabled. Is the option checked, it cannot be unchecked by unclicking "Select all". */
  disabled?: boolean;
} & (
  | {
      /** Label is what is displayed to the user. */
      label: ReactNode;
      /** Label used for filtering. Filter label is required if label is not a string. */
      filterLabel: string;
    }
  | {
      /** Label is what is displayed to the user. */
      label: string;
      /** Option can also pass a label to override filtering. */
      filterLabel?: string;
    }
);

export type SelectOptionGroup<TOption extends SelectDropdownOption = SelectDropdownOption> = Pick<
  DropdownGroupProps,
  'action' | 'countBadge' | 'icon' | 'initialIsOpen' | 'label' | 'tooltip'
> & {
  /** Group options. */
  options: TOption[];
};

/**
 * SelectOptionGroup typeguard
 */
export const isSelectOptionGroup = <TOption extends SelectDropdownOption = SelectDropdownOption>(
  optionOrGroup: SelectOptionGroup<TOption> | TOption,
): optionOrGroup is SelectOptionGroup<TOption> => 'options' in optionOrGroup;

/** Type of SelectDropdown's value based on isMultiple and useOptionAsValue. */
export type SelectDropdownValue<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
> = TIsMultiple extends false | undefined
  ? TUseOptionAsValue extends false | undefined
    ? TOption['value'] | null
    : TOption | null
  : TUseOptionAsValue extends false | undefined
    ? TOption['value'][]
    : TOption[];

/** SelectDropdown value/onChange and props affecting them. */
export type SelectDropdownStateProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
> = {
  /** Can select multiple values. Value becomes a list. */
  isMultiple?: TIsMultiple;
  /** Value is an option or a list of options instead of their values. */
  useOptionAsValue?: TUseOptionAsValue;
  /** Value can be null and cleared. Only affects single selects. */
  isClearable?: TIsClearable;
  /** List of options, list of option values, option or option value or null. Depends on isMultiple and useOptionAsValue. */
  value?: SelectDropdownValue<TOption, TIsMultiple, TUseOptionAsValue>;
  /** Change handler. New value is the same type as value above. */
  onChange?: (
    /** Only the onChange value is non-nullable when isClearable is false, because the field can be initialized to null. */
    value: TIsClearable extends false | undefined
      ? NonNullable<SelectDropdownValue<TOption, TIsMultiple, TUseOptionAsValue>>
      : SelectDropdownValue<TOption, TIsMultiple, TUseOptionAsValue>,
  ) => void;
};

/** Props passed to a render function as child. */
export type SelectDropdownChildRenderProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
> = {
  /** Selected options or option or null. */
  value: SelectDropdownValue<TOption, TIsMultiple, true>;
  /** Clear values handlers. This handles fixed values. */
  onClear: () => void;
  /** Is dropdown open. */
  isDropdownOpen: boolean;
  /** Is case of multiple select, are all options selected. */
  hasEveryOptionSelected: boolean;
  /** Flat list of options (without groups). */
  flatOptions: TOption[];
};
