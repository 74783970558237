import { omit } from 'lodash';
import { type SetRequired } from 'type-fest';

import { http } from '@amalia/core/http/client';
import { type EngineError } from '@amalia/core/types';
import { type PlanTemplateDehydrated } from '@amalia/payout-calculation/types';
import {
  type PutPlanRulesOrderRequest,
  type BadgeConfiguration,
  type CreateHighlightedKpiRequest,
  type CreatePlanBadgeConfigurationRequest,
  type CreatePlanCategoryRequest,
  type CreatePlanRequest,
  type DuplicatePlanRequest,
  type HighlightedKpi,
  type HighlightedKpiIdentifier,
  type ImportTokenFromOtherPlanRequest,
  type PatchPlanBadgeConfigurationRequest,
  type PatchPlanForecastingConfigurationRequest,
  type PatchPlanRequest,
  type PatchPlanVisibility,
  type PatchPlanWorkflowRequest,
  type Plan,
  type PlanCategory,
  type PutPlanHighlightedKpisRequest,
  type UpdatePlanCategoryRequest,
} from '@amalia/payout-definition/plans/types';

const e = encodeURIComponent;

export class PlansApiClient {
  public static async list(filters?: {
    userIds?: string[];
    planIds?: string[];
    periodId?: string;
  }): Promise<SetRequired<Plan, 'planAssignements'>[]> {
    let qs = '';
    if (filters) {
      const { userIds, planIds, periodId } = filters;

      qs = [
        ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
        ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
        ...(periodId ? [`periodId=${periodId}`] : [null]),
      ]
        .filter(Boolean)
        .join('&');
    }
    const { data } = await http.get<SetRequired<Plan, 'planAssignements'>[]>(`/plans?${qs}`);
    return data;
  }

  public static async get(planId: string): Promise<Plan> {
    const { data } = await http.get<Plan>(`/plans/${e(planId)}`);
    return data;
  }

  public static async getPlanTemplate(
    planId: string,
  ): Promise<{ template?: PlanTemplateDehydrated; error?: EngineError }> {
    const { data } = await http.get<{ template?: PlanTemplateDehydrated; error?: EngineError }>(
      `/plans/${e(planId)}/template`,
    );

    return data;
  }

  public static async create(plan: CreatePlanRequest): Promise<Plan> {
    const { data } = await http.post<Plan>('/plans/', plan);
    return data;
  }

  public static async update(plan: Plan): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/${plan.id}`, plan);
    return data;
  }

  public static async patchPlanVisibility(planId: string, patch: PatchPlanVisibility): Promise<Plan> {
    const { data } = await http.patch<Plan>(`/plans/${planId}/visibility`, patch);
    return data;
  }

  public static async patchPlanWorkflow({
    planId,
    ...patchPlanWorkflowRequest
  }: PatchPlanWorkflowRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (await http.patch<Plan>(`/plans/${e(planId)}/workflow`, patchPlanWorkflowRequest)).data;
  }

  public static async patchPlan({
    planId,
    ...patchPlanRequest
  }: PatchPlanRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (await http.patch<Plan>(`/plans/${e(planId)}`, patchPlanRequest)).data;
  }

  public static async patchPlanForecastingConfiguration({
    planId,
    ...patchPlanForecastingConfigurationRequest
  }: PatchPlanForecastingConfigurationRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (
      await http.patch<Plan>(`/plans/${e(planId)}/forecasting-configuration`, patchPlanForecastingConfigurationRequest)
    ).data;
  }

  public static async putPlanHighlightedKpis({
    planId,
    ...request
  }: PutPlanHighlightedKpisRequest & {
    planId: Plan['id'];
  }): Promise<Plan> {
    return (await http.put<Plan>(`/plans/${e(planId)}/highlighted-kpis`, request)).data;
  }

  public static async archive(planId: Plan['id']): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${e(planId)}`, { archived: true });
    return data;
  }

  public static async unarchive(planId: Plan['id']): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${e(planId)}`, { archived: false });
    return data;
  }

  public static async delete(planId: Plan['id']): Promise<void> {
    await http.delete(`/plans/${e(planId)}`);
  }

  public static async isDeletable(planId: Plan['id']): Promise<boolean> {
    const { data } = await http.get<boolean>(`/plans/${e(planId)}/is-deletable`);
    return data;
  }

  public static async upsertHighlightedKpi(
    planId: string,
    highlightedKpi: CreateHighlightedKpiRequest & { identifier: HighlightedKpiIdentifier },
  ): Promise<HighlightedKpi> {
    const { data } = await http.put<HighlightedKpi>(
      `/plans/${planId}/highlighted-kpis/${highlightedKpi.identifier}`,
      omit(highlightedKpi, 'identifier'),
    );

    return data;
  }

  public static async deleteHighlightedKpi(planId: string, identifier: HighlightedKpiIdentifier) {
    await http.delete(`/plans/${planId}/highlighted-kpis/${identifier}`);
  }

  public static async duplicatePlan({
    planId,
    ...duplicatePlanRequest
  }: DuplicatePlanRequest & { planId: Plan['id'] }): Promise<Plan> {
    const { data: newPlan } = await http.post<Plan>(`/plans/${e(planId)}/duplicate`, duplicatePlanRequest);
    return newPlan;
  }

  public static async importTokenFromOtherPlan({
    planId,
    ...importTokenFromOtherPlanRequest
  }: ImportTokenFromOtherPlanRequest & {
    planId: Plan['id'];
  }): Promise<void> {
    await http.post(`/plans/${e(planId)}/import-token-from-other-plan`, {
      ...importTokenFromOtherPlanRequest,
    });
  }

  public static async createPlanBadgeConfiguration(
    planId: Plan['id'],
    createBadgeConfigurationRequest: CreatePlanBadgeConfigurationRequest,
  ): Promise<void> {
    await http.post(`/plans/${e(planId)}/badges`, createBadgeConfigurationRequest);
  }

  public static async patchPlanBadgeConfiguration(
    planId: Plan['id'],
    badgeId: BadgeConfiguration['id'],
    patchBadgeConfigurationRequest: PatchPlanBadgeConfigurationRequest,
  ): Promise<void> {
    await http.patch(`/plans/${e(planId)}/badges/${e(badgeId)}`, patchBadgeConfigurationRequest);
  }

  public static async deletePlanBadgeConfiguration(planId: Plan['id'], badgeId: string): Promise<void> {
    await http.delete(`/plans/${e(planId)}/badges/${e(badgeId)}`);
  }

  public static async createCategory({
    planId,
    ...createPlanCategoryRequest
  }: CreatePlanCategoryRequest & { planId: Plan['id'] }): Promise<Plan> {
    return (await http.post<Plan>(`/plans/${e(planId)}/categories`, createPlanCategoryRequest)).data;
  }

  public static async updateCategory({
    planId,
    categoryName,
    ...updatePlanCategoryRequest
  }: UpdatePlanCategoryRequest & { planId: Plan['id']; categoryName: PlanCategory['name'] }): Promise<Plan> {
    return (await http.put<Plan>(`/plans/${e(planId)}/categories/${e(categoryName)}`, updatePlanCategoryRequest)).data;
  }

  public static async deleteCategory({
    planId,
    categoryName,
  }: {
    planId: Plan['id'];
    categoryName: PlanCategory['name'];
  }): Promise<Plan> {
    return (await http.delete<Plan>(`/plans/${e(planId)}/categories/${e(categoryName)}`)).data;
  }

  public static async putRulesOrder({ planId, ...request }: PutPlanRulesOrderRequest & { planId: Plan['id'] }) {
    await http.put(`/plans/${e(planId)}/rules-order`, request);
  }
}
