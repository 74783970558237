import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { log } from '@amalia/kernel/logger/client';

export const useClipboard = () => {
  const { snackInfo, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  const copy = useCallback(
    (text: string) => {
      const onSuccess = () => {
        const message = formatMessage({ defaultMessage: 'Copied to clipboard.' });
        snackInfo(message, { autoHideDuration: 1000 });
      };

      const onError = (error: Error) => {
        const message = formatMessage({ defaultMessage: 'Failed to copy "{text}" to clipboard.' }, { text });
        log.error(message, error);
        snackError(message, { autoHideDuration: 1000 });
      };

      // Using the Clipboard API
      // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
      navigator.clipboard.writeText(text).then(onSuccess).catch(onError);
    },
    [formatMessage, snackInfo, snackError],
  );

  return { copy };
};
