import { subject } from '@casl/ability';

import { type TeamContract } from '@amalia/tenants/teams/types';

import { SubjectsEnum } from '../../types';

export type ViewTeamSubject = { team: Pick<TeamContract, 'id'>; date?: Date | number };
export const viewTeamSubject = (payload: ViewTeamSubject) => subject(SubjectsEnum.Team, payload);

export type ViewAssignmentsTeamSubject = Pick<TeamContract, 'id'>;
export const viewAssignmentsTeamSubject = (payload: ViewAssignmentsTeamSubject) => subject(SubjectsEnum.Team, payload);
