import styled from '@emotion/styled';

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 4px;

  position: relative;
  left: 8px;
`;
