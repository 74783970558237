import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '../../general/icon-button/IconButton';
import { Group } from '../../layout/group/Group';
import { DatePickerBase } from '../date-picker-base/DatePickerBase';

import { YearSelectorButton } from './year-selector-button/YearSelectorButton';

const currentYear = new Date().getFullYear();

export type YearSelectorProps = {
  /** Year number. */
  readonly value?: number;
  readonly onChange?: (value: number) => void;
};

export const YearSelector = memo(function YearSelector({ value = currentYear, onChange }: YearSelectorProps) {
  const { formatMessage } = useIntl();

  const dateValue = useMemo(() => new Date(value, 0, 1), [value]);

  const handleSelectPreviousYear = useCallback(() => onChange?.(value - 1), [value, onChange]);
  const handleSelectNextYear = useCallback(() => onChange?.(value + 1), [value, onChange]);
  const handleChangeDate = useCallback((date: Date | null) => onChange?.(date!.getFullYear()), [onChange]);

  return (
    <Group align="center">
      <IconButton
        icon={<IconChevronLeft />}
        label={formatMessage({ defaultMessage: 'Previous year' })}
        onClick={handleSelectPreviousYear}
      />

      <div>
        <DatePickerBase
          showYearPicker
          dateFormat="yyyy"
          value={dateValue}
          onChange={handleChangeDate}
        >
          {({ isOpen: isCalendarOpen }) => <YearSelectorButton isOpen={isCalendarOpen} />}
        </DatePickerBase>
      </div>

      <IconButton
        icon={<IconChevronRight />}
        label={formatMessage({ defaultMessage: 'Next year' })}
        onClick={handleSelectNextYear}
      />
    </Group>
  );
});
