import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const workflowsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
    can(ActionsEnum.reset, SubjectsEnum.Workflow);
    can(ActionsEnum.modify, SubjectsEnum.Workflow);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },

  EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },

  READ_ONLY_EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },

  DEACTIVATED_USER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Workflow);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
