import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { type CompanyFeatureFlags } from './company-feature-flags';
import { type CompanyCustomization, type CompanyPreferences } from './company-preferences';

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Company {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  slug: string;
  status: CompanyStatus;
  statementFrequency: PeriodFrequencyEnum;
  currency: CurrencySymbolsEnum;
  symbols: CurrencySymbolsEnum[];
  batchNumber: number;
  nbBackwardRollingPeriods: number;
  nbForwardRollingPeriods: number;
  nbForwardPaymentRollingPeriods: number;
  //
  customization?: CompanyCustomization;
  preferences?: CompanyPreferences;
  //
  featureFlags: Record<CompanyFeatureFlags, boolean>;
  integrationsStatus?: CompanyIntegrations;
  companyIntegrations?: CompanyIntegration[];
}

export interface CompanyIntegrations {
  slack?: {
    accessToken?: string;
    status?: boolean;
  };
}

export enum CompanyIntegrationName {
  slack = 'slack',
}

export type CompanyIntegrationCredentials = CompanyIntegrationSlack;

export interface CompanyIntegrationSlack {
  accessToken: string;
  status: boolean;
  /**
   * Tenant ID in the integrated system.
   * E.g., in Slack we use the teamId which is the unique ID of a Slack workspace.
   */
  externalTenantId?: string;

  /**
   * Integrated App ID.
   * If you connect to Slack through a Slack app, it's the Slack app ID.
   */
  appId?: string;
}

export interface CompanyIntegration {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  name: CompanyIntegrationName;
  credentials: CompanyIntegrationCredentials;
  company: Partial<Company>;
  companyId: string;
}

export type CreateCompanyRequest = Partial<Company> & {
  admin: {
    firstName: string;
    lastName: string;
    email: string;
  };
  createCommonFiltersAndQuotas: boolean;
};
