import { useAuth0 } from '@auth0/auth0-react';
import { memo, useEffect } from 'react';

import { showLoadingScreen } from '@amalia/core/layout/loading-screen';
import { log } from '@amalia/kernel/logger/client';

export const AuthenticationLogin = memo(function AuthenticationLogin() {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    showLoadingScreen();
    loginWithRedirect().catch(log.error);
  });

  // Not returning anything since we're supposed to be redirected.
  return null;
});
