import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo, type ReactElement, useState } from 'react';

import { useResizeObserver } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { SelectDropdown, type SelectDropdownProps } from '../../overlays/select-dropdown/SelectDropdown';
import { type SelectOptionGroup } from '../../overlays/select-dropdown/SelectDropdown.types';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize } from '../meta/types';

import { SelectControl, type SelectControlProps } from './select-control/SelectControl';
import * as styles from './Select.styles';
import { type SelectOption, SelectSize } from './Select.types';

const SELECT_SIZE_FIELD_SIZE_MAPPING: Record<SelectSize, FieldSize> = {
  [SelectSize.SMALL]: FieldSize.SMALL,
  [SelectSize.MEDIUM]: FieldSize.MEDIUM,
};

const FORM_LAYOUT_SIZE_SELECT_SIZE_MAPPING: Record<FormLayoutSize, SelectSize> = {
  [FormLayoutSize.SMALL]: SelectSize.SMALL,
  [FormLayoutSize.MEDIUM]: SelectSize.MEDIUM,
};

export type SelectProps<
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = MergeAll<
  [
    Omit<
      SelectDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>,
      | 'children'
      | 'initialFocus'
      | 'onChangeSearchText'
      | 'searchText'
      | 'shouldDismiss'
      | 'shouldToggleOnClick'
      | 'shouldTriggerOnFocus'
    >,
    UseFormFieldPropsOptions,
    {
      /** Input name. */
      name?: SelectControlProps<TOption, TIsMultiple, TIsClearable>['name'];
      /** Select size. */
      size?: SelectControlProps<TOption, TIsMultiple, TIsClearable>['size'];
      /** Left icon. */
      icon?: SelectControlProps<TOption, TIsMultiple, TIsClearable>['icon'];
      /** Placeholder when not searching. */
      placeholder?: SelectControlProps<TOption, TIsMultiple, TIsClearable>['placeholder'];
      /** Component to render the label of the selected option inside the control on a single select component. */
      SingleValueLabelComponent?: SelectControlProps<TOption, TIsMultiple, TIsClearable>['SingleValueLabelComponent'];
      /** Component to render the label of an selected option inside the control on a multi select component. */
      MultiValueLabelComponent?: SelectControlProps<TOption, TIsMultiple, TIsClearable>['MultiValueLabelComponent'];
    },
  ]
>;

const SelectForwardRef = forwardRef(function Select<
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>(
  props: SelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};

  const {
    formFieldProps,
    otherProps: {
      name,
      size = formLayoutSize ? FORM_LAYOUT_SIZE_SELECT_SIZE_MAPPING[formLayoutSize] : SelectSize.MEDIUM,
      placeholder,
      icon,
      id,
      isClearable: propsIsClearable,
      SingleValueLabelComponent,
      MultiValueLabelComponent,
      className,
      ...otherProps
    },
  } = useFormFieldProps<SelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>>(props);

  /** Resize dropdown based on the size of the control. */
  const [{ width }, setWidth] = useState({ width: 0 });
  const controlContainerRef = useResizeObserver<HTMLDivElement>({ onResize: setWidth });

  // Is clearable by default. Could not cast during destructuring for some reason.
  const isClearable: TIsClearable = propsIsClearable ?? (true as TIsClearable);

  return (
    <FormField
      {...formFieldProps}
      className={className}
      size={SELECT_SIZE_FIELD_SIZE_MAPPING[size]}
    >
      <SelectDropdown<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>
        {...otherProps}
        shouldToggleOnClick
        shouldTriggerOnFocus
        id={id}
        isClearable={isClearable}
        css={css`
          > div {
            width: ${width}px;
          }
        `}
      >
        {(controlProps) => (
          // Need to wrap with a div here otherwise floating-ui changes the references of onX callbacks.
          <div
            ref={controlContainerRef}
            css={styles.selectControlContainer}
          >
            <SelectControl<TOption, TIsMultiple, TIsClearable>
              {...controlProps}
              ref={ref}
              disabled={otherProps.disabled}
              error={formFieldProps.error}
              icon={icon}
              id={id}
              isClearable={isClearable}
              isMultiple={otherProps.isMultiple}
              MultiValueLabelComponent={MultiValueLabelComponent}
              name={name}
              placeholder={placeholder}
              SingleValueLabelComponent={SingleValueLabelComponent}
              size={size}
            />
          </div>
        )}
      </SelectDropdown>
    </FormField>
  );
});

export const Select = Object.assign(
  memo(SelectForwardRef) as <
    TOption extends SelectOption = SelectOption,
    TIsMultiple extends boolean | undefined = undefined,
    TUseOptionAsValue extends boolean | undefined = undefined,
    TIsClearable extends boolean | undefined = true,
    TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
  >(
    props: SelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup> & {
      ref?: ForwardedRef<HTMLInputElement>;
    },
  ) => ReactElement | null,
  {
    Size: SelectSize,
  },
);
