import { css, keyframes, type Theme } from '@emotion/react';

import { type BorderRadiusVariant } from '@amalia/design-system/meta';

export const IS_VISIBLE_CLASSNAME = 'visible';

const skeletonAnimation = keyframes`
  from, to { opacity: 0.4 }
  50% { opacity: 1 }
`;

export const skeleton = (shape: BorderRadiusVariant) => (theme: Theme) => css`
  &.${IS_VISIBLE_CLASSNAME} {
    position: relative;
    overflow: hidden;
    color: transparent;
    display: flex;

    border-radius: ${theme.ds.borderRadiuses[shape]};

    transition: ${theme.ds.transitions.default('border-radius')};

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::before {
      background-color: ${theme.ds.colors.gray[0]};

      z-index: 10;
    }

    &::after {
      background-color: ${theme.ds.colors.gray[100]};

      animation: ${skeletonAnimation} 2000ms linear infinite;

      z-index: 11;
    }
  }
`;
