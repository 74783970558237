import { css, type Theme } from '@emotion/react';

import {
  AVATAR_OVERLAP,
  UsersAvatarStackSizeToAvatarSize,
  UsersAvatarStackSizeToTypographyVariant,
} from './UsersAvatarStack.constants';
import { type UsersAvatarStackSize } from './UsersAvatarStack.types';

export const moreUsersButton = (maxUsers: number, size: UsersAvatarStackSize) => (theme: Theme) => css`
  ${theme.ds.typographies[UsersAvatarStackSizeToTypographyVariant[size]]};

  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  box-sizing: border-box;

  width: ${UsersAvatarStackSizeToAvatarSize[size]}px;
  height: ${UsersAvatarStackSizeToAvatarSize[size]}px;

  border: 1px solid ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.round};

  transition: ${theme.ds.transitions.default('height', 'width', 'font-size')};
  overflow: hidden;

  background-color: ${theme.ds.colors.graySecondary[50]};
  color: ${theme.ds.colors.primary[700]};

  position: relative;
  left: ${(maxUsers - 1) * AVATAR_OVERLAP * -1}px;
`;
