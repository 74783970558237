import { upperFirst } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

type BooleanPropertyTrue<TBooleanIdentifier extends string> = Record<
  `is${Capitalize<TBooleanIdentifier>}True`,
  boolean
>;
type BooleanPropertyFalse<TBooleanIdentifier extends string> = Record<
  `is${Capitalize<TBooleanIdentifier>}False`,
  boolean
>;
type BooleanPropertyToggle<TBooleanIdentifier extends string> = Record<
  `toggle${Capitalize<TBooleanIdentifier>}`,
  () => void
>;
type BooleanPropertySetTrue<TBooleanIdentifier extends string> = Record<
  `set${Capitalize<TBooleanIdentifier>}True`,
  () => void
>;
type BooleanPropertySetFalse<TBooleanIdentifier extends string> = Record<
  `set${Capitalize<TBooleanIdentifier>}False`,
  () => void
>;
type BooleanPropertySet<TBooleanIdentifier extends string> = Record<
  `set${Capitalize<TBooleanIdentifier>}`,
  (booleanValue: boolean) => void
>;

type BooleanProperties<TBooleanIdentifier extends string> = BooleanPropertyFalse<TBooleanIdentifier> &
  BooleanPropertySet<TBooleanIdentifier> &
  BooleanPropertySetFalse<TBooleanIdentifier> &
  BooleanPropertySetTrue<TBooleanIdentifier> &
  BooleanPropertyToggle<TBooleanIdentifier> &
  BooleanPropertyTrue<TBooleanIdentifier>;

/**
 * The goal of this type is to create a type that take each string and create a Record of <`is${Capitalize<TBooleanIdentifiers[number]>}True`, boolean>
 *
 * Example:
 *
 * ```
 * CreateBooleanPropertiesFromArray<['deleteDashboardModal', 'duplicateDashboardModal']>
 *```
 * will return
 * ```
 * {
 *   isDeleteDashboardModalTrue: boolean;
 *   isDeleteDashboardModalFalse: boolean;
 *   toggleDeleteDashboardModal: () => void;
 *   setDeleteDashboardModalTrue: () => void;
 *   setDeleteDashboardModalFalse: () => void;
 *   setDeleteDashboardModal: (booleanValue: boolean) => void;
 *
 *   isDuplicateDashboardModalTrue: boolean;
 *   isDuplicateDashboardModalFalse: boolean;
 *   toggleDuplicateDashboardModal: () => void;
 *   setDuplicateDashboardModalTrue: () => void;
 *   setDuplicateDashboardModalFalse: () => void;
 *   setDuplicateDashboardModal: (booleanValue: boolean) => void;
 * }
 * ```
 */
type CreateBooleanPropertiesFromArray<
  TBooleanIdentifiers extends readonly string[],
  TAcc extends object = Record<string, never>,
> = TBooleanIdentifiers['length'] extends 0
  ? TAcc
  : TBooleanIdentifiers extends readonly [infer TBooleanIdentifier, ...infer TRest]
    ? TBooleanIdentifier extends string
      ? TRest extends readonly string[]
        ? CreateBooleanPropertiesFromArray<TRest, BooleanProperties<TBooleanIdentifier> & TAcc>
        : never
      : never
    : never;

type UseBoolStatesReturn<TBooleanIdentifiers extends readonly string[]> =
  CreateBooleanPropertiesFromArray<TBooleanIdentifiers>;

export const useBoolStates = <
  const TBooleanIdentifiers extends readonly string[],
  const TBooleanIdentifier extends TBooleanIdentifiers[number] = TBooleanIdentifiers[number],
>(
  booleanIdentifiers: TBooleanIdentifiers,
): UseBoolStatesReturn<TBooleanIdentifiers> => {
  const [booleanValues, setBooleanValues] = useState<Record<TBooleanIdentifier, boolean>>(
    booleanIdentifiers.reduce(
      (acc, booleanIdentifier) => ({ ...acc, [booleanIdentifier]: false }),
      {} as Record<TBooleanIdentifier, boolean>,
    ),
  );

  const setBooleanValue = useCallback((booleanIdentifier: TBooleanIdentifier, booleanValue: boolean) => {
    setBooleanValues((currentState) => ({
      ...currentState,
      [booleanIdentifier]: booleanValue,
    }));
  }, []);

  const setBooleanValueTrue = useCallback((booleanIdentifier: TBooleanIdentifier) => {
    setBooleanValues((currentState) => ({
      ...currentState,
      [booleanIdentifier]: true,
    }));
  }, []);

  const setBooleanValueFalse = useCallback((booleanIdentifier: TBooleanIdentifier) => {
    setBooleanValues((currentState) => ({
      ...currentState,
      [booleanIdentifier]: false,
    }));
  }, []);

  const toggleBooleanValue = useCallback((booleanIdentifier: TBooleanIdentifier) => {
    setBooleanValues((currentState) => ({
      ...currentState,
      [booleanIdentifier]: !currentState[booleanIdentifier],
    }));
  }, []);

  return useMemo(
    () =>
      booleanIdentifiers.reduce((acc, booleanIdentifier) => {
        const booleanIdentifierUpper = upperFirst(booleanIdentifier as TBooleanIdentifier);

        return {
          ...acc,
          [`is${booleanIdentifierUpper}True`]: booleanValues[booleanIdentifier as TBooleanIdentifier] === true,
          [`is${booleanIdentifierUpper}False`]: booleanValues[booleanIdentifier as TBooleanIdentifier] === false,
          [`set${booleanIdentifierUpper}True`]: () => setBooleanValueTrue(booleanIdentifier as TBooleanIdentifier),
          [`set${booleanIdentifierUpper}False`]: () => setBooleanValueFalse(booleanIdentifier as TBooleanIdentifier),
          [`toggle${booleanIdentifierUpper}`]: () => toggleBooleanValue(booleanIdentifier as TBooleanIdentifier),
          [`set${booleanIdentifierUpper}`]: (booleanValue: boolean) =>
            setBooleanValue(booleanIdentifier as TBooleanIdentifier, booleanValue),
        };
      }, {} as UseBoolStatesReturn<TBooleanIdentifiers>),
    [booleanIdentifiers, booleanValues, setBooleanValueTrue, setBooleanValueFalse, toggleBooleanValue, setBooleanValue],
  );
};
