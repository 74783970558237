import { lazy, memo } from 'react';

import { Layout } from '@amalia/core/layout/components';

const Home = lazy(async () => ({ default: (await import('./Home')).Home }));

export const HomeView = memo(function HomeView() {
  return (
    <Layout currentPage="home">
      <Home />
    </Layout>
  );
});
