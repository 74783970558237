import { css } from '@emotion/react';
import { type Icon, IconLoader } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, type ElementType, type RefAttributes } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconProps } from '../icons/types';

import { spinKeyframes, spinReverseKeyframes } from './IconLoading.styles';

export type IconLoadingProps = MergeAll<
  [
    TablerIconProps,
    {
      /** Icon to spin, it'll default to IconLoader if not provided. */
      icon?: ElementType<RefAttributes<Icon> & TablerIconProps>;
      /** Reverse the animation because not all icons are facing the same direction. Default animation rotates clockwise. */
      reverse?: boolean;
    },
  ]
>;

export const IconLoading = forwardRef(function IconLoading(
  { icon: Loader = IconLoader, reverse, ...props }: IconLoadingProps,
  ref: ForwardedRef<Icon>,
) {
  return (
    <Loader
      {...props}
      ref={ref}
      css={(theme) => css`
        animation: ${reverse ? spinReverseKeyframes : spinKeyframes} ${theme.ds.transitions.default.durationMs * 5}ms
          infinite ease-in-out;
      `}
    />
  );
});
