import { isNil } from 'lodash';
import { isAccessorNode, isFunctionNode } from 'mathjs';

import {
  type MathNode,
  type FunctionNode,
  type AccessorNode,
  type SymbolNode,
} from '@amalia/amalia-lang/formula/evaluate/shared';
import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { type Statement } from '@amalia/core/types';
import { type ComputedVariable } from '@amalia/payout-calculation/types';
import { type PlanRuleChart } from '@amalia/payout-definition/plans/types';

import { Formula2Service } from '../formula/formula2.service';

export type CommissionTableRow = [number | null, number | null, number];

export type ExtractedChartConfiguration = {
  error?: string;
  values?: {
    mode: 'LINEAR' | 'TIER';
    target: {
      value?: number;
      variable?: VariableDefinition;
    };
    table: {
      value?: CommissionTableRow[];
      variable?: VariableDefinition;
    };
  };
};

const getVariableMachineNameFromAccessorNode = (node: MathNode): string | null =>
  isAccessorNode(node) && 'value' in node.index.dimensions[0] && typeof node.index.dimensions[0].value === 'string'
    ? node.index.dimensions[0].value
    : null;

/**
 * Extract chart configuration from chart and return all values
 * @param statement
 * @param chart
 */
export const extractChartConfiguration = (
  statement: Pick<Statement, 'results'>,
  chart: PlanRuleChart,
): ExtractedChartConfiguration => {
  if (!chart.configuration.targetAchievementVariableId) {
    return { error: `Target Achievement chart ${chart.name} has no target achievement variables set up!` };
  }

  // Get the target achievement variable from statement results
  const targetAchievementVariable = Object.values(statement.results.definitions.variables).find(
    (v) => v.id === chart.configuration.targetAchievementVariableId,
  );

  if (!targetAchievementVariable) {
    return { error: `Unable to retrieve the variable for the target achievement chart ${chart.name}` };
  }

  // Try to get the FN node from its formula
  let fnNodeAsMathNode: MathNode | undefined = Formula2Service.parseNode(targetAchievementVariable.formula ?? '');
  if (!isFunctionNode(fnNodeAsMathNode) || !['TIER', 'LINEAR'].includes(fnNodeAsMathNode.fn.name)) {
    // If we don't have it directly, it's maybe later in the formula
    const nodesInFormula = Formula2Service.traverseNode([], fnNodeAsMathNode);
    fnNodeAsMathNode = nodesInFormula.find((f) => isFunctionNode(f) && ['TIER', 'LINEAR'].includes(f.fn.name));

    // If we still can't find it, return an error
    if (!fnNodeAsMathNode) {
      return { error: `No tier or linear function detected in target achievement variable for chart ${chart.name}` };
    }
  }

  const fnNode: FunctionNode = fnNodeAsMathNode as FunctionNode;

  // If formula is not used correctly, return error
  if (fnNode.args.length !== 2 || fnNode.args[0].type !== 'AccessorNode' || fnNode.args[1].type !== 'AccessorNode') {
    return {
      error: `Can't parse formula of target achievement variable for chart ${chart.name}: this formula doesn't use two accessors`,
    };
  }

  // If formula is not using statement variables (if wrong scope OR if formula uses function in aggregation functions)
  if (
    ((fnNode.args[0] as AccessorNode).object as SymbolNode).name !== 'statement' ||
    ((fnNode.args[1] as AccessorNode).object as SymbolNode).name !== 'statement'
  ) {
    return { error: `You must use two statement variables in the target achievement variable for chart ${chart.name}` };
  }

  // Get the variable machineNames
  const targetVariableMachineName = getVariableMachineNameFromAccessorNode(fnNode.args[0]);
  const tableVariableMachineName = getVariableMachineNameFromAccessorNode(fnNode.args[1]);

  if (!targetVariableMachineName || !tableVariableMachineName) {
    return {
      error: `Can't access statement variable names from target achievement variable formula on chart ${chart.name}`,
    };
  }

  // Get the statement variables that correspond
  const targetVariable: VariableDefinition | undefined =
    statement.results.definitions.variables[targetVariableMachineName];
  const tableVariable: VariableDefinition | undefined =
    statement.results.definitions.variables[tableVariableMachineName];

  // Get the computed objects that correspond to these variables
  const computedTarget = statement.results.computedObjects.find(
    (co) => (co as ComputedVariable).variableMachineName === targetVariableMachineName,
  ) as ComputedVariable<number> | undefined;
  const computedTable = statement.results.computedObjects.find(
    (co) => (co as ComputedVariable).variableMachineName === tableVariableMachineName,
  ) as ComputedVariable<CommissionTableRow[]> | undefined;

  if (!computedTarget || isNil(computedTarget.value) || !computedTable) {
    return { error: `Unable to retrieve both target and table variables values from formula for chart ${chart.name}` };
  }

  return {
    values: {
      mode: fnNode.fn.name as 'LINEAR' | 'TIER',
      table: {
        value: computedTable.value,
        variable: tableVariable,
      },
      target: {
        value: computedTarget.value,
        variable: targetVariable,
      },
    },
  };
};
