import { type RefObject, type SyntheticEvent } from 'react';

export const eventStopPropagation = (event?: SyntheticEvent) => event?.stopPropagation();

export const eventStopPropagationAndPreventDefault = (event?: SyntheticEvent) => {
  event?.stopPropagation();
  event?.preventDefault();
};

/**
 * There is this weird bug when clicking inside a Popover/Modal opened from a button inside a div with an event handler (such as onClick/onKeyDown).
 * Not sure why this happens since the modal is outside of the div in the HTML tree so the event should not bubble up to the div event handler.
 * This function just checks that the event target is inside the div container before calling the event handler.
 */
export const ignoreOutsideEvents =
  <TEvent extends SyntheticEvent>(containerRef: RefObject<HTMLElement>, eventHandler: (event: TEvent) => void) =>
  (event: TEvent) => {
    if (containerRef.current?.contains(event.target as Node)) {
      eventHandler(event);
    }
  };
