import { type ComputedTeam, type UserStatements } from '@amalia/core/types';
import { type Period } from '@amalia/payout-definition/periods/types';
import { TeamRole } from '@amalia/tenants/assignments/teams/types';

export const sortComputedTeams = (t1: ComputedTeam, t2: ComputedTeam) => {
  // If team is archived, put it at the end
  if (t1.isTeamArchived && !t2.isTeamArchived) return 1;
  if (!t1.isTeamArchived && t2.isTeamArchived) return -1;

  // If user is manager on t1 and not in t2, or the inverse, manage this
  const isT1Manager = t1.teamRole === TeamRole.TEAM_MANAGER;
  const isT2Manager = t2.teamRole === TeamRole.TEAM_MANAGER;
  if (isT1Manager && !isT2Manager) {
    return -1;
  }
  if (!isT1Manager && isT2Manager) {
    return 1;
  }

  // Otherwise, if he has the same role on both teams, sort them by name
  return t1.name.localeCompare(t2.name);
};

export const filterComputedTeamsOverPeriod = (team?: ComputedTeam, period?: Period) => {
  if (!team || !period) {
    return false;
  }
  if (team.effectiveAsOf && team.effectiveAsOf >= period.endDate) {
    return false;
  }
  return !(team.effectiveUntil && team.effectiveUntil <= period.startDate);
};

export const getUserTeamRoleFromUserStatementsList = (userStatements: UserStatements): ComputedTeam | null => {
  if (userStatements.lines.length >= 1) {
    const statement = userStatements.lines[0];
    const userTeams = (statement.resultSummary?.computedTeams ?? [])
      .filter((t: ComputedTeam) => filterComputedTeamsOverPeriod(t, statement.period))
      .sort(sortComputedTeams);

    // Return the most convenient team, with isManager at true if user is manager
    return (
      userTeams.find((t) => t.teamId === statement.resultSummary?.planAssignment.mainTeamId) ?? userTeams.at(0) ?? null
    );
  }

  return null;
};
