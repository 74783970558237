import { type Theme, css } from '@emotion/react';

import { FieldSize } from '../types';

export const error = (theme: Theme) => css`
  display: flex;
  align-items: center;
  word-break: break-word;

  color: ${theme.ds.colors.danger[900]};

  &.${FieldSize.SMALL} {
    gap: 4px;
  }

  &.${FieldSize.MEDIUM} {
    gap: 6px;
  }

  &.${FieldSize.LARGE} {
    gap: 8px;
  }
`;

export const text = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default()};
`;
