import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, useId, useLayoutEffect } from 'react';

import { IconButton, type IconButtonProps } from '../../../general/icon-button/IconButton';
import { useOptionalDropdownContext } from '../../../overlays/dropdown/Dropdown.context';
import { useOptionalSideMenuGroupContext } from '../side-menu-group/SideMenuGroup.context';
import { useSideMenuItemContext } from '../side-menu-item/SideMenuItem.context';

export type SideMenuItemActionProps = Omit<IconButtonProps, 'size'>;

export const SideMenuItemAction = forwardRef(function SideMenuItemAction(
  props: SideMenuItemActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const id = useId();
  const { disabled } = useSideMenuItemContext();
  const menuGroupContext = useOptionalSideMenuGroupContext();
  const dropdownContext = useOptionalDropdownContext();

  const isActive = !!(props.isActive ?? dropdownContext?.isOpen);

  useLayoutEffect(() => menuGroupContext?.onActionActivityChange(id, isActive), [id, isActive, menuGroupContext]);

  return (
    <IconButton
      {...props}
      ref={ref}
      data-visible={isActive}
      disabled={props.disabled || disabled}
      isActive={isActive}
      size={IconButton.Size.SMALL}
      css={
        !menuGroupContext &&
        ((theme) => css`
          transition: ${theme.ds.transitions.default('opacity')};
          opacity: 0;

          &[data-visible='true'],
          [data-side-menu-item]:hover &,
          &:focus-visible {
            opacity: 1;
          }
        `)
      }
    />
  );
});
