import { useQuery } from '@tanstack/react-query';
import { endOfDay, startOfDay } from 'date-fns';

import { useUtcDate } from '@amalia/ext/react/hooks';
import {
  type GetUserTeamAssignmentsOptions,
  UserTeamAssignmentsApiClient,
} from '@amalia/tenants/assignments/teams/api-client';

import { teamAssignmentsQueryKeys } from './queries.keys';

export const useUserTeamAssignments = (userId: string, options?: GetUserTeamAssignmentsOptions) =>
  useQuery({
    queryKey: teamAssignmentsQueryKeys.user(userId, options),
    queryFn: () => UserTeamAssignmentsApiClient.getUserTeamAssignments(userId, options),
    enabled: !!userId,
  });

export const useUserCurrentTeamAssignments = (
  userId: string,
  {
    referenceDate = new Date(),
    ...options
  }: Omit<GetUserTeamAssignmentsOptions, 'effectiveBetween'> & {
    referenceDate?: Date;
  } = {},
) => {
  const utcReferenceDate = useUtcDate(referenceDate);

  return useUserTeamAssignments(userId, {
    ...options,
    effectiveBetween: {
      start: startOfDay(utcReferenceDate),
      end: endOfDay(utcReferenceDate),
    },
  });
};

export const useUserCurrentAndFutureTeamAssignments = (
  userId: string,
  {
    referenceDate = new Date(),
    ...options
  }: Omit<GetUserTeamAssignmentsOptions, 'effectiveBetween'> & {
    referenceDate?: Date;
  } = {},
) => {
  const utcReferenceDate = useUtcDate(referenceDate);

  return useUserTeamAssignments(userId, {
    ...options,
    effectiveBetween: {
      start: startOfDay(utcReferenceDate),
    },
  });
};
