import { useMemo } from 'react';

import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type CurrencyOption, toCurrencyOption, useCurrencyOptions } from '@amalia/kernel/monetary/components';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

export const useCompanyCurrencyOptions = ({
  currentValue,
}: {
  currentValue?: CurrencySymbolsEnum;
} = {}): CurrencyOption[] => {
  const { data: company } = useCurrentCompany();

  const companySymbols = useMemo(
    () => Object.values(company.symbols).concat([company.currency]),
    [company.currency, company.symbols],
  );

  const companySymbolsOptions = useCurrencyOptions(companySymbols);

  /**
   * If the current value is not in the company symbols, we prepend it to the list of options as disabled.
   * This is to avoid the user selecting a currency that isn't in the company symbols anymore.
   * But we still need to be able to display the current value.
   *
   * Memoized to avoid running .includes if the current value hasn't changed.
   */
  const isCurrentValueNotInCompanySymbols = useMemo(
    () => currentValue && !companySymbols.includes(currentValue),
    [companySymbols, currentValue],
  );

  const disabledCurrentValueOption = useMemo(
    () =>
      currentValue && isCurrentValueNotInCompanySymbols ? [toCurrencyOption(currentValue, { disabled: true })] : [],
    [isCurrentValueNotInCompanySymbols, currentValue],
  );

  return useMemo(
    () => disabledCurrentValueOption.concat(companySymbolsOptions),
    [disabledCurrentValueOption, companySymbolsOptions],
  );
};
