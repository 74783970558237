import { keyframes } from '@emotion/react';

import { type CircularLoaderSize } from './CircularLoader.types';

export const rotateAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const CircularLoaderSizeToPx = {
  SMALL: 24,
  MEDIUM: 32,
  LARGE: 40,
} as const satisfies Record<CircularLoaderSize, number>;
