import { css } from '@emotion/react';
import { Form } from 'formik';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { getAssignmentStatus } from '@amalia/assignments/common/shared';
import {
  FormikCheckbox,
  FormikInput,
  Group,
  Modal,
  Stack,
  Tooltip,
  Typography,
  type ModalProps,
} from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { DuplicatePlanForm, type DuplicatePlanFormProps } from '../DuplicatePlanForm';

import { AssignmentDatePicker } from './AssignmentDatePicker';

export type DuplicatePlanFormModalProps = MergeAll<
  [
    Pick<ModalProps, 'isOpen' | 'onClose'>,
    DuplicatePlanFormProps,
    {
      plan: Pick<Plan, 'archived' | 'id' | 'name'>;
      planAssignments: Plan['planAssignements'];
    },
  ]
>;

export const DuplicatePlanFormModal = memo(function DuplicatePlanFormModal({
  isOpen,
  onClose,
  planAssignments,
  ...props
}: DuplicatePlanFormModalProps) {
  const canImportPlanAssignments = useMemo(
    () => (planAssignments ?? []).some((planAssignment) => getAssignmentStatus(planAssignment) === 'active'),
    [planAssignments],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <DuplicatePlanForm {...props}>
        {({ isValid, isSubmitting, values }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Duplicate plan" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Stack gap={24}>
                  {!!props.plan.archived && (
                    <p>
                      <FormattedMessage defaultMessage="This plan will be duplicated as a non-archived plan." />
                    </p>
                  )}

                  <FormikInput
                    required
                    label={<FormattedMessage defaultMessage="New plan name" />}
                    name="name"
                  />

                  <Tooltip
                    content={
                      !canImportPlanAssignments && (
                        <FormattedMessage defaultMessage="No active assignments available." />
                      )
                    }
                  >
                    <div
                      css={css`
                        width: fit-content;
                      `}
                    >
                      <FormikCheckbox
                        disabled={!canImportPlanAssignments}
                        label={<FormattedMessage defaultMessage="Copy plan assignments" />}
                        name="shouldImportAssignments"
                      />
                    </div>
                  </Tooltip>

                  {!!values.shouldImportAssignments && (
                    <Stack
                      gap={12}
                      css={css`
                        margin-top: 8px;
                      `}
                    >
                      <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                        <FormattedMessage defaultMessage="You can apply common assignment dates to all new assignments:" />
                      </Typography>
                      <Group
                        align="center"
                        gap={12}
                      >
                        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        <AssignmentDatePicker fieldName="assignmentsEffectiveAsOf" />
                        -
                        <AssignmentDatePicker fieldName="assignmentsEffectiveUntil" />
                      </Group>
                    </Stack>
                  )}
                </Stack>
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Confirm" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </DuplicatePlanForm>
    </Modal>
  );
});
