import { type Theme } from '@emotion/react';

import { type TypographyDefinition, TypographyVariant } from '@amalia/design-system/meta';

const is1stApril = new Date().getMonth() === 3 && new Date().getDate() === 1;
const robotoFontFamily = is1stApril ? '"Comic Neue", cursive, sans-serif' : 'Roboto, sans-serif';
const robotoMonoFontFamily = "'Roboto Mono', sans-serif";

const defaultProperties = {
  fontStyle: 'normal',
  letterSpacing: '0.005em',
  textTransform: 'none',
} as const satisfies Partial<TypographyDefinition>;

const largeHeadingBase = {
  ...defaultProperties,
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '54px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const heading1Base = {
  ...defaultProperties,
  fontSize: '25px',
  fontWeight: 400,
  lineHeight: '38px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const heading2Base = {
  ...defaultProperties,
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '33px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const heading3Base = {
  ...defaultProperties,
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const heading4Base = {
  ...defaultProperties,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const bodyLargeBase = {
  ...defaultProperties,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

// Yo dawg.
const bodyBaseBase = {
  ...defaultProperties,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const bodySmallBase = {
  ...defaultProperties,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const bodyXsmallBase = {
  ...defaultProperties,
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '17px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const bodyXxsmallBase = {
  ...defaultProperties,
  fontSize: '9px',
  fontWeight: 400,
  lineHeight: '14px',
  fontFamily: robotoFontFamily,
} as const satisfies TypographyDefinition;

const monospaceBase = {
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: robotoMonoFontFamily,
  fontStyle: 'normal',
  lineHeight: '21px',
  letterSpacing: 'initial',
} as const satisfies TypographyDefinition;

export const DEFAULT_THEME_TYPOGRAPHIES = {
  [TypographyVariant.LARGE_HEADING_REGULAR]: {
    ...largeHeadingBase,
  },

  [TypographyVariant.LARGE_HEADING_MEDIUM]: {
    ...largeHeadingBase,
    fontWeight: 500,
  },

  [TypographyVariant.LARGE_HEADING_BOLD]: {
    ...largeHeadingBase,
    fontWeight: 700,
  },

  [TypographyVariant.HEADING_1_REGULAR]: {
    ...heading1Base,
  },

  [TypographyVariant.HEADING_1_MEDIUM]: {
    ...heading1Base,
    fontWeight: 500,
  },

  [TypographyVariant.HEADING_1_BOLD]: {
    ...heading1Base,
    fontWeight: 700,
  },

  [TypographyVariant.HEADING_2_REGULAR]: {
    ...heading2Base,
  },

  [TypographyVariant.HEADING_2_MEDIUM]: {
    ...heading2Base,
    fontWeight: 500,
  },

  [TypographyVariant.HEADING_2_BOLD]: {
    ...heading2Base,
    fontWeight: 700,
  },

  [TypographyVariant.HEADING_3_REGULAR]: {
    ...heading3Base,
  },

  [TypographyVariant.HEADING_3_MEDIUM]: {
    ...heading3Base,
    fontWeight: 500,
  },

  [TypographyVariant.HEADING_3_BOLD]: {
    ...heading3Base,
    fontWeight: 700,
  },

  [TypographyVariant.HEADING_4_REGULAR]: {
    ...heading4Base,
  },

  [TypographyVariant.HEADING_4_MEDIUM]: {
    ...heading4Base,
    fontWeight: 500,
  },

  [TypographyVariant.HEADING_4_BOLD]: {
    ...heading4Base,
    fontWeight: 700,
  },

  [TypographyVariant.BODY_LARGE_REGULAR]: {
    ...bodyLargeBase,
  },

  [TypographyVariant.BODY_LARGE_MEDIUM]: {
    ...bodyLargeBase,
    fontWeight: 500,
  },

  [TypographyVariant.BODY_LARGE_BOLD]: {
    ...bodyLargeBase,
    fontWeight: 700,
  },

  [TypographyVariant.BODY_BASE_REGULAR]: {
    ...bodyBaseBase,
  },

  [TypographyVariant.BODY_BASE_MEDIUM]: {
    ...bodyBaseBase,
    fontWeight: 500,
  },

  [TypographyVariant.BODY_BASE_BOLD]: {
    ...bodyBaseBase,
    fontWeight: 700,
  },

  [TypographyVariant.BODY_SMALL_REGULAR]: {
    ...bodySmallBase,
  },

  [TypographyVariant.BODY_SMALL_MEDIUM]: {
    ...bodySmallBase,
    fontWeight: 500,
  },

  [TypographyVariant.BODY_SMALL_BOLD]: {
    ...bodySmallBase,
    fontWeight: 700,
  },

  [TypographyVariant.BODY_XSMALL_REGULAR]: {
    ...bodyXsmallBase,
  },

  [TypographyVariant.BODY_XSMALL_MEDIUM]: {
    ...bodyXsmallBase,
    fontWeight: 500,
  },

  [TypographyVariant.BODY_XSMALL_BOLD]: {
    ...bodyXsmallBase,
    fontWeight: 700,
  },

  [TypographyVariant.BODY_XXSMALL_REGULAR]: {
    ...bodyXxsmallBase,
  },

  [TypographyVariant.BODY_XXSMALL_MEDIUM]: {
    ...bodyXxsmallBase,
    fontWeight: 500,
  },

  [TypographyVariant.BODY_XXSMALL_BOLD]: {
    ...bodyXxsmallBase,
    fontWeight: 700,
  },

  [TypographyVariant.MONOSPACE_LARGE]: {
    ...monospaceBase,
    fontSize: '16px',
    lineHeight: '24px',
  },

  [TypographyVariant.MONOSPACE_BASE]: {
    ...monospaceBase,
  },

  [TypographyVariant.MONOSPACE_SMALL]: {
    ...monospaceBase,
    fontSize: '12px',
    lineHeight: '18px',
  },
} as const satisfies Theme['ds']['typographies'];
