import { isArray, isEmpty, last } from 'lodash';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { assert } from '@amalia/ext/typescript';
import { type Period } from '@amalia/payout-definition/periods/types';

export enum EngineErrorType {
  CALCULATION = 'CALCULATION',
  CONFIGURATION = 'CONFIGURATION',
  MISSING_QUOTA = 'MISSING_QUOTA',
  PAYMENT = 'PAYMENT',
  RELEASE = 'RELEASE',

  UNHANDLED = 'UNHANDLED',
}

export enum EngineErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export interface EngineErrorContext {
  // Current plan name.
  plan?: string;
  // Type of the token where the error happened.
  tokenType?: TokenType;
  // Id of the token where the error happened.
  tokenId?: string;
  // Machine name of the token where the error happened.
  tokenMachineName?: string;
  // Name of the token where the error happened.
  tokenName?: string;
  // Stack trace of parentIds to that token.
  tokenTraces?: string[];
}

export interface EngineError {
  // Error type.
  type: EngineErrorType;

  // Error level.
  level: EngineErrorLevel;

  // Error message.
  message: string;

  // Context of the error.
  context?: EngineErrorContext;
}

export const isEngineErrorContext = (candidate: unknown): candidate is EngineErrorContext =>
  !!candidate &&
  typeof candidate === 'object' &&
  Object.keys(candidate).every((key) =>
    ['plan', 'tokenType', 'tokenId', 'tokenMachineName', 'tokenName', 'tokenTraces'].includes(key),
  );

export const isEngineErrorContexts = (candidate: unknown): candidate is EngineErrorContext[] =>
  isArray(candidate) && candidate.every((c) => isEngineErrorContext(c));

export abstract class EngineErrorBase extends Error {
  protected readonly type: EngineErrorType = EngineErrorType.UNHANDLED;

  protected readonly level: EngineErrorLevel = EngineErrorLevel.ERROR;

  protected readonly context?: EngineErrorContext;

  public override toString() {
    throw new Error("That's how you get stack traces leaking in server response");
  }

  public static getMessageFromExistingError(e: Error): string {
    // This is a MathJs error
    if (
      'data' in e &&
      !!e.data &&
      typeof e.data === 'object' &&
      'fn' in e.data &&
      'actual' in e.data &&
      isArray(e.data.actual)
    ) {
      return `In ${e.data.fn}, got ${last(e.data.actual)}`;
    }

    return e.message;
  }

  public constructor(errorOrMessage: EngineError['message'] | Error, context?: EngineErrorContext) {
    super(
      errorOrMessage instanceof Error ? EngineErrorBase.getMessageFromExistingError(errorOrMessage) : errorOrMessage,
    );

    if (errorOrMessage instanceof Error) {
      this.stack = errorOrMessage.stack;
    }

    this.context =
      errorOrMessage instanceof EngineErrorBase ? { ...errorOrMessage.context, ...(context || {}) } : context;
  }

  public getError(): EngineError {
    if (isEmpty(this.context)) {
      // eslint-disable-next-line no-console
      console.error(`This error has no context: ${this.message} ${this.stack}`);
    }

    return {
      level: this.level,
      type: this.type,
      message: this.message,
      context: this.context,
    };
  }
}

export const isEngineErrorHandledProperly = (err: unknown): err is EngineErrorBase => err instanceof EngineErrorBase;

// Configuration error, triggered during the plan template build.
export class ConfigurationError extends EngineErrorBase {
  protected override readonly type = EngineErrorType.CONFIGURATION;
}

// Execution error, triggered while computing a statement.
export class CalculationError extends EngineErrorBase {
  protected override readonly type = EngineErrorType.CALCULATION;
}

// A quota is missing.
export class MissingQuotaError extends EngineErrorBase {
  protected override readonly type = EngineErrorType.MISSING_QUOTA;

  protected override readonly level = EngineErrorLevel.WARNING;

  public constructor(message: string, context?: EngineErrorContext) {
    assert(context?.tokenId && context.tokenName, 'Missing quota error: missing id or name');

    super(message, { ...context, tokenType: TokenType.QUOTA });
  }
}

export class PaymentReleaseError extends EngineErrorBase {
  public override type = EngineErrorType.RELEASE;

  public constructor(message: string, period: Period, paymentIds: string[], statementIds: string[]) {
    super(message);
    this.paymentIds = paymentIds;
    this.statementIds = statementIds;
    this.period = period;
  }

  public statementIds: string[];

  public paymentIds: string[];

  public period: Period;
}
