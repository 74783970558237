import { type ForwardedRef, forwardRef, useCallback, type MouseEventHandler } from 'react';

import { Dropdown } from '../../dropdown/Dropdown';
import { type DropdownItemButtonProps } from '../../dropdown/dropdown-item-button/DropdownItemButton';
import { useMenuDropdownContext } from '../MenuDropdown.context';

export type MenuDropdownItemProps = DropdownItemButtonProps;

export const MenuDropdownItem = forwardRef(function MenuDropdownItem(
  { onClick = undefined, ...props }: MenuDropdownItemProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { onClosePopover } = useMenuDropdownContext();

  const onClickProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onClick?.(event);
      onClosePopover();
    },
    [onClick, onClosePopover],
  );

  return (
    <Dropdown.ItemButton
      {...props}
      ref={ref}
      onClick={onClickProxy}
    />
  );
});
