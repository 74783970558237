import {
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfQuarter,
  endOfYear,
} from 'date-fns';
import { type ConfigType } from 'dayjs';
import { isNil } from 'lodash';

import { toDate, toTimestamp, type UnixTimestampInSeconds } from '@amalia/ext/dates';
import { dayjs } from '@amalia/ext/dayjs';
import { PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { type DateInput } from '../types/dates';

const getDateInput = (dateInput: DateInput) => {
  if (!dateInput.momentInput && !dateInput.timestamp) {
    throw new Error('Missing parameter');
  }

  return dateInput.momentInput || dateInput.timestamp;
};

export const formatUnixTimestamp = (unixTimeStamp: number) => dayjs.unix(unixTimeStamp).format('YYYY-MM-DD');

export const checkUnixTimestampBeforeFormat = (unixTimestamp: number | null) =>
  unixTimestamp ? formatUnixTimestamp(unixTimestamp) : null;

export const endOfMonthTimestamp = (dateInput: DateInput) => {
  const input = getDateInput(dateInput);

  return dayjs.utc(input).endOf('month').unix();
};

export const startOfMonthTimestamp = (dateInput: DateInput) => {
  const input = getDateInput(dateInput);

  return dayjs.utc(input).startOf('month').unix();
};

export const endOfDayTimestamp = (date: Date) => dayjs.utc(date).endOf('day').unix();

export const dateIsInAssignmentRange = (
  timestamp: number,
  assignment: { effectiveAsOf?: number | null; effectiveUntil?: number | null },
) =>
  (!assignment.effectiveAsOf || assignment.effectiveAsOf <= timestamp) &&
  (!assignment.effectiveUntil || timestamp < assignment.effectiveUntil);

/**
 * Check if two periods overlap.
 */
export const doPeriodsOverlap = (
  firstPeriod: { startDate?: number | null; endDate?: number | null },
  secondPeriod: { startDate?: number | null; endDate?: number | null },
): boolean => {
  const firstStartBoundary = firstPeriod.startDate || 0;
  const firstEndBoundary = firstPeriod.endDate || Infinity;

  const secondStartBoundary = secondPeriod.startDate || 0;
  const secondEndBoundary = secondPeriod.endDate || Infinity;

  const isFirstStartDateInRange = firstStartBoundary <= secondStartBoundary && secondStartBoundary <= firstEndBoundary;
  const isFirstEndDateInRange = firstStartBoundary <= secondEndBoundary && secondEndBoundary <= firstEndBoundary;

  const isSecondStartDateInRange = secondStartBoundary <= firstStartBoundary && firstStartBoundary <= secondEndBoundary;
  const isSecondEndDateInRange = secondStartBoundary <= firstEndBoundary && firstEndBoundary <= secondEndBoundary;

  return isFirstStartDateInRange || isFirstEndDateInRange || isSecondStartDateInRange || isSecondEndDateInRange;
};

export const fromNow = (date: Date | string) => dayjs(date).fromNow();

export type DateFormat =
  | '[Q]Q YY'
  | '[Q]Q YYYY'
  | '[Q]Q'
  | 'dddd'
  | 'HH:mm:ss'
  | 'L'
  | 'll'
  | 'LLL'
  | 'lll'
  | 'MM/YYYY'
  | 'MMM YY'
  | 'MMM'
  | 'MMMM YYYY'
  | 'YYYY-MM-DD HH:mm:ss'
  | 'YYYY-MM-DD'
  | 'YYYY-MM-DDTHH:mm:ss'
  | 'YYYY-MM-DDTHH:mm:ssZ'
  | 'YYYY';

export const formatDate = (date: NonNullable<ConfigType>, format: DateFormat) => dayjs.utc(date).format(format);

export const isValidDate = (dateString: string, format: DateFormat) => dayjs(dateString, format, true).isValid();

export const reformatDateString = (dateString: string, oldFormat: DateFormat, newFormat: DateFormat) =>
  dayjs(dateString, oldFormat).format(newFormat);

export const convertTimestampToDate = (timestamp: number) => dayjs.utc(timestamp, 'X').toDate();

export const convertUtcTimestampToDate = (timestamp?: number): string | undefined =>
  timestamp ? dayjs.utc(timestamp, 'X').format('YYYY-MM-DD') : undefined;

export const convertDateToUtcTimestamp = (
  date: Date | string | null,
  boundary: 'end' | 'start',
  _strict: boolean = false,
): number | null => {
  switch (true) {
    case !date:
      return null;
    case boundary === 'start':
      return (typeof date === 'string' ? dayjs.utc(date, 'YYYY-MM-DD', true) : dayjs.utc(date)).startOf('day').unix();
    case boundary === 'end':
      return (typeof date === 'string' ? dayjs.utc(date, 'YYYY-MM-DD', true) : dayjs.utc(date)).endOf('day').unix();
    default:
      throw new Error('Invalid date');
  }
};

export const convertTimestampToUtcDate = (timestamp: number | null): Date | null => {
  if (!timestamp) {
    return null;
  }
  // https://stackoverflow.com/questions/34050389/remove-timezone-from-a-moment-js-object
  return dayjs
    .utc(timestamp, 'X')
    .add(-1 * dayjs().utcOffset(), 'm')
    .toDate();
};

export function convertYYYYMMDDToUtcTimestamp(date: string): number;
export function convertYYYYMMDDToUtcTimestamp(date: null): null;
export function convertYYYYMMDDToUtcTimestamp(date: string | null): number | null {
  if (!date) {
    return null;
  }

  const momentDate = dayjs.utc(date, 'YYYY-MM-DD', true);

  if (!momentDate.isValid()) {
    throw new Error('Date is not in YYYY-MM-DD format');
  }

  return momentDate.unix();
}

export type ShiftDateUnits = 'days' | 'months' | 'weeks';

export const shiftDate = (datesString: string, offset: number, unit: ShiftDateUnits, format: string = 'YYYY-MM-DD') =>
  dayjs(datesString, format).add(offset, unit).format(format);

/**
 * Get startDate en endDate of a year in timestamp format
 * @param year
 * @returns
 */
export const getTimestampRangeOfYear = (year: number) => {
  const startDate = dayjs.utc({ year }).startOf('year').unix();
  const endDate = dayjs.utc({ year }).endOf('year').unix();

  return {
    startDate,
    endDate,
  };
};

/**
 * Get startDate en endDate of a month in timestamp format
 * @param year
 * @param month
 * @returns
 */
export const getTimestampRangeOfMonth = (year: number, month: number) => {
  const startDate = dayjs
    .utc({ year, month: month - 1 })
    .startOf('month')
    .unix();
  const endDate = dayjs
    .utc({ year, month: month - 1 })
    .endOf('month')
    .unix();

  return {
    startDate,
    endDate,
  };
};

/**
 * Get startDate en endDate of a quarter in timestamp format
 * @param year
 * @param quarter
 * @returns
 */
export const getTimestampRangeOfQuarter = (year: number, quarter: number) => {
  const startDate = dayjs.utc({ year }).quarter(quarter).startOf('quarter').unix();
  const endDate = dayjs.utc({ year }).quarter(quarter).endOf('quarter').unix();

  return {
    startDate,
    endDate,
  };
};

export const isDateRangeValid = (startDate?: Date | number | null, endDate?: Date | number | null): boolean => {
  if (!startDate || !endDate) {
    return true;
  }

  return dayjs(startDate).isSameOrBefore(endDate);
};

/**
 * Verify the timestamp is in UTC and match the start/end of a day.
 *
 * Based on the assumption that we don't have anything that has a granularity smaller than a day.
 *
 * @param timestamp
 * @param boundary
 */
export const isValidDateBoundary = (timestamp: number, boundary: 'END' | 'START'): boolean => {
  const momentObject = dayjs.utc(timestamp, 'X');

  switch (boundary) {
    case 'START':
      return momentObject.hour() === 0 && momentObject.minute() === 0 && momentObject.second() === 0;
    case 'END':
      return momentObject.hour() === 23 && momentObject.minute() === 59 && momentObject.second() === 59;
    default:
      throw new Error('Operation not supported');
  }
};

export const isDateValid = (date: Date) => date instanceof Date && !!date.getTime();

/**
 * Compare two dates, check if they are equal (in the tolerance).
 *
 * @param date1
 * @param date2
 * @param tolerance
 */
export const datesAreTheSame = (date1?: Date | null, date2?: Date | null, tolerance: number = 1000) => {
  if (!date1 && !date2) {
    return true;
  }

  if (!date1 || !date2) {
    return false;
  }

  return Math.abs(date1.getTime() - date2.getTime()) < tolerance;
};

/**
 * The calculation engine returns dates in two different formats, either a unix
 * timestamp (in case it's a result of a calculation), or date/date-time (fields from
 * the object definition. This function parse any input and returns a JS Date object.
 *
 * @param date
 */
export const dateFromCalculationEngineToJsDate = (date: number | string | null): Date => {
  if (isNil(date)) {
    throw new Error(`Cannot parse date ${date}`);
  }

  const momentObject = !Number.isNaN(+date)
    ? // Case where the calculation engine returned a unix timestamp.
      dayjs.utc(date, 'X')
    : // For the rest it should be a properly formatted date or date-time string.
      dayjs.utc(date);

  if (!momentObject.isValid()) {
    throw new Error(`Cannot parse date ${date}`);
  }
  return momentObject.toDate();
};

export interface TimeWindow {
  start: UnixTimestampInSeconds;
  end: UnixTimestampInSeconds;
}

export const getWindows = (boundaries: TimeWindow): Record<PeriodFrequencyEnum, TimeWindow[] | null> => {
  const dateFnsInterval = { start: toDate(boundaries.start), end: toDate(boundaries.end) };

  const getInterval = (eachCallback: typeof eachMonthOfInterval, endOfCallback: typeof endOfMonth): TimeWindow[] => {
    const starts = eachCallback(dateFnsInterval);
    return starts.map((start) => ({ start: toTimestamp(start), end: toTimestamp(endOfCallback(start)) }));
  };

  return {
    [PeriodFrequencyEnum.null]: null,
    [PeriodFrequencyEnum.month]: getInterval(eachMonthOfInterval, endOfMonth),
    [PeriodFrequencyEnum.quarter]: getInterval(eachQuarterOfInterval, endOfQuarter),
    [PeriodFrequencyEnum.year]: getInterval(eachYearOfInterval, endOfYear),
  };
};
