import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ViewDataExportSubject } from './subjects';

export const dataExportsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export);
  },

  MANAGER({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: ViewDataExportSubject) => user.id === creator.id);
  },

  READ_ONLY_MANAGER({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: ViewDataExportSubject) => user.id === creator.id);
  },

  EMPLOYEE({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: ViewDataExportSubject) => creator.id === user.id);
  },

  READ_ONLY_EMPLOYEE({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: ViewDataExportSubject) => creator.id === user.id);
  },

  DEACTIVATED_USER({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: ViewDataExportSubject) => creator.id === user.id);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
