import { type Company } from '@amalia/tenants/companies/types';
import { type UserContract } from '@amalia/tenants/users/types';

export interface TeamContract {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  company?: Company;
  user?: UserContract;

  name: string;

  archived: boolean;

  externalTeamId: string | null;

  parentTeam?: TeamContract;
  parentTeamId: string | null;

  childrenTeams: TeamContract[];
}

export type TeamMap = Record<TeamContract['id'], TeamContract>;

export enum TeamMemberType {
  EMPLOYEE = 'employee',
  MANAGER = 'manager',
}

export interface TeamScope {
  role: AssignmentType;
  scope: MatchScope;
  depth: number;
}

export enum AssignmentType {
  MINE = 'MINE',
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
}

export enum MatchScope {
  ALL = 'ALL',
  SUB = 'SUB',
}

export enum TeamAssignmentUserType {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  BOTH = 'BOTH',
}

export interface TeamPlanAssignment {
  planId: string;
  affectedUsersType: TeamAssignmentUserType;
}

export type TeamTreeNode<
  TTeam extends Pick<TeamContract, 'id' | 'parentTeamId'> = Pick<TeamContract, 'id' | 'parentTeamId'>,
> = {
  team: TTeam;
  children: TeamTreeNode<TTeam>[];
  parent?: TeamTreeNode<TTeam>;
};
