import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ChangelogApiClient } from '@amalia/amalia-meta/changelog/api-client';
import { type ChangelogEntryResponse } from '@amalia/amalia-meta/changelog/shared';

import { changelogQueryKeys } from './changelog.query-keys';

export const useInfiniteChangelogEntries = () => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: changelogQueryKeys.pages(),
    queryFn: ({ pageParam = null }: { pageParam?: ChangelogEntryResponse['cursor'] }) =>
      ChangelogApiClient.list({ cursor: pageParam }),
    getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.cursor : undefined),
    initialPageParam: null,
    staleTime: Infinity, // Never refetch after 1st load.
  });

  const changelogEntries = useMemo(() => data?.pages.flatMap((page) => page.entries) ?? [], [data?.pages]);

  return {
    data: changelogEntries,
    ...rest,
  };
};

export const useLatestChangelogEntries = (fromDate?: Date | null) =>
  useQuery({
    queryKey: changelogQueryKeys.latestEntries(fromDate!),
    queryFn: () => ChangelogApiClient.list({ newEntries: true, fromDate }),
    enabled: !!fromDate,
    staleTime: Infinity, // Never refetch after 1st load.
  });
