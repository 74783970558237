import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type Rule } from '@amalia/payout-definition/plans/types';

import { plansQueryKeys, rulesV2QueryKeys } from '../queries.keys';

import { RulesApiClient } from './rules.api-client';
import { rulesMutationKeys, rulesQueryKeys } from './rules.keys';

export const useRules = ({ enabled, select }: { enabled?: boolean; select?: (rules: Rule[]) => Rule[] } = {}) =>
  useQuery({
    queryKey: rulesQueryKeys.list(),
    queryFn: () => RulesApiClient.list(),
    select,
    enabled,
  });

export const useCreateRule = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: rulesMutationKeys.create(),
    mutationFn: RulesApiClient.create,
    onSuccess: async (ruleCreated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Rule created.' }));

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: rulesQueryKeys.list() }),
        queryClient.invalidateQueries({
          queryKey: ruleCreated.planId
            ? rulesV2QueryKeys.configurations.ofPlan.all(ruleCreated.planId)
            : rulesV2QueryKeys.configurations.all(),
        }),
        queryClient.invalidateQueries({
          queryKey: ruleCreated.planId
            ? plansQueryKeys.template.ofPlan(ruleCreated.planId)
            : plansQueryKeys.template.all(),
        }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while creating the rule: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const usePatchRule = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: rulesMutationKeys.patch(),
    mutationFn: RulesApiClient.patch,
    onSuccess: async (ruleUpdated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Rule updated successfully.' }));

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: rulesQueryKeys.list() }),
        queryClient.invalidateQueries({
          queryKey: ruleUpdated.planId
            ? rulesV2QueryKeys.configurations.ofPlan.all(ruleUpdated.planId)
            : rulesV2QueryKeys.configurations.all(),
        }),
        queryClient.invalidateQueries({
          queryKey: ruleUpdated.planId
            ? plansQueryKeys.template.ofPlan(ruleUpdated.planId)
            : plansQueryKeys.template.all(),
        }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while updating the rule: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const useUpdateRule = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: rulesMutationKeys.update(),
    mutationFn: (...args: Parameters<typeof RulesApiClient.update>) => RulesApiClient.update(...args),
    onSuccess: async (ruleUpdated) => {
      snackSuccess(formatMessage({ defaultMessage: 'Rule updated successfully.' }));

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: rulesQueryKeys.list() }),
        queryClient.invalidateQueries({
          queryKey: ruleUpdated.planId
            ? rulesV2QueryKeys.configurations.ofPlan.all(ruleUpdated.planId)
            : rulesV2QueryKeys.configurations.all(),
        }),
        queryClient.invalidateQueries({
          queryKey: ruleUpdated.planId
            ? plansQueryKeys.template.ofPlan(ruleUpdated.planId)
            : plansQueryKeys.template.all(),
        }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while updating the rule: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const useDeleteRule = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: rulesMutationKeys.delete(),
    mutationFn: (...args: Parameters<typeof RulesApiClient.delete>) => RulesApiClient.delete(...args),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Rule deleted successfully.' }));

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: rulesQueryKeys.list() }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.all() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.all() }),
      ]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while deleting the rule: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};
