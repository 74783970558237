import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

// Wrong type it was ComputeEngineResult instead of number[][] but it's creating a TS error
export const indicesLinear = new AmaliaFunctionDefault<[number, number[][]], number>({
  name: AmaliaFunctionKeys.LINEAR,
  category: AmaliaFunctionCategory.INDICES,
  nbParamsRequired: 2,
  description: 'Compare a parameter to tiers in a table and return a calculated value linear to the tier',

  exec: (tierToCompare, table) => {
    // The assert is good but it's not working because the scope.service return a shitty type, so we must disable it for now
    // assert(isComputedEngine2DTable<number>(table), 'In LINEAR, wrong table format');

    let toReturn = 0;
    let index = 0;
    // Sorry for the worst quickaround ever, but I don't have time to fix the type of table
    for (const tier of table) {
      if (tier[1] >= tierToCompare) {
        if (index === 0) {
          toReturn = tier[2];
        } else if (index === table.length - 1) {
          toReturn = tier[2];
        } else {
          toReturn =
            tier[2] + ((tierToCompare - tier[0]) * (table[index + 1][2] - tier[2])) / (table[index + 1][0] - tier[0]);
        }
        break;
      }
      index += 1;
    }
    return toReturn;
  },

  params: [
    { name: 'parameter', description: 'Variables, fields, properties etc.' },
    {
      name: 'table',
      description:
        'Table containing (min and max) and corresponding values. The third column defines the return value corresponding to the lower bound of the interval of the row.',
    },
  ],

  examples: [
    {
      desc: 'Returns 0.6 since 0.55 falls between 0.5 and 0.6 where the lower bound is 0.5 and the upper bound is 0.7',
      formula: 'LINEAR(0.55, [[0, 0.3, 0],[0.3, 0.5, 0],[0.5, 0.6, 0.5],[0.6, 0.7, 0.7]])' as AmaliaFormula,
    },
    {
      desc: 'Returns the corresponding linear value of the interval of Target Reach according to the linear curve.',
      formula: 'LINEAR(statement.targetReach, statement.payoutGrid)' as AmaliaFormula,
    },
  ],
});
