import { IconPencil } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { UserExternalIdSourceLabel, UserHrisIdSourceLabel } from '@amalia/data-capture/connectors/assets';
import { Table, Typography } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { type UserInfo } from '@amalia/tenants/users/profile/types';

import { UserIntegrations } from '../../../integrations';

import { ConfirmResetIntegrationEditPopoverModal } from './modal/ConfirmResetIntegrationEditPopoverModal';
import { IntegrationsEditPopover } from './popover/IntegrationsEditPopover';
import { useConfirmResetPopoverFieldsIfErrors } from './popover/useConfirmResetPopoverFieldsIfErrors';

export type IntegrationsEditCellProps = {
  readonly row: Pick<
    UserInfo,
    'externalId' | 'externalIdSource' | 'firstName' | 'hrisId' | 'hrisIdSource' | 'lastName'
  >;
};

const disableCloseTransition = {
  close: 0,
} as const;

export const IntegrationsEditCell = memo(function IntegrationsEditCell({ row }: IntegrationsEditCellProps) {
  const { isPopoverOpen, setPopoverOpen } = useBoolState(false, 'popoverOpen');

  const { isAnyInputFocused, setAnyInputFocusedTrue, setAnyInputFocusedFalse } = useBoolState(false, 'anyInputFocused');

  const { onChangeIsOpen, onCancelConfirmReset, onConfirmReset, isConfirmResetOpen, missingField, formValues } =
    useConfirmResetPopoverFieldsIfErrors({
      onChangeIsOpen: setPopoverOpen,
    });

  return (
    <Table.Cell.WithActions
      hideActions={isPopoverOpen ? false : undefined}
      tooltipContent={null}
      actions={
        <IntegrationsEditPopover
          initialFocus={-1}
          isOpen={isPopoverOpen}
          shouldDismiss={!isConfirmResetOpen && !isAnyInputFocused}
          transitionDuration={disableCloseTransition}
          onBlurInput={setAnyInputFocusedFalse}
          onChangeIsOpen={onChangeIsOpen}
          onFocusInput={setAnyInputFocusedTrue}
        >
          <Table.Cell.IconAction
            data-testid="integrations-edit-popover-open-button"
            icon={<IconPencil />}
            label={<FormattedMessage defaultMessage="Edit external and HRIS IDs" />}
          />
        </IntegrationsEditPopover>
      }
    >
      <UserIntegrations user={row} />

      {!!isConfirmResetOpen && (
        <ConfirmResetIntegrationEditPopoverModal
          isOpen
          onCancel={onCancelConfirmReset}
          onConfirm={onConfirmReset}
        >
          {missingField === 'externalId' && (
            <FormattedMessage
              defaultMessage="You didn't enter an external ID for {source} source.{br}If you leave, we will remove the external source."
              values={{
                source: (
                  <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                    <UserExternalIdSourceLabel externalIdSource={formValues.externalIdSource} />
                  </Typography>
                ),
              }}
            />
          )}
          {missingField === 'hrisId' && (
            <FormattedMessage
              defaultMessage="You didn't enter an HRIS ID for {source} source.{br}If you leave, we will remove the HRIS source."
              values={{
                source: (
                  <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                    <UserHrisIdSourceLabel hrisIdSource={formValues.hrisIdSource} />
                  </Typography>
                ),
              }}
            />
          )}
        </ConfirmResetIntegrationEditPopoverModal>
      )}
    </Table.Cell.WithActions>
  );
});
