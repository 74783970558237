import { css } from '@emotion/react';
import { memo, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Skeleton, TextLink, Tooltip } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { IconAmalia, IconAmaliaChristmas, IconAmaliaHalloween, IconAmaliaPinktober } from '@amalia/frontend/ui-icons';
import { NotificationsWidget } from '@amalia/tenants/users/notifications/components';

const today = new Date();

const isChristmas = today.getMonth() === 11;
const isPinktober = today.getMonth() === 9 && today.getDate() <= 15;
const isHalloween =
  (today.getMonth() === 9 && today.getDate() >= 26) || (today.getMonth() === 10 && today.getDate() <= 3);

export const LeftBarHeader = memo(function LeftBarHeader() {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Tooltip
        placement="right"
        content={
          isPinktober ? (
            <FormattedMessage
              defaultMessage="October marks Pinktober, a month dedicated to raising awareness about breast cancer and the vital importance of early detection.{br}You can learn more <link>here</link>."
              values={{
                link: (chunks) => (
                  <TextLink
                    colorScheme="light"
                    to="https://www.service-public.fr/particuliers/actualites/A15216?lang=en"
                  >
                    {chunks}
                  </TextLink>
                ),
              }}
            />
          ) : undefined
        }
      >
        {/* Special logos do not have the same viewbox as the regular logo, so we keep the same box size and we position the logo manually inside that. */}
        <Link
          to={generatePath(routes.ROOT)}
          css={css`
            display: flex;
            height: 17px;
            width: 90px;
            position: relative;
          `}
        >
          <Suspense
            fallback={
              <Skeleton
                css={css`
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                `}
              />
            }
          >
            {isChristmas ? (
              <IconAmaliaChristmas
                css={css`
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: auto;
                  width: 100%;
                `}
              />
            ) : isPinktober ? (
              <IconAmaliaPinktober
                css={css`
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: auto;
                  width: 100%;
                `}
              />
            ) : isHalloween ? (
              <IconAmaliaHalloween
                css={css`
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: auto;
                  width: 100%;
                `}
              />
            ) : (
              <IconAmalia
                css={css`
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: auto;
                  width: 100%;
                `}
              />
            )}
          </Suspense>
        </Link>
      </Tooltip>

      <NotificationsWidget />
    </div>
  );
});
