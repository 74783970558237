import { subject } from '@casl/ability';
import { permittedFieldsOf } from '@casl/ability/extra';

import { type UserProfile } from '@amalia/tenants/users/profile/types';

import { type Ability, type ActionsEnum, SubjectsEnum } from '../../types';

export const USER_PROFILE_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'role',
  'currency',
  'externalId',
  'externalIdSource',
  'hrisId',
  'hrisIdSource',
  'language',
] as const;

export type EditableUserField = (typeof USER_PROFILE_FIELDS)[number];

export type UserProfileSubject = Pick<UserProfile, 'id'>;
export const userProfileSubject = (user?: UserProfileSubject) => subject(SubjectsEnum.UserProfile, user ?? {});

export const permittedUserProfileFields = (
  ability: Ability,
  action: ActionsEnum.modify,
  user?: Pick<UserProfile, 'id'>,
) =>
  permittedFieldsOf(ability, action, userProfileSubject(user), {
    fieldsFrom: (rule) => rule.fields || [...USER_PROFILE_FIELDS],
  }) as EditableUserField[];
