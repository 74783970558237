import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const overwriteRecapsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap);
    can(ActionsEnum.export, SubjectsEnum.Overwrite_Recap);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap);
    can(ActionsEnum.export, SubjectsEnum.Overwrite_Recap);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap);
    can(ActionsEnum.export, SubjectsEnum.Overwrite_Recap);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
