import { toTimestamp } from '@amalia/ext/dates';

import { type AssignmentDates, type AssignmentStatus } from './types';

export const getAssignmentStatus = ({ effectiveAsOf, effectiveUntil }: AssignmentDates): AssignmentStatus => {
  const currentTimestamp = toTimestamp(new Date());

  return effectiveAsOf && effectiveAsOf > currentTimestamp
    ? 'future'
    : effectiveUntil && effectiveUntil < currentTimestamp
      ? 'expired'
      : 'active';
};
