import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionRawArgs } from '../../AmaliaFunction';

export const miscDefault = new AmaliaFunctionRawArgs({
  name: AmaliaFunctionKeys.DEFAULT,
  category: AmaliaFunctionCategory.MISC,
  nbParamsRequired: 2,
  description: 'Return a default value if the parameter is null or undefined',

  exec: (args, _, scope): ComputeEngineResult => {
    const [parameter, defaultValue] = args;

    try {
      const evaluationResult = parameter.evaluate(scope) as ComputeEngineResult;

      if (evaluationResult !== undefined && evaluationResult !== null) {
        return evaluationResult;
      }
    } catch (e) {
      // Silence TypeErrors.
      if (!(e instanceof TypeError)) {
        throw e;
      }
    }

    // If we're here, it means that either the evaluation returned null, or threw a type error.
    // Fallback to the default value.
    return defaultValue.evaluate(scope) as ComputeEngineResult;
  },

  params: [
    { name: 'parameter', description: 'Variables, Fields or Properties' },
    { name: 'defaultValue', description: 'Value to return if parameter is null or undefined.' },
  ],

  examples: [
    {
      desc: 'Returns the close date if it exists else, it returns the end date of the period.',
      formula: 'DEFAULT(opportunity.closeDate, statementPeriod.endDate)' as AmaliaFormula,
    },
    {
      desc: 'Returns the invoice date if it exists else, it returns an empty string.',
      formula: 'DEFAULT(opportunity.invoice.closeDate, "")' as AmaliaFormula,
    },
    {
      desc: 'Returns the amount cancelled if it exists else, it returns 0.',
      formula: 'DEFAULT(opportunity.amountCancelled, 0)' as AmaliaFormula,
    },
  ],
});
