import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const stringSplit = new AmaliaFunctionDefault<[string | null | undefined, string, number], string | null>({
  name: AmaliaFunctionKeys.SPLIT,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 3,
  description: 'Takes a pattern and divides a String into an ordered list of substrings',

  execMock: () => 'mock',
  exec: (text, separator, index) => {
    assert(text !== null && text !== undefined, `${AmaliaFunctionKeys.SPLIT}: source string is null or undefined`);
    return text.toString().split(separator)[index] ?? null;
  },

  params: [
    { name: 'string', description: 'String: variables, fields, properties, string' },
    {
      name: 'separator',
      description: 'The pattern describing where each split should occur',
    },
    {
      name: 'position',
      description: 'The position of the split part the function should return. Starting at 0 for the first part',
    },
  ],

  examples: [
    {
      desc: 'Returns first.',
      formula: 'SPLIT("first,second", ",", 0)' as AmaliaFormula,
      result: 'first',
    },
    {
      desc: 'Returns second.',
      formula: 'SPLIT("first,second", ",", 1)' as AmaliaFormula,
      result: 'second',
    },
    {
      desc: 'Returns null since all split parts are before that position.',
      formula: 'SPLIT("first,second", ",", 30)' as AmaliaFormula,
      result: null,
    },
    {
      desc: 'Returns first.',
      formula: 'SPLIT("first", ",", 0)' as AmaliaFormula,
      result: 'first',
    },
  ],
});
