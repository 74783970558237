import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { UserProfileApiClient } from '@amalia/tenants/users/profile/api-client';

import { userProfileMutationKeys, userProfileQueryKeys } from './queries.keys';

export const useRandomizeAvatar = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: userProfileMutationKeys.avatar.randomize(),
    mutationFn: UserProfileApiClient.randomizeUserAvatar,
    onSuccess: async () => {
      snackSuccess(
        formatMessage({
          defaultMessage: 'Your avatar has been successfully edited',
        }),
      );

      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
export const useUploadAvatar = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: userProfileMutationKeys.avatar.upload(),
    mutationFn: UserProfileApiClient.uploadAvatar,
    onSuccess: async () => {
      snackSuccess(
        formatMessage({
          defaultMessage: 'Your avatar has been successfully edited',
        }),
      );

      await queryClient.invalidateQueries({ queryKey: userProfileQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
export const useIsUpdatingAvatar = () =>
  useIsMutating({
    mutationKey: userProfileMutationKeys.avatar.all(),
  }) > 0;
