import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  forwardRef,
  type ForwardedRef,
  cloneElement,
  type ComponentPropsWithoutRef,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { Tooltip } from '../../overlays/tooltip/Tooltip';
import { IconLoading } from '../icon-loading/IconLoading';
import { type TablerIconElement } from '../icons/types';
import { UnstyledButton } from '../unstyled-button/UnstyledButton';

import * as styles from './IconAction.styles';
import { IconActionSize, IconActionVariant } from './IconAction.types';

const ICON_SIZE_MAPPING_PX: Record<IconActionSize, number> = {
  [IconActionSize.XSMALL]: 12,
  [IconActionSize.SMALL]: 16,
  [IconActionSize.MEDIUM]: 20,
} as const;

export type IconActionProps = MergeAll<
  [
    ComponentPropsWithoutRef<'button'>,
    {
      /** Icon of the action. */
      icon: TablerIconElement;
      /** Tooltip label. If possible, pass as string so it can be put as aria-label as well. */
      label: ReactNode;
      /** Action variant. Use DANGER for clear actions. */
      variant?: IconActionVariant;
      /** Icon size. */
      size?: IconActionSize;
      /** Force loading state. It will disable the button too. */
      isLoading?: boolean;
      /** While in loading state, show this icon instead. */
      iconLoading?: TablerIconElement;
      /** While in loading state, show this tooltip label instead. */
      labelLoading?: ReactNode;
    },
  ]
>;

const IconActionForwardRef = forwardRef(function IconAction(
  {
    icon,
    label,
    className = undefined,
    size = IconActionSize.MEDIUM,
    variant = IconActionVariant.DEFAULT,
    type = 'button',
    isLoading = false,
    iconLoading = <IconLoading />,
    labelLoading = <FormattedMessage defaultMessage="Loading…" />,
    disabled = false,
    ...props
  }: IconActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Tooltip content={isLoading ? labelLoading : label}>
      <UnstyledButton
        {...props}
        ref={ref}
        aria-label={props['aria-label'] || (typeof label === 'string' ? label : undefined)}
        className={clsx(className, size, variant)}
        css={styles.iconAction}
        disabled={disabled || isLoading}
        type={type}
      >
        {cloneElement(isLoading ? iconLoading : icon, {
          size: ICON_SIZE_MAPPING_PX[size],
          color: 'currentColor',
        })}
      </UnstyledButton>
    </Tooltip>
  );
});

export const IconAction = Object.assign(memo(IconActionForwardRef), {
  Size: IconActionSize,
  Variant: IconActionVariant,
});
