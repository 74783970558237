import { subject } from '@casl/ability';

import { type Statement } from '@amalia/core/types';

import { SubjectsEnum } from '../../types';

export type SimulateForecastedStatementSubject = Pick<Statement, 'userId'>;

export const simulateForecastedStatementSubject = (payload: SimulateForecastedStatementSubject) =>
  subject(SubjectsEnum.Forecasted_Statement, { ...payload }); // Need to spread here otherwise casl will throw.

export type CalculateForecastedStatementSubject = Pick<Statement, 'userId'>;

export const calculateForecastedStatementSubject = (payload: CalculateForecastedStatementSubject) =>
  subject(SubjectsEnum.Forecasted_Statement, { ...payload }); // Need to spread here otherwise casl will throw.
