import { TypographyVariant } from '@amalia/design-system/meta';

import { UsersAvatarStackSize } from './UsersAvatarStack.types';

export const UsersAvatarStackSizeToAvatarSize = {
  [UsersAvatarStackSize.SMALL]: 24,
  [UsersAvatarStackSize.MEDIUM]: 30,
  [UsersAvatarStackSize.LARGE]: 36,
} as const satisfies Record<UsersAvatarStackSize, number>;

export const UsersAvatarStackSizeToTypographyVariant = {
  [UsersAvatarStackSize.SMALL]: TypographyVariant.BODY_XSMALL_REGULAR,
  [UsersAvatarStackSize.MEDIUM]: TypographyVariant.BODY_SMALL_REGULAR,
  [UsersAvatarStackSize.LARGE]: TypographyVariant.BODY_BASE_REGULAR,
} as const satisfies Record<UsersAvatarStackSize, TypographyVariant>;

// Overlap between avatars in pixels.
export const AVATAR_OVERLAP = 6;
