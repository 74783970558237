import { type ReactNode, memo } from 'react';

import { ComponentSwitchItem } from './component-switch-item/ComponentSwitchItem';
import { ComponentSwitchContext } from './ComponentSwitch.context';
import { type ComponentSwitchItemValue } from './ComponentSwitch.types';

export interface ComponentSwitchProps {
  /** Currently active child item value. */
  readonly value?: ComponentSwitchItemValue | null;
  /** Component switch items. */
  readonly children?: ReactNode;
}

const ComponentSwitchBase = memo(function ComponentSwitch({ value = null, children = null }: ComponentSwitchProps) {
  return <ComponentSwitchContext.Provider value={value}>{children}</ComponentSwitchContext.Provider>;
});

export const ComponentSwitch = Object.assign(ComponentSwitchBase, {
  Item: ComponentSwitchItem,
});
