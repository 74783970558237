import { http } from '@amalia/core/http/client';
import {
  type CreateTeamRequest,
  type TeamContract,
  type LinkExistingTeamsRequest,
  type UnarchiveTeamRequest,
  type SetTeamNameRequest,
} from '@amalia/tenants/teams/types';

export class TeamsApiClient {
  public static async createTeam(team: CreateTeamRequest): Promise<TeamContract> {
    const { data } = await http.post<TeamContract>('/teams', team);
    return data;
  }

  public static async getTeams(): Promise<TeamContract[]> {
    const { data } = await http.get<TeamContract[]>('/teams');
    return data;
  }

  // Will be removed when team detail view is refactored.
  public static async getTeam(teamId: string): Promise<TeamContract> {
    const { data } = await http.get<TeamContract>(`/teams/team/${teamId}`);
    return data;
  }

  public static async setTeamName({
    teamId,
    ...setTeamNameRequest
  }: SetTeamNameRequest & { teamId: TeamContract['id'] }): Promise<void> {
    await http.put(`/teams/${encodeURIComponent(teamId)}/name`, setTeamNameRequest);
  }

  public static async deleteTeam(teamId: TeamContract['id']) {
    await http.delete(`/teams/${encodeURIComponent(teamId)}`);
  }

  public static async linkExistingTeams({
    teamId,
    ...linkExistingTeamsRequest
  }: LinkExistingTeamsRequest & { teamId: TeamContract['id'] }): Promise<void> {
    await http.post(`/teams/${encodeURIComponent(teamId)}/link-existing-teams`, linkExistingTeamsRequest);
  }

  public static async unlinkFromParentTeam(teamId: TeamContract['id']): Promise<void> {
    await http.delete(`/teams/${encodeURIComponent(teamId)}/parent-team`);
  }

  public static async archiveTeam(teamId: TeamContract['id']): Promise<{ archivedTeamsCount: number }> {
    return (await http.post<{ archivedTeamsCount: number }>(`/teams/${encodeURIComponent(teamId)}/archive`)).data;
  }

  public static async unarchiveTeam({
    teamId,
    ...unarchiveTeamRequest
  }: UnarchiveTeamRequest & { teamId: TeamContract['id'] }): Promise<{ unarchivedTeamsCount: number }> {
    return (
      await http.post<{ unarchivedTeamsCount: number }>(
        `/teams/${encodeURIComponent(teamId)}/unarchive`,
        unarchiveTeamRequest,
      )
    ).data;
  }
}
