import { type Variable, type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type Period } from '@amalia/payout-definition/periods/types';
import {
  type Challenge,
  type Plan,
  type PlanIsHiddenQueryChoices,
  type Rule,
} from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type Overwrite } from './overwrite';
import { type Adjustment, type Statement } from './statement';

export enum PaymentCategory {
  achievement = 'achievement',
  hold = 'hold',
  paid = 'paid',
  adjustments = 'adjustments',
}

export enum PaymentType {
  RULE_PAYOUT = 'RULE_PAYOUT',
  RULE_LINE_PAYOUT = 'RULE_LINE_PAYOUT',
  RULE_SPLIT = 'RULE_SPLIT',
  LINE_HOLD = 'LINE_HOLD',
  ADJUSTMENT = 'ADJUSTMENT',
  CHALLENGE = 'CHALLENGE',
}

export const PaymentTypeLabels: Record<PaymentType, string> = {
  [PaymentType.RULE_PAYOUT]: 'Rule Payout',
  [PaymentType.RULE_LINE_PAYOUT]: 'Rule - Line Payout',
  [PaymentType.RULE_SPLIT]: 'Rule Split',
  [PaymentType.LINE_HOLD]: 'Line Hold',
  [PaymentType.ADJUSTMENT]: 'Adjustment',
  [PaymentType.CHALLENGE]: 'Challenge',
};

export interface Payment {
  id: string;

  companyId?: string;

  period: Period;
  periodId: string;

  plan?: Plan;
  planId?: string;

  user?: UserContract;
  userId: string;

  statement: Partial<Statement>;
  statementId: string;

  type: PaymentType;

  value: number;
  totalValue?: number;
  rate: number;
  currency: CurrencySymbolsEnum;

  adjustmentId: string | null;

  paymentPeriod?: Period;
  paymentPeriodId?: string;

  ruleId?: string;
  rule?: Partial<Rule>;

  dealExternalId?: string;
  dealName?: string;
  dealObjectName?: string;

  paymentDate?: Date;
  forecastPaymentDate?: Date;

  objectVariable?: VariableDefinition;
  variableName?: string;

  adjustment?: Adjustment;

  challenge?: Challenge;
  challengeId?: string;

  masterPayment?: Payment;
  masterPaymentId?: string;

  overwrites?: Overwrite[];
}

export interface PatchPaymentRequest {
  paymentPeriodId: string | null;
}

// SPLIT
export interface PaymentSplitPart {
  value: number;
  paymentPeriodId: string;
}

export interface PaymentSplitConfiguration {
  splitParts: PaymentSplitPart[];
}

export interface PaymentLock {
  period: Period;
  periodId: string;
}

export type PaymentAggregatedItem = {
  type: PaymentCategory;
  sum: number;
  userId: string;
  statementId: string;
  currency: CurrencySymbolsEnum;
  rate: number;
  planId?: string;
  teamId?: string;
};

export type PaymentAggregatedList = Record<PaymentCategory, PaymentAggregatedItem[]>;
export type PaymentAggregatedListWithFlag = PaymentAggregatedList & { containsHoldRules: boolean };
export type PaymentAggregatedListByUser = Record<PaymentCategory, Record<string, PaymentAggregatedItem[]>>;

export type PaymentsOfPeriod = Partial<PaymentAggregatedList & { containsHoldRules: boolean }>;

export enum PaymentButtonStatus {
  none = 'none',
  unpaid = 'unpaid',
  paid = 'paid',
}

// TODO: this should be a derived type from Payment and not a whole redefinition. Best case scenario would be to merge them.
export interface PaymentContract {
  id: string;

  statement: Statement;

  companyId: string;

  challengeId?: string;

  masterPaymentId?: string;

  totalValue?: number;

  userId?: string;

  adjustmentId?: string | null;

  statementId: string;

  type: PaymentType;

  value: number;

  rate: number;

  currency: CurrencySymbolsEnum;

  periodId: string;

  period?: Omit<Period, 'company'>;

  plan?: Pick<Plan, 'id' | 'isHidden' | 'name'>;

  planId: string;

  paymentPeriod?: Pick<Period, 'endDate' | 'frequency' | 'name' | 'startDate'> | null;

  paymentPeriodId?: string | null;

  rule?: Pick<Rule, 'id' | 'name'>;

  ruleId?: string;

  user?: Pick<UserContract, 'firstName' | 'id' | 'lastName' | 'pictureURL'>;

  objectVariable?: Variable;

  variableName?: string;

  dealExternalId?: string;

  dealName?: string;

  dealObjectName?: string;

  adjustment?: Adjustment;

  paymentDate?: Date | null;

  forecastPaymentDate?: Date | null;

  overwrites?: Pick<Overwrite, 'field' | 'id' | 'overwriteValue' | 'sourceValue'>[];
}

export interface CommissionReportFilters {
  planHiddenStatus?: PlanIsHiddenQueryChoices;
  periodIds?: string[];
  teamIds?: string[];
  userIds?: string[];
  planIds?: string[];
  ruleIds?: string[];
  paymentPeriodIds?: string[];
}

export type PaymentAmountsByCategory = Record<PaymentCategory, number | null>;
