import { http } from '@amalia/core/http/client';
import { type PlanTemplateDehydrated } from '@amalia/payout-calculation/types';
import { type SaPlanSumup } from '@amalia/superadmin/shared';

export async function saGetPlans(companyId: string): Promise<SaPlanSumup[]> {
  const { data } = await http.get<SaPlanSumup[]>('/sa_plans', {
    params: {
      companyId,
    },
  });
  return data;
}

// The return type here is Partial<PlanTemplate> but we do not really need this type in the front.
export async function saGetPlanTemplate(
  planId: string,
  companyId: string,
  withQueryContainers: boolean,
): Promise<{ planTemplate: PlanTemplateDehydrated; error: { message: string; context: string } }> {
  const { data } = await http.get<{
    planTemplate: PlanTemplateDehydrated;
    error: { message: string; context: string };
  }>(`/sa_planTemplate/plans/${planId}/analyze`, {
    params: {
      companyId,
      'with-query-containers': withQueryContainers,
    },
  });

  return data;
}
