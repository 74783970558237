import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const analyticsHomeAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
