import { IconBuildingSkyscraper } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge, BadgeSize } from '@amalia/design-system/components';
import { useCompaniesList } from '@amalia/superadmin/state';
import { CompanyStatus } from '@amalia/tenants/companies/types';

import { CommandGroup } from '../../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/use-actions';
import { PagesEnum } from '../../shortcut-commands.types';

export const ListCompaniesCommand = memo(function ListCompaniesCommand() {
  const { data: companiesList } = useCompaniesList(true);

  const { primaryAction } = useActions(PagesEnum.LIST_COMPANIES);

  const companyOptions = useMemo(
    () =>
      (companiesList ?? [])
        .map((company) => ({
          name: company.name,
          adminEmail: company.admin?.email,
          status: company.status,
          id: company.id,
        }))
        .filter(({ adminEmail }) => adminEmail),
    [companiesList],
  );

  return (
    <CommandGroup
      Icon={IconBuildingSkyscraper}
      title={<FormattedMessage defaultMessage="Companies list" />}
    >
      {companyOptions.map(({ id, name, status, adminEmail }) => (
        <CommandItem
          key={id}
          keywords={[id, name]}
          label={name}
          value={id}
          rightElement={
            status === CompanyStatus.ACTIVE ? (
              <Badge
                size={BadgeSize.SMALL}
                variant={Badge.Status.SUCCESS}
              >
                <FormattedMessage defaultMessage="Active" />
              </Badge>
            ) : (
              <Badge
                size={BadgeSize.SMALL}
                variant={Badge.Status.MISSING}
              >
                <FormattedMessage defaultMessage="Inactive" />
              </Badge>
            )
          }
          onSelect={() => primaryAction?.onSelect(adminEmail!)}
        />
      ))}
    </CommandGroup>
  );
});
