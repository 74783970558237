/**
 * Throw this error inside onChange to signal to the QuickEdit that saving failed and that it should not leave edition mode.
 */
export class QuickEditAsyncValidationError extends Error {
  public constructor(private readonly internalError?: Error) {
    super('QuickEditAsyncValidationError');

    // Remove this constructor from stack trace.
    Error.captureStackTrace(this, QuickEditAsyncValidationError);
  }
}
