import { type CSSProperties } from 'react';

import { TokenType, type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amalia/ext/mui/theme';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { FormulaNodeEnum, IFormulaNode } from './IFormulaNode';

export class VariableFormulaNode extends IFormulaNode<Variable> {
  private readonly variableType: VariableType;

  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Variable,
    variableType: VariableType,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.VARIABLE, nodeObject, objectDefinition, relationship);
    this.variableType = variableType;
  }

  public getDesignerPath() {
    let type: TokenType | null;

    switch (this.variableType) {
      case VariableType.object:
        type = TokenType.FIELD;
        break;
      case VariableType.statement:
        type = TokenType.VARIABLE;
        break;
      case VariableType.user:
      case VariableType.plan:
      case VariableType.team:
        type = TokenType.QUOTA;
        break;
      default:
        type = null;
        break;
    }

    return type && this.nodeObject.id ? { type, id: this.nodeObject.id } : null;
  }

  public getStyles(): CSSProperties {
    // If this is a quota variable => print with the quota color
    if ([VariableType.user, VariableType.plan, VariableType.team].includes(this.variableType)) {
      return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.quota };
    }
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR[this.variableType] };
  }

  public getLabel(): string {
    return this.nodeObject.name.trim();
  }
}
