import { css } from '@emotion/react';
import { IconViewfinder } from '@tabler/icons-react';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DesignerTokenIcon } from '@amalia/amalia-lang/tokens/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { useQuotasWithAssignedPlans } from '@amalia/assignments/quotas/state';
import { Badge } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';

import { CommandGroup } from '../../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/use-actions';
import { PagesEnum } from '../../shortcut-commands.types';

export const ListQuotasCommand = memo(function ListQuotasCommand() {
  const { data: { items: quotasList } = {} } = useQuotasWithAssignedPlans();

  const { primaryAction } = useActions(PagesEnum.LIST_QUOTAS);

  const quotasListOptions = useMemo(
    () =>
      (quotasList ?? []).map((quotaWithAssignments) => ({
        name: quotaWithAssignments.quota.name,
        id: quotaWithAssignments.quota.id,
        assignedPlansCount: quotaWithAssignments.assignedPlans.length,
        frequency: quotaWithAssignments.quota.frequency,
        type: quotaWithAssignments.quota.type,
        isRequired: quotaWithAssignments.quota.isQuotaRequired,
        format: quotaWithAssignments.quota.format,
      })),
    [quotasList],
  );

  return (
    <CommandGroup
      Icon={IconViewfinder}
      title={<FormattedMessage defaultMessage="Quotas list" />}
    >
      {quotasListOptions.map(({ id, name, isRequired, format, assignedPlansCount }) => (
        <CommandItem
          key={id}
          keywords={[id, name]}
          value={id}
          label={
            <Fragment>
              {name}
              {!!isRequired && (
                <span
                  css={(theme) => css`
                    color: ${theme.ds.colors[ColorCategory.PRIMARY][400]};
                    padding-left: 2px;
                  `}
                  // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                >
                  *
                </span>
              )}
            </Fragment>
          }
          leftElement={
            <DesignerTokenIcon
              height={14}
              tokenFormat={format}
              tokenType={TokenType.QUOTA}
              width={14}
              css={css`
                flex: none;
                box-sizing: content-box;
              `}
            />
          }
          rightElement={
            <Badge
              size={Badge.Size.SMALL}
              variant={assignedPlansCount > 0 ? Badge.Hue.BLUE : Badge.Hue.GREY}
            >
              <FormattedMessage
                defaultMessage="{count, plural, =0 {Not used in any plan} one {Used in # plan} other {Used in # plans}}"
                values={{ count: assignedPlansCount }}
              />
            </Badge>
          }
          onSelect={primaryAction?.onSelect}
        />
      ))}
    </CommandGroup>
  );
});
