import { Fragment, memo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  DataGrid,
  getPageCount,
  Table,
  useControlledDataGridData,
  useDataGridStateInLocalStorage,
} from '@amalia/design-system/components';
import { useAuthorizedProfiles } from '@amalia/tenants/users/profile/state';
import { canUserBeInvited } from '@amalia/tenants/users/profile/types';

import { BulkSendInvitationAction } from './cells';
import { ConfirmUnselectUsersModal } from './filters/ConfirmUnselectUsersModal';
import { UsersTableFilters } from './filters/UsersTableFilters';
import { useUsersTableFilteredData } from './filters/useUsersTableFilteredData';
import { useUsersTableFilters } from './filters/useUsersTableFilters';
import { type UsersTableData } from './types';
import { useUsersTableColumns } from './useUsersTableColumns';

export const UsersTable = memo(function UsersTable() {
  const { isLoading, data: users } = useAuthorizedProfiles();
  const { formatMessage } = useIntl();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const columns = useUsersTableColumns();

  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    columnSorting,
    searchText,
    setSearchText,
    columnVisibility,
    setColumnVisibility,
  } = useDataGridStateInLocalStorage({
    key: ['directory', 'users'],
  });

  const { filters, setFilters, onConfirmChangeFilters, onCancelChangeFilters, isConfirmFiltersModalOpen } =
    useUsersTableFilters({
      setPage,
      selectedUserIds,
      setSelectedUserIds,
    });

  const filteredData = useUsersTableFilteredData(users, filters);

  const { data: pageData, total } = useControlledDataGridData(columns, filteredData, {
    page,
    pageSize,
    columnSorting,
    searchText,
  });

  const filteredPageCount = getPageCount(total, pageSize);

  return (
    <DataGrid<UsersTableData, string>
      columns={columns}
      data={pageData}
      isLoading={isLoading}
      isRowSelectableFn={canUserBeInvited}
      loadingRowsCount={10}
      rowKey="id"
      selectedRows={selectedUserIds}
      totalItems={total}
      bulkActions={
        <Table.BulkActions selectedRowsCount={selectedUserIds.length}>
          <BulkSendInvitationAction
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            users={users}
          />
        </Table.BulkActions>
      }
      columnVisibility={
        <DataGrid.ColumnVisibility
          columnVisibility={columnVisibility}
          onChangeColumnVisibility={setColumnVisibility}
        />
      }
      leftHeaderSlot={
        <Fragment>
          <UsersTableFilters
            filters={filters}
            isLoading={isLoading}
            users={users}
            onChange={setFilters}
          />
          <ConfirmUnselectUsersModal
            isOpen={isConfirmFiltersModalOpen}
            onCancel={onCancelChangeFilters}
            onConfirm={onConfirmChangeFilters}
          />
        </Fragment>
      }
      pageSize={
        <DataGrid.PageSize
          value={pageSize}
          onChange={setPageSize}
        />
      }
      pagination={
        <DataGrid.Pagination
          page={page}
          pageCount={filteredPageCount}
          onChangePage={setPage}
        />
      }
      search={
        <DataGrid.Search
          placeholder={formatMessage({ defaultMessage: 'Search…' })}
          value={searchText}
          onChange={setSearchText}
        />
      }
      onChangeSelectedRows={setSelectedUserIds}
    />
  );
});
