import { type Interpolation, type Theme } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge, UnstyledButton } from '@amalia/design-system/components';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { AssignedPlansDropdownList } from '../assigned-plans-dropdown-list/AssignedPlansDropdownList';

import { useUsedInPlans } from './useUsedInPlans';

export type UsedInPlansProps = {
  readonly tokenId: string | null;
  readonly plansList: Plan[];
  readonly currentPlanId?: string;
  readonly customCss?: Interpolation<Theme>;
};

export const UsedInPlans = memo(function UsedInPlans({
  tokenId,
  plansList,
  currentPlanId,
  customCss,
}: UsedInPlansProps) {
  const usedInPlans = useUsedInPlans(tokenId, plansList);

  if (
    !tokenId ||
    !usedInPlans.length ||
    (currentPlanId && usedInPlans.length === 1 && usedInPlans[0].id === currentPlanId)
  ) {
    return null;
  }

  return (
    <AssignedPlansDropdownList plans={usedInPlans}>
      <UnstyledButton
        css={customCss}
        disabled={!usedInPlans.length}
      >
        <Badge
          size={Badge.Size.SMALL}
          variant={Badge.Hue.BLUE}
        >
          <FormattedMessage
            defaultMessage="{count, plural, =0 {Not used in any plan} one {Used in # plan} other {Used in # plans}}"
            values={{ count: usedInPlans.length }}
          />
        </Badge>
      </UnstyledButton>
    </AssignedPlansDropdownList>
  );
});
