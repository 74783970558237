import { css, type Theme } from '@emotion/react';

export const IS_ACTIVE_CLASSNAME = 'is-active';
export const HAS_ACTION_CLASSNAME = 'has-action';
export const HAS_FOCUS_VISIBLE_CLASSNAME = 'has-focus-visible';
export const HAS_ERROR_CLASSNAME = 'has-error';

export const listElement = css`
  position: relative;
  display: flex;
  flex: 1;
`;

export const sideMenuItem = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;

  flex: 1;
  min-width: 0;

  padding: 0 8px;
  height: 30px;

  color: ${theme.ds.colors.gray[900]};
  transition: ${theme.ds.transitions.default('color', 'background-color', 'box-shadow', 'padding-right')};
  border-radius: ${theme.ds.borderRadiuses.squared};

  &:disabled {
    cursor: not-allowed;
    color: ${theme.ds.colors.gray[300]};
  }

  &:not(:disabled) {
    &:hover,
    &.${IS_ACTIVE_CLASSNAME} {
      background-color: ${theme.ds.colors.primary[50]};
    }

    &.${IS_ACTIVE_CLASSNAME} {
      color: ${theme.ds.colors.primary[500]};
    }
  }

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
  }

  [data-side-menu-item]:hover &.${HAS_ACTION_CLASSNAME}, &.${HAS_ACTION_CLASSNAME}.${HAS_FOCUS_VISIBLE_CLASSNAME} {
    padding-right: 38px;
  }

  &.${HAS_ERROR_CLASSNAME} {
    padding-right: 28px;

    [data-side-menu-item]:hover &.${HAS_ACTION_CLASSNAME}, &.${HAS_ACTION_CLASSNAME}.${HAS_FOCUS_VISIBLE_CLASSNAME} {
      padding-right: 58px;
    }
  }
`;

export const actionContainer = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
`;
