import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { dayjs } from '@amalia/ext/dayjs';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const datesFromDate = new AmaliaFunctionDefault<[number, string | null], string | null>({
  name: AmaliaFunctionKeys.fromDate,
  category: AmaliaFunctionCategory.DATES,

  nbParamsRequired: 1,
  description: 'Convert a timestamp to any date format',

  exec: (timestamp, format) => dayjs(timestamp, 'X').format(format || 'YYYY-MM-DD'),

  // 'A format can be specified to format the date differently, like `YYYY-MM-DD`. '
  // 'The date will be formatted by moment.js (see documentation here https://momentjs.com/docs/#/parsing/string-format/ or examples below).'
  params: [
    {
      name: 'timestamp',
      description: 'Date in timestamp format: variables, properties, fields, keywords or a timestamp',
    },
    { name: 'format', description: 'Output format that is by default YYYY-MM-DD', defaultValue: 'YYYY-MM-DD' },
  ],

  examples: [
    {
      desc: 'Returns 2023-05-23.',
      formula: 'fromDate(1684829783)' as AmaliaFormula,
      result: '2023-05-23',
    },
    {
      desc: 'Returns 23/05/2023.',
      formula: 'fromDate(1684829783, "DD/MM/YYYY")' as AmaliaFormula,
      result: '23/05/2023',
    },
    {
      desc: 'Returns May 23.',
      formula: 'fromDate(1684570583, "MMM YY")' as AmaliaFormula,
      result: 'May 23',
    },
    {
      desc: 'Returns 05/20/23.',
      formula: 'fromDate(1684570583, "MM/DD/YY")' as AmaliaFormula,
      result: '05/20/23',
    },
  ],
});
