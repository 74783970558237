import { type UserContract } from '@amalia/tenants/users/types';

export const userProfileQueryKeys = {
  all: () => ['user-profile'] as const,
  list: () => [...userProfileQueryKeys.all(), 'list'] as const,
  details: (userId: UserContract['id']) => [...userProfileQueryKeys.all(), 'details', userId] as const,
} as const;

export const userProfileMutationKeys = {
  all: () => ['user-profile'] as const,
  updateUserInfo: (userId: UserContract['id']) =>
    [...userProfileMutationKeys.all(), 'update-user-info', userId] as const,
  toggleDeactivation: (userId: UserContract['id']) =>
    [...userProfileMutationKeys.all(), 'toggle-deactivation', userId] as const,
  inviteUser: (userId: UserContract['id']) => [...userProfileMutationKeys.all(), 'invite-user', userId] as const,
  anyInviteUsers: () => [...userProfileMutationKeys.all(), 'invite-users'] as const,
  inviteUsers: (userIds: UserContract['id'][]) => [...userProfileMutationKeys.all(), 'invite-users', userIds] as const,
  sync: () => [...userProfileMutationKeys.all(), 'sync'] as const,
  avatar: {
    all: () => [...userProfileMutationKeys.all(), 'avatar'] as const,
    randomize: () => [...userProfileMutationKeys.avatar.all(), 'randomize'] as const,
    upload: () => [...userProfileMutationKeys.avatar.all(), 'upload'] as const,
  },
} as const;
