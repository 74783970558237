import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef } from 'react';

import { Button, type ButtonProps } from '../../../../../general/button/Button';

export type TableBulkActionProps = Omit<ButtonProps, 'size' | 'variant'>;

export const TableBulkAction = forwardRef(function TableBulkAction(
  { children, ...props }: TableBulkActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      {...props}
      ref={ref}
      variant={Button.Variant.PRIMARY_TEXT}
      css={(theme) => css`
        border-radius: 0;
        border-left: 1px solid ${theme.ds.colors.gray[100]};
      `}
    >
      {children}
    </Button>
  );
});
