import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ModifyPaymentSubject, type ViewPaymentSubject } from './subjects';

export const paymentsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.overwrite, SubjectsEnum.Payment);
    can(ActionsEnum.view, SubjectsEnum.Payment, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.Payment);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Payment, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Payment, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
  },

  MANAGER({ hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Payment, {
      subset: SubsetAccessEnum.MATCH_MANAGEES,
      predicate: ({ userId }: ViewPaymentSubject) => hierarchy.isManagerOf(userId, new Date()),
    });

    can(ActionsEnum.modify, SubjectsEnum.Payment, ({ userId }: ModifyPaymentSubject) =>
      hierarchy.isManagerOf(userId, new Date()),
    );
  },

  READ_ONLY_MANAGER({ hierarchy }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Payment, {
      subset: SubsetAccessEnum.MATCH_MANAGEES,
      predicate: ({ userId }: ViewPaymentSubject) => hierarchy.isManagerOf(userId, new Date()),
    });
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
