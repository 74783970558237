import { IconChartBar } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge } from '@amalia/design-system/components';
import { useFindDashboards, useGetHomeDashboardId } from '@amalia/reporting/dashboards-v2/state';

import { CommandGroup } from '../../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/use-actions';
import { PagesEnum } from '../../shortcut-commands.types';

export const ListDashboardsCommand = memo(function ListDashboardsCommand() {
  const { data: dashboardsList } = useFindDashboards();
  const { data: dashboardSetAsHome } = useGetHomeDashboardId();
  const dashboardSetAsHomeId = dashboardSetAsHome?.dashboardId;

  const { primaryAction } = useActions(PagesEnum.LIST_DASHBOARDS);

  const dashboardsListOptions = useMemo(
    () =>
      (dashboardsList ?? []).map((dashboard) => ({
        name: dashboard.name,
        id: dashboard.id,
      })),
    [dashboardsList],
  );

  return (
    <CommandGroup
      Icon={IconChartBar}
      title={<FormattedMessage defaultMessage="Dashboards list" />}
    >
      {dashboardsListOptions.map(({ id, name }) => (
        <CommandItem
          key={id}
          keywords={[id, name]}
          label={name}
          value={id}
          labelElement={
            id === dashboardSetAsHomeId && (
              <Badge
                size={Badge.Size.SMALL}
                variant={Badge.Color.PRIMARY}
              >
                <FormattedMessage defaultMessage="Home" />
              </Badge>
            )
          }
          onSelect={primaryAction?.onSelect}
        />
      ))}
    </CommandGroup>
  );
});
