import { groupBy } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { fromNow } from '@amalia/core/types';
import { VENDOR_LOGOS } from '@amalia/data-capture/connectors/assets';
import { useCustomObjectDefinitions } from '@amalia/data-capture/record-models/state';
import {
  CustomObjectDefToDataConnectorType,
  CustomObjectDefinitionType,
} from '@amalia/data-capture/record-models/types';
import { Typography } from '@amalia/design-system/components';

import { CommandGroup } from '../../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/use-actions';
import { PagesEnum } from '../../shortcut-commands.types';

export const ListCustomObjectsCommand = memo(function ListCustomObjectsCommand() {
  const { data: customObjectsList } = useCustomObjectDefinitions();

  const { primaryAction } = useActions(PagesEnum.LIST_CUSTOM_OBJECTS);

  const customObjectsListOptions = useMemo(
    () =>
      groupBy(
        customObjectsList
          .map((customObject) => ({
            name: customObject.name,
            id: customObject.id,
            type: customObject.type,
            machineName: customObject.machineName,
            updatedAt: customObject.updatedAt,
            Logo: CustomObjectDefToDataConnectorType[customObject.type]
              ? VENDOR_LOGOS[CustomObjectDefToDataConnectorType[customObject.type]!].CompactLogo
              : null,
          }))
          .filter((customObject) => customObject.type !== CustomObjectDefinitionType.VIRTUAL),
        'type',
      ),
    [customObjectsList],
  );

  return (
    <Fragment>
      {Object.keys(customObjectsListOptions).map((type) => (
        <CommandGroup
          key={type}
          title={type}
        >
          {customObjectsListOptions[type].map(({ id, name, Logo, machineName, updatedAt }) => (
            <CommandItem
              key={id}
              keywords={[id, name]}
              label={name}
              leftElement={!!Logo && <Logo width="18px" />}
              value={machineName}
              rightElement={
                <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
                  <FormattedMessage
                    defaultMessage="Updated {time}"
                    values={{ time: fromNow(updatedAt) }}
                  />
                </Typography>
              }
              onSelect={primaryAction?.onSelect}
            />
          ))}
        </CommandGroup>
      ))}
    </Fragment>
  );
});
