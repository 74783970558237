export const authzContextQueryKeys = {
  all: () => ['authenticated-context'] as const,
  currentUser: () => [...authzContextQueryKeys.all(), 'current-user'] as const,
} as const;

export const authzContextMutationKeys = {
  all: () => ['authenticated-context'] as const,
  updateSettings: () => [...authzContextMutationKeys.all(), 'update-settings'] as const,
  impersonate: () => [...authzContextMutationKeys.all(), 'impersonate'] as const,
};
