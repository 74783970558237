import { dayjs } from '@amalia/ext/dayjs';

export function convertDateToTimestamp(date: string | null, convertNullToInfinity = false) {
  if (convertNullToInfinity && (date === null || date === '')) {
    return Infinity;
  }
  if (date === null || date === '') {
    return null;
  }
  return dayjs.utc(date, 'YYYY-MM-DD').unix();
}

export const convertTimestampToDate = (timestamp: number | null) => {
  if (timestamp) {
    return dayjs.utc(timestamp, 'X').format('YYYY-MM-DD');
  }
  return null;
};

export const semesterRange = (date: dayjs.Dayjs): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  if ([1, 2].includes(date.quarter())) {
    return {
      startDate: date.clone().startOf('year'),
      endDate: date.clone().endOf('year').subtract(6, 'month'),
    };
  }
  return {
    startDate: date.clone().startOf('year').add(6, 'month'),
    endDate: date.clone().endOf('year'),
  };
};
