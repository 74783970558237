import { defineMessages } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';

export const formatsEnumMessages = defineMessages<FormatsEnum>({
  [FormatsEnum.number]: {
    defaultMessage: 'Number',
  },
  [FormatsEnum.boolean]: {
    defaultMessage: 'Boolean',
  },
  [FormatsEnum.date]: {
    defaultMessage: 'Date',
  },
  [FormatsEnum['date-time']]: {
    defaultMessage: 'Date-time',
  },
  [FormatsEnum.percent]: {
    defaultMessage: 'Percent',
  },
  [FormatsEnum.table]: {
    defaultMessage: 'Table',
  },
  [FormatsEnum.currency]: {
    defaultMessage: 'Currency',
  },
  [FormatsEnum.text]: {
    defaultMessage: 'Text',
  },
});
