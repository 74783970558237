import { useQuery } from '@tanstack/react-query';

import { getScope } from './calculation-scope.api-client';
import { calculationScopesQueryKeys } from './calculation-scope.keys';

export const useCalculationScope = () =>
  useQuery({
    queryKey: calculationScopesQueryKeys.details(),
    queryFn: getScope,
  });
