import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const stringSearch = new AmaliaFunctionDefault<[string, string], number | null>({
  name: AmaliaFunctionKeys.SEARCH,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 2,
  description:
    'Return the position of a Search String inside a Source String starting at 0. Return -1 if Search String is not found.',

  exec: (findText, withinText) => withinText.search(findText),

  params: [
    { name: 'searchString', description: 'String to search for: variables, fields, properties, string' },
    { name: 'sourceString', description: 'String to look into: array, variables, fields, properties, string' },
  ],

  examples: [
    {
      desc: 'Returns 5.',
      formula: 'SEARCH("Dupont", "Jean Dupont")' as AmaliaFormula,
      result: 5,
    },
    {
      desc: 'Returns 3. It takes the position of the first match.',
      formula: 'SEARCH("n", "Jean Dupont")' as AmaliaFormula,
      result: 3,
    },
  ],
});
