/// <reference types="redux-thunk/extend-redux" />

import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { errorHandler } from './middlewares';
import { planForecastsReducer } from './planForecasts/reducer';
import { plansReducer } from './plans/reducer';
import { snackbarsReducer } from './snackbars/reducer';
import { type RootState } from './types';

// Add __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ to window to enable Redux DevTools.
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducer = combineReducers<RootState>({
  planForecasts: planForecastsReducer,
  plans: plansReducer,
  snackbars: snackbarsReducer,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk, errorHandler];

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export type ThunkDispatch = typeof store.dispatch;

export const useThunkDispatch = () => useDispatch<ThunkDispatch>();
