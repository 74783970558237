import { IconExternalLink } from '@tabler/icons-react';
import { type ReactNode, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, ButtonLink } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

import { LayoutUnauthenticatedContent } from '../layout-unauthenticated/content/LayoutUnauthenticatedContent';
import { LayoutUnauthenticated } from '../layout-unauthenticated/LayoutUnauthenticated';

import { ReactComponent as MaintenancePageIllustration } from './maintenancePageIllustration.svg';

export type MaintenancePageProps = {
  readonly message?: ReactNode;
};

export const MaintenancePage = memo(function MaintenancePage({ message = undefined }: MaintenancePageProps) {
  return (
    <LayoutUnauthenticated>
      <LayoutUnauthenticatedContent
        title={<FormattedMessage defaultMessage="Amalia is offline" />}
        action={
          <ButtonLink
            icon={<IconExternalLink />}
            iconPosition={ButtonLink.IconPosition.RIGHT}
            variant={ButtonLink.Variant.PRIMARY_LIGHT}
            to={
              <Link
                openInNewTab
                to="https://status.amalia.io"
              />
            }
          >
            <FormattedMessage defaultMessage="Visit our status page for more information" />
          </ButtonLink>
        }
        message={
          <AlertBanner
            inline
            variant={AlertBanner.Variant.WARNING}
          >
            {message || (
              <FormattedMessage defaultMessage="Your app is currently under maintenance, come back later to access your account." />
            )}
          </AlertBanner>
        }
        picture={
          <MaintenancePageIllustration
            height={235.55}
            width={424}
          />
        }
      />
    </LayoutUnauthenticated>
  );
});
