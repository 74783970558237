import { useTheme } from '@emotion/react';
import { IconEyeClosed } from '@tabler/icons-react';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { match } from 'ts-pattern';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { Tooltip } from '@amalia/design-system/components';
import { HidableElementVisibility } from '@amalia/payout-definition/plans/types';

type TokenVisibilityIconProps = {
  readonly displayStatus: HidableElementVisibility;
  readonly tokenType: TokenType.FIELD | TokenType.FILTER | TokenType.QUOTA | TokenType.VARIABLE;
  readonly iconSize?: number;
};

export const TokenVisibilityIcon = memo(function TokenVisibilityIcon({
  displayStatus,
  tokenType,
  iconSize = 19.2,
}: TokenVisibilityIconProps) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const tooltipMessage = match({ tokenType })
    .with({ tokenType: TokenType.FILTER }, () => formatMessage({ defaultMessage: 'Shown only on designer' }))
    .otherwise(() => formatMessage({ defaultMessage: 'Hidden by default on statement' }));

  if (displayStatus === HidableElementVisibility.ON_DISPLAY) {
    return null;
  }

  return (
    <Tooltip content={tooltipMessage}>
      <IconEyeClosed
        color={theme.ds.colors.gray[900]}
        size={iconSize}
      />
    </Tooltip>
  );
});
