import { IconArrowDown, IconArrowRight, IconArrowUp } from '@tabler/icons-react';
import clsx from 'clsx';
import { forwardRef, memo, type ComponentPropsWithoutRef, type ForwardedRef } from 'react';
import { FormattedNumber } from 'react-intl';

import { TypographyVariant } from '@amalia/design-system/meta';
import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconComponent } from '../../general/icons/types';
import { Typography } from '../../general/typography/Typography';

import * as styles from './Trend.styles';
import { TrendDirection, TrendSize } from './Trend.types';

const RATIO_CLASSNAME_MAPPING: Record<TrendDirection, string> = {
  [TrendDirection.DOWN]: styles.DOWN_CLASSNAME,
  [TrendDirection.FLAT]: styles.FLAT_CLASSNAME,
  [TrendDirection.UP]: styles.UP_CLASSNAME,
};

const RATIO_ICON_MAPPING: Record<TrendDirection, TablerIconComponent> = {
  [TrendDirection.DOWN]: IconArrowDown,
  [TrendDirection.FLAT]: IconArrowRight,
  [TrendDirection.UP]: IconArrowUp,
};

const SIZE_ICON_SIZE_MAPPING: Record<TrendSize, number> = {
  [TrendSize.SMALL]: 10,
  [TrendSize.MEDIUM]: 12,
} as const;

const SIZE_TYPOGRAPHY_MAPPING: Record<TrendSize, TypographyVariant> = {
  [TrendSize.SMALL]: TypographyVariant.BODY_SMALL_REGULAR,
  [TrendSize.MEDIUM]: TypographyVariant.BODY_BASE_REGULAR,
} as const;

export type TrendProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Current value. */
      currentValue: number;
      /** Value to compare current value to. */
      previousValue: number;
      /** Trend size. */
      size?: TrendSize;
    },
  ]
>;

const TrendForwardRef = forwardRef(function Trend(
  { previousValue, currentValue, size = TrendSize.SMALL, ...props }: TrendProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const trendRatio = previousValue
    ? Math.round(((currentValue - previousValue) / previousValue) * 100) / 100 // Need to round here otherwise it can show a +0% or -0% up/down trend instead of 0% flat.
    : currentValue === 0
      ? 0
      : currentValue > 0
        ? 1
        : -1;

  const trendDirection: TrendDirection =
    trendRatio > 0 ? TrendDirection.UP : trendRatio < 0 ? TrendDirection.DOWN : TrendDirection.FLAT;

  const Icon = RATIO_ICON_MAPPING[trendDirection];

  return (
    <div
      {...props}
      ref={ref}
      className={clsx(RATIO_CLASSNAME_MAPPING[trendDirection], size)}
      css={styles.trend}
    >
      <Icon size={SIZE_ICON_SIZE_MAPPING[size]} />

      <Typography variant={SIZE_TYPOGRAPHY_MAPPING[size]}>
        <FormattedNumber
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          signDisplay="never"
          style="percent"
          value={trendRatio}
        />
      </Typography>
    </div>
  );
});

export const Trend = Object.assign(memo(TrendForwardRef), {
  Size: TrendSize,
});
