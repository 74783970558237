import { ActionsEnum, SubjectsEnum, type UserRoleForAccessControl, type DefinePermissions } from '../../types';

export const auditAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Audit);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Audit);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Audit);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
