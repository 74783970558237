import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const planAgreementsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
    can(ActionsEnum.view_list, SubjectsEnum.PlanAgreements);
    can(ActionsEnum.modify, SubjectsEnum.PlanAgreements);
    can(ActionsEnum.delete, SubjectsEnum.PlanAgreements);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
  },

  EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
  },

  READ_ONLY_EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
