import { Auth0Provider, type Auth0ProviderOptions } from '@auth0/auth0-react';
import { StyledEngineProvider, type Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, generatePath, Route, RouterProvider } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { DesignSystemProvider } from '@amalia/design-system/components';
import { DEFAULT_THEME } from '@amalia/design-system/themes/default';
import { amaliaTheme } from '@amalia/ext/mui/theme';
import { store } from '@amalia/frontend/web-data-layers';
import { IFrameAuthentication, InitiatedSSOAuthentication } from '@amalia/kernel/auth/components';
import { config } from '@amalia/kernel/config/client';
import { queryClient } from '@amalia/kernel/config/react-query';
import { UnexpectedErrorPage } from '@amalia/kernel/error/components';
import { StoreProvider } from '@amalia/lib-ui';

import { App } from './App';
import { AppProviders } from './AppProviders';
import { WebLocalizationProvider } from './components/WebLocalizationProvider';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

/**
 * Cannot turn off the error messages from Emotion, so we filter them out.
 * https://github.com/emotion-js/emotion/issues/1105#issuecomment-1885044724
 */
// eslint-disable-next-line no-console
const consoleError = console.error;
// eslint-disable-next-line no-console
console.error = function filterEmotionErrors(msg: unknown, ...args: unknown[]) {
  if (msg && typeof msg === 'string' && msg.includes('server-side rendering')) {
    return;
  }

  consoleError(msg, ...args);
};

const shouldSkipRedirectCallback = [
  generatePath(routes.AUTH_CONNECTOR_CALLBACK, { connector: 'netsuite' }),
  generatePath(routes.AUTH_CONNECTOR_CALLBACK, { connector: 'zoho' }),
  generatePath(routes.AUTH_CONNECTOR_CALLBACK, { connector: 'msdynamics' }),
].includes(window.location.pathname);

const auth0AuthorizationParams: Auth0ProviderOptions['authorizationParams'] = {
  audience: config.auth0.audience,
  scope: config.auth0.scope,
  redirect_uri: config.auth0.redirectUri,
};

const AuthenticatedApp = function AuthenticatedApp() {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route
        element={<IFrameAuthentication />}
        errorElement={<UnexpectedErrorPage />}
        path={routes.AUTH_IFRAME}
      />
      <Route
        element={<InitiatedSSOAuthentication />}
        errorElement={<UnexpectedErrorPage />}
        path={routes.AUTH_SSO_INITIATED}
      />
      <Route
        element={<AuthenticatedApp />}
        errorElement={<UnexpectedErrorPage />}
        path="*"
      />
    </Fragment>,
  ),
);

createRoot(document.getElementById('root')!).render(
  <Auth0Provider
    useRefreshTokens
    useRefreshTokensFallback
    authorizationParams={auth0AuthorizationParams}
    clientId={config.auth0.clientId}
    domain={config.auth0.domain}
    skipRedirectCallback={shouldSkipRedirectCallback}
    useFormData={false}
  >
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <WebLocalizationProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={amaliaTheme}>
              <DesignSystemProvider theme={DEFAULT_THEME}>
                <RouterProvider router={router} />
              </DesignSystemProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </WebLocalizationProvider>
      </QueryClientProvider>
    </StoreProvider>
  </Auth0Provider>,
);
