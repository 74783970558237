import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionRawArgs } from '../../AmaliaFunction';

export const miscIf = new AmaliaFunctionRawArgs({
  name: AmaliaFunctionKeys.IF,
  category: AmaliaFunctionCategory.MISC,
  nbParamsRequired: 3,
  description: 'Return an argument if a condition is met, otherwise, return a different one',

  generateComputedFunctionResult: (args) => ({
    condition: args[0].toString() as AmaliaFormula,
  }),

  execMock: () => 1,

  exec: (args, _, scope) => {
    const conditionValue = args[0].compile().evaluate(scope) as ComputeEngineResult;

    return conditionValue
      ? (args[1].compile().evaluate(scope) as ComputeEngineResult)
      : (args[2].compile().evaluate(scope) as ComputeEngineResult);
  },

  params: [
    { name: 'condition', description: 'Boolean condition (returns 1 if true or 0 if false).' },
    { name: 'trueArgument', description: 'Argument to return if the condition is true.' },
    { name: 'falseArgument', description: 'Argument to return if the condition is false.' },
  ],

  examples: [
    {
      desc: 'If the close date is after the start date of the period, returns amount, else returns 0.',
      formula: 'IF(opportunity.closeDate >= statementPeriod.startDate, opportunity.amount, 0)' as AmaliaFormula,
    },
    {
      desc: 'If statement.achievement / statement.target is greater than 1, returns accelerator, else returns 0.',
      formula: 'IF(statement.achievement / statement.target > 1, statement.accelerator, 0)' as AmaliaFormula,
    },
    {
      desc: 'If opportunity type if renewal or new business, returns amount, else returns amount * 0.5.',
      formula:
        'IF(opportunityType="Renewal" or opportunityType="New Business", opportunity.amount, opportunity.amount * 0.5)' as AmaliaFormula,
    },
    {
      desc: 'If oppToLineItemLink exists, returns amount, else returns 0.',
      formula: 'IF(opportunity.oppToLineItemLink, opportunity.oppToLineItemLink.amount, 0)' as AmaliaFormula,
    },
  ],
});
