import { css, type Theme } from '@emotion/react';

export const SELECTED_CLASSNAME = 'selected';

export const tab = (theme: Theme) => css`
  display: flex; // Make the inner div take 100% height.

  min-height: 32px;

  color: ${theme.ds.colors.gray[700]};
  transition: ${theme.ds.transitions.default('color', 'box-shadow')};

  outline-offset: -1px;

  > svg {
    flex: none;
  }

  &:disabled {
    color: ${theme.ds.colors.gray[300]};
  }

  &.${SELECTED_CLASSNAME} {
    color: ${theme.ds.colors.gray[900]};
  }

  &:focus-visible {
    border-radius: ${theme.ds.borderRadiuses.squared};
    outline: 1px solid ${theme.ds.colors.primary[200]};
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
  }
`;

export const label = css`
  padding: 0 8px;

  > svg {
    flex: none;
  }
`;

export const border = (theme: Theme) => css`
  height: 3px;

  transition: ${theme.ds.transitions.default('background-color')};
  background-color: transparent;
  border-radius: 1px;

  // The tabs border is in absolute positioning, so to make it work we need to define a position here.
  position: relative;

  .${SELECTED_CLASSNAME} > div > & {
    background-color: ${theme.ds.colors.primary[400]};
  }
`;
