import { SubsetAccessEnum } from '../../subsets/enums';
import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const userDataAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.EVERYTHING });
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.EVERYTHING });
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.EVERYTHING });
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
  },

  EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
  },

  READ_ONLY_EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
