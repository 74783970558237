import styled from '@emotion/styled';
import { IconChevronDown } from '@tabler/icons-react';

export const CollapseIconChevron = styled(IconChevronDown, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  flex: none;
  transition: ${({ theme }) => theme.ds.transitions.default()};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(-0deg')};
`;
