import { IconRefresh, IconSend } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconLoading, Table } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { log } from '@amalia/kernel/logger/client';
import { UserProfileApiClient } from '@amalia/tenants/users/profile/api-client';
import { useInviteUsers } from '@amalia/tenants/users/profile/state';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

import { ConfirmBulkInviteUsersModal } from '../../../../invitation/confirm-modal/ConfirmBulkInviteUsersModal';

export type BulkSendInvitationActionProps = {
  readonly users: Pick<UserProfile, 'email' | 'firstName' | 'id' | 'lastName'>[];
  readonly selectedUserIds: string[];
  readonly setSelectedUserIds: (selectedUserIds: string[]) => void;
};

export const BulkSendInvitationAction = memo(function BulkSendInvitationAction({
  users,
  selectedUserIds,
  setSelectedUserIds,
}: BulkSendInvitationActionProps) {
  const { isConfirmModalOpen, setConfirmModalOpenTrue, setConfirmModalOpenFalse } = useBoolState(
    false,
    'confirmModalOpen',
  );

  const { mutateAsync: sendInvitations, isPending } = useInviteUsers(selectedUserIds);

  const onClickInvite = useCallback(() => {
    sendInvitations()
      .then(() => {
        /* In case of success for every invitation, reset the table selection. */
        setSelectedUserIds([]);
      })
      .catch((err: unknown) => {
        /* On error, only keep as selected the rows for which we failed to send the invitation */
        log.error(err);

        if (!UserProfileApiClient.isBulkInvitationError(err)) {
          return;
        }

        const { errors } = err.response.data;
        setSelectedUserIds(errors.map(({ id }) => id));
      });

    setConfirmModalOpenFalse();
  }, [sendInvitations, setSelectedUserIds, setConfirmModalOpenFalse]);

  return (
    <Fragment>
      <Table.BulkAction
        disabled={isPending}
        icon={<IconSend />}
        iconLoading={<IconLoading icon={IconRefresh} />}
        isLoading={isPending}
        onClick={setConfirmModalOpenTrue}
      >
        <FormattedMessage
          defaultMessage="Send {count, plural, one {invitation} other {invitations}}"
          values={{ count: selectedUserIds.length }}
        />
      </Table.BulkAction>

      <ConfirmBulkInviteUsersModal
        isOpen={isConfirmModalOpen}
        selectedUserIds={selectedUserIds}
        users={users}
        onClose={setConfirmModalOpenFalse}
        onSubmit={onClickInvite}
      />
    </Fragment>
  );
});
