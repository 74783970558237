import { useQuery } from '@tanstack/react-query';

import { QuotasWithAssignedPlansApiClient } from '@amalia/assignments/quotas/api-client';

import { quotasQueryKeys } from './queries.keys';

export const useQuotaWithAssignedPlans = (...args: Parameters<(typeof QuotasWithAssignedPlansApiClient)['get']>) =>
  useQuery({
    queryKey: quotasQueryKeys.withAssignedPlans.details(...args),
    queryFn: () => QuotasWithAssignedPlansApiClient.get(...args),
  });

export const useQuotasWithAssignedPlans = (...args: Parameters<(typeof QuotasWithAssignedPlansApiClient)['search']>) =>
  useQuery({
    queryKey: quotasQueryKeys.withAssignedPlans.list(...args),
    queryFn: () => QuotasWithAssignedPlansApiClient.search(...args),
  });
