import { type UserQuotaAssignmentsByFrequency } from '@amalia/assignments/quotas/types';
import { http } from '@amalia/core/http/client';

export type GetUserQuotaAssignmentsOptions = {
  effectiveBetween?: {
    start?: Date | null;
    end?: Date | null;
  };
};

export class UserQuotaAssignmentsApiClient {
  public static async getUserQuotaAssignments(
    userId: string,
    {
      effectiveBetween: { start: effectiveBetweenStartDate = null, end: effectiveBetweenEndDate = null } = {},
    }: GetUserQuotaAssignmentsOptions = {},
  ) {
    const { data: userQuotaAssignments } = await http.get<UserQuotaAssignmentsByFrequency>(
      `/users/${encodeURIComponent(userId)}/quota-assignments`,
      {
        params: {
          'effectiveBetween.start': effectiveBetweenStartDate,
          'effectiveBetween.end': effectiveBetweenEndDate,
        },
      },
    );

    return userQuotaAssignments;
  }
}
