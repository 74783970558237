import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const dashboardsv2AbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.create, SubjectsEnum.DashboardsV2);
    can(ActionsEnum.view, SubjectsEnum.DashboardsV2);
    can(ActionsEnum.modify, SubjectsEnum.DashboardsV2);
    can(ActionsEnum.delete, SubjectsEnum.DashboardsV2);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.DashboardsV2);
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.DashboardsV2);
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.DashboardsV2);
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
