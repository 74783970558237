import { type DatePickerProps as ReactDatePickerProps } from 'react-datepicker';

import { type DatePickerValueProps } from '../DatePickerBase.types';

export const useMapDatePickerProps = <TWithRange extends boolean | undefined = undefined>({
  value,
  placeholder,
  endDate,
  startDate,
}: DatePickerValueProps<TWithRange>) =>
  ({
    endDate: Array.isArray(value) ? (value[1] ?? undefined) : endDate,
    placeholderText: placeholder,
    selected: Array.isArray(value) ? value[0] : value,
    startDate: Array.isArray(value) ? (value[0] ?? undefined) : startDate,
  }) satisfies Partial<ReactDatePickerProps>;
