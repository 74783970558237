import { type ColumnDefinitions, type RowData } from '../../Table.types';

export const shouldRenderHeader = <TData extends RowData>(
  columns: ColumnDefinitions<TData>,
  isSelectionEnabled: boolean,
) => {
  if (!columns.length) {
    return false;
  }

  return (
    isSelectionEnabled ||
    columns.some((column) => column.header || column.icon || column.actions || column.subTitle || column.tooltip)
  );
};
