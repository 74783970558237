import { css } from '@emotion/react';
import { Container } from '@mui/material';
import { Fragment, type ReactNode, memo } from 'react';

import { CircularLoader, Group, LinearLoader } from '@amalia/design-system/components';

export type PageContainerProps = {
  readonly containerMaxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  readonly showLoadingBar?: boolean;
  readonly isLoading?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
};

export const PageContainer = memo(function PageContainer({
  containerMaxWidth = undefined,
  showLoadingBar = false,
  isLoading = false,
  className = undefined,
  children = undefined,
}: PageContainerProps) {
  return (
    <Fragment>
      {!!showLoadingBar && (
        <div
          css={css`
            position: relative;
          `}
        >
          <LinearLoader
            size={4}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
            `}
          />
        </div>
      )}

      <Container
        className={className}
        maxWidth={containerMaxWidth || false}
        css={[
          css`
            min-height: 100%;

            position: relative;
            font-size: 12px; // Needed for some em sizes in the app (RuleSummary).
            padding-bottom: 40px;
            padding-top: 24px;
          `,
          !containerMaxWidth &&
            css`
              @media (min-width: 2000px) {
                max-width: 1900px;
              }
            `,
        ]}
      >
        {isLoading ? (
          <Group
            align="center"
            justify="center"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            `}
          >
            <CircularLoader size={CircularLoader.Size.LARGE} />
          </Group>
        ) : (
          children
        )}
      </Container>
    </Fragment>
  );
});
