import { type ReactNode, memo, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useBoolState, useStateWithRef } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { DatePickerBase, type DatePickerBaseProps } from '../../../data-input/date-picker-base/DatePickerBase';
import { FilterDatePickerSymbol } from '../getFilterType';

import { FilterDatePickerTag, type FilterDatePickerTagProps } from './filter-date-picker-tag/FilterDatePickerTag';

export type FilterDatePickerProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    Omit<DatePickerBaseProps<TWithRange>, 'children' | 'clearButtonLabel' | 'isClearable' | 'popperPlacement'>,
    Pick<FilterDatePickerTagProps, 'emptyValueLabel' | 'error' | 'id' | 'isStatic' | 'label'>,
    {
      /** Override label of filter inside the "Add filter" menu. Defaults to `label` (with a 0 count if function). */
      menuLabel?: ReactNode;
    },
  ]
>;

const FilterDatePickerBase = function FilterDatePicker<TWithRange extends boolean | undefined = undefined>({
  value: propsValue,
  onChange: propsOnChange,
  error,
  label,
  emptyValueLabel,
  isStatic,
  onCalendarOpen,
  onCalendarClose,
  ...props
}: FilterDatePickerProps<TWithRange>) {
  const [internalValue, setInternalValue, internalValueRef] = useStateWithRef(propsValue);

  // Sync internalValue with propsValue.
  useEffect(() => {
    setInternalValue(propsValue);
  }, [propsValue, setInternalValue]);

  const { isOpen, setOpenTrue, setOpenFalse } = useBoolState(false, 'open');

  const handleCalendarOpen = useCallback(() => {
    onCalendarOpen?.();
    setOpenTrue();
  }, [onCalendarOpen, setOpenTrue]);

  const handleCalendarClose = useCallback(() => {
    propsOnChange?.(internalValueRef.current);
    onCalendarClose?.();
    setOpenFalse();
  }, [onCalendarClose, setOpenFalse, propsOnChange, internalValueRef]);

  return (
    <DatePickerBase<TWithRange>
      {...props}
      isClearable
      clearButtonLabel={<FormattedMessage defaultMessage="Clear filter" />}
      popperPlacement="bottom-start"
      value={internalValue}
      onCalendarClose={handleCalendarClose}
      onCalendarOpen={handleCalendarOpen}
      onChange={setInternalValue}
    >
      <FilterDatePickerTag
        disabled={props.disabled}
        emptyValueLabel={emptyValueLabel}
        error={error}
        id={props.id}
        isCalendarOpen={isOpen}
        isStatic={isStatic}
        label={label}
        ownValue={internalValue} // 'value' is overridden by DatePickerBase and we can't format it as we want.
        selectsRange={props.selectsRange}
      />
    </DatePickerBase>
  );
};

export const FilterDatePicker = Object.assign(memo(FilterDatePickerBase) as typeof FilterDatePickerBase, {
  symbol: FilterDatePickerSymbol,
});
