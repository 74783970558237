import { uniq } from 'lodash';
import { useMemo } from 'react';

import { type SelectOption } from '@amalia/design-system/components';
import { CURRENCY_SYMBOL_LABELS, CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { CurrencyDisplay } from '../CurrencyDisplay';

export type CurrencyOption = SelectOption<CurrencySymbolsEnum>;

export const toCurrencyOption = (
  currencySymbol: CurrencySymbolsEnum,
  option?: Partial<CurrencyOption>,
): CurrencyOption => ({
  ...option,
  value: currencySymbol,
  valueLabel: currencySymbol,
  label: <CurrencyDisplay currencySymbol={currencySymbol} />,
  filterLabel: [currencySymbol, CURRENCY_SYMBOL_LABELS[currencySymbol]].join(' '),
});

export const useCurrencyOptions = (currencySymbols = Object.values(CurrencySymbolsEnum)) =>
  useMemo(
    () =>
      uniq(currencySymbols)
        .toSorted((a, b) => a.localeCompare(b))
        .map((currencySymbol) => toCurrencyOption(currencySymbol)),
    [currencySymbols],
  );
