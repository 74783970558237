import { css, useTheme } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import { uniq, without } from 'lodash';
import { type ReactNode, memo, type ReactElement, type JSXElementConstructor, useState, useCallback } from 'react';
import { useFocusRing } from 'react-aria';

import { useResizeObserver, useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { type IconButtonProps } from '../../../general/icon-button/IconButton';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../general/typography/Typography';
import { Group } from '../../../layout/group/Group';
import { Stack } from '../../../layout/stack/Stack';
import { Tooltip, type TooltipProps } from '../../../overlays/tooltip/Tooltip';
import { SideMenuItemAction } from '../side-menu-item-action/SideMenuItemAction';

import { SideMenuGroupContext, type SideMenuGroupContextValue } from './SideMenuGroup.context';

export type SideMenuGroupProps = {
  /** Section label. If not specified, the tooltip and action are ignored. */
  readonly label?: ReactNode;
  /** Section help. */
  readonly tooltip?: TooltipProps['content'];
  /** Option action. */
  readonly action?: ReactElement<IconButtonProps, JSXElementConstructor<IconButtonProps>>;
  /** Section items. */
  readonly children?: ReactNode;
};

const SideMenuGroupBase = function SideMenuGroup({
  label,
  tooltip = undefined,
  action = undefined,
  children = undefined,
}: SideMenuGroupProps) {
  const theme = useTheme();

  // We want to show actions only on hover, without using display: none because we can't tab to them.
  // There are several alternatives but the only one that works well is using opacity.
  // We render actions in absolute positioning and adjusting the right padding of the label element to compensate.
  // We need to measure the width of the actions to adjust the padding accordingly.
  // Then we need to show the buttons (and therefore adjust the padding) when:
  // * the group is hovered (using :hover css pseudo-class),
  // * any action is focused (using useFocusRing from react-aria),
  // * any action is active (in the case of a dropdown, the button is active but the focus is in the dropdown) -> using the side menu group context.
  const [{ width }, setWidth] = useState({ width: 0 });

  const actionsRef = useResizeObserver<HTMLDivElement>({
    onResize: setWidth,
  });

  const { isFocusVisible, focusProps } = useFocusRing({ within: true });
  const [activeActions, setActiveActions] = useState<string[]>([]);

  const areActionsShown = activeActions.length > 0 || isFocusVisible;

  const handleActionActivityChange = useCallback(
    (id: string, isActive: boolean) =>
      setActiveActions((prevActiveActions) =>
        isActive ? uniq([...prevActiveActions, id]) : without(prevActiveActions, id),
      ),
    [],
  );

  const contextValue = useShallowObjectMemo<SideMenuGroupContextValue>({
    onActionActivityChange: handleActionActivityChange,
  });

  return (
    <div
      css={css`
        padding: 0 8px;
      `}
    >
      {!!label && (
        <SideMenuGroupContext.Provider value={contextValue}>
          <Group
            data-side-menu-item
            align="center"
            gap={8}
            css={css`
              height: 32px;

              position: relative;
              padding: 0 8px;
              padding-right: ${areActionsShown ? width + 14 : 8}px;

              &:hover {
                padding-right: ${width + 14}px;
              }
            `}
          >
            <Typography
              as={TextOverflow}
              variant={Typography.Variant.BODY_SMALL_MEDIUM}
              css={(theme) => css`
                color: ${theme.ds.colors.gray[700]};
              `}
            >
              {label}
            </Typography>

            {!!tooltip && (
              <Tooltip content={tooltip}>
                <IconHelp
                  color={theme.ds.colors.gray[800]}
                  size={14}
                  css={css`
                    flex: none;
                  `}
                />
              </Tooltip>
            )}

            {!!action && (
              <div
                {...focusProps}
                ref={actionsRef}
                css={(theme) => css`
                  margin-left: auto;

                  position: absolute;
                  right: 6px;
                  top: 50%;
                  transform: translateY(-50%);

                  transition: ${theme.ds.transitions.default('opacity')};
                  opacity: ${areActionsShown ? 1 : 0};

                  [data-side-menu-item]:hover & {
                    opacity: 1;
                  }
                `}
              >
                {action}
              </div>
            )}
          </Group>
        </SideMenuGroupContext.Provider>
      )}

      <Stack
        as="ul"
        gap={1}
        css={css`
          list-style-type: none;

          &:empty {
            display: none;
          }
        `}
      >
        {children}
      </Stack>
    </div>
  );
};

export const SideMenuGroup = Object.assign(memo(SideMenuGroupBase), {
  Action: SideMenuItemAction,
});
