import { log } from '@amalia/kernel/logger/client';

// We're overriding the window object to put variables in it.
declare global {
  interface Window {
    env?: Record<string, string>;
  }
}
const envVariables = window.env ?? {};

/**
 * Returns runtime environment variable if set.
 * Runtime environment is configured in `public/index.html` inside window.env.
 * They are configured using placeholders as in window.env = { VARIABLE : '$VARIABLE' }.
 *
 * They will be swapped with their value during deploy, just before sending the bundle
 * to the bucket (see .gitlab-ci.yml).
 */
export const getEnvironmentVariable = (variableName: string, isRequired?: boolean): string => {
  // Try to fetch the variable from the values in index.html.
  if (
    envVariables[variableName] &&
    // Remove NX placeholders, that doesn't count as a value.
    envVariables[variableName] !== `%${variableName}%`
  ) {
    return envVariables[variableName];
  }

  // Still hasn't found anything? Let's verify it wasn't required.
  if (isRequired && process.env['NODE_ENV'] !== 'test') {
    throw new Error(`Variable ${variableName} has no value.`);
  }

  // Else, return a falsy string you can test against.
  return '';
};

export const config = {
  api: {
    url: `${getEnvironmentVariable('NX_PUBLIC_API_BASE_URL', true)}/api`,
  },
  apps: {
    fileDownloadUrl: `${getEnvironmentVariable('NX_PUBLIC_FILE_DOWNLOAD_URL', true)}/file-download`,
  },
  auth0: {
    domain: getEnvironmentVariable('NX_PUBLIC_AUTH0_DOMAIN', true),
    clientId: getEnvironmentVariable('NX_PUBLIC_AUTH0_CLIENT_ID', true),
    audience: getEnvironmentVariable('NX_PUBLIC_AUTH0_AUDIENCE', true),
    redirectUri: window.location.origin,
    scope: 'openid profile email',
  },
  integrations: {
    slackId: getEnvironmentVariable('NX_PUBLIC_SLACK_CLIENT_ID', false),
  },
  hubspot: {
    hubspotChatId: getEnvironmentVariable('NX_PUBLIC_HUBSPOT_SLACKBOT_ID', false),
  },
  directory: {
    userUploadTemplate: 'https://docs.google.com/spreadsheets/d/1-vxmHq_x29jthYalMYnhZ212cm7g0hvVJbuFym5ku30',
    csvMandatoryColumns: ['First Name', 'Last Name', 'Email'],
    csvOptionalColumns: ['Role', 'Currency', 'Language', 'External ID', 'HRIS ID'],
  },
  /** @deprecated */
  quota: {
    quarterlyQuotaUploadTemplate: 'https://docs.google.com/spreadsheets/d/1LMmjFUgtp4siO3q0su7-2ZeFvi3yOANpEhWy5oReeEQ',
    monthlyQuotaUploadTemplate: 'https://docs.google.com/spreadsheets/d/1w3mAxWUQ9PFtV9HrX-PCI3_ieY2qU608otYVg3Z7THI',
    yearlyQuotaUploadTemplate: 'https://docs.google.com/spreadsheets/d/1LuUmF3ENfHdPmEi01crc2p093hFr77zFXvS4BqYiK8I',
    csvMandatoryColumns: ['Email'],
    csvOptionalColumns: ['Value'],
  },
  opportunities: {
    opportunitiesUploadTemplate: 'https://docs.google.com/spreadsheets/d/1wNWMgFe8g_Sm8j5Sf9S4HCn2sOD8uIXfvIn_7leG7hQ',
    requiredColumn: ['Record ID'],
  },
  planAgreements: {
    sampleTemplateURI: 'https://docs.google.com/document/d/1IOdkrjWrVnJO8Kir_e07OVnhKiyr8z970mNxOb_85LI',
  },
} as const;

if (!['production', 'test'].includes(process.env['NODE_ENV'] ?? '')) {
  log.info('App starting with configuration', config);
}
