import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef } from 'react';

import { isEnum, type MergeAll } from '@amalia/ext/typescript';

import { CircularLoaderSizeToPx, rotateAnimation } from './CircularLoader.styles';
import { CircularLoaderSize } from './CircularLoader.types';

export type CircularLoaderProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Size of the circle. */
      readonly size?: CircularLoaderSize | number;
      /** Thickness of the circle. */
      readonly thickness?: number;
    },
  ]
>;

const CircularLoaderForwardRef = forwardRef(function CircularLoader(
  { size = CircularLoaderSize.MEDIUM, thickness = 2, ...props }: CircularLoaderProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const sizeInPx = isEnum(size, CircularLoaderSize) ? CircularLoaderSizeToPx[size] : size;

  return (
    <div
      {...props}
      ref={ref}
      data-testid="spinner"
      css={css`
        width: ${sizeInPx}px;
        height: ${sizeInPx}px;
        flex: none;
        overflow: hidden;
      `}
    >
      {/* Wrap inside a div with no overflow because the rotating animation adds an overflow when it's not at 0deg. */}
      <div
        css={(theme) => css`
          width: 100%;
          height: 100%;
          border-radius: 100%;
          border: ${thickness}px solid ${theme.ds.colors.primary[400]};
          border-left-color: transparent;

          animation: ${rotateAnimation} 800ms linear infinite;
        `}
      />
    </div>
  );
});

export const CircularLoader = Object.assign(CircularLoaderForwardRef, {
  Size: CircularLoaderSize,
});
