import { noop } from 'lodash';
import { type ReactElement, memo, type ComponentPropsWithoutRef, useState } from 'react';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { Group } from '../../layout/group/Group';
import { Stack } from '../../layout/stack/Stack';

import { Tab, type TabProps } from './tab/Tab';
import { TabsContext, type TabsContextValue } from './Tabs.context';
import * as styles from './Tabs.styles';
import { type TabValue } from './Tabs.types';

type TabElement<TTabValue extends TabValue = TabValue> = ReactElement<TabProps<TTabValue>> | false | null | undefined;

export type TabsProps<TTabValue extends TabValue = TabValue> = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Value of selected tab. */
      readonly value: TTabValue;
      /** Change handler. */
      readonly onChange?: (value: TTabValue) => void;
      /** Tabs. */
      readonly children: TabElement<TTabValue> | TabElement<TTabValue>[];
    },
  ]
>;

const TabsBase = function Tabs<const TTabValue extends TabValue = TabValue>({
  value,
  onChange = noop,
  children,
  ...props
}: TabsProps<TTabValue>) {
  const [contentContainerElement, setContentContainerElement] = useState<HTMLElement | null>(null);
  const contextValue = useShallowObjectMemo<TabsContextValue<TTabValue>>({
    value,
    onChange,
    contentContainerElement,
  });

  return (
    <TabsContext.Provider value={contextValue as TabsContextValue}>
      <Stack gap={24}>
        <Stack
          {...props}
          reverse
          css={styles.tabs}
        >
          <div css={styles.border} />
          <Group
            align="flex-end"
            gap={8}
          >
            {children}
          </Group>
        </Stack>

        {/* Content is rendered here via a Portal by the Tab component. */}
        <div
          ref={setContentContainerElement}
          css={styles.contentContainer}
        />
      </Stack>
    </TabsContext.Provider>
  );
};

export const Tabs = Object.assign(memo(TabsBase) as typeof TabsBase, {
  Tab,
});
