import { css } from '@emotion/react';
import { forwardRef, memo } from 'react';

import { ButtonLink, type ButtonLinkProps } from '../../../navigation/button-link/ButtonLink';

export type DropdownActionLinkProps = Omit<ButtonLinkProps, 'size' | 'variant'>;

const DropdownActionLinkForwardRef = forwardRef<HTMLAnchorElement, DropdownActionLinkProps>(function DropdownActionLink(
  { children, ...props },
  ref,
) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        width: 100%;
        & > a {
          width: 100%;
        }
      `}
    >
      <ButtonLink
        {...props}
        ref={ref}
        size={ButtonLink.Size.MEDIUM}
        variant={ButtonLink.Variant.PRIMARY_TEXT}
        css={css`
          width: 100%;
          justify-content: center;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        `}
      >
        {children}
      </ButtonLink>
    </div>
  );
});

export const DropdownActionLink = memo(DropdownActionLinkForwardRef);
