import { noop } from 'lodash';
import { useCallback, useState } from 'react';

export interface ConfirmModalControls {
  isOpened: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

export const useConfirmModal = ({
  onConfirm = noop,
  onCancel = noop,
}): {
  controls: ConfirmModalControls;
  openModal: (...params: unknown[]) => void;
} => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [passthroughsParams, setPassthroughsParams] = useState<unknown[]>([]);

  const openModal = useCallback(
    (...params: unknown[]) => {
      setIsOpened(true);
      setPassthroughsParams(params);
    },
    [setIsOpened],
  );

  const handleConfirm = useCallback(() => {
    onConfirm(...passthroughsParams);
    setIsOpened(false);
    setPassthroughsParams([]);
  }, [onConfirm, setIsOpened, passthroughsParams]);

  const handleClose = useCallback(() => {
    onCancel(...passthroughsParams);
    setIsOpened(false);
    setPassthroughsParams([]);
  }, [onCancel, setIsOpened, passthroughsParams]);

  return {
    controls: {
      isOpened,
      handleConfirm,
      handleClose,
    },
    openModal,
  };
};
