import { css } from '@emotion/react';
import { memo, type ReactNode, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../../../../general/typography/Typography';
import { Group } from '../../../../layout/group/Group';
import { Paper } from '../../../../layout/paper/Paper';
import { useTableContext } from '../../Table.context';

type TableBulkActionElement = ReactElement<TableBulkActionsProps> | false | null | undefined;

export type TableBulkActionsProps = {
  /** Override selectedRowsCount (will default to selectedRows.length). */
  readonly selectedRowsCount?: number;
  /** Override default selected label. */
  readonly selectedLabel?: ReactNode;
  /** Bulk actions. */
  readonly children: TableBulkActionElement | TableBulkActionElement[];
};

export const TableBulkActions = memo(function TableBulkActions({
  selectedRowsCount,
  selectedLabel,
  children,
}: TableBulkActionsProps) {
  const { selectedRowsCount: defaultSelectedRowsCount } = useTableContext();

  return (
    <Paper
      css={(theme) => css`
        border: 1px solid ${theme.ds.colors.gray[100]};
      `}
    >
      <Group align="center">
        <div
          css={css`
            padding: 4px 16px;
          `}
        >
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            {selectedLabel || (
              <FormattedMessage
                defaultMessage="{selectedRowsCount, number} selected"
                values={{ selectedRowsCount: selectedRowsCount ?? defaultSelectedRowsCount }}
              />
            )}
          </Typography>
        </div>

        {children}
      </Group>
    </Paper>
  );
});
