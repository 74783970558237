import { type Theme, css } from '@emotion/react';

import { FormLayoutDirection } from '../../../layout/form-layout/FormLayout.types';
import { FieldSize, LabelPosition } from '../types';

export const fieldWithLabel = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default('gap')};

  display: flex;

  /* When using the default position, we use the form layout direction. */
  &.${LabelPosition.DEFAULT} {
    &.${FormLayoutDirection.VERTICAL} {
      flex-direction: column;

      &.${FieldSize.SMALL} {
        gap: 4px;
      }

      &.${FieldSize.MEDIUM} {
        gap: 6px;
      }

      &.${FieldSize.LARGE} {
        gap: 8px;
      }
    }

    &.${FormLayoutDirection.HORIZONTAL} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &.${FieldSize.SMALL} {
        gap: 8px;
      }

      &.${FieldSize.MEDIUM} {
        gap: 12px;
      }

      &.${FieldSize.LARGE} {
        gap: 16px;
      }
    }
  }

  /* When setting the label position to the right we ignore the form layout direction. */
  &.${LabelPosition.RIGHT} {
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-start;

    &.${FieldSize.SMALL} {
      gap: 8px;
    }

    &.${FieldSize.MEDIUM} {
      gap: 12px;
    }

    &.${FieldSize.LARGE} {
      gap: 16px;
    }
  }
`;

export const label = css`
  .${FormLayoutDirection.VERTICAL} &.${LabelPosition.DEFAULT} {
    align-self: flex-start;
  }

  .${FormLayoutDirection.HORIZONTAL} &.${LabelPosition.DEFAULT} {
    white-space: nowrap;
    flex-shrink: 0;
  }
`;

export const error = css`
  &.${FieldSize.SMALL} {
    margin-top: 6px;
  }

  &.${FieldSize.MEDIUM} {
    margin-top: 8px;
  }

  &.${FieldSize.LARGE} {
    margin-top: 10px;
  }
`;
