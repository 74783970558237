import { type Theme } from '@emotion/react';
import styled from '@emotion/styled';

enum LineStyle {
  SOLID = 'solid',
  DASHED = 'dashed',
}

interface DividerProps {
  grayShade?: keyof Theme['ds']['colors']['gray'];
  lineStyle?: LineStyle;
}

const DividerBase = {
  Horizontal: styled.div<DividerProps>`
    width: 100%;
    height: 0;
    align-self: stretch;
    border-top: 1px ${({ lineStyle }) => lineStyle ?? LineStyle.SOLID}
      ${({ theme, grayShade }) => theme.ds.colors.gray[grayShade ?? 200]};
    flex: none;
  `,

  Vertical: styled.div<DividerProps>`
    align-self: stretch;
    width: 0;
    border-left: 1px ${({ lineStyle }) => lineStyle ?? LineStyle.SOLID}
      ${({ theme, grayShade }) => theme.ds.colors.gray[grayShade ?? 200]};
    flex: none;
  `,
} as const;

export const Divider = Object.assign(DividerBase, { Style: LineStyle });
