import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type CalculateForecastedStatementSubject, type SimulateForecastedStatementSubject } from './subjects';

export const forecastedStatementsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement);
  },

  EMPLOYEE({ user }, { can }) {
    can(
      ActionsEnum.simulate,
      SubjectsEnum.Forecasted_Statement,
      ({ userId }: SimulateForecastedStatementSubject) => userId === user.id,
    );

    can(
      ActionsEnum.calculate,
      SubjectsEnum.Forecasted_Statement,
      ({ userId }: CalculateForecastedStatementSubject) => userId === user.id,
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
