/**
 * Open a URL in a new tab.
 *
 * https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
 */
export function openInNewTab(url: string, options?: string) {
  const newWindow = window.open(url, '_blank', [options, 'noopener', 'noreferrer'].filter(Boolean).join(','));

  if (newWindow) {
    newWindow.opener = null;
  }
}
