import { type CurrencyValue } from '@amalia/kernel/monetary/types';

import { type AuthType, type ConnectorAuth } from './auth';
import { type DataConnectorTypes } from './connector-types';

// FIELDS
// TODO: refactor DataConnectorFieldType to be an enum instead.
export type DataConnectorFieldType =
  | 'address'
  | 'boolean'
  | 'currency'
  | 'date'
  | 'datetime'
  | 'enum'
  | 'number'
  | 'percent'
  | 'string'
  | 'time';

export interface DataConnectorObjectField {
  name: string;
  label: string;
  type: DataConnectorFieldType;

  enabled?: boolean;
  overrideName?: string;
  relationshipName?: string;
  allowedTypes?: DataConnectorFieldType[];
  format?: string;
  isId?: boolean;
  isName?: boolean;
  unique?: boolean;
  referenceTo?: string[];
  isMandatory?: boolean;
  matchingField?: string;
}

// OBJECT
export enum DataConnectorFilterOperator {
  EQ = 'EQ',
  NEQ = 'NEQ',
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  CONTAINS = 'CONTAINS',
  HAS_PROPERTY = 'HAS_PROPERTY',
  IN = 'IN',
}

export interface DataConnectorObjectFilter {
  name?: string;
  value?: Date | string;
  operator?: DataConnectorFilterOperator;
  condition?: string;
}

export interface DataConnectorObjectSort {
  name: string;
  direction: 'ASC' | 'DESC';
}

export type RecordContentPropertyType = CurrencyValue | boolean | number | string | null;

export type RecordContent = Record<string, RecordContentPropertyType>;

// RECORD
export interface DataConnectorObjectRecord {
  id?: string;
  object: string;
  content: RecordContent;
  ownerId?: string;
  url?: string;
}

export type DataConnectorObjectFrequencyCron = '0 0 * * *' | 'manually';

export interface DataConnectorObject {
  externalId?: string;
  label: string;
  name: string;
  alias?: string;
  mainDateField?: string;
  maxFetchRecords?: number;
  filters?: DataConnectorObjectFilter[];
  sort?: DataConnectorObjectSort;
  fields?: DataConnectorObjectField[];
  relations?: DataConnectorObject[];
  frequencyCron?: DataConnectorObjectFrequencyCron;
  isLinkedToDirectoryOrQuotas?: boolean;
  synchronizeOnlyToExistantUser?: boolean;
  isPrunable?: boolean;
  shouldPurgeBeforeSync?: boolean;
  /* Used for powerBI: DAX Query used to import object records. */
  query?: string;

  isLinkedToTeams?: boolean;
  synchronizeOnlyToExistantTeam?: boolean;
  isLinkedToTeamAssignments?: boolean;

  /* Used for powerBI: Field name we use as a facet to paginate on during import. */
  paginationField?: {
    table: string;
    column: string;
  };
}

// SOURCE
export interface DataConnectorSource {
  objects?: DataConnectorObject[];
  externalUrl?: string;
}

// TYPES

// CATEGORIES
export enum DataConnectorCategories {
  USER = 'user',
  DATA = 'data',
}

// AUTH
export interface UserInfo {
  userName?: string;
  displayName?: string;
  email?: string;

  tenantId?: string;
}

// USER
export type DataConnectorUser = {
  id: string;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  createdAt?: string;
  updatedAt?: string;
  primaryTeamId?: string | null;
  archived?: boolean;
  userId?: string;
};

// CONNECTORS
export enum DataConnectorStatus {
  /* Connector is active */
  ACTIVE = 'ACTIVE',
  /* Connector is in error (out of sync) */
  ERROR = 'ERROR',
}

export interface DataConnector {
  id: string;
  auth?: ConnectorAuth | null;
  alias: string | null;

  authType?: AuthType | null;

  source?: DataConnectorSource;
  type: DataConnectorTypes;
  category: DataConnectorCategories;
  status?: DataConnectorStatus;
  updatedAt?: Date;

  // We can store connector objet catalog when not available from api.
  // catalog entries:
  // - keys : objectNames
  // - values: object definitions
  catalog?: unknown;

  /**
   * tenant id in connector system (can be Salesforce organization id, Hubspot portal id, ...)
   */
  tenantId?: string;
}

export interface PatchDataConnectorRequest {
  externalUrl?: string;
  alias?: string;
}
