import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type Calculation, type DataRefreshment } from '@amalia/core/types';
import { assert } from '@amalia/ext/typescript';
import { type Period } from '@amalia/payout-definition/periods/types';
import {
  saGetCompanies,
  saGetCompany,
  saDeleteCompany,
  saGetUsers,
  saGetOngoingCalculations,
  saGetLastCalculations,
  saGetOngoingRefreshments,
  saGetLastRefreshments,
  saGetPeriods,
  saGetConnectors,
  saGetStatementsOnError,
  saGetPlans,
  saGetAllMasterSettings,
  type PaginationOf,
} from '@amalia/superadmin/api-client';

import { superadminQueryKeys } from './queries.key';

export const useIsSALoading = () => useIsFetching({ queryKey: superadminQueryKeys.all() });

// QUERY
export const useCompaniesList = (enabled: boolean) =>
  useQuery({
    queryKey: superadminQueryKeys.companies.list(),
    queryFn: () => saGetCompanies(),
    enabled,
  });

export const useCompany = (companyId?: string) =>
  useQuery({
    queryKey: superadminQueryKeys.companies.details(companyId!),
    queryFn: () => {
      assert(companyId, 'companyId is required');
      return saGetCompany(companyId);
    },
    enabled: !!companyId,
  });

type DeleteCompanyMutationInput = {
  companyId: string;
};

/**
 * React query mutation hook to delete a company.
 */
export const useDeleteCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: DeleteCompanyMutationInput) => saDeleteCompany(variables.companyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: superadminQueryKeys.companies.all() });
    },
  });
};

export const useUsersList = (companyId?: string) =>
  useQuery({
    enabled: !!companyId,
    queryKey: superadminQueryKeys.users.list(companyId!),
    queryFn: () => saGetUsers(companyId!),
    select: (data) => data.toSorted((u1, u2) => u1.company.name.localeCompare(u2.company.name)),
  });

export const useOnGoingCalculations = () =>
  useQuery({
    queryKey: superadminQueryKeys.calculations.ongoing(),
    queryFn: () => saGetOngoingCalculations(),
    placeholderData: (previousData) => previousData ?? [],
    refetchInterval: 10_000,
  });

export const useLastCalculations = (companiesId: string[], filterErrors: boolean, page: number = 1) =>
  useQuery({
    queryKey: superadminQueryKeys.calculations.last(companiesId, filterErrors, page),
    queryFn: () => saGetLastCalculations(page, companiesId, filterErrors),
    refetchInterval: 10_000,
    placeholderData: (previousData) =>
      previousData ??
      ({
        items: [],
        totalItems: 0,
        currentPage: 1,
        pageCount: 1,
      } satisfies PaginationOf<Calculation>),
  });

export const useOnGoingRefreshments = () =>
  useQuery({
    queryKey: superadminQueryKeys.dataUpdates.ongoing(),
    queryFn: () => saGetOngoingRefreshments(),
    refetchInterval: 10_000,
  });

export const useLastRefreshments = (companiesId: string[], filterErrors: boolean, page: number = 1) =>
  useQuery({
    queryKey: superadminQueryKeys.dataUpdates.last(companiesId, filterErrors, page),
    queryFn: () => saGetLastRefreshments(page, companiesId, filterErrors),
    placeholderData: (previousData) =>
      previousData ??
      ({
        items: [],
        totalItems: 0,
        currentPage: 1,
        pageCount: 1,
      } satisfies PaginationOf<DataRefreshment>),
    refetchInterval: 10_000,
  });

export const useCompanyPeriods = (companyId: string | null) =>
  useQuery({
    queryKey: superadminQueryKeys.periods.list(companyId!),
    queryFn: () => {
      assert(companyId, 'companyId is required');
      return saGetPeriods(companyId);
    },
    select: (data) =>
      data
        .toSorted((a, b) => b.startDate - a.startDate)
        .map((period) => ({
          label: period.name,
          value: period.id,
        })),
    placeholderData: [] satisfies Period[],
    enabled: !!companyId,
  });

export const useConnectorsList = (companiesId: string[]) =>
  useQuery({
    queryKey: superadminQueryKeys.connectors.list(companiesId),
    queryFn: () => saGetConnectors(companiesId),
    placeholderData: [],
  });

export const useStatementsOnErrorList = (companiesId: string[]) =>
  useQuery({
    queryKey: superadminQueryKeys.statements.inError(companiesId),
    queryFn: () => saGetStatementsOnError(companiesId),
    placeholderData: [],
  });

export const usePlansList = (companyId?: string) =>
  useQuery({
    enabled: !!companyId,
    queryKey: superadminQueryKeys.plans.list(companyId!),
    queryFn: () => saGetPlans(companyId!),
    placeholderData: [],
  });

export const useMasterSettings = () =>
  useQuery({
    queryKey: superadminQueryKeys.masterSettings(),
    queryFn: saGetAllMasterSettings,
  });
