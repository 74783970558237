import { LOCAL_STORAGE_MOCK_TOKEN_KEY } from '@amalia/lib-ui';
import { type UserContract } from '@amalia/tenants/users/types';

export const LOCAL_STORAGE_COMPANY_ID_KEY = 'companyId';
export const LOCAL_STORAGE_USER_ID_KEY = 'userId';

const LOCAL_STORAGE_VALUES_TO_PRESERV = ['left-bar-state'];

// Add HubSportConversations type to window
declare global {
  interface Window {
    HubSpotConversations?: {
      clear: (options: { resetWidget: boolean }) => void;
    };
  }
}

export type WithMaybeCompanyId<T extends { company?: { id?: string } }, U = T['company']> = Omit<
  T,
  'company' | 'companyId'
> & {
  company?: U;
  companyId?: string;
};

/**
 * On load, check if local storage has the same companyId that current user.
 * If that's not the case, clear local storage data and register new companyId
 */
export const checkLocalStorageOnLoad = ({
  company,
  companyId,
  id: currentUserId,
}: WithMaybeCompanyId<UserContract>) => {
  // Do not clear storage in Cypress tests, or else the robot won't be able to authenticate.
  if (localStorage.getItem(LOCAL_STORAGE_MOCK_TOKEN_KEY)) {
    return;
  }

  const currentUserCompanyId = companyId ?? company?.id;

  if (currentUserCompanyId && localStorage.getItem(LOCAL_STORAGE_COMPANY_ID_KEY) !== currentUserCompanyId) {
    // Save values to restore later
    const values: Record<string, string> = {};
    LOCAL_STORAGE_VALUES_TO_PRESERV.forEach((v) => {
      const value = localStorage.getItem(v);
      if (value !== null) {
        values[v] = value;
      }
    });

    localStorage.clear();

    // Restore values
    Object.entries(values).forEach(([k, v]) => {
      localStorage.setItem(k, v);
    });

    localStorage.setItem(LOCAL_STORAGE_COMPANY_ID_KEY, currentUserCompanyId);
  }
  if (currentUserId && localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) !== currentUserId) {
    // Try to clear the Hubspot tokens from local storage
    window.HubSpotConversations?.clear({ resetWidget: true });
    localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, currentUserId);
  }
};
