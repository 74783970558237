export const userInformationQueryKeys = {
  all: () => ['user-information'] as const,
  list: () => [...userInformationQueryKeys.all(), 'list'] as const,
  byProperty: (property: string, value: string) =>
    [...userInformationQueryKeys.all(), 'by-property', property, value] as const,
} as const;

export const notificationsQueryKeys = {
  all: () => ['notifications'] as const,
  unreadCount: () => [...notificationsQueryKeys.all(), 'unread-count'] as const,
  pages: () => [...notificationsQueryKeys.all(), 'pages'] as const,
} as const;
