import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineDayjsInput } from '../dates.types';

export const datesMonthNumber = new AmaliaFunctionDefault<[ComputeEngineDayjsInput], number>({
  name: AmaliaFunctionKeys.monthNumber,
  category: AmaliaFunctionCategory.DATES,
  nbParamsRequired: 1,
  description: 'Return the month in number format of a given date',

  exec: (date: ComputeEngineDayjsInput) => dayjs(date, 'X').month() + 1,

  params: [
    {
      name: 'date',
      description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
  ],

  examples: [
    {
      desc: 'Returns 9',
      formula: 'monthNumber(toDate("2023-09-16"))' as AmaliaFormula,
      result: 9,
    },
    {
      desc: 'Returns the month in number format of Close Date.',
      formula: 'monthNumber(opportunity.closeDate)' as AmaliaFormula,
    },
  ],
});
