import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef } from 'react';

import { Link, type LinkProps } from '@amalia/ext/react-router-dom';

export type TextLinkProps = LinkProps & {
  readonly colorScheme?: 'dark' | 'light';
};

export const TextLink = forwardRef(function TextLink(
  { children, colorScheme = 'dark', ...props }: TextLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Link
      {...props}
      ref={ref}
      css={(theme) => css`
        &:not([data-disabled]) {
          text-decoration: none;

          transition: ${theme.ds.transitions.default()};

          color: ${colorScheme === 'dark' ? theme.ds.colors.primary[400] : theme.ds.colors.primary[300]};
          outline: none;

          &:hover {
            color: ${colorScheme === 'dark' ? theme.ds.colors.primary[500] : theme.ds.colors.primary[200]};
          }

          &:focus-visible {
            text-decoration: underline;
          }
        }
      `}
    >
      {children}
    </Link>
  );
});
