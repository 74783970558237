import { useIsFetching } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useState } from 'react';

import { quotasQueryKeys } from '@amalia/assignments/quotas/state';
import { customObjectDefinitionsQueryKeys } from '@amalia/data-capture/record-models/state';
import { useBoolState } from '@amalia/ext/react/hooks';
import { customReportsQueryKeys } from '@amalia/reporting/custom-reports/state';
import { dashboardsQueryKeys } from '@amalia/reporting/dashboards-v2/state';
import { superadminQueryKeys } from '@amalia/superadmin/state';
import { teamsQueryKeys } from '@amalia/tenants/teams/state';
import { userProfileQueryKeys } from '@amalia/tenants/users/profile/state';

import { type PagesEnum } from './commands/shortcut-commands.types';

const SHORTCUT_COMMANDS_QUERY_KEYS = [
  customObjectDefinitionsQueryKeys.all(),
  customReportsQueryKeys.all(),
  superadminQueryKeys.plans.all(),
  userProfileQueryKeys.all(),
  superadminQueryKeys.companies.all(),
  teamsQueryKeys.all(),
  dashboardsQueryKeys.all(),
  quotasQueryKeys.all(),
] as const;

export const useShortcutCommands = () => {
  const [search, setSearch] = useState('');
  const [pages, setPages] = useState<PagesEnum[]>([]);
  const { isCommandsModalOpen, setCommandsModalOpen, toggleCommandsModalOpen, setCommandsModalOpenFalse } =
    useBoolState(false, 'commandsModalOpen');
  const page = pages[pages.length - 1];

  const isCommandsLoading =
    useIsFetching({
      predicate: (query) =>
        // Check that the query starts with one of the target query keys.
        SHORTCUT_COMMANDS_QUERY_KEYS.some((queryKey) => isEqual(queryKey, query.queryKey.slice(0, queryKey.length))),
    }) > 0;

  return {
    search,
    setSearch,
    pages,
    setPages,
    page,
    isCommandsModalOpen,
    setCommandsModalOpen,
    toggleCommandsModalOpen,
    setCommandsModalOpenFalse,
    isCommandsLoading,
  };
};
