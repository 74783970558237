import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type Quota } from '@amalia/amalia-lang/tokens/types';
import { QuotasAssignmentsApiClient } from '@amalia/assignments/quotas/api-client';
import {
  type QuotaPlanAssignments,
  type QuotaTeamAssignments,
  type QuotaUserAssignments,
  type SearchQuotaAssignmentsRequest,
} from '@amalia/assignments/quotas/types';
import { assert } from '@amalia/ext/typescript';

import { quotaAssignmentsMutationKeys, quotaAssignmentsQueryKeys } from './queries.keys';

export const useQuotaAssignments = <
  TQuotaAssignments extends QuotaPlanAssignments | QuotaTeamAssignments | QuotaUserAssignments,
>(
  quotaId?: Quota['id'],
  { startDate, endDate, planIds }: Partial<SearchQuotaAssignmentsRequest> = {},
) =>
  useQuery({
    enabled: !!quotaId && !!startDate && !!endDate,
    queryKey: quotaAssignmentsQueryKeys.ofVariable.list(quotaId!, startDate!, endDate!, planIds),
    queryFn: () => {
      assert(
        !!quotaId && !!startDate && !!endDate,
        'Query should be disabled if quotaId or startDate or endDate is not provided',
      );

      return QuotasAssignmentsApiClient.getQuotaAssignments<TQuotaAssignments>(quotaId, {
        startDate,
        endDate,
        planIds,
      });
    },
  });

export const useUpsertQuotaAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: quotaAssignmentsMutationKeys.upsertOne(),
    mutationFn: QuotasAssignmentsApiClient.upsertQuotaAssignment,
    onSuccess: async (_, upsertQuotaRequest) => {
      await Promise.all(
        [
          upsertQuotaRequest.userId &&
            queryClient.invalidateQueries({
              queryKey: quotaAssignmentsQueryKeys.ofUser.all(upsertQuotaRequest.userId),
            }),
          queryClient.invalidateQueries({
            queryKey: quotaAssignmentsQueryKeys.ofVariable.all(upsertQuotaRequest.variableId),
          }),
        ].filter(Boolean),
      );
    },
  });
};

export const useUpsertQuotasAssignments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: quotaAssignmentsMutationKeys.upsertMany(),
    mutationFn: QuotasAssignmentsApiClient.upsertQuotasAssignments,
    onSuccess: async (_, upsertQuotasRequest) => {
      await Promise.all(
        upsertQuotasRequest.quotas.map((upsertQuotaRequest) =>
          Promise.all(
            [
              upsertQuotaRequest.userId &&
                queryClient.invalidateQueries({
                  queryKey: quotaAssignmentsQueryKeys.ofUser.all(upsertQuotaRequest.userId),
                }),
              queryClient.invalidateQueries({
                queryKey: quotaAssignmentsQueryKeys.ofVariable.all(upsertQuotaRequest.variableId),
              }),
            ].filter(Boolean),
          ),
        ),
      );
    },
  });
};

export const useIsUpsertAssignmentLoading = () =>
  useIsMutating({ mutationKey: quotaAssignmentsMutationKeys.upsertOne() }) +
    useIsMutating({ mutationKey: quotaAssignmentsMutationKeys.upsertMany() }) >
  0;

export const useDeleteQuotaAssignments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: quotaAssignmentsMutationKeys.deleteMany(),
    mutationFn: QuotasAssignmentsApiClient.deleteQuotaAssignments,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: quotaAssignmentsQueryKeys.all() });
    },
  });
};

export const useDeleteQuotaAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: quotaAssignmentsMutationKeys.deleteOne(),
    mutationFn: QuotasAssignmentsApiClient.deleteQuotaAssignment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: quotaAssignmentsQueryKeys.all() });
    },
  });
};
