import { type PaginatedQuery } from '@amalia/core-http-types';

import { type DataGridState } from './useDataGridState';

export const useDatagridStateToPaginatedQuery = (
  datagridState: DataGridState,
): Pick<PaginatedQuery, 'desc' | 'limit' | 'page' | 'search' | 'sort'> => ({
  desc: datagridState.columnSorting[0]?.direction === 'desc',
  sort: datagridState.columnSorting[0]?.id,
  page: datagridState.page,
  limit: datagridState.pageSize,
  search: datagridState.searchText,
});
