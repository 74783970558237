import { useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amalia/design-system/components';
import { LayoutUnauthenticated, LayoutUnauthenticatedContent } from '@amalia/kernel/auth/components';

const broadcastChannel = new BroadcastChannel('connectors-oauth-callback');

export const ConnectorsOAuthCallbackView = function ConnectorsOAuthCallbackView() {
  useLayoutEffect(() => {
    broadcastChannel.postMessage({ payload: window.location.search });
    window.close();
  }, []);

  return (
    <LayoutUnauthenticated>
      <LayoutUnauthenticatedContent
        title={<FormattedMessage defaultMessage="Authentication" />}
        message={
          <AlertBanner
            inline
            variant={AlertBanner.Variant.INFO}
          >
            <FormattedMessage defaultMessage="Validating authentication..." />
          </AlertBanner>
        }
      />
    </LayoutUnauthenticated>
  );
};
