import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

export const dataAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Data);
    can(ActionsEnum.modify, SubjectsEnum.Data);
    can(ActionsEnum.overwrite, SubjectsEnum.Data);
    can(ActionsEnum.refresh, SubjectsEnum.Data);
  },

  MANAGER({ user }, { can }) {
    // Doesn't make a lot of sense, see discussion in https://gitlab.com/amal-ia/amalia-web/-/issues/3909
    can(ActionsEnum.overwrite, SubjectsEnum.Data);

    if (user.company.featureFlags.DATA_REFRESH_MANAGERS) {
      can(ActionsEnum.refresh, SubjectsEnum.Data);
    }
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
