import { defineMessages } from 'react-intl';

import { UserRole } from '@amalia/tenants/users/types';

export const UserRoleLabels = defineMessages<UserRole>({
  [UserRole.ADMIN]: {
    defaultMessage: 'Admin',
    description: 'roles names',
  },
  [UserRole.READ_ONLY_ADMIN]: {
    defaultMessage: 'Read-only admin',
    description: 'roles names',
  },
  [UserRole.FINANCE]: {
    defaultMessage: 'Finance',
    description: 'roles names',
  },
  [UserRole.EMPLOYEE]: {
    defaultMessage: 'Employee',
    description: 'roles names',
  },
  [UserRole.MANAGER]: {
    defaultMessage: 'Manager',
    description: 'roles names',
  },
  [UserRole.READ_ONLY_MANAGER]: {
    defaultMessage: 'Read-only manager',
    description: 'roles names',
  },
  [UserRole.READ_ONLY_EMPLOYEE]: {
    defaultMessage: 'Read-only employee',
    description: 'roles names',
  },
} as const);
