import { FloatingPortal } from '@floating-ui/react';
import { Fragment, memo, type ReactElement } from 'react';

import { Checkbox } from '../../../../data-input/checkbox/Checkbox';
import { Skeleton } from '../../../../feedback/skeleton/Skeleton';
import { type UseTableMultiSelectionValue } from '../../hooks/useTableMultiSelection';
import { type TableBulkActionsProps } from '../../table-helpers/bulk-actions/TableBulkActions';
import { useBulkActionsPopover } from '../../table-helpers/bulk-actions/useBulkActionsPopover';
import { useTableContext } from '../../Table.context';
import { type RowData, type ColumnDefinitions, type RowKey } from '../../Table.types';
import { TableHeaderCell } from '../table-header-cell/TableHeaderCell';
import { TableHeaderRow } from '../table-header-row/TableHeaderRow';

const SKELETON_PLACEHOLDER = 'Lorem';

export type TableHeaderProps<TData extends RowData, TKey extends RowKey> = Pick<
  UseTableMultiSelectionValue<TKey>,
  | 'handleSelectAllRows'
  | 'hasAllRowsSelected'
  | 'hasSomeRowsSelected'
  | 'isSelectAllDisabled'
  | 'isSelectionEnabled'
  | 'selectedRowsCount'
  | 'selectionColumnRef'
  | 'selectionColumnWidth'
> & {
  readonly columns: ColumnDefinitions<TData>;
  readonly bulkActions?: ReactElement<TableBulkActionsProps>;
};

const TableHeaderBase = function TableHeader<TData extends RowData, TKey extends RowKey>({
  columns,
  bulkActions,
  isSelectionEnabled,
  isSelectAllDisabled,
  selectedRowsCount,
  selectionColumnRef,
  hasAllRowsSelected,
  hasSomeRowsSelected,
  selectionColumnWidth,
  handleSelectAllRows,
}: TableHeaderProps<TData, TKey>) {
  const { isLoading, pinFirstColumn, pinLastColumn } = useTableContext();

  const {
    referenceRef,
    floatingRef,
    isMounted,
    floatingStyles,
    transitionStyles,
    getReferenceProps,
    getFloatingProps,
  } = useBulkActionsPopover({
    anchorRef: selectionColumnRef,
    enabled: !!(isSelectionEnabled && bulkActions && selectedRowsCount),
  });

  return (
    <thead>
      <TableHeaderRow index={0}>
        {!!isSelectionEnabled && (
          <Fragment>
            <TableHeaderCell
              {...getReferenceProps({
                ref: referenceRef,
              })}
              isStickyLeft={!!pinFirstColumn && !isLoading}
              size={0}
              stickyLeftOffset={0}
            >
              <Checkbox
                checked={hasAllRowsSelected}
                disabled={isLoading || isSelectAllDisabled}
                indeterminate={!!hasSomeRowsSelected && !hasAllRowsSelected}
                size={Checkbox.Size.SMALL}
                onChange={handleSelectAllRows}
              />
            </TableHeaderCell>

            {!!isMounted && !!bulkActions && (
              <FloatingPortal>
                <div
                  ref={floatingRef}
                  {...getFloatingProps()}
                  style={{
                    ...floatingStyles,
                    ...transitionStyles,
                  }}
                >
                  {bulkActions}
                </div>
              </FloatingPortal>
            )}
          </Fragment>
        )}

        {columns.map((column, index) => (
          <TableHeaderCell
            key={column.id}
            actions={!isLoading ? column.actions : undefined}
            backgroundColor={column.headerBackgroundColor}
            data-columnid={column.id}
            data-testid={`table-header-cell-${column.id}`}
            icon={!isLoading ? column.icon : undefined}
            isStickyLeft={index === 0 && pinFirstColumn}
            isStickyRight={index === columns.length - 1 && pinLastColumn}
            size={column.size}
            stickyLeftOffset={selectionColumnWidth}
            subTitle={!isLoading ? column.subTitle : undefined}
            tooltip={!isLoading ? column.tooltip : undefined}
          >
            {isLoading ? <Skeleton shape={Skeleton.Shape.ROUND}>{SKELETON_PLACEHOLDER}</Skeleton> : column.header}
          </TableHeaderCell>
        ))}
      </TableHeaderRow>
    </thead>
  );
};

export const TableHeader = memo(TableHeaderBase) as typeof TableHeaderBase;
