import { http } from '@amalia/core/http/client';
import { type RulePlanConfiguration, type UpdateRuleAssignment } from '@amalia/payout-definition/plans/types';

const e = encodeURIComponent;

export class RulePlanAssignment {
  public static async getRuleConfigurations(planId: string): Promise<RulePlanConfiguration[]> {
    const { data } = await http.get<RulePlanConfiguration[]>(`/plans-v2/${planId}/rules`);
    return data;
  }

  public static async getRuleConfiguration(planId: string, ruleId: string): Promise<RulePlanConfiguration> {
    const { data } = await http.get<RulePlanConfiguration>(`/plans-v2/${planId}/rules/${ruleId}`);
    return data;
  }

  public static async updateRuleAssignment({
    planId,
    ruleId,
    patch,
  }: {
    planId: string;
    ruleId: string;
    patch: UpdateRuleAssignment;
  }): Promise<RulePlanConfiguration> {
    const { data } = await http.patch<RulePlanConfiguration>(
      `/plans-v2/${e(planId)}/rule-assignments/${e(ruleId)}`,
      patch,
    );
    return data;
  }
}
