import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@amalia/design-system/components';
import { PlanVisibility } from '@amalia/payout-definition/plans/types';
import { usePatchPlanVisibility } from '@amalia/payout-definition/state';

import { type PlanVisibilityModalCommonProps } from './types';

export type PlanVisibilityHideModalProps = PlanVisibilityModalCommonProps;

export const PlanVisibilityHideModal = memo(function PlanVisibilityHideModal({
  closeModal,
  isOpen,
  planId,
  planName,
}: PlanVisibilityHideModalProps) {
  const { mutate: patchPlanVisibility } = usePatchPlanVisibility(planId);

  const handleClickHidePlan = useCallback(() => {
    patchPlanVisibility({
      visibility: PlanVisibility.HIDDEN,
    });

    closeModal();
  }, [closeModal, patchPlanVisibility]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Unpublish plan" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to unpublish the plan “<b>{planName}</b>”?{br}The statements for this plan will now be accessible exclusively to admins and hidden from assigned users."
            values={{ planName, b: (chunks) => <b>{chunks}</b> }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={handleClickHidePlan}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
