import { type FormikProps } from 'formik';
import { memo, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amalia/design-system/components';
import { FormikForm } from '@amalia/ext/formik';

import { CreateUserForm, type CreateUserFormValues, type CreateUserFormProps } from '../CreateUserForm';
import { CreateUserFormFields } from '../fields/CreateUserFormFields';

export type CreateUserFormModalProps = Omit<CreateUserFormProps, 'children'> & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const CreateUserFormModal = memo(function CreateUserFormModal({
  isOpen,
  onClose,
  onSubmitSuccess,
  ...props
}: CreateUserFormModalProps) {
  const formRef = useRef<FormikProps<CreateUserFormValues>>(null);

  const handleSubmitSuccess = useCallback(() => {
    formRef.current?.resetForm({
      values: formRef.current.initialValues,
    });

    onSubmitSuccess?.();
  }, [onSubmitSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <CreateUserForm
        {...props}
        ref={formRef}
        onSubmitSuccess={handleSubmitSuccess}
      >
        {({ isValid, isSubmitting }) => (
          <FormikForm.FormDisplayContents>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Add a user" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <CreateUserFormFields />
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Add user" />
              </Modal.MainAction>
            </Modal.Actions>
          </FormikForm.FormDisplayContents>
        )}
      </CreateUserForm>
    </Modal>
  );
});
