import { CustomReportsPresetsEnum } from '@amalia/reporting/custom-reports/shared';

import { type UserRoleForAccessControl, type DefinePermissions, ActionsEnum, SubjectsEnum } from '../../types';

import { type ViewPresetReportSubject } from './subjects';

const canSeePresetReports = ({ presetReportId }: ViewPresetReportSubject) =>
  [
    CustomReportsPresetsEnum.PRESET_LEADERBOARD,
    CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
    CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
    CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_PLAN,
    CustomReportsPresetsEnum.PRESET_PLAN_KPIS,
  ].includes(presetReportId);

export const presetReportsAbilityDefinitions = {
  ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
  },

  READ_ONLY_ADMIN(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
  },

  FINANCE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
  },

  MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
  },

  READ_ONLY_MANAGER(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
  },

  EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, ({ presetReportId }: ViewPresetReportSubject) =>
      [
        CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
        CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
        CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_RULE,
        CustomReportsPresetsEnum.PRESET_PLAN_KPIS,
      ].includes(presetReportId),
    );
  },

  READ_ONLY_EMPLOYEE(_, { can }) {
    can(ActionsEnum.view, SubjectsEnum.PresetReport, ({ presetReportId }: ViewPresetReportSubject) =>
      [
        CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
        CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
        CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_RULE,
        CustomReportsPresetsEnum.PRESET_PLAN_KPIS,
      ].includes(presetReportId),
    );
  },
} as const satisfies Partial<Record<UserRoleForAccessControl, DefinePermissions>>;
