import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import {
  ButtonLink,
  DropdownList,
  type DropdownListItem,
  type DropdownListProps,
} from '@amalia/design-system/components';
import { isLinkElement } from '@amalia/ext/react-router-dom';

import { type UsersAvatarStackUser } from '../UsersAvatarStack.types';

export type UsersAvatarStackDropdownProps = Pick<DropdownListProps, 'children'> & {
  readonly users: UsersAvatarStackUser[];
};

export const UsersAvatarStackDropdown = memo(function UsersAvatarStackDropdown({
  users,
  children,
}: UsersAvatarStackDropdownProps) {
  const items = useMemo<DropdownListItem[]>(
    () =>
      users.map(
        ({ user, to }): DropdownListItem => ({
          key: user.id,
          filterLabel: formatUserFullName(user),
          label: (
            <UserPrettyFormat
              firstName={user.firstName}
              lastName={user.lastName}
              pictureURL={user.pictureURL}
            />
          ),
          rightActions: !!to && (
            <ButtonLink
              size={ButtonLink.Size.SMALL}
              to={to}
              variant={ButtonLink.Variant.PRIMARY_TEXT}
            >
              {(isLinkElement(to) ? to.props.children : undefined) || <FormattedMessage defaultMessage="View" />}
            </ButtonLink>
          ),
        }),
      ),
    [users],
  );

  return <DropdownList items={items}>{children}</DropdownList>;
});
