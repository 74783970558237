import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type FiltersType } from '@amalia/core/types';
import { type PaginatedQuery } from '@amalia/core-http-types';
import { useLastConnectorImport } from '@amalia/data-capture/imports/state';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';

import { customObjectsMutationKeys, customObjectsQueryKeys } from './queries.keys';

export const useCustomObjectsPage = ({
  objectDefinitionName,
  pagination,
  filters,
  overwrite = false,
  dataConnectorId,
}: {
  objectDefinitionName: CustomObjectDefinition['machineName'];
  pagination: PaginatedQuery;
  filters?: FiltersType[] | null;
  overwrite?: boolean;
  isSyncRunning?: boolean;
  dataConnectorId?: string;
}) => {
  const { data: lastDataImport, isPending } = useLastConnectorImport(dataConnectorId, objectDefinitionName);

  return useQuery({
    enabled: !!objectDefinitionName && !isPending,
    queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.page(
      objectDefinitionName,
      pagination,
      filters,
      overwrite,
      lastDataImport?.updatedAt, // Refresh the data when the last import is updated.
    ),
    queryFn: async () => CustomObjectsApiClient.getObjects(objectDefinitionName, pagination, filters, overwrite),
  });
};

export const useIsCustomObjectsPageLoading = (objectDefinitionName: CustomObjectDefinition['machineName']) =>
  useIsFetching({ queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinitionName) }) > 0;

export const usePurgeCustomObjects = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customObjectsMutationKeys.ofCustomObjectDefinition.purge(objectDefinitionName),
    mutationFn: CustomObjectsApiClient.purgeObjectsByDefinition,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinitionName),
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomObject = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customObjectsMutationKeys.ofCustomObjectDefinition.patch(objectDefinitionName),
    mutationFn: CustomObjectsApiClient.patchCustomObject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinitionName),
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useClearCustomObject = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customObjectsMutationKeys.ofCustomObjectDefinition.clear(objectDefinitionName),
    mutationFn: CustomObjectsApiClient.clearCustomObject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinitionName),
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useBulkOverwrite = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: customObjectsMutationKeys.ofCustomObjectDefinition.bulkOverwrite(objectDefinitionName),
    mutationFn: (args: Parameters<(typeof CustomObjectsApiClient)['bulkOverwrite']>) =>
      CustomObjectsApiClient.bulkOverwrite(...args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: customObjectsQueryKeys.ofCustomObjectDefinition.all(objectDefinitionName),
      });
    },
  });
};
