import { useQuery } from '@tanstack/react-query';

import { assert } from '@amalia/ext/typescript';
import { type RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';
import { type Plan, type PlanIsHiddenQueryChoices } from '@amalia/payout-definition/plans/types';
import { type TeamContract } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { getOvertimeWidget } from '../services/dashboards/dashboards.repository';

export const overtimeQueryKeys = {
  // Note: maybe we can put these queries in the dashboards domain?
  all: () => ['dashboards-overtime-data'] as const,
  details: (filters: {
    periodIds: RelativePeriodKeyword[];
    referenceDate?: string;
    userIds?: UserContract['id'][];
    planIds?: Plan['id'][];
    teamIds?: TeamContract['id'][];
    planHiddenStatus?: PlanIsHiddenQueryChoices;
  }) => [...overtimeQueryKeys.all(), 'details', filters] as const,
} as const;

export const useUserOvertimeData = ({
  periodIds,
  referenceDate,
  userId,
  planId,
}: {
  periodIds: RelativePeriodKeyword[];
  referenceDate?: string;
  userId?: UserContract['id'];
  planId?: Plan['id'];
}) =>
  useQuery({
    enabled: !!userId && !!planId && !!referenceDate,
    queryKey: overtimeQueryKeys.details({
      periodIds,
      referenceDate,
      userIds: [userId!],
      planIds: [planId!],
    }),
    queryFn: async () => {
      assert(userId, 'userId is required');
      assert(planId, 'planId is required');
      return getOvertimeWidget({
        periodIds,
        referenceDate,
        userIds: [userId],
        planIds: [planId],
      });
    },
  });

export const useOverTimeData = ({
  periodIds,
  referenceDate,
  userIds,
  planIds,
  teamIds,
  planHiddenStatus,
}: {
  periodIds: RelativePeriodKeyword[];
  referenceDate?: string;
  userIds?: UserContract['id'][];
  planIds?: Plan['id'][];
  teamIds?: TeamContract['id'][];
  planHiddenStatus?: PlanIsHiddenQueryChoices;
}) =>
  useQuery({
    queryKey: overtimeQueryKeys.details({
      periodIds,
      referenceDate,
      userIds,
      planIds,
      teamIds,
      planHiddenStatus,
    }),
    queryFn: async () =>
      getOvertimeWidget({
        periodIds,
        referenceDate,
        userIds,
        planIds,
        teamIds,
        planHiddenStatus,
      }),
  });
