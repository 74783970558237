import { type ChangelogEntryResponse } from '@amalia/amalia-meta/changelog/shared';
import { http } from '@amalia/core/http/client';

export class ChangelogApiClient {
  public static async list({
    newEntries = false,
    cursor = null,
    fromDate = null,
  }: {
    newEntries?: boolean;
    cursor?: string | null;
    fromDate?: Date | null;
  }) {
    const { data } = await http.get<ChangelogEntryResponse>('/changelog-entries', {
      params: {
        cursor: cursor ?? '',
        from: fromDate?.toISOString() ?? '',
        new: newEntries ? 'true' : 'false',
      },
    });

    return data;
  }
}
