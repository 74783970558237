import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const stringConcat = new AmaliaFunctionDefault<(number | string)[], string>({
  name: AmaliaFunctionKeys.CONCAT,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 0,
  description: 'Return the concatenated strings',
  hasInfiniteParams: true,

  exec: (...stringsToConcat) => stringsToConcat.join(''),

  params: [],

  examples: [
    {
      desc: 'Returns First Name - Opportunity ID.',
      formula: 'CONCAT(user.firstName, " - ", opportunity.id)' as AmaliaFormula,
    },
    {
      desc: 'Returns Jean, Dupont.',
      formula: 'CONCAT("Jean", ", ", "Dupont")' as AmaliaFormula,
      result: 'Jean, Dupont',
    },
  ],
});
