import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { type SimpleSelectOption, Tooltip } from '@amalia/design-system/components';
import { useAuthorizedProfiles } from '@amalia/tenants/users/profile/state';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

export const useUserOptions = () => {
  const { formatMessage } = useIntl();
  const { data: users } = useAuthorizedProfiles();

  return useMemo(
    () =>
      users.map(({ email, firstName, id, lastName, pictureURL }) => ({
        label: (
          <Tooltip
            placement="right"
            content={
              <FormattedMessage
                defaultMessage="{firstName} {lastName} - {email}"
                values={{
                  firstName,
                  lastName,
                  email,
                }}
              />
            }
          >
            <UserPrettyFormat
              firstName={firstName}
              lastName={lastName}
              pictureURL={pictureURL}
            />
          </Tooltip>
        ),
        value: id,
        filterLabel: [firstName, lastName, email].join(' '),
        valueLabel: formatMessage(
          { defaultMessage: '{firstName} {lastName}' },
          {
            firstName,
            lastName,
          },
        ),
      })),
    [users, formatMessage],
  ) satisfies SimpleSelectOption<UserProfile['id']>[];
};
