import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DatePicker, useDatePickerTimestampsAdapter } from '@amalia/design-system/components';
import { type UnixTimestampInSeconds } from '@amalia/ext/dates';

import { type DuplicatePlanFormValues } from '../DuplicatePlanForm';

interface AssignmentDatePickerProps {
  readonly fieldName: 'assignmentsEffectiveAsOf' | 'assignmentsEffectiveUntil';
}

export const AssignmentDatePicker = memo(function AssignmentDatePicker({ fieldName }: AssignmentDatePickerProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<DuplicatePlanFormValues>();

  const datePickerProps = useDatePickerTimestampsAdapter({
    value: values[fieldName],
    minDate: fieldName === 'assignmentsEffectiveAsOf' ? null : values.assignmentsEffectiveAsOf,
    maxDate: fieldName === 'assignmentsEffectiveAsOf' ? values.assignmentsEffectiveUntil : null,
    boundary: fieldName === 'assignmentsEffectiveAsOf' ? 'start' : 'end',
    onChange: useCallback(
      (value: UnixTimestampInSeconds | null) => setFieldValue(fieldName, value),
      [fieldName, setFieldValue],
    ),
  });

  return (
    <DatePicker
      {...datePickerProps}
      isClearable
      clearButtonLabel={<FormattedMessage defaultMessage="Reset date to indefinite" />}
      dateFormat="yyyy-MM-dd"
      placeholder={formatMessage({ defaultMessage: 'Indefinite' })}
    />
  );
});
