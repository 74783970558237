import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useSnackbars } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { CompaniesApiClient } from '@amalia/tenants/companies/api-client';

import { companiesMutationKeys, companiesQueryKeys } from './queries.keys';

export const useCompany = ({ enabled }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: companiesQueryKeys.currentCompany(),
    queryFn: CompaniesApiClient.getCompany,
    refetchOnWindowFocus: false,
    enabled,
  });

export const useCurrentCompany = () => {
  const { data, ...rest } = useCompany();

  assert(data, 'useCurrentCompany: data should be defined');

  return {
    data,
    ...rest,
  };
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();
  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: companiesMutationKeys.updateCompany(),
    mutationFn: (params: Parameters<typeof CompaniesApiClient.updateCompany>) =>
      CompaniesApiClient.updateCompany(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: companiesQueryKeys.currentCompany() });
    },
    throwOnError: false,
    onError: (err) => {
      snackError(err.message);
    },
  });
};
