import { hashKey } from '@tanstack/react-query';
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';

const isStateFn = <TState>(setStateAction: unknown): setStateAction is (prevState?: TState) => TState =>
  typeof setStateAction === 'function';

// Helper function to get a value from localStorage and validate it against a schema.
const getFromLocalStorage = <TState>(
  key: string,
  validationSchema?: { validateSync: (value: unknown, options?: { strict?: boolean }) => TState | undefined },
) => {
  const localStorageState = localStorage.getItem(key);
  try {
    return localStorageState
      ? validationSchema
        ? validationSchema.validateSync(JSON.parse(localStorageState), { strict: true })
        : (JSON.parse(localStorageState) as TState)
      : undefined;
  } catch (err) {
    return undefined;
  }
};

export function useStateInLocalStorage<TState>(
  key: unknown[] | string,
  initialState: TState | ((stateFromLocalStorage?: TState) => TState),
  validationSchema?: { validateSync: (value: unknown, options?: { strict?: boolean }) => TState | undefined },
): [state: TState, setState: Dispatch<SetStateAction<TState>>, clear: () => void];

export function useStateInLocalStorage<TState = undefined>(
  key: unknown[] | string,
  initialState?: undefined,
  validationSchema?: { validateSync: (value: unknown, options?: { strict?: boolean }) => TState | undefined },
): [state: TState | undefined, setState: Dispatch<SetStateAction<TState | undefined>>, clear: () => void];

export function useStateInLocalStorage<TState>(
  /** Key in localStorage. Will be hashed using react-query's hashing method. */
  key: unknown[] | string,
  /** Initial state. If there is a value in localStorage, it is ignored. You can pass a function to get the value from localStorage (if any) and return the computed state. */
  initialState?: TState | ((stateFromLocalStorage?: TState) => TState),
  /**
   * Optional validation schema. If the value in localStorage does not match the schema, it will be ignored.
   * You can use this to invalidate the localStorage after a schema change.
   */
  validationSchema?: { validateSync: (value: unknown, options?: { strict?: boolean }) => TState | undefined },
): [state: TState | undefined, setState: Dispatch<SetStateAction<TState | undefined>>, clear: () => void] {
  const hashedKey = hashKey(Array.isArray(key) ? key : [key]);

  const [state, setState] = useState(() => {
    // Get the state from localStorage if any. If it doesn't match the schema, it will be undefined (as if there was no value).
    const stateInLocalStorage = getFromLocalStorage(hashedKey, validationSchema);
    return isStateFn<TState>(initialState) ? initialState(stateInLocalStorage) : (stateInLocalStorage ?? initialState);
  });

  // When the state changes, persist it in local storage.
  useEffect(() => {
    localStorage.setItem(hashedKey, JSON.stringify(state));
  }, [state, hashedKey]);

  const handleClear = useCallback(() => localStorage.removeItem(hashedKey), [hashedKey]);

  return [state, setState, handleClear];
}
