import { type Period } from '@amalia/payout-definition/periods/types';
import { type Plan, type Rule } from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

export const plansQueryKeys = {
  all: () => ['plans'] as const,
  allLists: () => [...plansQueryKeys.all(), 'list'] as const,
  list: (filters?: { userIds?: UserContract['id'][]; planIds?: Plan['id'][]; periodId?: Period['id'] }) =>
    [...plansQueryKeys.all(), 'list', filters] as const,
  template: {
    all: () => [...plansQueryKeys.all(), 'template'] as const,
    ofPlan: (planId: Plan['id']) => [...plansQueryKeys.template.all(), planId] as const,
  },
  details: (planId: Plan['id']) => [...plansQueryKeys.all(), 'details', planId] as const,
  isDeletable: (planId: Plan['id']) => [...plansQueryKeys.details(planId), 'is-deletable'] as const,
} as const;

export const plansMutationKeys = {
  all: () => ['plans'] as const,
  create: () => [...plansMutationKeys.all(), 'create'] as const,
  duplicate: () => [...plansMutationKeys.all(), 'duplicate'] as const,
  archive: () => [...plansMutationKeys.all(), 'archive'] as const,
  delete: () => [...plansMutationKeys.all(), 'delete'] as const,
  anyPatchVisibility: () => [...plansMutationKeys.all(), 'any-patch-visibility'] as const,
  patchVisibility: (planId: Plan['id']) => [...plansMutationKeys.anyPatchVisibility(), planId] as const,
  patch: (planId: Plan['id']) => [...plansMutationKeys.all(), 'patch', planId] as const,
  importFromOtherPlan: (planId: Plan['id']) => [...plansMutationKeys.all(), 'import-from-other-plan', planId] as const,
  computeTemplate: (planId: Plan['id']) => [...plansMutationKeys.all(), 'compute-template', planId] as const,
} as const;

export const rulesV2QueryKeys = {
  all: () => ['rules-v2'] as const,
  configurations: {
    all: () => [...rulesV2QueryKeys.all(), 'configurations'] as const,
    ofPlan: {
      all: (planId: Plan['id']) => [...rulesV2QueryKeys.configurations.all(), 'of-plan', planId] as const,
      list: (planId: Plan['id']) => [...rulesV2QueryKeys.configurations.ofPlan.all(planId), 'list'] as const,
      details: (planId: Plan['id'], ruleId: Rule['id']) =>
        [...rulesV2QueryKeys.configurations.ofPlan.all(planId), 'details', ruleId] as const,
    } as const,
  } as const,
} as const;

export const rulesV2MutationKeys = {
  all: () => ['rules-v2'] as const,
  configurations: {
    all: () => [...rulesV2MutationKeys.all(), 'configurations'] as const,
    updateRuleAssignment: () => [...rulesV2MutationKeys.configurations.all(), 'update-rule-assignment'] as const,
  } as const,
} as const;
