import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type RecordContent } from '@amalia/data-capture/connectors/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export const stringMatch = new AmaliaFunctionDefault<[string, RecordContent[], string[]?], boolean>({
  name: AmaliaFunctionKeys.MATCH,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 2,
  description:
    'Return true if lookupValue matches searchKey in lookupArray. If you are using Team Hierarchy on Amalia, please use MATCH_USERS_ASSIGNED function',

  exec: (lookupValue, lookupArray, searchKeys) => {
    if (!lookupValue) {
      return false;
    }

    const keys = searchKeys || ['id'];
    for (const object of lookupArray) {
      let hasMatch = true;
      for (const key of keys) {
        hasMatch &&= lookupValue.toString().includes(object[key] as string);
      }
      if (hasMatch) {
        return true;
      }
    }
    return false;
  },

  // + 'Loop through the `lookupArray`. Returns true if a value of one of '
  // + 'the `searchKeys` properties of one item of this array is equal or included inside the `lookupValue`.'
  params: [
    { name: 'lookupValue', description: 'Value to look up: variables, fields, properties, string, keywords' },
    { name: 'lookupArray', description: 'Array to search in: array, keywords such as team.employees' },
    { name: 'searchKeys', description: 'Keys to look up such as ["externalId"]', defaultValue: '["id"]' },
  ],

  examples: [
    {
      desc: "Returns true if Opportunity Owner ID matches one of the user's team members.",
      formula: 'MATCH(opportunity.ownerId, user.teamMembers, ["externalId"])',
    },
    {
      desc: 'Returns true.',
      formula:
        'MATCH("Jean Dupont", [{ firstName: "Jean", lastName: "Dupont" }], ["firstName", "lastName"])' as AmaliaFormula,
      result: true,
    },
  ],
});
