export const directoryMatchingFields = [
  'firstName',
  'lastName',
  'email',
  'hrisId',
  'externalId',
  'language',
  'currency',
] as const;

export type DirectoryMatchingField = (typeof directoryMatchingFields)[number];
