import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineDayjsInput } from '../dates.types';

export const datesDaysTo = new AmaliaFunctionDefault<[ComputeEngineDayjsInput, ComputeEngineDayjsInput], number>({
  name: AmaliaFunctionKeys.daysTo,
  category: AmaliaFunctionCategory.DATES,

  exec: (date1, date2): number => dayjs(date2, 'X').diff(dayjs(date1, 'X'), 'days'),

  nbParamsRequired: 2,

  description: 'Return the number of days between two dates',

  params: [
    {
      name: 'date1',
      description:
        'Date to start counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
    {
      name: 'date2',
      description:
        'Date to end counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
  ],

  examples: [
    {
      desc: 'Returns 5',
      formula: 'daysTo(toDate("2023-05-01"), toDate("2023-05-06"))' as AmaliaFormula,
      result: 5,
    },
    {
      desc: 'Returns 6',
      formula: 'daysTo(toDate("2023-04-28"), toDate("2023-05-04"))' as AmaliaFormula,
      result: 6,
    },
    {
      desc: 'Returns the number of days between the statement start date and the Close Date.',
      formula: 'daysTo(statementPeriod.startDate, opportunity.closeDate)' as AmaliaFormula,
    },
  ],
});
