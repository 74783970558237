import { memo } from 'react';

export const IconDataPayfit2 = memo(function PayfitIcon({ size }: { readonly size: number }) {
  return (
    <svg
      height={size}
      viewBox="0 0 50 50"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-616.000000, -188.000000)">
        <g transform="translate(436.000000, 188.000000)">
          <g transform="translate(180.000000, 0.000000)">
            <circle
              cx="24.691358"
              cy="24.691358"
              fill="#0864CF"
              r="24.691358"
            />
            <path
              d="M24.0640687,21.8325014 L29.6279129,21.8325014 C30.2541161,21.8325014 30.7617538,21.3318952 30.7617538,20.714366 C30.7617538,20.0968369 30.2541161,19.5962307 29.6279149,19.5962307 L22.0831613,19.5963092 C21.7664303,19.5963092 21.5096568,19.8494909 21.5096128,20.1618347 L21.5076546,33.49916 C21.5106172,33.5448022 21.5121042,33.5906487 21.5121042,33.6366507 C21.5121042,34.8098675 20.5476651,35.7609476 19.3579692,35.7609476 C18.1682733,35.7609476 17.2038342,34.8098675 17.2038342,33.6366507 C17.2041784,33.57335 17.2038695,33.5587364 17.2010142,33.5170161 L17.2010142,15.6330886 C17.2010142,15.4769236 17.329378,15.350319 17.4877365,15.3502907 C21.3527064,15.3497856 24.4103893,15.3497856 26.6607851,15.3503429 C29.1317602,15.3511391 29.1317602,15.3511391 29.6272519,15.3512983 C32.6330265,15.3512983 35.0696881,17.7542085 35.0696881,20.7183484 C35.0696881,23.6026853 32.7578301,25.9683661 29.8332737,26.0814159 L24.0640687,26.0814159 C23.9056877,26.0814159 23.7772945,25.9548011 23.7772945,25.7986139 L23.7772945,22.1153034 C23.7772945,21.9591162 23.9056877,21.8325014 24.0640687,21.8325014 Z M23.7792813,33.5500553 L23.7772945,28.5939887 C23.7772353,28.4378014 23.9055804,28.3111392 24.0639615,28.3110809 C24.0639972,28.3110809 24.064033,28.3110809 24.0640687,28.3110809 L29.9451025,28.3111867 C34.0659377,28.1444039 37.3333313,24.7953729 37.3333313,20.7143685 C37.3333313,16.6251006 34.053153,13.2720877 29.8962275,13.1144615 C29.8848707,13.1129804 25.0884495,13.1118638 15.5069641,13.1111118 L15.5069641,13.1111118 C15.1902034,13.1111118 14.9333325,13.3642977 14.9333325,13.6766708 C14.9333325,13.6766989 14.9333325,13.6767271 14.9333325,13.6767552 L14.9347744,33.5240844 C14.9338137,33.5615438 14.9333325,33.599068 14.9333325,33.6366509 C14.9333325,36.0464616 16.9143099,37.9999993 19.3579692,37.9999993 C21.8016285,37.9999993 23.7826059,36.0464616 23.7826059,33.6366509 C23.7826059,33.5940069 23.781747,33.5704832 23.7792813,33.5500553 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
