import { useAuth0 } from '@auth0/auth0-react';
import { IconDots, IconLogout, IconSettings, IconUser } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { formatUserFullName } from '@amalia/core/types';
import { Avatar, MenuDropdown, Tooltip, Typography } from '@amalia/design-system/components';
import { useTextOverflows } from '@amalia/ext/react/hooks';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext, useAuthenticatedContext } from '@amalia/kernel/auth/state';
import { config } from '@amalia/kernel/config/client';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';
import { UserApiClient } from '@amalia/tenants/users/api-client';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';

import { type PageIdentifier } from '../left-bar-menu/useNavigationItems';

import * as styles from './LeftBarUser.styles';

export type LeftBarUserProps = {
  readonly currentPage?: PageIdentifier;
};

export const LeftBarUser = memo(function LeftBarUser({ currentPage }: LeftBarUserProps) {
  const { authenticatedContext } = useAuthenticatedContext();
  const { logout: auth0logout } = useAuth0();
  const { ref: overflowRef, doesTextOverflow } = useTextOverflows();
  const { formatMessage } = useIntl();
  const { user } = authenticatedContext;

  const ability = useAbilityContext();
  const { isFeatureEnabled: isPaymentsLockEnabled } = useFeatureFlag(CompanyFeatureFlags.PAYMENTS_LOCK);
  const canAccessCompanySettings =
    ability.can(ActionsEnum.modify, SubjectsEnum.Workflow) ||
    ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) ||
    (isPaymentsLockEnabled && ability.can(ActionsEnum.modify_settings, SubjectsEnum.Company)) ||
    ability.can(ActionsEnum.view_settings, SubjectsEnum.Company);

  const handleLogout = useCallback(async () => {
    await UserApiClient.logout();
    await auth0logout({
      logoutParams: {
        returnTo: config.auth0.redirectUri,
      },
    });
  }, [auth0logout]);

  return (
    <div
      css={styles.leftBarUser(!!authenticatedContext.impersonation)}
      data-testid="user-navbar-box"
    >
      <Avatar
        size={Avatar.Size.XSMALL}
        user={authenticatedContext.user}
      />

      <Tooltip
        title={authenticatedContext.user.company.name}
        content={
          authenticatedContext.impersonation ? (
            <FormattedMessage
              defaultMessage="You are impersonating: {userName} ({email})"
              values={{
                userName: formatUserFullName(authenticatedContext.user),
                email: authenticatedContext.user.email,
              }}
            />
          ) : doesTextOverflow ? (
            <FormattedMessage
              defaultMessage="{userName} ({email})"
              values={{
                userName: formatUserFullName(user),
                email: user.email,
              }}
            />
          ) : (
            user.email
          )
        }
      >
        <Typography
          ref={overflowRef}
          css={styles.userName}
          variant={Typography.Variant.BODY_SMALL_MEDIUM}
        >
          {formatUserFullName(user)}
        </Typography>
      </Tooltip>

      <MenuDropdown
        content={
          <Fragment>
            <MenuDropdown.ItemLink
              icon={<IconUser />}
              label={<FormattedMessage defaultMessage="Account" />}
              to={generatePath(tenantUsersRoutes.PROFILE_PAGE)}
            />

            {canAccessCompanySettings ? (
              <MenuDropdown.ItemLink
                icon={<IconSettings />}
                isActive={currentPage === 'companySettings'}
                label={<FormattedMessage defaultMessage="Company settings" />}
                to={generatePath(routes.COMPANY_SETTINGS)}
              />
            ) : null}

            <MenuDropdown.Item
              icon={<IconLogout />}
              label={<FormattedMessage defaultMessage="Log out" />}
              onClick={handleLogout}
            />
          </Fragment>
        }
      >
        <MenuDropdown.IconButton
          icon={<IconDots />}
          label={formatMessage({ defaultMessage: 'User menu' })}
        />
      </MenuDropdown>
    </div>
  );
});
