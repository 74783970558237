import { useTheme, ClassNames } from '@emotion/react';
import { IconAlertTriangle, IconCircleCheck, IconExclamationCircle, IconInfoCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ReactNode,
  cloneElement,
  memo,
  type ComponentPropsWithoutRef,
  forwardRef,
  type ForwardedRef,
} from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconElement } from '../../general/icons/types';
import { Typography } from '../../general/typography/Typography';

import * as styles from './AlertBanner.styles';
import { AlertBannerVariant } from './AlertBanner.types';

const DEFAULT_VARIANT_ICON_MAPPING: Record<AlertBannerVariant, TablerIconElement> = {
  [AlertBannerVariant.INFO]: <IconInfoCircle />,
  [AlertBannerVariant.WARNING]: <IconAlertTriangle />,
  [AlertBannerVariant.ERROR]: <IconExclamationCircle />,
  [AlertBannerVariant.SUCCESS]: <IconCircleCheck />,
};

export type AlertBannerProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Alert variant. */
      variant?: AlertBannerVariant;
      /** Should render as inline-flex. */
      inline?: boolean;
      /** Should show a border (useful when using the Banner against the app background.) */
      withBorder?: boolean;
      /** Align icon vertically. Useful when the content is not just text (like a button). */
      alignCenter?: boolean;
      /** Alert message. Text typography is handled by the component. */
      children: ReactNode;
    },
  ]
>;

const AlertBannerForwardRef = forwardRef(function AlertBanner(
  {
    variant = AlertBannerVariant.INFO,
    inline = false,
    withBorder = false,
    alignCenter = false,
    className,
    children,
    ...props
  }: AlertBannerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme();

  return (
    <div
      {...props}
      ref={ref}
      css={styles.alertBanner}
      className={clsx(className, variant, {
        [styles.INLINE_CLASSNAME]: inline,
        [styles.WITH_BORDER_CLASSNAME]: withBorder,
        [styles.ALIGN_CENTER_CLASSNAME]: alignCenter,
      })}
    >
      <ClassNames>
        {({ css }) =>
          cloneElement(DEFAULT_VARIANT_ICON_MAPPING[variant], {
            width: 16, // Actual size of icon.
            height: alignCenter ? 16 : theme.ds.typographies.bodySmallMedium.lineHeight, // Align-center with first line of text.
            color: styles.getIconColor(theme, variant),
            className: css`
              flex: none;
            `,
          })
        }
      </ClassNames>

      <Typography
        as="div"
        variant={Typography.Variant.BODY_SMALL_MEDIUM}
      >
        {children}
      </Typography>
    </div>
  );
});

export const AlertBanner = Object.assign(memo(AlertBannerForwardRef), {
  Variant: AlertBannerVariant,
});
