import { css } from '@emotion/react';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import { memo, type MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { match } from 'ts-pattern';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { IconButton, IconButtonSize } from '@amalia/design-system/components';
import { HidableElementVisibility } from '@amalia/payout-definition/plans/types';

const DisplayStatusToIcon = {
  [HidableElementVisibility.AVAILABLE]: <IconEyeClosed />,
  [HidableElementVisibility.ON_DISPLAY]: <IconEye />,
};

type TokenVisibilityIconButtonProps = {
  readonly 'data-testid'?: string;
  readonly displayStatus: HidableElementVisibility;
  readonly isIconAlwaysDisplayed?: boolean;
  readonly onUpdateDisplayStatus: (event: MouseEvent) => Promise<void> | void;
  readonly tokenType?: TokenType;
  readonly iconSize?: IconButtonSize;
  readonly isForecastView: boolean;
};

const hiddenStyle = css`
  visibility: hidden;
`;

export const TokenVisibilityIconButton = memo(function TokenVisibilityIconButton({
  'data-testid': dataTestId,
  displayStatus,
  isIconAlwaysDisplayed = false,
  onUpdateDisplayStatus,
  tokenType,
  iconSize = IconButtonSize.SMALL,
  isForecastView,
}: TokenVisibilityIconButtonProps) {
  const { formatMessage } = useIntl();

  const statementOrForecast = isForecastView
    ? formatMessage({ defaultMessage: 'forecast' })
    : formatMessage({ defaultMessage: 'statement' });

  const label = match({ tokenType, displayStatus })
    .with({ tokenType: TokenType.FILTER, displayStatus: HidableElementVisibility.AVAILABLE }, () =>
      formatMessage(
        { defaultMessage: 'Shown only on designer{br}Click to show on {statementOrForecast}' },
        { statementOrForecast },
      ),
    )
    .with({ tokenType: TokenType.FILTER, displayStatus: HidableElementVisibility.ON_DISPLAY }, () =>
      formatMessage(
        { defaultMessage: 'Shown on {statementOrForecast}{br}Click to show only in designer' },
        { statementOrForecast },
      ),
    )
    .with({ displayStatus: HidableElementVisibility.AVAILABLE }, () =>
      formatMessage({ defaultMessage: 'Hidden on {statementOrForecast}{br}Click to show it' }, { statementOrForecast }),
    )
    .otherwise(() =>
      formatMessage({ defaultMessage: 'Shown on {statementOrForecast}{br}Click to hide it' }, { statementOrForecast }),
    );

  return (
    <IconButton
      css={displayStatus === HidableElementVisibility.ON_DISPLAY && !isIconAlwaysDisplayed && hiddenStyle}
      data-testid={dataTestId}
      icon={DisplayStatusToIcon[displayStatus]}
      label={label}
      size={iconSize}
      onClick={onUpdateDisplayStatus}
    />
  );
});
