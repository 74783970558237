import { AbilityBuilder, type ConditionsMatcher, fieldPatternMatcher, PureAbility as AbilityType } from '@casl/ability';

import { isEnum } from '@amalia/ext/typescript';
import { type AuthenticatedContext } from '@amalia/kernel/auth/types';
import { AmaliaRole, userReadOnlyRoleMatcher, UserRole } from '@amalia/tenants/users/types';

import { type Ability, type DefinePermissions } from '../types/abilities';
import { SubjectsEnum } from '../types/subjects';
import { type UserRoleForAccessControl } from '../types/user';

import { analyticsHomeAbilityDefinitions } from './analytics-home/definitions';
import { apiKeysAbilityDefinitions } from './api-keys/definitions';
import { auditAbilityDefinitions } from './audit/definitions';
import { commentThreadMessagesAbilityDefinitions } from './comment-thread-messages/definitions';
import { companiesAbilityDefinitions } from './companies/definitions';
import { customReportsAbilityDefinitions } from './custom-reports/definitions';
import { dashboardPaymentsAbilityDefinitions } from './dashboard-payments/definitions';
import { dashboardsv2AbilityDefinitions } from './dashboards-v2/definitions';
import { dataAbilityDefinitions } from './data/definitions';
import { dataConnectorsAbilityDefinitions } from './data-connectors/definitions';
import { dataExportsAbilityDefinitions } from './data-exports/definitions';
import { foldersAbilityDefinitions } from './folders/definitions';
import { forecastedStatementsAbilityDefinitions } from './forecasted-statements/definitions';
import { overwriteRecapsAbilityDefinitions } from './overwrite-recaps/definitions';
import { paymentLocksAbilityDefinitions } from './payment-locks/definitions';
import { paymentsAbilityDefinitions } from './payments/definitions';
import { planAgreementsAbilityDefinitions } from './plan-agreements/definitions';
import { planAssignmentsAbilityDefinitions } from './plan-assignments/definitions';
import { plansAbilityDefinitions } from './plans/definitions';
import { presetReportsAbilityDefinitions } from './preset-reports/definitions';
import { quotasAbilityDefinitions } from './quotas/definitions';
import { repHomeAbilityDefinitions } from './rep-home/definitions';
import { statementsAbilityDefinitions } from './statements/definitions';
import { superadminAbilityDefinitions } from './superadmin/definitions';
import { superadminCalculationsAbilityDefinitions } from './superadmin-calculations/definitions';
import { superadminCompaniesAbilityDefinitions } from './superadmin-companies/definitions';
import { superadminConnectorsAbilityDefinitions } from './superadmin-connectors/definitions';
import { superadminPlansAbilityDefinitions } from './superadmin-plans/definitions';
import { superadminRefreshmentsAbilityDefinitions } from './superadmin-refreshments/definitions';
import { superadminSettingsAbilityDefinitions } from './superadmin-settings/definitions';
import { superadminStatementsAbilityDefinitions } from './superadmin-statements/definitions';
import { superadminUsersAbilityDefinitions } from './superadmin-users/definitions';
import { teamsAbilityDefinitions } from './teams/definitions';
import { userDataAbilityDefinitions } from './user-data/definitions';
import { userProfilesAbilityDefinitions } from './user-profiles/definitions';
import { usersAbilityDefinitions } from './users/definitions';
import { workflowsAbilityDefinitions } from './workflows/definitions';

const lambdaMatcher: ConditionsMatcher<unknown> = (matchConditions) =>
  // FIXME: Sylvain volunteered.
  // @ts-expect-error -- Predicate is a keyword from us.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  matchConditions.predicate || matchConditions;

const abilityDefinitions: Record<SubjectsEnum, Partial<Record<UserRoleForAccessControl, DefinePermissions>>> = {
  [SubjectsEnum.ApiKeys]: apiKeysAbilityDefinitions,
  [SubjectsEnum.Audit]: auditAbilityDefinitions,
  [SubjectsEnum.CommentThreadMessage]: commentThreadMessagesAbilityDefinitions,
  [SubjectsEnum.Company]: companiesAbilityDefinitions,
  [SubjectsEnum.CustomReport]: customReportsAbilityDefinitions,
  [SubjectsEnum.DashboardPayments]: dashboardPaymentsAbilityDefinitions,
  [SubjectsEnum.DashboardsV2]: dashboardsv2AbilityDefinitions,
  [SubjectsEnum.Data]: dataAbilityDefinitions,
  [SubjectsEnum.Data_Connector]: dataConnectorsAbilityDefinitions,
  [SubjectsEnum.Data_Export]: dataExportsAbilityDefinitions,
  [SubjectsEnum.Folders]: foldersAbilityDefinitions,
  [SubjectsEnum.Forecasted_Statement]: forecastedStatementsAbilityDefinitions,
  [SubjectsEnum.Overwrite_Recap]: overwriteRecapsAbilityDefinitions,
  [SubjectsEnum.Payment]: paymentsAbilityDefinitions,
  [SubjectsEnum.PaymentLock]: paymentLocksAbilityDefinitions,
  [SubjectsEnum.Plan]: plansAbilityDefinitions,
  [SubjectsEnum.PlanAgreements]: planAgreementsAbilityDefinitions,
  [SubjectsEnum.Plan_Assignment]: planAssignmentsAbilityDefinitions,
  [SubjectsEnum.PresetReport]: presetReportsAbilityDefinitions,
  [SubjectsEnum.Quota]: quotasAbilityDefinitions,
  [SubjectsEnum.RepHome]: repHomeAbilityDefinitions,
  [SubjectsEnum.AnalyticsHome]: analyticsHomeAbilityDefinitions,
  [SubjectsEnum.Statement]: statementsAbilityDefinitions,
  [SubjectsEnum.Team]: teamsAbilityDefinitions,
  [SubjectsEnum.User]: usersAbilityDefinitions,
  [SubjectsEnum.Workflow]: workflowsAbilityDefinitions,
  [SubjectsEnum.UserProfile]: userProfilesAbilityDefinitions,
  [SubjectsEnum.UserData]: userDataAbilityDefinitions,
  [SubjectsEnum.SuperAdmin]: superadminAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_calculations]: superadminCalculationsAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_refreshments]: superadminRefreshmentsAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_settings]: superadminSettingsAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_connectors]: superadminConnectorsAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_statements]: superadminStatementsAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_users]: superadminUsersAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_plans]: superadminPlansAbilityDefinitions,
  [SubjectsEnum.SuperAdmin_companies]: superadminCompaniesAbilityDefinitions,
} as const;

function defineAbilitiesForRole(
  role: UserRoleForAccessControl,
  authenticatedContext: AuthenticatedContext,
  builder: AbilityBuilder<AbilityType>,
) {
  Object.values(SubjectsEnum).forEach((subject) => {
    abilityDefinitions[subject][role]?.(authenticatedContext, builder);
  });
}

export function defineAbilityFor(authenticatedContext?: AuthenticatedContext): Ability {
  const builder = new AbilityBuilder<AbilityType>(AbilityType);

  if (!authenticatedContext?.user) {
    return builder.build({ conditionsMatcher: lambdaMatcher });
  }

  const { user, meta, impersonation } = authenticatedContext;

  const appliedRole = impersonation
    ? user.role in userReadOnlyRoleMatcher
      ? userReadOnlyRoleMatcher[user.role]
      : undefined
    : user.role;

  if (user.clearedAt) {
    defineAbilitiesForRole('DEACTIVATED_USER', authenticatedContext, builder);
  } else if (appliedRole && isEnum(appliedRole, UserRole)) {
    defineAbilitiesForRole(appliedRole, authenticatedContext, builder);
  } else {
    throw new Error(`Trying to use unknown role "${user.role}"`);
  }

  // Add rights for impersonating to impersonated user rights
  if (meta?.isSuperAdmin) {
    defineAbilitiesForRole('AMALIA_ADMIN', authenticatedContext, builder);

    // If email is part of the whitelist for amalia tech admins, add the other cumulative role
    if (meta.amaliaRoles?.includes(AmaliaRole.TECH_ADMIN)) {
      defineAbilitiesForRole('AMALIA_TECH_ADMIN', authenticatedContext, builder);
    }
  }

  return builder.build({ conditionsMatcher: lambdaMatcher, fieldMatcher: fieldPatternMatcher });
}
