import { type Selection, type ScaleLinear, type ScaleBand } from 'd3';

import { amaliaTheme, colors } from '@amalia/ext/mui/theme';
import { assert } from '@amalia/ext/typescript';

import { type CommonChartStyleProps } from './chart.styles';
import {
  type CardinalChartRow,
  type CardinalChartRowWithMultipleValues,
  type CardinalChartRowWithNumberOnly,
} from './chart.types';

export enum ChartModifierKeys {
  TOOLTIP_SCATTER_PLOT = 'TOOLTIP_SCATTER_PLOT',
  HIGHLIGHTED_POINT = 'HIGHLIGHTED_POINT',
  LABEL = 'LABEL',
}

const helpers = {
  // eslint-disable-next-line consistent-return
  tooltipCallout: (g: Selection<SVGGElement, unknown, any, unknown>, value: unknown) => {
    if (!value) {
      g.style('display', 'none');
      return;
    }

    g.style('display', null).style('pointer-events', 'none').style('font', '12px sans-serif');

    const path = g
      .selectAll('path')
      .data([null])
      .join('path')
      .attr('fill', colors.black)
      .attr('opacity', 0.8)
      .attr('stroke', 'none');

    const text = g
      .selectAll('text')
      .data([null])
      .join('text')
      .call((childText) =>
        childText
          .selectAll('tspan')
          .data(`${value}`.split(/\n/u))
          .join('tspan')
          .attr('fill', amaliaTheme.palette.common.white)
          .attr('x', 0)
          .attr('y', (_, i: number) => `${i * 1.1}em`)
          .style('font-weight', 'bold')
          .text((d: string) => d),
      );

    const { y, width: w, height: h } = (text.node() as SVGTextElement).getBBox();

    text.attr('transform', `translate(${-w / 2},${15 - y})`);
    path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
  },
};

export type ChartModifierInput = {
  svg: Selection<any, unknown, any, any>;
  y: ScaleLinear<number, number>;
  x: ScaleBand<string> | ScaleLinear<number, number>;
  data: (CardinalChartRow | CardinalChartRowWithMultipleValues | CardinalChartRowWithNumberOnly)[];
  margin: number;
  styles: CommonChartStyleProps;
  height: number;
  width: number;
  classes: object;
};

export type ChartModifierParams = {
  // TOOLTIP_SCATTER_PLOT
  tooltipFormatFn?: (d: unknown) => string;

  // HIGHLIGHTED_POINT
  point?: CardinalChartRowWithNumberOnly;
  tooltipText?: string;

  // AXIS
  // max of axis
  yMax?: number;
  // number of axis (2+) to compute its position
  axisNumber?: number;

  forceFillColor?: string;
};

export type ChartModifierFunction = (input: ChartModifierInput) => void;
export type ChartModifierCaller = (params: ChartModifierParams) => ChartModifierFunction;

export const ChartModifiers: Record<ChartModifierKeys, ChartModifierCaller> = {
  [ChartModifierKeys.TOOLTIP_SCATTER_PLOT]:
    ({ tooltipFormatFn, forceFillColor }) =>
    ({ svg, x, y, data, margin, styles }: ChartModifierInput) => {
      const divTooltip = svg.append('g');

      svg
        .selectAll('dot')
        .data(data)
        .enter()
        .append('circle')
        .attr('r', (d) => {
          const pointsWithSameAbsAndHigherValue = data.find(
            (dp) => dp.x === d.x && (dp as CardinalChartRow).value > (d as CardinalChartRow).value,
          );
          // Only print the data point with the higher value when multiple points have the same abs
          return pointsWithSameAbsAndHigherValue ? 0 : 4;
        })
        .attr('stroke', amaliaTheme.palette.common.white)
        .attr('fill', forceFillColor || colors[`${styles.color || 'primary'}-500` as keyof typeof colors])
        .attr('stroke-width', 1.5)
        .attr('cx', (d) => ((x as ScaleLinear<number, number>)(d.x as number) || 0) + (margin || 0) * 0.5)
        .attr('cy', (d) => y((d as CardinalChartRow).value) || 0)
        .on(
          'mouseover',
          (_e: unknown, d: CardinalChartRow | CardinalChartRowWithMultipleValues | CardinalChartRowWithNumberOnly) => {
            divTooltip
              .attr(
                'transform',
                `translate(${((x as ScaleLinear<number, number>)(d.x as number) || 0) + (margin || 0) * 0.5},${
                  (y((d as CardinalChartRow).value) || 0) + 10
                })`,
              )
              .call(helpers.tooltipCallout, tooltipFormatFn?.(d) || `${d.x}: ${(d as CardinalChartRow).value}`);
          },
        )
        .on('mouseout', () => divTooltip.call(helpers.tooltipCallout, null));

      return svg;
    },
  [ChartModifierKeys.HIGHLIGHTED_POINT]:
    ({ point, tooltipText, forceFillColor }) =>
    ({ svg, x, y, margin, styles }) => {
      const divTooltip = svg.append('g');

      assert(point);

      const xPosition = (x as ScaleLinear<number, number>)(point.x);

      svg
        .append('circle')
        .attr('r', 6)
        .attr('stroke', forceFillColor || colors[`${styles.color || 'primary'}-500` as keyof typeof colors])
        .attr('fill', amaliaTheme.palette.common.white)
        .attr('stroke-width', 1.5)
        .attr('cx', (xPosition || 0) + (margin || 0) * 0.5)
        .attr('cy', y(point.value) || 0)
        .on('mouseover', () => {
          divTooltip
            .attr('transform', `translate(${(xPosition || 0) + (margin || 0) * 0.5},${(y(point.value) || 0) + 10})`)
            .call(helpers.tooltipCallout, tooltipText);
        })
        .on('mouseout', () => {
          divTooltip.call(helpers.tooltipCallout, null);
        });

      return svg;
    },
  [ChartModifierKeys.LABEL]:
    ({ point }) =>
    ({ svg }) => {
      assert(point);

      svg
        .append('text')
        .attr('x', point.x)
        .attr('y', point.value)
        .attr('text-anchor', 'middle')
        .text(point.valueLabel ?? '');
    },
};
