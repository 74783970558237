import { UTCDateMini } from '@date-fns/utc';
import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { toTimestamp, type UnixTimestampInSeconds } from '@amalia/ext/dates';

import { type DatePickerBaseProps } from '../DatePickerBase';

/**
 * Our DatePicker components use Dates but in certain cases we need to work with timestamps.
 * Use this adapter to convert between the two easily.
 *
 * Note: I didn't need to make it work with ranges so it only handles single values.
 */
export const useDatePickerTimestampsAdapter = ({
  value,
  onChange,
  minDate,
  maxDate,
  boundary,
}: {
  /** Current value. */
  value?: UnixTimestampInSeconds | null;
  /** Change handler. */
  onChange?: (value: UnixTimestampInSeconds | null) => void;
  /** Min date (inclusive). */
  minDate?: UnixTimestampInSeconds | null;
  /** Max date (inclusive). */
  maxDate?: UnixTimestampInSeconds | null;
  /** Reset value to start or end of day on change. */
  boundary?: 'end' | 'start';
}): Pick<DatePickerBaseProps<false>, 'maxDate' | 'minDate' | 'onChange' | 'value'> => {
  const dateValue = useMemo(() => (value ? new UTCDateMini(value * 1000) : null), [value]);
  const dateMinDate = useMemo(() => (minDate ? new UTCDateMini(minDate * 1000) : null), [minDate]);
  const dateMaxDate = useMemo(() => (maxDate ? new UTCDateMini(maxDate * 1000) : null), [maxDate]);

  const handleChange = useCallback(
    (newDate: Date | null) => {
      const utcDate = newDate ? new UTCDateMini(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()) : null;

      switch (boundary) {
        case 'end':
          return onChange?.(utcDate ? toTimestamp(endOfDay(utcDate)) : null);
        case 'start':
          return onChange?.(utcDate ? toTimestamp(startOfDay(utcDate)) : null);
        default:
          return onChange?.(utcDate ? toTimestamp(utcDate) : null);
      }
    },
    [boundary, onChange],
  );

  return {
    maxDate: dateMaxDate,
    minDate: dateMinDate,
    onChange: handleChange,
    value: dateValue,
  };
};
