import { type JSXElementConstructor, type ReactElement } from 'react';

import { assert } from '@amalia/ext/typescript';

import { type SelectOptionGroup } from '../../overlays/select-dropdown/SelectDropdown.types';

import { type FilterDatePickerProps } from './filter-date-picker/FilterDatePicker';
import {
  getFilterDatePickerEmptyValue,
  isEmptyFilterDatePickerValue,
} from './filter-date-picker/helpers/filterDatePickerValue';
import { type FilterSelectProps } from './filter-select/FilterSelect';
import { getFilterSelectEmptyValue, isEmptyFilterSelectValue } from './filter-select/helpers/filterSelectValue';
import { type FilterSelectOption } from './Filters.types';

export const FilterSelectSymbol = Symbol('FilterSelect');
export const FilterDatePickerSymbol = Symbol('FilterDatePicker');

export type FilterSymbol = typeof FilterDatePickerSymbol | typeof FilterSelectSymbol;

export type FilterSelectElementProps = FilterSelectProps<
  FilterSelectOption,
  boolean | undefined,
  boolean | undefined,
  SelectOptionGroup<FilterSelectOption>
>;

export type FilterSelectElement = ReactElement<
  FilterSelectElementProps,
  JSXElementConstructor<FilterSelectElementProps> & { readonly symbol: unique symbol }
>;

export type FilterDatePickerElementProps = FilterDatePickerProps<boolean | undefined>;

export type FilterDatePickerElement = ReactElement<
  FilterDatePickerElementProps,
  JSXElementConstructor<FilterDatePickerElementProps> & { readonly symbol: unique symbol }
>;

export type FilterElement = FilterDatePickerElement | FilterSelectElement | false | null | undefined;

export const getFilterType = (filter: unknown): FilterSymbol | undefined =>
  filter &&
  typeof filter === 'object' &&
  'type' in filter &&
  filter.type &&
  typeof filter.type === 'object' &&
  'symbol' in filter.type &&
  typeof filter.type.symbol === 'symbol'
    ? (filter.type.symbol as FilterSymbol)
    : undefined;

export const isFilterSelect = (filter: unknown): filter is FilterSelectElement =>
  getFilterType(filter) === FilterSelectSymbol;

export const isFilterDatePicker = (filter: unknown): filter is FilterDatePickerElement =>
  getFilterType(filter) === FilterDatePickerSymbol;

export const isEmptyFilterValue = (filter: unknown): boolean => {
  switch (getFilterType(filter)) {
    case FilterSelectSymbol: {
      assert(isFilterSelect(filter));
      return isEmptyFilterSelectValue(filter.props.value);
    }

    case FilterDatePickerSymbol: {
      assert(isFilterDatePicker(filter));
      return isEmptyFilterDatePickerValue(filter.props.value);
    }

    default:
      return true;
  }
};

export const clearFilterValue = (filter: unknown): void => {
  switch (getFilterType(filter)) {
    case FilterSelectSymbol: {
      assert(isFilterSelect(filter));
      filter.props.onChange(getFilterSelectEmptyValue(filter.props.isMultiple));
      break;
    }

    case FilterDatePickerSymbol: {
      assert(isFilterDatePicker(filter));
      filter.props.onChange?.(getFilterDatePickerEmptyValue(filter.props.selectsRange));
      break;
    }

    default:
      break;
  }
};
