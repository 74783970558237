import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { http } from '@amalia/core/http/client';
import {
  type PatchRuleRequest,
  type CreateRuleRequest,
  type Plan,
  type Rule,
} from '@amalia/payout-definition/plans/types';

const e = encodeURIComponent;

export class RulesApiClient {
  public static async update(rule: Rule): Promise<Rule> {
    const { data } = await http.put<Rule>(`/rules/${e(rule.id)}`, rule);
    return data;
  }

  public static async delete(ruleId: string): Promise<void> {
    await http.delete(`/rules/${e(ruleId)}`);
  }

  public static async list(): Promise<Rule[]> {
    const { data } = await http.get<Rule[]>(`/rules`);
    return data;
  }

  public static async create(createRuleRequest: CreateRuleRequest): Promise<Rule> {
    const { data } = await http.post<Rule>('/rules', createRuleRequest);
    return data;
  }

  public static async patch({ ruleId, ...request }: PatchRuleRequest & { ruleId: Rule['id'] }): Promise<Rule> {
    const { data } = await http.patch<Rule>(`/rules/${e(ruleId)}`, request);
    return data;
  }

  public static async findRuleIdWhereTokenIsUsed({
    planId,
    tokenType,
    tokenId,
  }: {
    planId: Plan['id'];
    tokenType: TokenType;
    tokenId: string;
  }): Promise<{ ruleId: string | null; fallbackRuleId: string | null }> {
    const { data } = await http.post<{ ruleId: string | null; fallbackRuleId: string | null }>(
      `/plans-v2/${planId}/find-ruleId-where-token-is-used`,
      {
        tokenType,
        tokenId,
      },
    );
    return data;
  }
}
