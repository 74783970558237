import { useCallback } from 'react';
import {
  type NavigateOptions,
  type To,
  // eslint-disable-next-line no-restricted-imports -- Allowed here only.
  useNavigate as useReactRouterNavigate,
  type NavigateFunction,
} from 'react-router-dom';

import { toPromise } from '@amalia/ext/typescript';

// Copied the interface from react-router-dom but removed the Promise.
interface SyncNavigateFunction {
  (to: To, options?: NavigateOptions): void;
  (delta: number): void;
}

// react-router decided to make it a Promise, so we wrap it here to avoid warnings.
export const useNavigate = () => {
  const navigate = useReactRouterNavigate();

  return useCallback(
    (...args: Parameters<NavigateFunction>) => {
      toPromise(navigate(...args)).catch(() => {});
    },
    [navigate],
  ) as SyncNavigateFunction;
};
