import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const IS_OPEN_CLASSNAME = 'open';
export const IS_COLLAPSIBLE_CLASSNAME = 'collapsible';

export const DropdownGroupContainer = styled.div`
  * + &,
  & + *:not(&) {
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
  }
`;

export const dropdownGroupTriggerContainer = css`
  position: relative;
`;

export const dropdownGroupTrigger = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 20px);

  padding: 3.5px 12px 3.5px 8px;

  color: ${theme.ds.colors.gray[700]};

  border-radius: ${theme.ds.borderRadiuses.squared};

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};

    position: relative;
    z-index: 1;
  }
`;

export const labelTooltipContainer = css`
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0; // For TextOverflow.
`;

export const iconLabelContainer = css`
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 0; // For TextOverflow.

  > svg {
    flex: none;
  }
`;

export const icon = css`
  flex: none;
`;

export const arrow = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[800]};
`;

export const actions = css`
  flex: none;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;

  > * {
    flex: none;
  }
`;

export const actionPlaceholder = css`
  width: 24px;
`;

export const actionContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;

  &.${IS_COLLAPSIBLE_CLASSNAME} {
    right: 34px;
  }
`;
