import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, forwardRef, type ForwardedRef, memo } from 'react';

import { type MergeAll, isEnum } from '@amalia/ext/typescript';

import { AvatarSize, type AvatarUser } from './Avatar.types';

const AVATAR_SIZE_TO_DIMENSIONS: Record<AvatarSize, number> = {
  [AvatarSize.XSMALL]: 16,
  [AvatarSize.SMALL]: 20,
  [AvatarSize.MEDIUM]: 30,
  [AvatarSize.LARGE]: 48,
};

export type AvatarProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'div'>, 'children'>,
    {
      /** Preset avatar size or size in px. */
      size?: AvatarSize | number;
      /** Image href. */
      user?: AvatarUser;
    },
  ]
>;

const AvatarForwardRef = forwardRef(function Avatar(
  { size = AvatarSize.MEDIUM, user, ...props }: AvatarProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const sizeInPx = isEnum(size, AvatarSize) ? AVATAR_SIZE_TO_DIMENSIONS[size] : size;

  return (
    <div
      {...props}
      ref={ref}
      role="img"
      css={[
        (theme) => css`
          width: ${sizeInPx}px;
          height: ${sizeInPx}px;
          border-radius: ${theme.ds.borderRadiuses.round};
          transition: ${theme.ds.transitions.default('height', 'width')};
          flex: none;
          overflow: hidden;

          background-image: none;
          background-color: ${theme.ds.colors.gray[200]};
          color: ${theme.ds.colors.gray[500]};
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        `,
        user?.pictureURL &&
          css`
            background-image: url(${user.pictureURL});
          `,
      ]}
    />
  );
});

export const Avatar = Object.assign(memo(AvatarForwardRef), {
  Size: AvatarSize,
});
