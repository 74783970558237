import { memo } from 'react';

import { Group } from '@amalia/design-system/components';

import { IconPlanVisibility } from './icons/IconPlanVisibility';

export type PlanNameWithVisibilityProps = {
  readonly planName: string;
  readonly isPlanHidden: boolean;
};

export const PlanNameWithVisibility = memo(function PlanNameWithVisibility({
  planName,
  isPlanHidden,
}: PlanNameWithVisibilityProps) {
  return (
    <Group
      align="center"
      gap={8}
    >
      <IconPlanVisibility isPlanHidden={isPlanHidden} />
      {planName}
    </Group>
  );
});
