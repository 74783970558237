import { groupBy } from 'lodash';
import { type ReactNode } from 'react';

import { type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';

export const groupVariableOptionsByType = (
  variablesList: Variable[],
  selectedTypes: VariableType[],
  // Pass a function to customize the label rendering
  customLabelRenderer?: (variable: Variable) => ReactNode | string,
) => {
  const availableVariables = variablesList
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .map((variable) => ({
      value: variable.id,
      searchString: variable.name,
      label: customLabelRenderer?.(variable) || variable.name,
      type: variable.type,
    }));

  // Group them by their type.
  const variableGroupedByType = groupBy(availableVariables, 'type') as Partial<
    Record<VariableType, typeof availableVariables>
  >;

  // Put them into option groups.
  return [
    selectedTypes.includes(VariableType.plan) && {
      label: 'Plan Variables',
      options: variableGroupedByType[VariableType.plan] ?? [],
    },
    selectedTypes.includes(VariableType.team) && {
      label: 'Team Variables',
      options: variableGroupedByType[VariableType.team] ?? [],
    },
    selectedTypes.includes(VariableType.user) && {
      label: 'User Variables',
      options: variableGroupedByType[VariableType.user] ?? [],
    },
    selectedTypes.includes(VariableType.statement) && {
      label: 'Statement Variables',
      options: variableGroupedByType[VariableType.statement] ?? [],
    },
    selectedTypes.includes(VariableType.object) && {
      label: 'Object Variables',
      options: variableGroupedByType[VariableType.object] ?? [],
    },
  ].filter(Boolean) as { label: string; options: { label: ReactNode | string; value: string }[] }[];
};

export const filterVariablesThatContainsKeywords = (variablesList: Variable[], keywords: string[] = []) =>
  variablesList.filter((v) => keywords.some((k) => v.formula?.toLowerCase().includes(k.toLowerCase())));

export const isTableFormulaPure = (formula: string) =>
  /^\[(?:\[(?:-?(?:\d+(?:\.\d+)?|Infinity)(?:\s?,\s?)?)+\](?:\s?,\s?)?)+\]$/u.exec(formula);
