import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { SideMenuActions } from './side-menu-actions/SideMenuActions';
import { SideMenuGroup } from './side-menu-group/SideMenuGroup';
import { SideMenuItem } from './side-menu-item/SideMenuItem';
import { SideMenuPlaceholder } from './side-menu-placeholder/SideMenuPlaceholder';
import { SideMenuContext, type SideMenuContextValue } from './SideMenu.context';
import { type SideMenuItemValue } from './SideMenu.types';

export type SideMenuProps<TValue extends SideMenuItemValue> = MergeAll<
  [ComponentPropsWithoutRef<'div'>, SideMenuContextValue<TValue>]
>;

const SideMenuBase = function SideMenu<TValue extends SideMenuItemValue = SideMenuItemValue>({
  value,
  onChange,
  children,
  ...props
}: SideMenuProps<TValue>) {
  const contextValue = useShallowObjectMemo<SideMenuContextValue<TValue>>({ value, onChange });

  return (
    // Need to cast here because we can't use generics in createContext.
    <SideMenuContext.Provider value={contextValue as unknown as SideMenuContextValue}>
      <div
        {...props}
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          border-right: 1px solid ${theme.ds.colors.gray[100]};
          padding: 16px 0;
        `}
      >
        {children}
      </div>
    </SideMenuContext.Provider>
  );
};

export const SideMenu = Object.assign(memo(SideMenuBase) as typeof SideMenuBase, {
  Actions: SideMenuActions,
  Group: SideMenuGroup,
  Item: SideMenuItem,
  Placeholder: SideMenuPlaceholder,
});
